import { CenteredCardLayout } from "../../components/CenteredCardLayout";
import { useEffect, useState } from "react";
import { DashboardService } from "../../services/DashboardService";
import { FormControl, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import "./TableCSS.css";
import { DashboardCardComponent } from "../../components/DashboardCardComponent";
import { AccountGroupService } from "../../services/AccountGroupService";
import { AccountGroupDTO } from "../../models/AccountGroupDTO";
import { SessionInstanceReportService } from "../../services/SessionInstanceReportService";
import { strings } from "../../localization/Localization";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import { PaginatedMultiSelect } from "../../components/PaginatedMultiSelect";
import { DateTimeUtils } from "../../utils/DateTimeUtils";
import { TableHeader } from "../../components/TableHeader";


export function DashboardTotalData() {
    const [totalNumberOfAccounts, setTotalNumberOfAccounts] = useState<number | undefined>();
    const [totalNumberOfRegisteredBots, setTotalNumberOfRegisteredBots] = useState<number | undefined>();
    const [totalNumberOfExecutedSessions, setTotalNumberOfExecutedSessions] = useState<number | undefined>();
    const [totalNumberOfProxies, setTotalNumberOfProxies] = useState<number | undefined>();
    const [totalTracksListened, setTotalTracksListened] = useState<number | undefined>();
    const [accountGroups, setAccountGroups] = useState<AccountGroupDTO[]>([]);
    const [selectedGroups, setSelectedAccountGroups] = useState<AccountGroupDTO[]>([]);
    const [date, setDate] = useState<string | null>(DateTimeUtils.formatDateDayJs(dayjs()));
    const columns = [
        { label: strings.name, sortBy: "name", sortable: false },
        { label: strings.numberOfListenedSongs, sortBy: "listenedTracks", sortable: false },
    ];
    const [songs, setSongs] = useState<any[]>([]);

    useEffect(() => {
        fetchAccountGroups()
            .then(groups => {
                setAccountGroups(groups);
            })
    }, []);

    useEffect(() => {
        getTotalCount();
    }, [totalNumberOfAccounts, totalNumberOfRegisteredBots, totalNumberOfExecutedSessions, totalNumberOfProxies])

    useEffect(() => {
        if (selectedGroups.length > 0) {
            getTotalListenedTracks(date)
                .then(response => {
                    if (response && response.data !== undefined) {
                        setTotalTracksListened(response.data);
                    }
                })
        }
    }, [selectedGroups, date]);

    useEffect(() => {
        if (selectedGroups.length > 0 && date) {
            getTotalListenedTracksByAccountGroup()
                .then(response => {
                    if (response !== undefined) {
                        setSongs(response);
                    }
                })
        }
    }, [selectedGroups, date]);

    function handleDateChanged(date: Dayjs | null) {
        setDate(DateTimeUtils.formatDateDayJs(date));
    }

    function getTotalCount() {
        DashboardService.totalCount(
            totalNumberOfAccounts,
            totalNumberOfRegisteredBots,
            totalNumberOfExecutedSessions,
            totalNumberOfProxies
        ).then((data) => {
            setTotalNumberOfAccounts(data?.accounts);
            setTotalNumberOfRegisteredBots(data?.registeredBots);
            setTotalNumberOfExecutedSessions(data?.successfullyExecutedSessionsInstances);
            setTotalNumberOfProxies(data?.proxies);
        });
    }

    const fetchAccountGroups = async () => {
        const page = 0;
        const size = 10;
        const accountGroupsResponse = await AccountGroupService.getAllAccountGroupsPaged(page, size);
        return accountGroupsResponse.content;
    };

    const getTotalListenedTracks = async (date: string | null) => {
        if (selectedGroups) {
            const accountGroupIds = selectedGroups
                .filter((group) => group !== undefined)
                .map((group) => group.id);
            return SessionInstanceReportService.totalListenedTracksPerDay(date, accountGroupIds);
        }
    };

    const getTotalListenedTracksByAccountGroup = async () => {
        if (selectedGroups) {
            const accountGroupIds = selectedGroups
                .filter((group) => group !== undefined)
                .map((group) => group.id);
            return SessionInstanceReportService.getTotalListenedTracksPerAccountGroups(date, accountGroupIds);
        }
    };

    return (
        <CenteredCardLayout
            maxWidth={"100%"}
            minWidth={"100%"}
            maxHeight={"auto"}
            style={{ padding: "20px" }}
        >
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <DashboardCardComponent
                    className={"mt-3"}
                    text={strings.totalNumberOfAccounts}
                    value={totalNumberOfAccounts}
                />
                <DashboardCardComponent
                    className={"mt-3"}
                    text={strings.totalNumberOfProxies}
                    value={totalNumberOfProxies}
                />
                <DashboardCardComponent
                    className={"mt-4"}
                    text={strings.totalNumberOfRegisteredBots}
                    value={totalNumberOfRegisteredBots}
                />
                <DashboardCardComponent
                    className={"mt-4"}
                    text={strings.totalNumberOfSuccessfullyExecutedSessions}
                    value={totalNumberOfExecutedSessions}
                />

                <DashboardCardComponent
                    className={"mt-4"}
                    text={selectedGroups.length !== 0 ? strings.totalStreamsOnSelectedDate : ""}
                    value={selectedGroups.length !== 0 ? totalTracksListened : "Please select at least one account group"}
                    xs={12}
                >
                    <Grid container spacing={2} sx={{ alignItems: "center", padding: "20px", justifyContent: "space-between" }}>
                        <Grid item xs={6}>
                            <FormControl
                                fullWidth
                                sx={{ textAlign: "left" }}
                                error={accountGroups.length === 0}
                            >
                                <PaginatedMultiSelect<AccountGroupDTO>
                                    label={strings.accountGroupsTitle}
                                    value={selectedGroups ? selectedGroups : []}
                                    valueMapper={item => item.id.toString()}
                                    keyMapper={item => item.id.toString()}
                                    itemMapper={item => <>{item.name}</>}
                                    labelMapper={item => item.name}
                                    dataFetcher={(page, size, filter) => AccountGroupService.getAllAccountGroupsPaged(page, size, filter)}
                                    onChange={(updatedItems: AccountGroupDTO[]) => {
                                        setSelectedAccountGroups(updatedItems);
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={5}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    onChange={handleDateChanged}
                                    value={date}
                                    label={strings.date}
                                    inputFormat="DD.MM.YYYY"
                                    renderInput={(params: any) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                        />
                                    )}
                                    componentsProps={{
                                        actionBar: {
                                            actions: ["today"],
                                        },
                                    }}
                                    InputAdornmentProps={{
                                        position: "start",
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                    {selectedGroups.length !== 0 &&
                        <Grid container spacing={2} sx={{ alignItems: "center", padding: "20px", justifyContent: "space-between" }}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                                    <TableHead>
                                        <TableHeader
                                            columns={columns}
                                            updateSort={() => { }}
                                            sortConfigurationString={undefined}
                                        />
                                    </TableHead>
                                    <TableBody>
                                        {songs && songs.length > 0 ? (
                                            songs.map((e: any) => (
                                                <TableRow key={e.id} hover={true} className={"cursor-pointer"}>
                                                    <TableCell sx={{ width: '50%' }}>{e.accountGroup.name}</TableCell>
                                                    <TableCell sx={{ width: '50%' }}>{e.listenedTracks}</TableCell>
                                                </TableRow>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell colSpan={2} align="center">No data available</TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>

                                </Table>
                            </TableContainer>
                        </Grid>
                    }
                </DashboardCardComponent>
            </Grid>
        </CenteredCardLayout>
    );
}
