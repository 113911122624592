import {CookieDTO} from "./CookieDTO";


export class CookieCollectionDTO{
    id : number;
    accountId : number;
    name : string;
    cookies  : CookieDTO[];

    constructor(json:any) {
        this.id = json.id;
        this.accountId = json.accountId;
        this.name = json.name;
        this.cookies = json.cookies;
    }
}