import {UserFilter} from "../models/UserFilter";
import {Page} from "../pagination/Page";
import {UserDTO} from "../models/UserDTO";
import axios from "../network/AxiosInstance";
import {Session} from "../models/Session";
import {AuthService} from "./AuthService";
import {RoleDTO} from "../models/RoleDTO";

export class RoleService {
    static async getAllRoles(): Promise<Array<RoleDTO>> {
        const response = await axios.get<Array<RoleDTO>>(`${AuthService.PREFIX}/userroles/role/all`);
        const data =  response.data;
        return data.map(role => new RoleDTO(role))
    }
}