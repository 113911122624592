import { CheckCircle, Clear } from "@mui/icons-material";
import { FormControl,IconButton, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import React, { useEffect, useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import { useParams, useSearchParams } from "react-router-dom";
import { CenteredCardLayout } from "../../components/CenteredCardLayout";
import { TableHeader } from "../../components/TableHeader";
import { strings } from "../../localization/Localization";
import { AgeGroupDTO } from "../../models/AgeGroupDTO";
import { RegionDTO } from "../../models/RegionDTO";
import { Session } from "../../models/Session";
import { SessionInstance } from "../../models/SessionInstance";
import { AgeGroupService } from "../../services/AgeGroupService";
import { RegionService } from "../../services/RegionService";
import { SessionInstanceService } from "../../services/SessionInstanceService";
import { SessionService } from "../../services/SessionService";
import { BooleanParser } from "../../utils/BooleanParser";
import { DateTimeUtils } from "../../utils/DateTimeUtils";
import { NumberParser } from "../../utils/NumberParser";
import { SortingConfiguration } from "../../utils/SortingUtils";
import { AddEditSession } from "./AddEditSession";
import { Dayjs } from "dayjs";
import { PageTable } from "../../components/PageTable";
import { EnumType } from "../../utils/EnumType";
export function SessionDetails() {
  const columns = [
    { label: strings.accountGroup, sortBy: "account.accountGroup.name", sortable: true },
    { label: strings.email, sortBy: "account.email", sortable: true },
    { label: strings.region, sortBy: "account.region.label", sortable: true },
    { label: strings.ageGroup, sortBy: "account.ageGroup.label", sortable: true },
    { label: strings.labelProxyName, sortBy: "proxy.name", sortable: true },
    { label: strings.dateCreated, sortBy: "dateCreated", sortable: true },
    { label: strings.completed, sortBy: "completed", sortable: false },
  ]
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [sessionInstances, setSessionInstances] = useState<SessionInstance[]>([]);
  const [totalElements, setTotalElements] = useState(0);
  const rowsPerPage = NumberParser.parseNumber(searchParams.get("rowsPerPage"), 5);
  const page = NumberParser.parseNumber(searchParams.get("page"), 0);
  const version = searchParams.get("version") ?? "";
  const [sessionHistory, setSessionHistory] = useState<Session[]>([]);
  const usesProxy = BooleanParser.parseBooleanNullable(searchParams.get("usesProxy"))
  const sessionKey = searchParams.get("sessionKey") ?? "";
  const accountGroupName = searchParams.get("accountGroupName") ?? "";
  const accountEmail = searchParams.get("accountEmail") ?? "";
  const accountRegionId = searchParams.get("accountRegionId") ?? "";
  const accountAgeGroupId = searchParams.get("accountAgeGroupId") ?? "";
  const proxyName = searchParams.get("proxyName") ?? "";
  const [regions, setRegions] = useState<RegionDTO[]>([]);
  const [ageGroups, setAgeGroups] = useState<AgeGroupDTO[]>([]);
  const dateCreated = searchParams.get("dateCreated") ?? null;
  const sortingConfigurationString = searchParams.get("sort");

  function setSearchParam(key: string, value: string | null | undefined) {
    if (value) {
      searchParams.set(key, value);
    } else {
      searchParams.delete(key);
    }
    setSearchParams(searchParams)
  }
  function updateSessionKey(key: string | undefined): void {
    setSearchParam("sessionKey", key)
  }
  function setPage(page: number) {
    setSearchParam("page", page.toString());
  }
  function updateSort(sortingConfiguration: SortingConfiguration): void {
    setSearchParam("sort", sortingConfiguration.toSearchString());
  }
  function handleChangePage(
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchParam("rowsPerPage", event.target.value);
    setPage(0);
  }
  function returnPercentage(item: SessionInstance) {
    let percentage: number = 0.0;
    if (item.finishedActions < item.totalActions) {
      percentage = parseFloat(((item.finishedActions / item.totalActions) * 100).toFixed(2));
    }
    return percentage;
  }
  function checkIsActionIsFinished(item: SessionInstance) {
    if (item.finishedActions < item.totalActions) {
      return false;
    } else if (item.finishedActions === 0 && item.totalActions === 0) {
      return false;
    } else {
      return true;
    }
  }
  function setUsesProxy(value: boolean | undefined): void {
    setSearchParam("usesProxy", value?.toString() ?? "");
    setPage(0);
  }

  function handleChangeRegionId(event: SelectChangeEvent) {
    setSearchParam("accountRegionId", event.target.value);
    setPage(0);
  }

  function handleChangeAgeGroup(event: SelectChangeEvent) {
    setSearchParam("accountAgeGroupId", event.target.value)
    setPage(0);
  }

  function handleChangeAccountGroup(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    setSearchParam("accountGroupName", event.target.value)
    setPage(0);
  }

  function handleChangeEmail(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchParam("accountEmail", event.target.value)
    setPage(0);
  }

  function handleChangeProxyName(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchParam("proxyName", event.target.value)
    setPage(0);
  }

  function handleChangeDateCreated(date: Dayjs | null) {
    setSearchParam("dateCreated", DateTimeUtils.formatDateDayJs(date));
    setPage(0);
  }

  function handleChangeVersion(event: SelectChangeEvent) {
    setSearchParam("version", event.target.value)
    setPage(0);
  }
  useEffect(() => {
    async function getAllSessionInstancesPaged(page: number): Promise<void> {
      const response = await SessionInstanceService.getAllSessionInstancesPaged(page, rowsPerPage, version, accountGroupName, accountEmail, accountRegionId, accountAgeGroupId, proxyName, sessionKey, undefined, undefined, usesProxy, dateCreated, SortingConfiguration.fromSearchString(sortingConfigurationString));
      setTotalElements(response.totalElements);
      setSessionInstances(response.content);
    }
    async function getVersionHistoryOfSession(): Promise<void> {
      const response = await SessionService.versionHistoryOfSession(id);
      setSessionHistory(response)
    }
    if (sessionKey) {
      getAllSessionInstancesPaged(page).then((_) => {
      });
    }
    if (id) {
      getVersionHistoryOfSession().then((_) => {
      });
    }
  }, [sessionKey, version, sortingConfigurationString, accountGroupName, accountEmail, accountAgeGroupId, accountRegionId, proxyName, dateCreated, usesProxy, page, rowsPerPage, id])
  useEffect(() => {
    async function getAllRegions(): Promise<void> {
      const regions = await RegionService.getRegions();
      setRegions(regions);
    }

    async function getAllAgeGroups(): Promise<void> {
      const ageGroups = await AgeGroupService.getAllAgeGroups();
      setAgeGroups(ageGroups);
    }

    async function loadData(): Promise<void> {
      await getAllRegions();
      await getAllAgeGroups();
    }

    loadData().then((_) => {
    });

  }, []);

  function getSessionHistoryLabel(index: number): string {
    let prefix = strings.latest;

    if (index > 0) {
      prefix = `V.${sessionHistory.length - index}`;
    }

    return `${prefix} (${DateTimeUtils.formatDate(sessionHistory[index].dateCreated)})`;
  }
  const searchRows = [
    {
      id: "accountGroup",
      label: strings.accountGroup,
      search: true,
      type: EnumType.string,
      variant:"standard",
      handleChange: handleChangeAccountGroup,
      value:accountGroupName,
    },
    {
      id: "email",
      label: strings.email,
      search: true,
      type: EnumType.string,
      handleChange: handleChangeEmail,
      variant: "standard",
      value:accountEmail,
    },
    {
      id: "region",
      label: strings.region,
      search: true,
      type: EnumType.dropdown,
      elements:regions,
      value:accountRegionId,
      handleChange: handleChangeRegionId,
      handleRemoveFilter:()=>{setSearchParam("accountRegionId", undefined);setPage(0)},
      variant: "standard",
    },
    {
      id: "ageGroup",
      label: strings.ageGroup,
      search: true,
      type: EnumType.dropdown,
      elements:ageGroups,
      value:accountAgeGroupId,
      handleRemoveFilter:()=>{setSearchParam("accountAgeGroupId", undefined);setPage(0)},
      handleChange: handleChangeAgeGroup,
      variant: "standard",
    },
    {
      id: "proxyName",
      label: strings.labelProxyName,
      search: true,
      type: EnumType.string,
      handleChange:handleChangeProxyName,
      variant: "standard",
      value:proxyName
    },
    {
      id: "dateCreated",
      label: strings.dateCreated,
      search: true,
      value:dateCreated,
      type: EnumType.date,
      handleChange:handleChangeDateCreated
    },
    {
        id: "useProxy",
        label: strings.useProxy,
        search: true,
        type: EnumType.boolean,
        handleChange:setUsesProxy,
        value:usesProxy
      },
  ]

  const rows = sessionInstances.map((element) => {
    return {
      id: element.id,
      values: [
        {
          type: "text",
          name: "accountGroup",
          value: element.account?.accountGroup?.name,
        },
        {
          type: "text",
          name: "email",
          value: element?.account?.email,
        },
        {
          type: "text",
          name: "region",
          value: element.account?.region?.label,
        },
        {
          type: "text",
          name: "ageGroup",
          value: element?.account?.ageGroup?.label,
        },
        {
          type: "text",
          name: "proxyName",
          value: element.proxy?.name,
        },
        {
          type: "text",
          name: "dateCreated",
          value: DateTimeUtils.formatDate(element?.dateCreated?.toString()),
        },
        {
            type: "text",
            name: "completed",
            value: checkIsActionIsFinished(element) ? (
                <CheckCircle
                    color="success"
                    sx={{fontSize: "65px", width: "65px"}}
                />
            ) : (
                <div style={{maxWidth: "65px"}}>
                    <CircularProgressbar
                        value={returnPercentage(element)}
                        text={`${returnPercentage(element)}%`}
                        strokeWidth={7}
                    />
                </div>
            )
          },
      ],
    };
  })

  return (
    <>
      <AddEditSession updateKey={updateSessionKey} />
      <>
        <h4 className="mb-3">{strings.sessionInstances}</h4>
        <CenteredCardLayout minWidth={"60%"} maxWidth={"60%"} className="pb-5">
          <FormControl style={{ minWidth: "200px" }} variant="standard" className="mb-3">
            <InputLabel>{strings.version}</InputLabel>
            <Select
              defaultValue={""}
              value={sessionHistory.length > 0 && version ? version : ""}
              onChange={handleChangeVersion}
              label={strings.version}
              endAdornment={
                version && (
                  <IconButton
                    onClick={() =>
                      {setSearchParam("version", undefined); setPage(0)}
                    }
                  >
                    <Clear />
                  </IconButton>
                )
              }
            >
              {sessionHistory.map((session, index) => (
                <MenuItem disableRipple key={session.id} value={session.id}>
                  {getSessionHistoryLabel(index)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <PageTable
                searchRows={searchRows}
                headerRows={<TableHeader columns={columns} sortConfigurationString={sortingConfigurationString} updateSort={updateSort} />}
                rows={rows}
                page={page}
                rowsPerPage={rowsPerPage}
                totalElements={totalElements}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </CenteredCardLayout>
      </>

    </>
  );
}
