export enum SortDirection {
    ASC = "ASC",
    DESC = "DESC"
}

export function parseSortDirection(value: string): SortDirection | undefined {
    const sortDirections = [SortDirection.ASC, SortDirection.DESC];

    for (const sortDirection of sortDirections) {
        if (sortDirection.toString() === value) {
            return sortDirection;
        }
    }

    return undefined;
}
