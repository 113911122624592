import { ServiceDTO } from "./ServiceDTO";
import { TargetPlaylistItemDTO } from "./TargetPlaylistItemDTO";

export class TargetPlaylistDTO {
    id: string;
    dateCreated: string;
    dateModified: string;
    service: ServiceDTO;
    key: string;
    name: string;
    description: string;
    weight: number;
    type: string;
    items: TargetPlaylistItemDTO[];

    constructor(json: any) {
        this.id = json.id;
        this.dateCreated = json.dateCreated;
        this.dateModified = json.dateModified;
        this.service = json.service;
        this.key = json.key;
        this.name = json.name;
        this.description = json.description;
        this.weight = json.weight;
        this.type = json.type;
        this.items = json.items;
    }
}
