import {SideBarPage} from "../../../components/SideBarPage";
import {
    Button,
    Checkbox, FormControl,
    Grid,
    Paper,
    Table, TableBody,
    TableCell, TableContainer, TablePagination,
    TableRow, TextField,
} from "@mui/material";
import TableHead from "@mui/material/TableHead";
import {strings} from "../../../localization/Localization";
import {Controller, useForm} from "react-hook-form";
import {PaginationPlus} from "../../../components/PaginationPlus";
import React, {useEffect, useState} from "react";
import {useParams, useSearchParams} from "react-router-dom";
import {ProxyService} from "../../../services/ProxyService";
import {NumberParser} from "../../../utils/NumberParser";
import {ProxyDTO} from "../../../models/ProxyDTO";
import {CenteredCardLayout} from "../../../components/CenteredCardLayout";
import {Routes} from "../../../router/Routes";
import {useNavigateWithParams} from "../../../router/NavigateWithParams";
import {ProxyGroupService} from "../../../services/ProxyGroupService";
import {showErrorDialog, showSuccessDialog} from "../../../common/Dialogs";
import {ErrorHandler} from "../../../utils/ErrorHandler";
import ConfirmationModal from "../../../components/ConfirmationModal";

export class ProxyGroupData {
    name: string
    proxyIds: ProxyDTO[]

    constructor(json: any) {
        this.name = json.name;
        this.proxyIds = json.proxyIds;
    }

    toProxyGroupCreationDTO(): ProxyGroupData {
        return new ProxyGroupData({
            name: this.name,
            proxyIds: this.proxyIds
            }
        )
    }
}

export function ProxyGroupDetails() {
    const listLinks = [
        { label: strings.dashboard, currentlyOpened: false, href: "/"},
        { label: strings.proxyGroups, currentlyOpened: false, href:"/proxies/proxy-groups"},
        { label: strings.proxyGroup,  currentlyOpened: true },
    ]
    const [name, setName] = useState<string>("");
    const [searchParams, setSearchParams] = useSearchParams();
    const rowsPerPage = NumberParser.parseNumber(searchParams.get("rowsPerPage"), 5);
    const [allProxies, setAllProxies] = useState<ProxyDTO[]>([]);
    const [totalElements, setTotalElements] = useState(5);
    const page = NumberParser.parseNumber(searchParams.get("page"), 0);
    const [checkedProxies, setCheckedProxies] = useState<any>([])
    const [existingProxies, setExistingProxies] = useState<any>([]);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const navigate = useNavigateWithParams();
    let { id } = useParams();
    const isAddMode = !id;

    function onSubmit() {
        isAddMode ? addProxyGroup() : editProxyGroup(id);
    }

    function setSearchParam(key: string, value: string | null | undefined) {
        if (value) {
            searchParams.set(key, value);
        } else {
            searchParams.delete(key);
        }

        setSearchParams(searchParams)
    }

    function setPage(page: number) {
        setSearchParam("page", page.toString());
    }

    function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("rowsPerPage", event.target.value);
        setPage(0);
    }

    function handleClickOpen(): any {
        setOpenModal(true);
    }

    function handleClose(): any {
        setOpenModal(false);
    }

    function handleCancel(): void {
        navigate(Routes.PROXY_GROUPS, {});
    }

    function buildProxyGroupDTO() {
        return new ProxyGroupData(getValues()).toProxyGroupCreationDTO();
    }

    function addProxyGroup() {
        const proxyGroupDetails: ProxyGroupData = buildProxyGroupDTO();

        const proxyGroup: any = {
            name: proxyGroupDetails.name,
            proxyIds: checkedProxies.map((proxy: any) => proxy.id)
        }

        ProxyGroupService.createProxyGroup(proxyGroup).then(() => {
            showSuccessDialog(
                strings.success,
                strings.proxyGroupCreated,
                strings.ok
            ).then((_) => {});
            navigate(Routes.PROXY_GROUPS);
        })
            .catch((error) => {
                const message = ErrorHandler.parseErrorMessage(error);
                showErrorDialog(strings.error, message, strings.ok).then((_) => { });
            });
    }

    function editProxyGroup(id: any) {
        const proxyCombinedIds = [...checkedProxies, ...existingProxies].map(item => item.id)
        const proxyGroupBuild = buildProxyGroupDTO();

        const proxyGroup: any = {
            name: proxyGroupBuild?.name,
            proxyIds: proxyCombinedIds
        }

        ProxyGroupService.editProxyGroup(id, proxyGroup)
            .then(() => {
                showSuccessDialog(
                    strings.success,
                    strings.proxyGroupUpdated,
                    strings.ok
                ).then((_) => {});
                navigate(Routes.PROXY_GROUPS);
            })
            .catch((error) => {
                const message = ErrorHandler.parseErrorMessage(error);
                showErrorDialog(strings.error, message, strings.ok).then((_) => { });
            });
    }

    async function deleteProxyGroup(): Promise<void> {
        setOpenModal(false);

        if (!id) {
            return;
        }

        try {
            await ProxyGroupService.deleteProxyGroup(+id);
            showSuccessDialog(strings.success, strings.proxyGroupDeleted, strings.ok).then(_ => {
                navigate(Routes.PROXY_GROUPS);
                window.location.reload();
            });
        } catch (e: any) {
            const errorMessage = ErrorHandler.parseErrorMessage(e);
            showErrorDialog(strings.error, errorMessage, strings.ok).then(_ => {});
        }
    }

    const {
        control,
        watch,
        getValues,
        setValue,
        formState: { errors },
    } = useForm<ProxyGroupData>({
        defaultValues: {
            name: "",
            proxyIds: []
        }
    });
    watch();

    useEffect(() => {
        async function fetchProxyGroup(): Promise<void> {
            async function getProxyGroupById(id: number): Promise<void> {
                const proxyGroup = await ProxyGroupService.getProxyGroupById(id);
                setValue("name", proxyGroup.name)
                setValue("proxyIds", proxyGroup?.proxies)
            }

            async function getAllOfGroup(id: number): Promise<void> {
                await ProxyService.getAllOfGroup(id).then((data) => {
                    setExistingProxies(data)
                });
            }

            if (id) {
                await getProxyGroupById(parseInt(id));
                await getAllOfGroup(parseInt(id));
            }
        }

        fetchProxyGroup().then((_) => { });

    }, [id, setValue, getValues])

    useEffect(() => {
        function getProxies(page: number) {
            ProxyService.getAllProxiesPaged2(page, rowsPerPage, name, null, undefined).then((data) => {
                setAllProxies(data.content);
                setTotalElements(data.totalElements);
            })
        }

        getProxies(page);

    }, [rowsPerPage, page, searchParams])

    return(
        <>
        <SideBarPage pageTitle={isAddMode ? strings.addProxyGroup : strings.editProxyGroup} breadcrumbs={listLinks} component={
            <CenteredCardLayout minWidth={"75%"} maxWidth={"100%"}>
                <Grid item xs={12} sm={4} md={6}>
                    <FormControl fullWidth>
                        <Controller
                            name="name"
                            rules={{
                                required: true,
                            }}
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    value={field.value ?? ""}
                                    className="mb-3"
                                    sx={{ width: "100%" }}
                                    onChange={field.onChange}
                                    name="name"
                                    label={strings.proxyGroupName}
                                />
                            )}
                        />
                    </FormControl>
                </Grid>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
                <TableContainer component={Paper} style={{width: "45%"}}>
                <Table sx={{minWidth: 200}} aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{strings.availableProxies}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{width: '40%'}}>{strings.proxyName}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {allProxies?.map((e: any) => (
                            <TableRow
                                hover={true}
                                key={e.id}
                            >
                                <TableCell>
                                    <Checkbox
                                        checked={checkedProxies.find((item: any) => item.id === e.id) !== undefined
                                            || existingProxies.some((acc: any) => acc.id === e.id)}
                                        disabled={existingProxies.some((acc: any) => acc.id === e.id)}
                                        sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
                                        onClick={() => {
                                            setCheckedProxies([...checkedProxies, {id: e.id, name: e.name}]);
                                            if (checkedProxies.some((acc: any) => acc.id === e.id)) {
                                                setCheckedProxies(checkedProxies.filter((acc: any) => acc.id !== e.id));
                                            }
                                        }}/>{e.name !== undefined && e.name}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Grid sx={{display: 'flex', flexDirection: 'column', alignItems: 'end', marginTop: "10px"}}>
                    <PaginationPlus totalElements={totalElements} rowsPerPage={rowsPerPage} page={page}
                                    onChangePage={(page) => handleChangePage(null, page)}/>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={totalElements}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}/>
                </Grid>
            </TableContainer>

                <TableContainer component={Paper} style={{width: "45%", overflowY: "scroll"}}
                                             sx={{maxHeight: 740}}>
                <Table sx={{minWidth: 200, maxHeight: 200}}>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{width: '40%'}}>{strings.name}</TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody style={{overflow: "scroll"}}>
                        {existingProxies.length !== 0 &&
                            <TableRow>
                                <TableCell><strong>{strings.proxiesInGroup}: </strong></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>}
                        {existingProxies.length !== 0 && existingProxies.map((ex: any) => (
                                <TableRow
                                    hover={true}
                                    key={ex.id}
                                >
                                    <TableCell sx={{fontSize: 13}}>
                                        {ex.name}
                                    </TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell><Button variant="contained" style={{padding: "1%"}} onClick={() => {
                                        setExistingProxies(
                                            existingProxies?.filter(
                                                (a: { id: number; }) => a.id !== ex.id
                                            )
                                        );
                                    }}>x</Button></TableCell>
                                </TableRow>
                            )
                        )}
                        {checkedProxies.length === 0 && existingProxies.length === 0 ? (
                                <TableRow>
                                    <TableCell><span style={{color: "gray"}}>{strings.emptyTableMessage}</span></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            )
                            :
                            checkedProxies?.map((e: any, index: number) => <TableRow
                                    hover={true}
                                    key={e.id}>
                                    <TableCell>
                                        {e.name !== undefined && e.name}
                                    </TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell><Button variant="contained" style={{padding: "1%"}} onClick={() => {
                                        setCheckedProxies(
                                            checkedProxies?.filter(
                                                (a: { id: number; }) => a.id !== e.id
                                            )
                                        );
                                    }}>x</Button></TableCell>
                                </TableRow>
                            )}
                    </TableBody>
                </Table>
            </TableContainer>
            </div>
                <div className="d-flex justify-content-evenly">
                    {" "}
                    {!isAddMode ?
                        <Button
                            style={{width:"40%"}}
                            className="mt-3"
                            variant={"contained"}
                            color={"error"}
                            onClick={handleClickOpen}
                        >
                            {strings.delete}
                        </Button>
                        :
                        <Button
                            variant="contained"
                            style={{width:"40%"}}
                            onClick={handleCancel}
                            color={"warning"}
                            className="mt-3"
                            fullWidth
                        >
                            {strings.cancel}
                        </Button>
                    }
                    <Button
                        type="submit"
                        onClick={() => {
                            onSubmit();
                        }}
                        fullWidth
                        style={{width:"40%"}}
                        variant="contained"
                        className="mt-3"
                    >
                        {isAddMode ? strings.addProxyGroup : strings.save}
                    </Button>
                </div>
            </CenteredCardLayout>
        }/>
    {
        !isAddMode ?
            <ConfirmationModal
                open={openModal}
                handleClose={handleClose}
                handleConfirm={deleteProxyGroup}
                title={strings.deleteProxyGroup}
                content={strings.deleteProxyGroupConfirm}
                actionButtonName={strings.delete}/> : null
    }
    </>
    )
}