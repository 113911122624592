import {UserData} from "./UserData";

export class UserCreationDTO{
    userData: UserData;
    roleIds: number [];
    enabled: boolean | undefined;
    constructor(json:any){
        this.userData = UserData.fromObject(json.userData);
        this.roleIds = json.roleIds
        this.enabled = json.enabled;
    }

}