import {
    Grid,
    Pagination,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from "@mui/material";
import {Box} from "@mui/system";
import React, {useEffect, useState} from "react";
import {strings} from "../../localization/Localization";
import {SessionInstance} from "../../models/SessionInstance";
import {SessionInstanceService} from "../../services/SessionInstanceService";
import {DateTimeUtils} from "../../utils/DateTimeUtils";
import {CircularProgressbar} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {Dayjs} from "dayjs";
import { LightTooltip } from "../../components/LightTooltip";
import "../dashboard/TableCSS.css";
import {SessionInstanceController} from "../../controllers/SessionInstanceController";
import {useNavigate} from "react-router-dom";
import {PaginationPlus} from "../../components/PaginationPlus";

export function DashboardSessionInstances() {
    const [sessionInstances, setSessionInstances] = useState<SessionInstance[]>([]);
    const [sessionName] = useState<string | undefined>(undefined);
    const [sessionKey] = useState<string | undefined>(undefined);
    const [sessionServiceId] = useState<number | undefined>(undefined);
    const [usesProxy] = useState<boolean | undefined>(undefined);
    const [sessionId] = useState<number | undefined>(undefined);
    const [accountGroupName] = useState<string | undefined>(undefined);
    const [accountEmail] = useState<string | undefined>(undefined);
    const [accountRegionId] = useState<number | undefined>(undefined);
    const [accountAgeGroupId] = useState<number | undefined>(undefined);
    const [proxyName] = useState<string | undefined>(undefined);
    const [dateCreated] = useState<string | null | Dayjs>(null);
    const [totalElements, setTotalElements] = useState(0);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage] = useState(4);

    const navigate = useNavigate();

    function handleChangePage(
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) {
        setPage(newPage);
    }

    function checkIsActionIsFinished(item: SessionInstance) {
        if (item.finishedActions < item.totalActions) {
            return false;
        } else if (item.finishedActions === 0 && item.totalActions === 0) {
            return false;
        } else {
            return true;
        }
    }

    function returnPercentage(item: SessionInstance) {
        let percentage: number = 0.0;
        if (item.finishedActions < item.totalActions) {
            percentage = parseFloat(((item.finishedActions / item.totalActions) * 100).toFixed(2));
        }
        return percentage;
    }

    useEffect(() => {
        async function getAllSessionInstancesPaged(page: number): Promise<void> {
            const response = await SessionInstanceService.getAllSessionInstancesPaged(page, rowsPerPage, sessionId, accountGroupName, accountEmail, accountRegionId, accountAgeGroupId, proxyName, sessionKey, sessionServiceId, sessionName, usesProxy, dateCreated, null);
            setTotalElements(response.totalElements);
            setSessionInstances(response.content);
            setPage(page);
        }

        getAllSessionInstancesPaged(page).then((_) => {
        });
    }, [
        accountEmail,
        proxyName,
        accountGroupName,
        sessionServiceId,
        sessionName,
        accountRegionId,
        usesProxy,
        sessionKey,
        dateCreated,
        accountAgeGroupId,
        sessionId,
        page,
        rowsPerPage
    ]);

    return (
                    <Box className={"tableCSS"}>
                        <div className={"m-3"}>
                            <div>{strings.sessionInstances}</div>
                            <TableContainer component={Paper}>
                                <Table
                                    sx={{ minWidth: 750 }}
                                    aria-label="custom pagination table"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                {strings.sessionId}
                                            </TableCell>
                                            <TableCell>{strings.sessionKey}</TableCell>
                                            <TableCell>{strings.sessionName}</TableCell>
                                            <TableCell>
                                                {strings.service}
                                            </TableCell>
                                            <TableCell>{strings.accountGroup}</TableCell>
                                            <TableCell>{strings.email}</TableCell>
                                            <TableCell>{strings.region}</TableCell>
                                            <TableCell>{strings.ageGroup}</TableCell>
                                            <TableCell>{strings.labelProxyName}</TableCell>
                                            <TableCell>{strings.dateCreated}</TableCell>
                                            <TableCell>{strings.completed}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {sessionInstances.map((sessionInstance) => (
                                            <TableRow
                                                key={sessionInstance.id}
                                                onClick={() => SessionInstanceController.navigateToSessionInstanceDetails(navigate, sessionInstance.id)}
                                                className="cursor-pointer"
                                                hover={true}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {sessionInstance.session?.id}
                                                </TableCell>
                                                <LightTooltip title={sessionInstance.session?.key}><TableCell className="truncate-table-cell">{sessionInstance.session?.key}</TableCell></LightTooltip >
                                                <LightTooltip title={sessionInstance.session?.name}><TableCell className="truncate-table-cell">{sessionInstance.session?.name}</TableCell></LightTooltip >
                                                <TableCell>
                                                    {sessionInstance.account?.accountGroup?.service.label}
                                                </TableCell>
                                                <LightTooltip title={sessionInstance.account?.accountGroup?.name}>
                                                    <TableCell className="truncate-table-cell">{sessionInstance.account?.accountGroup?.name}</TableCell>
                                                </LightTooltip >
                                                <LightTooltip title={sessionInstance?.account?.email}><TableCell width={"15%"} className="truncate-table-cell">{sessionInstance?.account?.email}</TableCell></LightTooltip >
                                                <TableCell>
                                                    {sessionInstance.account?.region?.label
                                                        ? sessionInstance.account?.region?.label
                                                        : "/"}
                                                </TableCell>
                                                <TableCell>
                                                    {sessionInstance.account?.ageGroup
                                                        ? sessionInstance.account.ageGroup.toString()
                                                        : "/"}
                                                </TableCell>
                                                <LightTooltip title={sessionInstance.proxy?.name.toString()}>
                                                    <TableCell className="truncate-table-cell">
                                                        {sessionInstance.proxy?.name
                                                            ? sessionInstance.proxy.name.toString()
                                                            : "/"}
                                                    </TableCell>
                                                </LightTooltip >
                                                <LightTooltip title={DateTimeUtils.formatDateObject(
                                                    sessionInstance.dateCreated)}>
                                                    <TableCell className="truncate-table-cell">
                                                        {DateTimeUtils.formatDateObject(
                                                            sessionInstance.dateCreated
                                                        )}
                                                    </TableCell>
                                                </LightTooltip >

                                                <TableCell>
                                                    {checkIsActionIsFinished(sessionInstance) ? (
                                                        <CheckCircleIcon
                                                            color="success"
                                                            sx={{fontSize: "65px", width: "65px"}}
                                                        />
                                                    ) : (
                                                        <div style={{maxWidth: "65px"}}>
                                                            <CircularProgressbar
                                                                value={returnPercentage(sessionInstance)}
                                                                text={`${returnPercentage(sessionInstance)}%`}
                                                                strokeWidth={7}
                                                            />
                                                        </div>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                        <Grid sx={{display: 'flex', flexDirection: 'column', alignItems: 'end', marginTop: "10px"}}>
                            <PaginationPlus totalElements={totalElements} rowsPerPage={rowsPerPage} page={page} onChangePage={(page) => handleChangePage(null, page)}/>
                            <TablePagination
                            rowsPerPageOptions={[]}
                            component="div"
                            count={totalElements}
                            rowsPerPage={4}
                            page={page}
                            onPageChange={handleChangePage}
                            sx={{
                                    ".MuiTablePagination-displayedRows": {
                                        marginTop: "15px"
                                    }
                            }}
                        />
                        </Grid>
                    </Box>
    );
}
