import { CookieCollectionDTO } from "./CookieCollectionDTO";

export class FingerprintDTO{
    id: number
    name: string
    accountId: number
    acceptLanguages: string;
    availHeight: number
    availWidth: number
    deviceMemory: number
    devicePixelRatio: number
    hardwareConcurrency: number
    height: number
    sysLocale: string
    timezoneName: string
    timezoneOffset: string
    userAgent: string
    webglParams: any
    width: number
    cookieCollection?: CookieCollectionDTO

    constructor(json: any) {
        this.id = json.id;
        this.name = json.name;
        this.accountId = json.accountId;
        this.acceptLanguages = json.acceptLanguages;
        this.availHeight = json.availHeight;
        this.availWidth = json.availWidth;
        this.deviceMemory = json.deviceMemory;
        this.devicePixelRatio = json.devicePixelRatio;
        this.hardwareConcurrency = json.hardwareConcurrency;
        this.height = json.height;
        this.sysLocale = json.sysLocale;
        this.timezoneName = json.timezoneName;
        this.timezoneOffset = json.timezoneOffset;
        this.userAgent = json.userAgent;
        this.webglParams = json.webglParams;
        this.width = json.width;
        this.cookieCollection = json.cookieCollection ? new CookieCollectionDTO(json.cookieCollection) : undefined;
    }
}