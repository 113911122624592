import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select, SelectChangeEvent
} from "@mui/material";
import {strings} from "../localization/Localization";
import IconButton from "@mui/material/IconButton";
import {Clear} from "@mui/icons-material";
import {PaginatedSelect} from "../components/PaginatedSelect";
import {AccountGroupDTO} from "../models/AccountGroupDTO";
import {AccountGroupService} from "../services/AccountGroupService";
import React, {useEffect, useState} from "react";
import {ServiceDTO} from "../models/ServiceDTO";
import {RegionDTO} from "../models/RegionDTO";
import {ServicesService} from "../services/ServicesService";
import {RegionService} from "../services/RegionService";
import {ProxyDTO} from "../models/ProxyDTO";
import {ProxyService} from "../services/ProxyService";
import {AccountService} from "../services/AccountService";
import {showErrorDialog, showSuccessDialog} from "../common/Dialogs";
import {ErrorHandler} from "../utils/ErrorHandler";

type VoidCallback = {
    (): void
};

type SetDialogOpenCallback = {
    (value: boolean): void
};

interface ImportAccountsForCreationDialogProps{
    dialogOpen: boolean;
    onImportSuccessful: VoidCallback;
    setDialogOpen: SetDialogOpenCallback;
}

export function ImportAccountsForCreationDialog(props: ImportAccountsForCreationDialogProps) {
    const [services, setServices] = useState<ServiceDTO[] | undefined>([]);
    const [regions, setRegions] = useState<RegionDTO[] | undefined>([]);
    const [selectedFile, setSelectedFile] = useState(undefined);
    const [accountGroupForImport, setAccountGroupForImport] = useState<AccountGroupDTO | undefined>(undefined);
    const [proxy, setProxy] = useState<ProxyDTO | undefined>(undefined);
    const [creationProxy, setCreationProxy] = useState<ProxyDTO | undefined>(undefined);
    const [serviceIdForImport, setServiceIdForImport] = useState<number | undefined>(undefined);
    const [regionIdForImport, setRegionIdForImport] = useState<number | undefined>(undefined);
    const [creationAccountGroup, setCreationAccountGroup] = useState<AccountGroupDTO | undefined>(undefined);

    useEffect(() => {
        async function loadServices(): Promise<void> {
            const services = await ServicesService.getAllServices();
            setServices(services);
        }

        async function loadRegions(): Promise<void> {
            const regions = await RegionService.getRegions();
            setRegions(regions);
        }

        async function loadData(): Promise<void> {
            await loadServices();
            await loadRegions();
        }

        loadData().then(_ => {});
    }, []);

    const handleFileSelect = (event: any) => {
        setSelectedFile(event.target.files[0])
    }

    function onServiceIdForImportChange(event: SelectChangeEvent): void {
        if (event.target.value) {
            setServiceIdForImport(+event.target.value);
        } else {
            setServiceIdForImport(undefined);
        }
    }

    function onRegionIdForImportChange(event: SelectChangeEvent): void {
        if (event.target.value) {
            setRegionIdForImport(+event.target.value);
        } else {
            setRegionIdForImport(undefined);
        }
    }

    function validateImportAccounts(): boolean {
        return serviceIdForImport !== undefined  && regionIdForImport !== undefined && selectedFile !== undefined;
    }

    async function importAccounts() {
        try {
            if (serviceIdForImport && regionIdForImport && selectedFile) {
                props.setDialogOpen(false);

                await AccountService.importForAccountCreation(accountGroupForImport?.id, serviceIdForImport, regionIdForImport, selectedFile, proxy?.id, creationProxy?.id, creationAccountGroup?.id);
                showSuccessDialog(strings.success, strings.accountsImportedSuccessfully, strings.ok).then(_ => {
                    props.onImportSuccessful();
                });
            }
        } catch (e) {
            const message = ErrorHandler.parseErrorMessage(e);
            showErrorDialog(strings.error, message, strings.ok).then(_ => {});
        }
    }

    function onClose(): void {
        props.setDialogOpen(false);
    }

    return (<Dialog open={props.dialogOpen} onClose={onClose}>
            <DialogTitle>{strings.importAccountsForCreation}</DialogTitle>
            <DialogContent>
                <input type="file" onChange={handleFileSelect} className="mb-3"/>
                <FormControl className={"w-100 mt-3 mb-1"}>
                    <InputLabel id="servicelabel">{strings.service}</InputLabel>
                    <Select
                        labelId="servicelabel"
                        id="servicelabel"
                        onChange={onServiceIdForImportChange}
                        label={strings.region}
                        value={serviceIdForImport?.toString() ?? ""}
                        inputProps={{shrink: serviceIdForImport}}
                        endAdornment={
                            serviceIdForImport && (<IconButton
                                    onClick={() => setServiceIdForImport(undefined)}
                                >
                                    <Clear/>
                                </IconButton>
                            )
                        }
                    >
                        {services?.map((s) => (
                            <MenuItem key={s.id} value={s.id.toString()}>
                                {s.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>


                <FormControl className={"w-100 mb-1"}>
                    <InputLabel id="regionLabel">{strings.region}</InputLabel>
                    <Select
                        labelId="region"
                        id="regionLabel"
                        onChange={onRegionIdForImportChange}
                        label={strings.region}
                        value={regionIdForImport?.toString() ?? ""}
                        inputProps={{shrink: regionIdForImport}}
                        endAdornment={
                            regionIdForImport && (<IconButton
                                    onClick={() => setRegionIdForImport(undefined)}
                                >
                                    <Clear/>
                                </IconButton>
                            )
                        }
                    >
                        {regions?.map((s) => (
                            <MenuItem key={s.id} value={s.id.toString()}>
                                {s.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl className={"w-100 mb-1"} fullWidth variant="standard">
                    <PaginatedSelect<AccountGroupDTO>
                        value={accountGroupForImport}
                        onChange={setAccountGroupForImport}
                        label={strings.accountGroup}
                        valueMapper={(item) => item.id.toString()}
                        keyMapper={(item) => item.id.toString()}
                        itemMapper={(item) => <>{item.name}</>}
                        labelMapper={(item) => item.name}
                        dataFetcher={(page, size, filter) => {
                            return AccountGroupService.getAllAccountGroupsPaged(page, size, filter, serviceIdForImport);
                        }}
                        inputProps={{
                            variant: undefined,
                        }}
                    />
                </FormControl>

                <FormControl className={"w-100 mb-1"} fullWidth variant="standard">
                    <PaginatedSelect<AccountGroupDTO>
                        value={creationAccountGroup}
                        onChange={setCreationAccountGroup}
                        label={strings.accountGroupAfterCreation}
                        valueMapper={(item) => item.id.toString()}
                        keyMapper={(item) => item.id.toString()}
                        itemMapper={(item) => <>{item.name}</>}
                        labelMapper={(item) => item.name}
                        dataFetcher={(page, size, filter) => {
                            return AccountGroupService.getAllAccountGroupsPaged(page, size, filter, serviceIdForImport);
                        }}
                        inputProps={{
                            variant: undefined,
                        }}
                    />
                </FormControl>

                <FormControl className={"w-100 mb-1"} fullWidth variant="standard">
                    <PaginatedSelect<ProxyDTO>
                        value={proxy}
                        onChange={setProxy}
                        label={strings.proxy}
                        valueMapper={(item) => item.id.toString()}
                        keyMapper={(item) => item.id.toString()}
                        itemMapper={(item) => <>{item.name}</>}
                        labelMapper={(item) => item.name}
                        dataFetcher={(page, size, filter) => {
                            return ProxyService.getAllProxiesPaged2(page, size, filter, null, regionIdForImport);
                        }}
                        inputProps={{
                            variant: undefined,
                        }}
                    />
                </FormControl>

                <FormControl className={"w-100 mb-1"} fullWidth variant="standard">
                    <PaginatedSelect<ProxyDTO>
                        value={creationProxy}
                        onChange={setCreationProxy}
                        label={strings.creationProxy}
                        valueMapper={(item) => item.id.toString()}
                        keyMapper={(item) => item.id.toString()}
                        itemMapper={(item) => <>{item.name}</>}
                        labelMapper={(item) => item.name}
                        dataFetcher={(page, size, filter) => {
                            return ProxyService.getAllProxiesPaged2(page, size, filter, null, regionIdForImport);
                        }}
                        inputProps={{
                            variant: undefined,
                        }}
                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button color={"error"} onClick={onClose}>{strings.cancel}</Button>
                <Button disabled={!validateImportAccounts()} onClick={importAccounts}>{strings.importAccounts}</Button>
            </DialogActions>
        </Dialog>
    );
}
