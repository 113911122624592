export class AccountTransferDTO {
    sourceAccountGroupId: number;
    targetAccountGroupId: number;
    proxyGroupId: number;
    maxAccountsPerProxy: number;
    maxTransferredAccounts: number;

    constructor(json: any) {
        this.sourceAccountGroupId = json.sourceAccountGroupId;
        this.targetAccountGroupId = json.targetAccountGroupId;
        this.proxyGroupId = json.proxyGroupId;
        this.maxAccountsPerProxy = json.maxAccountsPerProxy;
        this.maxTransferredAccounts = json.maxTransferredAccounts;
    }
}