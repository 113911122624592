export class ListenedTrackClientInfoDTO {
    serviceName:string;
    serviceId: number;
    trackName?:string | null;
    trackArtist?:string | null;
    totalListeningTimeInSeconds:number;
    totalViews:number | null;
    constructor(json:ListenedTrackClientInfoDTO){
        this.serviceName = json.serviceName;
        this.serviceId = json.serviceId;
        this.trackName = json.trackName;
        this.trackArtist = json.trackArtist;
        this.totalListeningTimeInSeconds = json.totalListeningTimeInSeconds;
        this.totalViews = json.totalViews;
    }

    trackEquals(other: ListenedTrackClientInfoDTO): boolean {
        return this.serviceId === other.serviceId && this.trackName === other.trackName && this.trackArtist === other.trackArtist;
    }
}