import {SideBarPage} from "../../components/SideBarPage";
import {strings} from "../../localization/Localization";
import {NumberParser} from "../../utils/NumberParser";
import {Link, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {MobileProxyStatusDTO} from "../../models/MobileProxyStatusDTO";
import {MobileProxiesService} from "../../services/MobileProxiesService";
import {SortingConfiguration} from "../../utils/SortingUtils";
import TableContainer from "@mui/material/TableContainer";
import {Grid, IconButton, Input, Paper, TablePagination, TextField} from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import {PaginationPlus} from "../../components/PaginationPlus";
import {defaultRowsPerPageOptions} from "../../common/Constants";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {DateTimeUtils} from "../../utils/DateTimeUtils";
import {TableHeader} from "../../components/TableHeader";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {Clear} from "@mui/icons-material";
import {Dayjs} from "dayjs";
import {CountryDTO} from "../../models/CountryDTO";
import {CountryService} from "../../services/CountryService";
import {PaginatedSelect} from "../../components/PaginatedSelect";
import {Routes} from "../../router/Routes";
import {guardedCallback, guardedObject} from "../../router/guards/GuardedCallback";
import {PrivilegeGuard, PrivilegeGuardMode} from "../../router/guards/PrivilegeGuard";
import {Privileges} from "../../models/nomenclatures/Privileges";

export function MobileProxies() {

    const listLinks = [
        { label: strings.dashboard, currentlyOpened: false, href:"/"},
        { label: strings.mobileProxies,  currentlyOpened: true },
    ]

    const columns = [
        {label: strings.serialNumber, sortBy: "mb.serialNumber", sortable: true},
        {label: strings.friendlyName, sortBy: "mb.friendlyName", sortable: true},
        {label: strings.server, sortBy: "serverName", sortable: true},
        {label: strings.country, sortBy: "c.commonName", sortable: true},
        {label: strings.proxyUrl, sortBy: "proxyUrl", sortable: true},
        {label: strings.rotateUrl, sortBy: "rotateUrl", sortable: true},
        {label: strings.date, sortBy: "dateModified", sortable: true},
        {label: strings.status, sortBy: "status", sortable: true},
    ];

    const [searchParams, setSearchParams] = useSearchParams();
    const sortingConfigurationString = searchParams.get("sort");
    const navigate = useNavigate();

    const page = NumberParser.parseNumber(searchParams.get("page"), 0);
    const [totalElements, setTotalElements] = useState(0);
    const rowsPerPage = NumberParser.parseNumber(searchParams.get("rowsPerPage"), 25);
    const serialNumber = searchParams.get("serialNumber") ?? "";
    const friendlyName = searchParams.get("friendlyName") ?? "";
    const server = searchParams.get("server") ?? "";
    const [country, setCountry] = useState<CountryDTO | undefined>(undefined);
    const proxyUrl = searchParams.get("proxyUrl") ?? "";
    const rotateUrl = searchParams.get("rotateUrl") ?? "";
    const date = searchParams.get("date") ?? null;
    const status = searchParams.get("status") ?? "";
    const [mobileProxies, setMobileProxies] = useState<MobileProxyStatusDTO[]>([])
    const location = useLocation();

    useEffect(() => {
        MobileProxiesService.getAllMobileProxies(page, rowsPerPage, serialNumber, friendlyName, server, country?.id,
            proxyUrl, rotateUrl, date, status, SortingConfiguration.fromSearchString(sortingConfigurationString)).then((data) => {
            setMobileProxies(data.content);
            setTotalElements(data.totalElements);
        })
    }, [page, rowsPerPage, serialNumber, friendlyName, server, country, proxyUrl, rotateUrl, date, status, sortingConfigurationString])

    function setSearchParam(key: string, value: string | null | undefined) {
        if (value) {
            searchParams.set(key, value);
        } else {
            searchParams.delete(key);
        }

        setSearchParams(searchParams)
    }

    function setPage(page: number) {
        setSearchParam("page", page.toString());
    }

    function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("rowsPerPage", event.target.value);
        setPage(0);
    }

    function handleSerialNumberChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("serialNumber", event.target.value);
        setPage(0);
    }

    function handleFriendlyNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("friendlyName", event.target.value);
        setPage(0);
    }

    function handleServerChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("server", event.target.value);
        setPage(0);
    }

    function handleCountryChange(event: CountryDTO | undefined) {
        setCountry(event);
        setPage(0);
    }

    function handleProxyUrlChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("proxyUrl", event.target.value);
        setPage(0);
    }

    function handleRotateUrlChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("rotateUrl", event.target.value);
        setPage(0);
    }

    function handleDateChanged(date: Dayjs | null) {
        setSearchParam("date", DateTimeUtils.formatDateDayJs(date));
        setPage(0);
    }

    function handleStatusChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("status", event.target.value);
        setPage(0);
    }

    function updateSort(sortingConfiguration: SortingConfiguration): void {
        setSearchParam("sort", sortingConfiguration.toSearchString());
    }

    function navigateWithNavigatedFrom(pathname: string) {
        navigate({
            pathname: pathname,
            search: `?navigatedFrom=${location.pathname}`
        });
    }

    function navigateMobilePhoneRow(id: number): any {
        navigateWithNavigatedFrom(Routes.MOBILE_PHONES_DETAILS.replace(":id", id.toString()));
    }

    function getRowLink(entry: MobileProxyStatusDTO): string {
        return guardedObject([
            new PrivilegeGuard(
                [Privileges.MANAGE_MOBILE_PHONES],
                PrivilegeGuardMode.hasAll
            )
        ], Routes.MOBILE_PHONES_DETAILS.replace(":id", entry.mobilePhone.id.toString())) ?? "";
    }

    return <SideBarPage
        pageTitle={strings.mobileProxies}
        breadcrumbs={listLinks}
        component={
        <Grid>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Input
                                    name="serialNumber"
                                    value={serialNumber}
                                    onChange={handleSerialNumberChange}
                                    placeholder={strings.serialNumberFilter}
                                    style={{width: "auto"}}
                                />
                            </TableCell>
                            <TableCell>
                                <Input
                                    name="friendlyName"
                                    value={friendlyName}
                                    onChange={handleFriendlyNameChange}
                                    placeholder={strings.friendlyNumberFilter}
                                    style={{width: "auto"}}
                                />
                            </TableCell>
                            <TableCell>
                                <Input
                                    name="server"
                                    value={server}
                                    onChange={handleServerChange}
                                    placeholder={strings.serverFilter}
                                    style={{width: "auto"}}
                                />
                            </TableCell>
                            <TableCell>
                                <PaginatedSelect<CountryDTO>
                                    value={country}
                                    onChange={handleCountryChange}
                                    label={strings.country}
                                    valueMapper={(item) => item.id.toString()}
                                    keyMapper={(item) => item.id.toString()}
                                    itemMapper={(item) => <>{item.commonName}</>}
                                    labelMapper={(item) => item.commonName}
                                    dataFetcher={(page, size, filter) => {
                                        return CountryService.findAllCountriesPaged(
                                            page,
                                            size,
                                            filter
                                        );
                                    }}/>
                            </TableCell>
                            <TableCell>
                                <Input
                                    name="proxyUrl"
                                    value={proxyUrl}
                                    onChange={handleProxyUrlChange}
                                    placeholder={strings.proxyUrl}
                                    style={{width: "auto"}}
                                />
                            </TableCell>
                            <TableCell>
                                <Input
                                    name="rotateUrl"
                                    value={rotateUrl}
                                    onChange={handleRotateUrlChange}
                                    placeholder={strings.rotateUrl}
                                    style={{width: "auto"}}
                                />
                            </TableCell>
                            <TableCell width={"20%"}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        onChange={handleDateChanged}
                                        value={date}
                                        label={strings.dateModified}
                                        inputFormat="DD.MM.YYYY"
                                        renderInput={(params: any) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                onKeyDown={(e)=>e.preventDefault()}
                                            />
                                        )}
                                        componentsProps={{
                                            actionBar: {
                                                actions: ["today"],
                                            },
                                        }}
                                        InputProps={{
                                            endAdornment: date && (
                                                <IconButton sx={{height: "10px", width: "10px"}}
                                                            onClick={() => {
                                                                handleDateChanged(null);
                                                            }}>
                                                    <Clear/>
                                                </IconButton>
                                            ),
                                        }}
                                        InputAdornmentProps={{
                                            position: "start",
                                        }}
                                    />
                                </LocalizationProvider>
                            </TableCell>
                            <TableCell width={"20%"}>
                                <Input
                                    name="status"
                                    value={status}
                                    onChange={handleStatusChange}
                                    placeholder={strings.statusFilter}
                                    style={{width: "auto"}}
                                />
                            </TableCell>
                        </TableRow>
                        <TableHeader columns={columns} sortConfigurationString={sortingConfigurationString}
                                     updateSort={updateSort}></TableHeader>
                    </TableHead>
                    <TableBody>
                        {mobileProxies?.map((mobileProxy) => (
                            <TableRow key={mobileProxy.mobilePhone.id}
                                      component={getRowLink(mobileProxy) ? Link : TableRow} to={getRowLink(mobileProxy)}
                                      hover
                                      className="cursor-pointer"
                                      onClick={guardedCallback([
                                          new PrivilegeGuard(
                                              [Privileges.MANAGE_MOBILE_PHONES],
                                              PrivilegeGuardMode.hasAll
                                          )
                                      ],() => navigateMobilePhoneRow(mobileProxy.mobilePhone.id))}>
                                <TableCell>{mobileProxy?.mobilePhone?.serialNumber}</TableCell>
                                <TableCell>{mobileProxy?.mobilePhone?.friendlyName}</TableCell>
                                <TableCell>{mobileProxy?.serverName}</TableCell>
                                <TableCell>{mobileProxy?.countryDTO?.commonName}</TableCell>
                                <TableCell>{mobileProxy?.proxyUrl}</TableCell>
                                <TableCell>{mobileProxy?.rotateUrl}</TableCell>
                                <TableCell>{DateTimeUtils.formatDate(mobileProxy?.dateModified)}</TableCell>
                                <TableCell>{mobileProxy.status}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid sx={{display: 'flex', flexDirection: 'column', alignItems: 'end', marginTop: "10px"}}>
            <PaginationPlus totalElements={totalElements} rowsPerPage={rowsPerPage} page={page} onChangePage={(page) => handleChangePage(null, page)}/>
            <TablePagination
            rowsPerPageOptions={defaultRowsPerPageOptions}
            component="div"
            count={totalElements}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            />
            </Grid>
        </Grid>

        }/>
}
