import {HeatMapGrid} from "react-grid-heatmap";
import React, {useEffect, useState} from "react";
import {DateTimeUtils} from "../../utils/DateTimeUtils";
import {ScheduledInterval} from "../../models/ScheduledInterval";
import {AccountGroupService} from "../../services/AccountGroupService";
import {BotConditionService} from "../../services/BotConditionService";
import {Session} from "../../models/Session";
import {TableViewWithoutPagination} from "../../components/TableViewWithoutPagination";
import {strings} from "../../localization/Localization";
import Typography from "@mui/material/Typography";

export function DashboardHeatMap() {
    const [scheduledIntervals, setScheduledIntervals] = useState<ScheduledInterval[]>([])
    const [numberOfNotDeadBots, setNumberOfNotDeadBots] = useState<number>(0);
    const [sessionRows, setSessionRows] = useState<any>();
    const xLabels = scheduledIntervals.map(i=>`${DateTimeUtils.convertSecToHours(i.startSecondOfDay) + "\r\n - \r\n" + DateTimeUtils.convertSecToHours(i.endSecondOfDay)}`)
    const yLabels = ['']

    function ConstructRowsAndShowTable(sessions:Session[]){
        const rows = sessions.map((element) => {
            return {
                id: element?.id,
                values: [
                    {
                        name: "sessionName",
                        value: element?.name
                    },
                    {
                        name: "accountGroupName",
                        value: element?.accountGroup?.name,
                    },
                    {
                        name: "numberOfSchedules",
                        value: element?.accountGroup?.schedules?.length,
                    },
                    {
                        name: "numberOfAccounts",
                        value: element?.accountGroup?.accountIds?.length,
                    }
                ]}
        })
        setSessionRows(rows)
    }

    const data = new Array(1)
        .fill(0)
        .map(() =>
            new Array(xLabels.length).fill(0).map(() => Math.floor(Math.random() * 50 + 50))
        )

    useEffect(() => {

        function getScheduledIntervals() {
            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            AccountGroupService.getScheduledIntervals(timeZone).then((data) => {
                setScheduledIntervals(data)
            })
        }

        function getConditionCount() {
            BotConditionService.botCondition().then((data) => {
                setNumberOfNotDeadBots(data?.alive! + data?.idle!)
            })
        }

        getScheduledIntervals();
        getConditionCount();
    }, []);

    return (
        <div className = "heatMapContainerDashboard">
            <Typography textAlign={"center"} fontSize={"25px"}>{strings.schedule}</Typography>
            <div>
                <HeatMapGrid
                    data={data}
                    xLabels={xLabels}
                    yLabels={yLabels}
                    cellRender={(x, y, value) => (
                        <div title={`Pos(${x}, ${y}) = ${scheduledIntervals[y].scheduledAccounts}`}>{scheduledIntervals[y].scheduledAccounts}</div>
                    )}
                    cellStyle={(x, y, ratio) => ({
                        background: `rgb(255, 0, 0, ${numberOfNotDeadBots === 0 ? 1 : scheduledIntervals[y].scheduledAccounts/numberOfNotDeadBots  > 1 ? 1 : scheduledIntervals[y].scheduledAccounts/numberOfNotDeadBots})`,
                        fontSize: '.8rem',
                        color: `rgb(0, 0, 0, ${scheduledIntervals[y].scheduledAccounts / 2 + 0.4})`
                    })}
                    onClick={(x, y) => ConstructRowsAndShowTable(scheduledIntervals[y].sessions)}/>
            </div>
            <br/>
            <TableViewWithoutPagination columnNames={[strings.sessionName, strings.accountGroupName, strings.numberOfSchedules, strings.numberOfAccounts]} rows={sessionRows} emptyRowsMessage={strings.scheduledIntervalNoScenarios}/>
        </div>
    )
}
