import {Breadcrumbs, Link, Typography} from "@mui/material";
import {BreadcrumbsModel} from "../models/BreadcrumbsModel";

type Props = {
    listLinks?: BreadcrumbsModel[];
};

export function BreadcrumbsComponent({listLinks}: Props) {
    return (
        listLinks !== null && listLinks !== undefined && listLinks.length > 0 ? (
            <div className="pb-3">
                <Breadcrumbs aria-label="breadcrumb">
                    {listLinks?.map((link, index) => (
                        !link.currentlyOpened ?
                            <Link underline="hover" color="inherit" href={link.href} key={index}>
                                {link.label}
                            </Link>
                            :
                            <Typography key={index} color="text.primary">{link.label}</Typography>
                    ))}
                </Breadcrumbs>
            </div>
        ) : <></>
    )
}
