import { Badge, Checkbox, IconButton, TableCell, TableRow } from "@mui/material";
import React, { ChangeEvent, useCallback, useState } from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { SortDirection } from "../utils/SortDirection";
import SortIcon from "@mui/icons-material/Sort";
import { SortingConfiguration } from "../utils/SortingUtils";

interface Header {
    label: string;
    sortBy: string;
    sortable: boolean;
    checkbox?: boolean;
}

type Props = {
    columns: Header[];
    updateSort: (sortConfiguration: SortingConfiguration) => void;
    sortConfigurationString: string | null | undefined;
    selectAll?: boolean;
    onSelectAll?: (checked: boolean) => void;
};

export function TableHeader({
                                columns,
                                updateSort,
                                sortConfigurationString,
                                selectAll,
                                onSelectAll,
                            }: Props) {
    const sortConfiguration = SortingConfiguration.fromSearchString(sortConfigurationString);

    const sortingBy = useCallback(
        (propertyName: string) => {
            sortConfiguration.toggleSort(propertyName);
            updateSort(sortConfiguration);
        },
        [sortConfiguration, updateSort]
    );

    const getSortDirection = (property: string) => {
        const config = sortConfiguration.getItem(property);

        return config ? (
            <Badge badgeContent={sortConfiguration.indexOf(property) +  1} color="primary">
                {config.sortDirection === SortDirection.DESC ? (
                    <ExpandMoreIcon />
                ) : (
                    <ExpandLessIcon />
                )}
            </Badge>
        ) : (
            <SortIcon />
        );
    };

    return (
        <TableRow>
            {columns.map(({ label, sortBy, sortable, checkbox }, index) => {
                return (
                    <TableCell key={sortBy} style={{ whiteSpace: "nowrap" }}>
                        {label}
                        {checkbox ? (
                            <Checkbox
                                indeterminate={selectAll === null}
                                checked={selectAll ?? false}
                                onChange={(event) => onSelectAll && onSelectAll(event.target.checked)}
                            />
                        ) : (
                            sortable && (
                                <IconButton onClick={() => sortingBy(sortBy)}>
                                    {getSortDirection(sortBy)}
                                </IconButton>
                            )
                        )}
                    </TableCell>
                );
            })}
        </TableRow>
    );
}