import {styled} from "@mui/material/styles";
import {Tooltip, tooltipClasses} from "@mui/material";
import React from "react";

interface TooltipProps {
    title: string | undefined | any,
    children?: React.ReactNode | any,
    className?: string,
    followCursor?: boolean,
    arrow?: boolean
}

export function LightTooltip({title, children} : TooltipProps) {

    const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} children={children}/>
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }));

    return (
        <LightTooltip title={title} followCursor={true} arrow={true}>
            {children}
        </LightTooltip>
    )
}