import axiosInstance from "../network/AxiosInstance";
import {AuthService} from "./AuthService";
import {FingerprintDTO} from "../models/FingerprintDTO";
import {Page} from "../pagination/Page";

export class FingerprintService{
    static async importFingerprint(accountId: number, file: any) {
        let formData = new FormData();
        formData.append("file", file)

        return await axiosInstance.post(`${AuthService.PREFIX}/bot-session/fingerprint/import/${accountId}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
    }

    static async exportFingerprint(fingerprintId: number | undefined): Promise<any>{
        const response = await axiosInstance.get(
            `${AuthService.PREFIX}/bot-session/fingerprint/export/${fingerprintId}`);
        return response.data
    }

    static async getAllFingerprintsPage(page: number, size: number, accountId: number | undefined): Promise<Page<FingerprintDTO>> {
        const response = await axiosInstance.get<Page<FingerprintDTO>>(
            `${AuthService.PREFIX}/bot-session/fingerprint/all-paged/${accountId}`, {
                params: {
                    page,
                    size,
                }
            },
        );
        const data = response.data;

        return new Page<FingerprintDTO>(
            data.content.map((cookies) => new FingerprintDTO(cookies)),
            data.totalElements,
        );
    }
}