import {AccountDetailsComponent} from "../Accounts/AccountDetailsComponent";
import {strings} from "../../localization/Localization";
import {SideBarPage} from "../../components/SideBarPage";
import {Routes} from "../../router/Routes";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {SessionInstancesCard} from "../../components/SessionInstancesCard";
import {AccountService} from "../../services/AccountService";
import {AccountDetailedStatusDTO} from "../../models/AccountDetailedStatusDTO";
import {NumberParser} from "../../utils/NumberParser";


export function ProblematicAccountDetails() {
    const listLinks = [
        { label: strings.dashboard, currentlyOpened: false, href:Routes.HOME},
        { label: strings.problematicAccounts,  currentlyOpened: false, href:Routes.PROBLEMATIC_ACCOUNTS },
        { label: strings.accountDetails, currentlyOpened: true},
    ]
    const [accountDetailedStatus, setAccountDetailedStatus] = useState<AccountDetailedStatusDTO | null>(null)
    let { id } = useParams();

    useEffect(() => {
        async function getAccountDetailedStatus(id: number): Promise<void> {
            const accountDetailStatus = await AccountService.getAccountDetailedStatus(id);
            setAccountDetailedStatus(accountDetailStatus)
        }
        async function loadData(): Promise<void> {
            if (id) {
                await getAccountDetailedStatus(parseInt(id));
            }
        }
        loadData().then(_ => { });
    }, [id])

    return (
        <>
        <SideBarPage pageTitle={strings.editAccount}
                     breadcrumbs={listLinks}
                     component={
        <> <AccountDetailsComponent
            id={NumberParser.parseNumberNullable(id)}
        />
            {accountDetailedStatus &&
                <div>
                    <SessionInstancesCard title={strings.lastSessionInstance}
                                          sessionInstanceReduced={accountDetailedStatus.lastSessionInstance}/>

                    <SessionInstancesCard title={strings.lastFailedSessionInstance}
                                          sessionInstanceReduced={accountDetailedStatus.lastFailedSessionInstance}/>

                    <SessionInstancesCard title={strings.lastSessionInstanceWithoutProfile}
                                          sessionInstanceReduced={accountDetailedStatus.lastSessionInstanceWithoutProfile}/>

                    <SessionInstancesCard title={strings.lastSessionInstanceWithProfile}
                                          sessionInstanceReduced={accountDetailedStatus.lastSessionInstanceWithProfile}/>

                    <SessionInstancesCard title={strings.lastSuccessfulSessionInstance}
                                          sessionInstanceReduced={accountDetailedStatus.lastSuccessfulSessionInstance}/>
                </div>
            }
        </>
                     }
        ></SideBarPage>
        </>
    )
}
