import {NavigateFunction, NavigateOptions, To, useNavigate, createSearchParams} from "react-router-dom";
import React from "react";

export interface NavigateWithParamsFunction {
    (path: string, params?: Record<string, any>): void;
}

export function useNavigateWithParams(): NavigateWithParamsFunction {
    const navigate = useNavigate();

    const navigateWithParams = React.useCallback<NavigateWithParamsFunction>(
        (path: string, params?: Record<string, any>, options: NavigateOptions = {}) => {
            let search: string = "";

            if (params) {
                search = createSearchParams(params).toString();
            }

            navigate({
                pathname: path,
                search: search,
            }, options);
        }, [navigate],
    );

    return navigateWithParams;
}
