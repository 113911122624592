import {parseSortDirection, SortDirection} from "./SortDirection";

export class SortingConfiguration {
    items: SortingConfigurationItem[];

    constructor(
        items: SortingConfigurationItem[],
    ) {
        this.items = items;
    }

    toggleSort(sortProperty: string): void {
        const index = this.items.findIndex(
            (config) => config.sortProperty === sortProperty,
        );

        if (index > -1) {
            const currentSortType = this.items[index].sortDirection;
            if (currentSortType === SortDirection.DESC) {
                this.items.splice(index, 1);
            } else {
                this.items[index].sortDirection = SortDirection.DESC;
            }
        } else {
            this.items.push(new SortingConfigurationItem(
                sortProperty,
                SortDirection.ASC,
            ));
        }
    }

    getItem(sortProperty: string): SortingConfigurationItem | undefined {
        return this.items.find(
            (sortConfig) => sortConfig.sortProperty === sortProperty,
        );
    }

    indexOf(sortProperty: string): number {
        return this.items.findIndex(item => item.sortProperty === sortProperty);
    }

    static fromSearchString(searchString: string | undefined | null): SortingConfiguration {
        if (!searchString) {
            return new SortingConfiguration([]);
        }

        const sortItems: string[] = searchString.split("|");
        const items: SortingConfigurationItem[] = [];

        for(const sortItemString of sortItems) {
            const item = SortingConfigurationItem.fromSearchString(sortItemString);

            if (item) {
                items.push(item);
            }
        }

        return new SortingConfiguration(items);
    }

    toSearchString(): string {
        return this.items.map(item => item.toSearchString()).join("|");
    }

    appendToBackEndSearchParams(params: URLSearchParams): void {
        for (const item of this.items) {
            params.append("sort", item.toBackEndSearchString());
        }
    }
}

export class SortingConfigurationItem {
    sortProperty: string;
    sortDirection: SortDirection;

    constructor(
        sortProperty: string,
        sortDirection: SortDirection,
    ) {
        this.sortProperty = sortProperty;
        this.sortDirection = sortDirection;
    }

    static fromSearchString(searchString: string | undefined | null): SortingConfigurationItem | null {
        if (!searchString) {
            return null;
        }

        const propertyAndDirection: string[] = searchString.split(",");

        if (propertyAndDirection.length !== 2) {
            return null;
        }

        const property = propertyAndDirection[0];

        if (!property) {
            return null;
        }

        const direction: SortDirection | undefined = parseSortDirection(propertyAndDirection[1]);

        if (!direction) {
            return null;
        }

        return new SortingConfigurationItem(
            property,
            direction,
        );
    }

    toSearchString(): string {
        return `${this.sortProperty},${this.sortDirection}`;
    }

    toBackEndSearchString(): string {
        return `${this.sortProperty},${this.sortDirection}`;
    }
}
