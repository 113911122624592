import React, {useEffect, useState} from "react";
import {strings} from "../../localization/Localization";
import {useSearchParams} from "react-router-dom";
import {AccountGroupDTO} from "../../models/AccountGroupDTO";
import {AccountReportResponseDTO} from "../../models/AccountReportResponseDTO";
import {RegionDTO} from "../../models/RegionDTO";
import {ProxyGroupReducedDTO} from "../../models/ProxyGroupReducedDTO";
import {SortingConfiguration} from "../../utils/SortingUtils";
import {Dayjs} from "dayjs";
import {DateTimeUtils} from "../../utils/DateTimeUtils";
import {SessionInstanceReportService} from "../../services/SessionInstanceReportService";
import {AccountService} from "../../services/AccountService";
import {AccountDeletionDTO} from "../../models/AccountDeletionDTO";
import {showErrorDialog, showSuccessDialog} from "../../common/Dialogs";
import {ErrorHandler} from "../../utils/ErrorHandler";
import {AccountSwitchDTO} from "../../models/AccountSwitchDTO";
import {AccountTransferDTO} from "../../models/AccountTransferDTO";
import {SideBarPage} from "../../components/SideBarPage";
import {FullPageLoadingCircle} from "../../components/FullPageLoadingCircle";
import TableContainer from "@mui/material/TableContainer";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    Paper,
    TableCell
} from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {PaginatedSelect} from "../../components/PaginatedSelect";
import {AccountGroupService} from "../../services/AccountGroupService";
import {NullableDatePicker} from "../../components/NullableDatePicker";
import {RegionService} from "../../services/RegionService";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import DeleteIcon from "@mui/icons-material/Delete";
import {TableHeader} from "../../components/TableHeader";
import TableBody from "@mui/material/TableBody";
import ConfirmationModal from "../../components/ConfirmationModal";
import {ProxyGroupService} from "../../services/ProxyGroupService";

interface reportsProps {
    isTotalExecutedSessionInstances?: boolean
    isAccountHasAtLeastOneListenedTrack?: boolean
    isAccountsWithLastSuccessfulSession?: boolean
    isAccountsWithNoListenedTrack?: boolean
    isAccountsThatSwitchedAccountGroup?: boolean
}

export function ReportsComponent({isTotalExecutedSessionInstances, isAccountHasAtLeastOneListenedTrack, isAccountsWithLastSuccessfulSession,
                                 isAccountsWithNoListenedTrack, isAccountsThatSwitchedAccountGroup}: reportsProps) {
    const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
    const [executedSessionInstances, setExecutedSessionInstances] = useState<number | null>(null);

    const columns = [
        {label: strings.email, sortBy: "email", sortable: false},
        {label: strings.service, sortBy: "service", sortable: false},
        {label: strings.region, sortBy: "region", sortable: false},
        {label: isTotalExecutedSessionInstances ? (strings.totalExecutedSessionInstances + ": " + executedSessionInstances) : "", sortBy: "totalExecutedBotSessions", sortable: false},
        {label: "", sortBy: "empty", sortable: false, checkbox: true},
        {label: strings.selectedAccounts + selectedRows.size, sortBy: "selectedAccounts", sortable: false}
    ];
    const listLinks = [
        { label: strings.dashboard, currentlyOpened: false, href:"/"},
        { label: strings.reports,  currentlyOpened: true },
    ]
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [accountGroup, setAccountGroup] = useState<AccountGroupDTO | undefined>(undefined)
    const [accounts, setAccounts] = useState<AccountReportResponseDTO[]>([]);
    const [region, setRegion] = useState<RegionDTO>();
    const date = searchParams.get("date") ?? null;
    const sortingConfigurationString = searchParams.get("sort");
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [switchGroupPopupOpen, setSwitchGroupPopupOpen] = useState<boolean>(false);
    const [transferGroupPopupOpen, setTransferGroupPopupOpen] = useState<boolean>(false);
    const [selectedAccountGroupInModal, setSelectedAccountGroupInModal] = useState<AccountGroupDTO | undefined>(undefined);
    const [selectedProxyGroupInModal, setSelectedProxyGroupInModal] = useState<ProxyGroupReducedDTO | undefined>(undefined);
    const numSelectedAccounts = selectedRows.size;
    const confirmationContent = `Are you sure you want to delete ${numSelectedAccounts} selected accounts?`;

    const handleSelectAllWrapper = (checked: boolean) => {
        const allAccountIds = new Set(accounts.map(account => account.id));

        if (checked) {
            // Select all rows
            const newSelectedRows = new Set([
                ...selectedRows,
                ...allAccountIds,
            ]);

            setSelectedRows(newSelectedRows);
        } else {
            // Deselect all rows
            setSelectedRows(new Set([...selectedRows].filter(id => !allAccountIds.has(id))));
        }
    };

    const handleRowCheckboxClick = (isChecked: boolean, accountId: number) => {
        let newSelectedRows = new Set(selectedRows);
        if (isChecked) {
            newSelectedRows.add(accountId);
        } else {
            newSelectedRows.delete(accountId);
        }
        setSelectedRows(newSelectedRows);
    };
    function setSearchParam(key: string, value: string | null | undefined) {
        if (value) {
            searchParams.set(key, value);
        } else {
            searchParams.delete(key);
        }

        setSearchParams(searchParams)
    }
    function updateSort(sortingConfiguration: SortingConfiguration): void {
        setSearchParam("sort", sortingConfiguration.toSearchString());
    }
    const handleAccountGroupFilter = (event: AccountGroupDTO | undefined) => {
        setAccountGroup(event);
        setExecutedSessionInstances(null)
    }

    const handleRegionFilter = (event: RegionDTO | undefined) => {
        setRegion(event);
    }

    function setPage(page: number) {
        setSearchParam("page", page.toString());
    }

    function handleDateChange(date: Dayjs | null) {
        setSearchParam("date",DateTimeUtils.formatDateDayJs(date));
        setPage(0);
    }

    const openConfirmationModal = () => {
        setConfirmationModalOpen(true);
    };

    const closeConfirmationModal = () => {
        setConfirmationModalOpen(false);
    };

    const openSwitchGroupPopup = () => {
        setSwitchGroupPopupOpen(true);
    };

    const closeSwitchGroupPopup = () => {
        setSwitchGroupPopupOpen(false);
        setSelectedAccountGroupInModal(undefined)
    };

    const openTransferGroupPopup = () => {
        setTransferGroupPopupOpen(true);
    };

    const closeTransferGroupPopup = () => {
        setTransferGroupPopupOpen(false);
        setSelectedAccountGroupInModal(undefined)
        setSelectedProxyGroupInModal(undefined)
    }
    useEffect(() => {
            async function getTotalExecutedSessionInstances(): Promise<void> {
                if(accountGroup !== undefined && date) {
                    setIsLoading(true)
                    if(isTotalExecutedSessionInstances){
                        SessionInstanceReportService.getTotalExecutedSessionInstances(date, [accountGroup.id], region?.id).then((res) => {
                            setAccounts(res.accounts)
                            setExecutedSessionInstances(res.executedSessionInstances)
                        }).finally(() => {setIsLoading(false)})
                    } else if(isAccountHasAtLeastOneListenedTrack){
                        SessionInstanceReportService.getAccountsWithAtLeastOneListenedTrack(date, [accountGroup.id], region?.id).then((res) => {
                            setAccounts(res)
                        }).finally(() => {setIsLoading(false)})
                    } else if(isAccountsWithLastSuccessfulSession){
                        SessionInstanceReportService.getAccountsWithLastSuccessfulSession(date, [accountGroup.id], region?.id).then((res) => {
                            setAccounts(res)
                        }).finally(() => {setIsLoading(false)})
                    } else if(isAccountsWithNoListenedTrack){
                        SessionInstanceReportService.getAccountsWithNoListenedTracks(date, [accountGroup.id], region?.id).then((res) => {
                            setAccounts(res)
                        }).finally(() => {setIsLoading(false)})
                    } else if(isAccountsThatSwitchedAccountGroup){
                        SessionInstanceReportService.getAccountsWithAtLeastOneListenedTrack(date, [accountGroup.id], region?.id).then((res) => {
                            setAccounts(res)
                        }).finally(() => setIsLoading(false))
                    } else return;
                }
            }

            getTotalExecutedSessionInstances();

    }, [accountGroup?.accountIds, region?.id, date])

    const deleteMultipleAccounts = async () => {
        try {
            await AccountService.deleteMultipleAccounts(new AccountDeletionDTO({
                accountIds: [...selectedRows],
            }));
            setSelectedRows(new Set());
            showSuccessDialog(strings.success, strings.successfullyDeletedUser, strings.ok).then(_ => {
                window.location.reload();
            });
            setConfirmationModalOpen(false)
        } catch (error) {
            const errorMessage = ErrorHandler.parseErrorMessage(error);
            showErrorDialog(strings.error, errorMessage, strings.ok).then(_ => {});
        }
    };

    const switchAccounts = async () => {

        const selectedAccountIds = Array.from(selectedRows);
        const accountsToSwitch = new AccountSwitchDTO({
            accountIds: selectedAccountIds,
            targetAccountGroupId: selectedAccountGroupInModal?.id
        });

        try {
            await AccountService.switchAccountsToAccountGroup(accountsToSwitch);
            setSelectedRows(new Set());
            showSuccessDialog(strings.success, strings.successfullySwitchedAccounts, strings.ok).then(_ => {
                window.location.reload();
            });
            setSwitchGroupPopupOpen(false)
            setSelectedAccountGroupInModal(undefined)
        } catch (error) {
            const errorMessage = ErrorHandler.parseErrorMessage(error);
            showErrorDialog(strings.error, errorMessage, strings.ok).then(_ => {});
            setSwitchGroupPopupOpen(false)
            setSelectedAccountGroupInModal(undefined)
        }
    };

    const transferAccounts = async () => {

        const selectedAccountIds = Array.from(selectedRows).map(index => accounts[index].id);
        const accountsToTransfer = new AccountTransferDTO({
            accountIds: selectedAccountIds,
            sourceAccountGroupId: accountGroup?.id,
            targetAccountGroupId: selectedAccountGroupInModal?.id,
            proxyGroupId: selectedProxyGroupInModal?.id
        });
        try {
            await AccountService.transferAccountsToAccountGroup(accountsToTransfer);

            setSelectedRows(new Set());
            showSuccessDialog(strings.success, strings.successfullyTransferedAccounts, strings.ok).then(_ => {
                window.location.reload();
            });
            setTransferGroupPopupOpen(false)
            setSelectedAccountGroupInModal(undefined)
            setSelectedProxyGroupInModal(undefined)

        } catch (error) {
            const errorMessage = ErrorHandler.parseErrorMessage(error);
            showErrorDialog(strings.error, errorMessage, strings.ok).then(_ => {});
            // Handle errors appropriately
            setTransferGroupPopupOpen(false)
            setSelectedAccountGroupInModal(undefined)
            setSelectedProxyGroupInModal(undefined)
        }
    };

    const handleSelectedAccountGroupFilter = (selectedGroup: AccountGroupDTO | undefined) => {
        setSelectedAccountGroupInModal(selectedGroup);
    };

    const handleSelectedProxyGroupFilter = (selectedGroup: ProxyGroupReducedDTO | undefined) => {
        setSelectedProxyGroupInModal(selectedGroup);
    };

    function getNumberOfSelectedRowsInPage() {
        return accounts.filter(account => selectedRows.has(account.id)).length;
    }

    function getPageTitle() {
        if (isTotalExecutedSessionInstances) {
            return strings.totalExecutedSessionInstances;
        } else if (isAccountHasAtLeastOneListenedTrack) {
            return strings.accountsThatHaveAtLeastOneListenedTrack;
        } else if (isAccountsWithLastSuccessfulSession) {
            return strings.accountsWithlastSuccessfulSession;
        } else if (isAccountsWithNoListenedTrack) {
            return strings.accountsWithNoListenedTracks;
        } else if (isAccountsThatSwitchedAccountGroup) {
            return strings.accountsThatSwitchedAccountGroup;
        } else {
            return "";
        }
    }

    useEffect(() => {
        setSelectedRows(new Set());
        setAccountGroup(undefined);
        setAccounts([]);
        setRegion(undefined);
        setExecutedSessionInstances(null);
        setConfirmationModalOpen(false);
        setSwitchGroupPopupOpen(false);
        setTransferGroupPopupOpen(false);
        setSelectedAccountGroupInModal(undefined);
        setSelectedProxyGroupInModal(undefined);
    }, [isTotalExecutedSessionInstances, isAccountsWithLastSuccessfulSession, isAccountsWithNoListenedTrack, isAccountHasAtLeastOneListenedTrack, isAccountsThatSwitchedAccountGroup]);
    return(
        <SideBarPage breadcrumbs={listLinks}
                     pageTitle={getPageTitle()}
                     component={
                         <>
                             <FullPageLoadingCircle loading={isLoading} />
                             <TableContainer component={Paper}>
                                 <Table sx={{minWidth: 650}}>
                                     <TableHead>
                                         <TableRow>
                                             <TableCell style={{maxWidth: "20%"}}>
                                                 <FormControl required>
                                                     <PaginatedSelect<AccountGroupDTO>
                                                         value={accountGroup}
                                                         onChange={handleAccountGroupFilter}
                                                         label={strings.accountGroup}
                                                         valueMapper={(item) => item.id.toString()}
                                                         keyMapper={(item) => item.id.toString()}
                                                         itemMapper={(item) => <>{item.name}</>}
                                                         labelMapper={(item) => item.name}
                                                         dataFetcher={(page, size, filter) => {
                                                             return AccountGroupService.getAllAccountGroupsPaged(page, size, filter);
                                                         }}
                                                         closeOnSelect={true}
                                                     />
                                                 </FormControl>
                                             </TableCell>
                                             <TableCell style={{maxWidth: "20%"}}>
                                                 <NullableDatePicker label={strings.date} value={date} onChange={handleDateChange} />
                                             </TableCell>
                                             <TableCell style={{maxWidth: "20%"}}>
                                                 <FormControl>
                                                     <PaginatedSelect<RegionDTO>
                                                         value={region}
                                                         onChange={handleRegionFilter}
                                                         label={strings.region}
                                                         valueMapper={(item) => item.id.toString()}
                                                         keyMapper={(item) => item.id.toString()}
                                                         itemMapper={(item) => <>{item.label}</>}
                                                         labelMapper={(item) => item.label}
                                                         dataFetcher={(page, size, filter) => {
                                                             return RegionService.getAllRegionsPaged(page, size, filter);
                                                         }}
                                                         closeOnSelect={true}
                                                     />
                                                 </FormControl>
                                             </TableCell>
                                             <TableCell></TableCell>
                                             <TableCell></TableCell>
                                             <TableCell>
                                                 <Grid display={"flex"} direction={"row"}>
                                                     <Button style={{float: "right"}} variant="contained" disabled={selectedRows.size ===  0 || !accountGroup} onClick={openSwitchGroupPopup}><SwapHorizIcon/>{strings.switchGroup}</Button>
                                                     <Button style={{float: "right", marginLeft: "10px"}} variant="contained" disabled={!accountGroup} onClick={openTransferGroupPopup}><SwapHorizIcon/>{strings.transferAccounts}</Button>
                                                     <Button style={{float: "right", marginLeft: "10px"}} variant="contained" disabled={selectedRows.size ===  0 || !accountGroup} onClick={openConfirmationModal}><DeleteIcon/>{strings.deleteSelected}</Button>
                                                 </Grid>
                                             </TableCell>
                                         </TableRow>
                                         <TableHeader columns={columns} sortConfigurationString={sortingConfigurationString}
                                                      updateSort={updateSort} selectAll={getNumberOfSelectedRowsInPage() === accounts.length}
                                                      onSelectAll={handleSelectAllWrapper}></TableHeader>
                                     </TableHead>
                                     {accountGroup ? <TableBody>
                                         {accounts?.map((row) => (
                                             <TableRow key={row.id}
                                                       hover={true}
                                                       className={"cursor-pointer"}
                                                       onClick={() => handleRowCheckboxClick(!selectedRows.has(row.id), row.id)}
                                             >
                                                 <TableCell>{row.email}</TableCell>
                                                 <TableCell>{row.accountGroupName}</TableCell>
                                                 <TableCell>{row.regionLabel ? row.regionLabel : " "}</TableCell>
                                                 <TableCell>
                                                     {/*{isTotalExecutedSessionInstances ? executedSessionInstances : null}*/}
                                                 </TableCell>
                                                 <TableCell>
                                                     <Checkbox
                                                         checked={selectedRows.has(row.id)}
                                                         onChange={(event) => handleRowCheckboxClick(event.target.checked, row.id)}
                                                     />
                                                 </TableCell>
                                                 <TableCell></TableCell>
                                             </TableRow>
                                         ))}
                                     </TableBody> : null}


                                 </Table>
                             </TableContainer>
                             <ConfirmationModal
                                 open={confirmationModalOpen}
                                 handleClose={closeConfirmationModal}
                                 handleConfirm={deleteMultipleAccounts}
                                 title={strings.deleteSelectedAccounts}
                                 content={confirmationContent}
                                 actionButtonName={strings.delete}
                             />
                             <Dialog
                                 open={switchGroupPopupOpen}
                                 onClose={closeSwitchGroupPopup}
                             >
                                 <DialogTitle>
                                     {strings.switchGroupShort}
                                 </DialogTitle>
                                 <DialogContent>
                                     <FormControl fullWidth>
                                         <PaginatedSelect<AccountGroupDTO>
                                             value={selectedAccountGroupInModal}
                                             onChange={handleSelectedAccountGroupFilter}
                                             label={strings.accountGroup}
                                             valueMapper={(item) => item.id.toString()}
                                             keyMapper={(item) => item.id.toString()}
                                             itemMapper={(item) => <>{item.name}</>}
                                             labelMapper={(item) => item.name}
                                             dataFetcher={(page, size, filter) => {
                                                 return AccountGroupService.getAllAccountGroupsPaged(page, size, filter, accountGroup?.service?.id);
                                             }}
                                             closeOnSelect={true}
                                         />
                                     </FormControl>
                                 </DialogContent>
                                 <DialogActions>
                                     <Button onClick={closeSwitchGroupPopup}>Cancel</Button>
                                     <Button onClick={switchAccounts} disabled={!selectedAccountGroupInModal} color="primary">
                                         {strings.switchAccounts}
                                     </Button>
                                 </DialogActions>
                             </Dialog>
                             <Dialog
                                 open={transferGroupPopupOpen}
                                 onClose={closeTransferGroupPopup}
                             >
                                 <DialogTitle>
                                     {strings.transferAccountsH}
                                 </DialogTitle>
                                 <DialogContent>
                                     <FormControl fullWidth>
                                         <PaginatedSelect<AccountGroupDTO>
                                             value={selectedAccountGroupInModal}
                                             onChange={handleSelectedAccountGroupFilter}
                                             label={strings.targetAccountGroup}
                                             valueMapper={(item) => item.id.toString()}
                                             keyMapper={(item) => item.id.toString()}
                                             itemMapper={(item) => <>{item.name}</>}
                                             labelMapper={(item) => item.name}
                                             dataFetcher={(page, size, filter) => {
                                                 return AccountGroupService.getAllAccountGroupsPaged(page, size, filter, accountGroup?.service?.id);
                                             }}
                                             closeOnSelect={true}
                                         />
                                     </FormControl>
                                     <FormControl fullWidth style={{marginTop:"20px"}}>
                                         <PaginatedSelect<ProxyGroupReducedDTO>
                                             value={selectedProxyGroupInModal}
                                             onChange={handleSelectedProxyGroupFilter}
                                             label={strings.proxyGroup}
                                             valueMapper={(item) => item.id.toString()}
                                             keyMapper={(item) => item.id.toString()}
                                             itemMapper={(item) => <>{item.name}</>}
                                             labelMapper={(item) => item.name}
                                             dataFetcher={(page, size, filter) => {
                                                 return ProxyGroupService.getAllProxyGroupsPaged(page, size, filter);
                                             }}
                                             closeOnSelect={true}
                                         />
                                     </FormControl>
                                 </DialogContent>
                                 <DialogActions>
                                     <Button onClick={closeTransferGroupPopup}>Cancel</Button>
                                     <Button onClick={transferAccounts} disabled={!selectedAccountGroupInModal} color="primary">
                                         {strings.transferAccountsShort}
                                     </Button>
                                 </DialogActions>
                             </Dialog>
                         </>

                     }
        />
    )
}