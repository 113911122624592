import {ReactElement} from "react";
import { BreadcrumbsModel } from "../models/BreadcrumbsModel";
import {Page} from "./Page";
import SideBarWithContent from "./SideBarWithContent"
export interface SideBarPageProps {
    component: ReactElement,
    pageTitle: string,
    titleBarTitle?: string,
    breadcrumbs?: BreadcrumbsModel[],
}

export function SideBarPage(props: SideBarPageProps) {
    return (
        <Page
            title={props.pageTitle}
            component={
                <SideBarWithContent
                    title={props.titleBarTitle ?? props.pageTitle}
                    component={props.component}
                    breadcrumbs={props.breadcrumbs}
                />
            }
        />
    )
}
