import {PrivilegeDTO} from "./PrivilegeDTO";

export class RoleDTO{
    id : number;
    role : string;
    label : string;
    privilege : PrivilegeDTO[]
    constructor(json:any) {
        this.id = json.id;
        this.role = json.role;
        this.label = json.label;
        this.privilege = json.privilege
    }
}