import axios from "../network/AxiosInstance";
import { ActionType } from "../models/ActionType";
import { AuthService } from "./AuthService";

export class ActionTypeService{
    static async getAllActionTypes():Promise<Array<ActionType>>{
        const response =  await axios.get<Array<ActionType>>(`${AuthService.PREFIX}/bot-session/action-type/all/`);
        const data = response.data;
        return data.map(actionType => new ActionType(actionType))

    }
}