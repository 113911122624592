export class SharedActionCreationDTO {
    actionTypeId:number | string | undefined;
    sourceKey:string | undefined;
    regionId:number | undefined;
    serviceId:number | undefined;
    constructor(json:SharedActionCreationDTO) {
        this.actionTypeId = json.actionTypeId;
        this.sourceKey = json.sourceKey;
        this.regionId = json.regionId;
        this.serviceId = json.serviceId;
    }
}