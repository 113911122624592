import { useEffect, useState } from "react";
import { useDebounce } from "./UseDebounce";
import { defaultDebounceTimeInMilliseconds } from "../../common/Constants";

export function useStateWithDebounce<T>(
  initialValue: T,
  onDebouncedValueSet?: (value: T) => void,
): [T, T, (value: T) => void] {
  const [value, setValue] = useState<T>(initialValue);
  const [debouncedValue, setDebouncedValue] = useState<T>(initialValue);

  useEffect(() => {
    if (onDebouncedValueSet) {
      onDebouncedValueSet(debouncedValue);
    }
  }, [debouncedValue]);

  const setDebouncedValueWithDebounce = useDebounce((value: T) => {
    setDebouncedValue(value);
  }, defaultDebounceTimeInMilliseconds);

  function setValueAndDebouncedValue(value: T) {
    setValue(value);
    setDebouncedValueWithDebounce(value);
  }

  return [value, debouncedValue, setValueAndDebouncedValue];
}
