import {AccountDTO} from "./AccountDTO";
import {SessionReducedDTO} from "./SessionReducedDTO";
import {ProxyDTO} from "./ProxyDTO";
import {BotMinimalDTO} from "./BotMinimalDTO";
import {CookieCollectionReducedDTO} from "./CookieCollectionReducedDTO";
import {MobilePhoneDTO} from "./MobilePhoneDTO";

export class SessionInstanceReducedDTO {
    id:number;
    dateCreated:Date;
    dateModified:Date;
    session:SessionReducedDTO | undefined;
    account:AccountDTO | undefined;
    cookieCollectionId:number;
    proxy:ProxyDTO | undefined;
    bot:BotMinimalDTO|undefined
    totalActions:number;
    finishedActions: number;
    successfullyExecuted:boolean;
    sessionInstanceConditionType:string;
    profile:string;
    usedCookieCollection:CookieCollectionReducedDTO|undefined;
    performedLogin:boolean;
    initialized: boolean;
    mobilePhone: MobilePhoneDTO | undefined

    constructor(json:SessionInstanceReducedDTO){
        this.id = json.id;
        this.dateCreated = new Date(json.dateCreated);
        this.dateModified = new Date(json.dateModified);
        this.session =  json.session ?  new SessionReducedDTO(json.session):undefined;
        this.account  = json.account ?  new AccountDTO(json.account) : undefined;
        this.cookieCollectionId = json.cookieCollectionId;
        this.proxy = json.proxy ? new ProxyDTO(json.proxy) : undefined;
        this.bot = json.bot ? new BotMinimalDTO(json.bot) : undefined;
        this.totalActions = json.totalActions;
        this.finishedActions = json.finishedActions;
        this.successfullyExecuted=json.successfullyExecuted;
        this.sessionInstanceConditionType=json.sessionInstanceConditionType;
        this.profile = json.profile;
        this.usedCookieCollection = json.usedCookieCollection ? new CookieCollectionReducedDTO(json.usedCookieCollection) : undefined;
        this.performedLogin=json.performedLogin;
        this.initialized = json.initialized;
        this.mobilePhone = json.mobilePhone ? new MobilePhoneDTO(json.mobilePhone) : undefined;
    }
}