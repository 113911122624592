import {Session} from "./Session";
import {AccountGroupScheduleDTO} from "./AccountGroupScheduleDTO";

export class ScheduledInterval{
    startSecondOfDay:number;
    endSecondOfDay: number;
    scheduledAccounts:number;
    sessions: Session[]
    constructor(json:any){
        this.startSecondOfDay=json.startSecondOfDay;
        this.endSecondOfDay=json.endSecondOfDay;
        this.scheduledAccounts=json.scheduledAccounts;
        this.sessions = json.sessionDTOs?.map((session: any) => new Session(session)) ?? []
    }

}