import {
    Box,
    Button,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Pagination,
    Paper,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField
} from "@mui/material";
import React, { useEffect, useState } from "react"
import {useSearchParams } from "react-router-dom";
import { SideBarPage } from "../../components/SideBarPage";
import { strings } from "../../localization/Localization";
import { RegionDTO } from "../../models/RegionDTO";
import { ServiceDTO } from "../../models/ServiceDTO";
import { SharedActionDTO } from "../../models/SharedActionDTO"
import { Routes } from "../../router/Routes";
import { RegionService } from "../../services/RegionService";
import { ServicesService } from "../../services/ServicesService";
import { SharedActionService } from "../../services/SharedActionService";
import { NumberParser } from "../../utils/NumberParser";
import { SortingConfiguration } from "../../utils/SortingUtils";
import { defaultRowsPerPageOptions } from "../../common/Constants";
import { TableHeader } from "../../components/TableHeader";
import { ActionTypeService } from "../../services/ActionTypeService";
import { Add, Clear} from "@mui/icons-material";
import { ActionType } from "../../models/ActionType";
import { DateTimeUtils } from "../../utils/DateTimeUtils";
import { Dayjs } from "dayjs";
import { NullableDatePicker } from "../../components/NullableDatePicker";
import SharedActionModal from "./SharedActionModal";
import { SharedActionCreationDTO } from "../../models/SharedActionCreationDTO";
import { showErrorDialog, showSuccessDialog } from "../../common/Dialogs";
import { ErrorHandler } from "../../utils/ErrorHandler";
import { Controller, useForm } from "react-hook-form";
import ConfirmationModal from "../../components/ConfirmationModal";
class SharedActionData {
  dateCreated?: string;
  dateModified?: string;
  actionType: string;
  actionTypeData: ActionType | undefined;
  sourceKey: string | undefined;
  region: RegionDTO | undefined;
  service: ServiceDTO | undefined;
  constructor(json: SharedActionData) {
      this.dateCreated = json.dateCreated;
      this.dateModified = json.dateModified;
      this.actionType = json.actionType;
      this.actionTypeData = json.actionTypeData ?  new ActionType(json.actionTypeData) : undefined;
      this.sourceKey = json.sourceKey ? json.sourceKey : undefined;
      this.region = json.region ? new RegionDTO(json.region) : undefined;
      this.service = json.service ? new ServiceDTO(json.service) : undefined;
  }
  toSharedActionDTO(): SharedActionCreationDTO {
    return new SharedActionCreationDTO(
        {
          actionTypeId: this.actionTypeData?.id,
          sourceKey: this.sourceKey,
          regionId: this.region?.id,
          serviceId:this.service?.id
        }
    );
  }
}
export function SharedActions() {
  const columns = [
    { label: strings.sharedActionId, sortBy: "id", sortable: true },
    { label: strings.action, sortBy: "actionType", sortable: true },
    { label: strings.sourceKey, sortBy: "sourceKey", sortable: true },
    { label: strings.dateCreated, sortBy: "dateCreated", sortable: true },
    { label: strings.dateModified, sortBy: "dateModified", sortable: true },
    { label: "", sortBy: "undefined", sortable: false },
    { label:"", sortBy: "", sortable: false },
  ]
  const listLinks = [
    { label: strings.dashboard, currentlyOpened: false, href: Routes.HOME },
    { label: strings.sharedActions, currentlyOpened: true },
  ]
  const [searchParams, setSearchParams] = useSearchParams();
  const [sharedActions, setSharedActions] = useState<SharedActionDTO[]>([]);
  const actionTypeId = searchParams.get("actionTypeId") ?? "";
  const sourceKey = searchParams.get("sourceKey") ?? "";
  const dateCreated = searchParams.get("dateCreated") ?? null;
  const dateModified = searchParams.get("dateModified") ?? null;
  const rowsPerPage = NumberParser.parseNumber(searchParams.get("rowsPerPage"), 25);
  const [totalElements, setTotalElements] = useState<number>(0);
  const sortingConfigurationString = searchParams.get("sort");
  const page = NumberParser.parseNumber(searchParams.get("page"), 0);
  const regionId = searchParams.get("regionId") ?? "";
  const serviceId = searchParams.get("serviceId") ?? "";
  const [services, setServices] = useState<ServiceDTO[]>([]);
  const [isAddMode, setIsAddMode] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [sharedActionData, setSharedActionData] = useState<SharedActionDTO>();
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [actionId, setActionId] = useState<number>()
  function handleClickOpen() {
    setOpen(true);
  }
  function setActionData(item: SharedActionDTO) {
    setSharedActionData(item)
  }
  function handleClickOpenModal(): any {
    setOpenDeleteModal(true);
 }
  function handleCloseModal(): any {
    setOpenDeleteModal(false);
  }
  function handleClose() {
    setOpen(false);
    setTimeout(()=>{setIsAddMode(false)}, 200)
 };
  const [regions, setRegions] = useState<RegionDTO[]>([]);
  const [actionTypes, setActionTypes] = useState<ActionType[]>([])
  function setSearchParam(key: string, value: string | null | undefined): void {
    if (value) {
      searchParams.set(key, value);
    } else {
      searchParams.delete(key);
    }
    setSearchParams(searchParams)
  }
  function setPage(page: number) {
    setSearchParam("page", page.toString());
  }
  function updateSort(sortingConfiguration: SortingConfiguration): void {
    setSearchParam("sort", sortingConfiguration.toSearchString());
  }
  function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchParam("rowsPerPage", event.target.value);
    setPage(0);
  }
  function handleChangeActionTypeId(event: SelectChangeEvent) {
    setSearchParam("actionTypeId", event.target.value)
    setPage(0);
  }
  function handleChangeRegionId(event: SelectChangeEvent) {
    setSearchParam("regionId", event.target.value)
    setPage(0);
    setValue("region",  regions.find((el=>el.id.toString()===event.target.value.toString())))
  }

  function setServiceId(serviceId: string): void {
      setSearchParam("serviceId", serviceId)
      setPage(0);
      setValue("service", services.find((el=>el.id.toString() === serviceId)));
  }

  function handleChangeServiceId(event: SelectChangeEvent) {
    setServiceId(event.target.value);
  }
  
  function handleChangeSourceKey(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchParam("sourceKey", event.target.value);
    setPage(0);
  }
  function handleChangeDateCreated(date: Dayjs | null) {
    setSearchParam("dateCreated", DateTimeUtils.formatDateDayJs(date));
    setPage(0);
  }
  function handleChangeDateModified(date: Dayjs | null) {
    setSearchParam("dateModified", DateTimeUtils.formatDateDayJs(date));
    setPage(0);
  }
  useEffect(() => {
    async function getAllServices(): Promise<void> {
      const services = await ServicesService.getAllServices();
      setServices(services);
      if (!serviceId && (services?.length ?? 0) > 0) {
          setServiceId(services[0].id.toString());
      }
    }

    async function getAllRegions(): Promise<void> {
      const regions = await RegionService.getRegions();
      setRegions(regions);
    }
    async function getAllActionTypes(): Promise<void> {
      const actionTypes = await ActionTypeService.getAllActionTypes();
      setActionTypes(actionTypes);
    }
    async function loadData(): Promise<void> {
      await getAllRegions();
      await getAllServices();
      await getAllActionTypes();
    }

    loadData().then((_) => {
    });
  }, [])
  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    getValues,
    formState: { errors },
  } = useForm<SharedActionData>({
    defaultValues: {
      dateCreated: undefined,
      dateModified: undefined,
      actionType:"",
      actionTypeData : undefined,
      sourceKey:"",
      region: undefined,
      service:undefined
    },
    mode: "onChange",
  });
  watch();
  function onSubmit(){
    return isAddMode ? addSharedAction() : editSharedAction()
  }

  function buildSSharedActionCreationDTO(): SharedActionCreationDTO {
    return new SharedActionData(getValues()).toSharedActionDTO();
  }

  function addSharedAction() {
    const sharedActionCreationDTO = buildSSharedActionCreationDTO();
    SharedActionService.createSharedAction(sharedActionCreationDTO)
      .then(() => {
          setOpen(false)
          showSuccessDialog(strings.success, strings.sharedActionAddedSuccessfully, strings.ok).then(_ => {getAllSharedActionsPaged(page); setIsAddMode(false)});
      })
      .catch((error) => {
        const message = ErrorHandler.parseErrorMessage(error);
        setOpen(false)
        showErrorDialog(strings.error, message, strings.ok).then(_ => {});
      });
  }
  function editSharedAction() {
   const sharedAction = buildSSharedActionCreationDTO();
    if (sharedActionData === null) {
      return;
    }
    if(sharedActionData)
    SharedActionService.editSharedActions(sharedAction, sharedActionData?.id)
      .then(() => {
          setOpen(false)
          showSuccessDialog(strings.success, strings.sharedActionEditedSuccessfully, strings.ok).then(_ => {getAllSharedActionsPaged(page)});
          
      })
      .catch((error) => {
          const message = ErrorHandler.parseErrorMessage(error);
          setOpen(false)
          showErrorDialog(strings.error, message, strings.ok).then(_ => {});
      });
  }
  function deleteSharedAction(id: number | undefined) {
    if (id !== undefined) {
        SharedActionService.deleteSharedAction(id)
            .then(() => {
                handleCloseModal()
                handleClose()
                showSuccessDialog(
                    strings.success,
                    strings.successfullyDeletedUser,

                    strings.ok
                )
                    .then((_) => {getAllSharedActionsPaged(page)})
                    .catch((error) => {
                        const errorMessage = ErrorHandler.parseErrorMessage(error);
                        showErrorDialog(strings.error, errorMessage, strings.ok).then(
                            (_) => { }
                        );
                    });
            });
    }
}
function checkValueOfActionType() {
  if (!isAddMode && getValues(`actionTypeData.id`)) {
    return true;
  } else if (!isAddMode && !getValues(`actionTypeData.id`)) {
    return false;
  } else if (isAddMode && getValues(`actionTypeData.id`)) {
    return true;
  } else if (isAddMode && !getValues(`actionTypeData.id`)) {
    return false;
  }
}
  useEffect(() => {
    if (sharedActionData) {
      const sharedAction = sharedActionData;
      if(sharedAction.actionTypeData) {
      setValue("actionTypeData", sharedAction.actionTypeData);
      }
      if(sharedAction.region){
        setValue("region", sharedAction.region)
      }
      setValue("sourceKey", sharedAction.sourceKey);
      setValue("dateCreated", sharedAction.dateCreated)
      setValue("dateModified", sharedAction.dateModified)
      setValue("service", sharedAction.service)
    }else {
      setValue("service", services.find((el=>el.id.toString() === serviceId.toString())))
      setValue("region",  regions.find((el=>el.id.toString()===regionId.toString())))
    }
    if(isAddMode){
      setSharedActionData(undefined)
      setValue("actionTypeData", undefined)
      setValue("sourceKey", undefined);
    }
    
  }, [sharedActionData, setValue, isAddMode]);
  async function getAllSharedActionsPaged(page: number): Promise<void> {
    const response = await SharedActionService.getAllSharedActionsPaged(
      page,
      rowsPerPage,
      actionTypeId,
      sourceKey,
      regionId,
      serviceId,
      dateCreated,
      dateModified,
      SortingConfiguration.fromSearchString(sortingConfigurationString),
    );
    setTotalElements(response.totalElements);
    setSharedActions(response.content);
  }
  useEffect(() => {
    if (serviceId) {
      getAllSharedActionsPaged(page).then(_ => { })
    } else {
      setSharedActions([])
    }
  }, [page, rowsPerPage, actionTypeId, sourceKey, dateCreated, dateModified, serviceId, regionId, sortingConfigurationString, searchParams])
  
  return (
    <>
      <SideBarPage
        pageTitle={strings.sharedActions}
        breadcrumbs={listLinks}
        component={
          <Box>
            <div className="pb-3 d-flex justify-content-start">
              <FormControl style={{ minWidth: "200px", textAlign: "left" }} variant="standard">
                <InputLabel>{strings.region}</InputLabel>
                <Select
                  defaultValue={""}
                  value={regions.length > 0 && regionId ? String(regionId) : ""}
                  onChange={handleChangeRegionId}
                  label={strings.region}
                  endAdornment={
                    regionId && (
                      <IconButton
                        onClick={() =>
                          setSearchParam("regionId", null)
                        }
                      >
                        <Clear />
                      </IconButton>
                    )
                  }
                >
                  {regions?.map((region) => (
                    <MenuItem disableRipple key={region.id} value={region.id}>
                      {region.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl style={{ minWidth: "200px", textAlign: "left", marginLeft: "20px" }} variant="standard">
                <InputLabel>{strings.service}</InputLabel>
                <Select
                  defaultValue={""}
                  value={services.length > 0 && serviceId ? String(serviceId) : ""}
                  onChange={handleChangeServiceId}
                  label={strings.service}
                >
                  {services?.map((service) => (
                    <MenuItem disableRipple key={service.id} value={service.id}>
                      {service.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 500 }}
                aria-label="custom pagination table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>
                      <FormControl fullWidth variant="standard">
                        <InputLabel>{strings.action}</InputLabel>
                        <Select
                          defaultValue={""}
                          value={actionTypes.length > 0 && actionTypeId ? String(actionTypeId) : ""}
                          onChange={handleChangeActionTypeId}
                          label={strings.action}
                          endAdornment={
                            actionTypeId && (
                              <IconButton
                                onClick={() =>
                                  setSearchParam("actionTypeId", null)
                                }
                              >
                                <Clear />
                              </IconButton>

                            )
                          }
                        >
                          {actionTypes?.map((action) => (
                            <MenuItem disableRipple key={action.id} value={action.id}>
                              {action.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <TextField
                        name="sourceKey"
                        variant="standard"
                        value={sourceKey}
                        label={strings.sourceKey}
                        onChange={handleChangeSourceKey}
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      <NullableDatePicker label={strings.dateCreated} value={dateCreated} onChange={handleChangeDateCreated} />
                    </TableCell>
                    <TableCell>
                      <NullableDatePicker label={strings.dateModified} value={dateModified} onChange={handleChangeDateModified} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell><Button
                      variant="contained"
                      className="btn btn-sm"
                      disabled={!serviceId}
                      onClick={() => {
                        setIsAddMode(true)
                        setValue("actionTypeData", undefined);
                        setValue("sourceKey", undefined)
                        handleClickOpen()
                      }}
                    >
                      {" "}
                      <Add /> {strings.addSharedAction}
                    </Button>
                    </TableCell>
                  </TableRow>
                  <TableHeader columns={columns} sortConfigurationString={sortingConfigurationString} updateSort={updateSort}></TableHeader>
                  <TableRow>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sharedActions.map((action) => (
                    <TableRow key={action.id} className="cursor-pointer" onClick={() => {
                      setActionId(action.id)
                      handleClickOpen();
                      setActionData(action);
                    }
                    }>
                      <TableCell component="th" scope="row">
                        {action.id}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {action.actionTypeData?.label}
                      </TableCell>
                      <TableCell>{action.sourceKey}</TableCell>
                      <TableCell>
                        {DateTimeUtils.formatDate(action.dateCreated)}
                      </TableCell>
                      <TableCell>
                        {DateTimeUtils.formatDate(action.dateModified)}
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
              <Grid sx={{display: 'flex', flexDirection: 'column', alignItems: 'end', marginTop: "10px"}}>
                  <Pagination  count={Math.ceil(totalElements / rowsPerPage)} showFirstButton showLastButton page={page + 1}
                               onChange={(e, page) => handleChangePage(null, page - 1)}/>
            <TablePagination
              rowsPerPageOptions={defaultRowsPerPageOptions}
              component="div"
              count={totalElements}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
              </Grid>
           <SharedActionModal open={open} isAddMode={isAddMode} handleClose={handleClose} handleOpenDelete={handleClickOpenModal} save={handleSubmit(onSubmit)}
           content={
            <div className="mt-2">
               <FormControl 
                        fullWidth
                        sx={{ textAlign: "left" }}
                        error={
                          errors.actionTypeData !== undefined
                        }
                      >
                        <InputLabel
                          shrink={
                            getValues(`actionTypeData.id`)
                              ? true
                              : false
                          }
                        >
                          {strings.action}
                        </InputLabel>
                        <Controller
                          name="actionTypeData.id"
                          rules={{
                            required: true,
                          }}
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              value={value ? value : ""}
                              onChange={onChange}
                              defaultValue={""}
                              input={
                                <OutlinedInput
                                  label={strings.action}
                                  notched={checkValueOfActionType()}
                                />
                              }
                            >
                              {actionTypes.map((actionType) => (
                                <MenuItem
                                  value={actionType.id}
                                  key={actionType.id}
                                >
                                  {actionType.label}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                      </FormControl>
                      <TextField
                        {...register(`sourceKey`, {
                          required: actionTypes.find((el=>el.id===getValues("actionTypeData.id")))?.sourceKeyRequired
                        })}
                        className="mt-3"
                        sx={{ width: "100%" }}
                        fullWidth
                        name={"sourceKey"}
                        label={strings.sourceKey}
                        error={errors.sourceKey !== undefined}
                      />
            </div>
           }
            />
          <ConfirmationModal
          open={openDeleteModal}
          handleClose={handleCloseModal}
          handleConfirm={()=>deleteSharedAction(actionId)}
          title={strings.deleteSharedAction}
          content={strings.deleteThisSharedAction}
          actionButtonName={strings.delete}/>
          </Box>
        }
      />
    </>
  );

}
