import {ReactElement} from "react";
import {Guard} from "./Guard";

class GuardedRouteProps {
    constructor(
        public component: ReactElement,
        public guards: Guard[],
    ) {
    }
}

export function GuardedRoute(props: GuardedRouteProps) {
    function checkAndBuildFallback(): ReactElement | null {
        for (const guard of props.guards) {
            if (!guard.check()) {
                return guard.fallback;
            }
        }

        return null;
    }

    const fallback = checkAndBuildFallback();

    return (
        fallback !== null ? fallback : props.component
    );
}
