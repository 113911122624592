import {StorageKeys} from "./StorageKeys";
import {JWTPayload} from "../models/JWTPayload";
import jwtDecode from "jwt-decode";

export class StorageService {
    static saveAuthToken(token: string): void {
        localStorage.setItem(StorageKeys.AUTH_TOKEN, token);
    }

    static hasAuthToken(): boolean {
        return localStorage.getItem(StorageKeys.AUTH_TOKEN) !== null;
    }

    static clearAuthToken(): void {
        localStorage.removeItem(StorageKeys.AUTH_TOKEN);
    }

    static setLanguage(language: string): void {
        localStorage.setItem(StorageKeys.ACTIVE_LANGUAGE, language);
    }

    static getLanguage(): string | null {
        return localStorage.getItem(StorageKeys.ACTIVE_LANGUAGE);
    }

    static hasLanguage(): boolean {
        return this.getLanguage() !== null;
    }

    static getAuthToken(): string | null {
        return localStorage.getItem(StorageKeys.AUTH_TOKEN);
    }

    static getJWTPayload(): JWTPayload | null {
        const token = StorageService.getAuthToken();

        if (!token) {
            return null;
        }

        return jwtDecode<JWTPayload>(token);
    }
}
