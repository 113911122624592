import {strings} from "../localization/Localization";
import {CenteredCardLayout} from "./CenteredCardLayout";
import React from "react";
import {useNavigate} from "react-router-dom";
import {Routes} from "../router/Routes";
import {SessionInstanceReducedDTO} from "../models/SessionInstanceReducedDTO";
import {DateTimeUtils} from "../utils/DateTimeUtils";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {LinkText} from "./LinkText";


interface CardProps {
    title: string;
    sessionInstanceReduced: SessionInstanceReducedDTO | undefined;
}

export function SessionInstancesCard(props: CardProps) {
    const sessionInstanceReduced = props.sessionInstanceReduced;

    const navigate = useNavigate();

    function openSessionInstance(): void {
        navigate(Routes.SESSION_INSTANCE_DETAILS.replace(":id", sessionInstanceReduced?.id?.toString() ?? "/"));
    }

    function openSession(): void {
        if (sessionInstanceReduced?.session?.enabled) {
            navigate(Routes.SESSION_DETAILS.replace(":id", sessionInstanceReduced?.session.id.toString() ?? "/"));
        }
    }

    function navigateToProxyDetails() {
        if (sessionInstanceReduced?.proxy) {
            navigate(Routes.PROXY_DETAILS.replace(":id", sessionInstanceReduced?.proxy.id.toString() ?? "/"));
        }
    }

    function navigateToBotDetails() {
        if (sessionInstanceReduced?.bot) {
            navigate(Routes.BOT_DETAILS.replace(":id", sessionInstanceReduced?.bot.id.toString() ?? "/"));
        }
    }

    return (

        <>
            <CenteredCardLayout className="mt-3" minWidth="70%">
                <div className="d-flex justify-content-md-center align-items-center mb-3"><b>{props.title}</b></div>
                {
                    sessionInstanceReduced === undefined ? (
                        <div className="p-1">{strings.thereIsNoSuchSessionForThisAccount}</div>
                    ) : (
                        <>
                            <LinkText className="mb-1"
                                      text={<span>{strings.id.toUpperCase()}: {sessionInstanceReduced?.id}</span>}
                                      onClick={openSessionInstance}
                                      enabled={true}
                            />
                            <div className="p-1 mb-1">{strings.dateCreated}: {DateTimeUtils.formatDateObject(sessionInstanceReduced.dateCreated)}</div>
                            <div className="p-1 mb-1">{strings.dateModified}: {DateTimeUtils.formatDateObject(sessionInstanceReduced.dateModified)}</div>
                            <LinkText className="mb-1"
                                      text={<span>{strings.session}: {sessionInstanceReduced.session?.name}</span>}
                                      onClick={openSession}
                                      enabled={sessionInstanceReduced.session?.enabled}
                            />
                            <LinkText className="mb-1"
                                      text={<span>{strings.proxy}: {sessionInstanceReduced.proxy ? sessionInstanceReduced.proxy.name : (
                                          <CancelIcon
                                              color="error"
                                          />
                                      )}</span>}
                                      onClick={navigateToProxyDetails}
                                      enabled={sessionInstanceReduced.proxy?.isNotDeleted ?? false}
                            />
                            <LinkText className="mb-1"
                                      text={<span>{strings.bot}: {sessionInstanceReduced.bot ? sessionInstanceReduced.bot.name : (
                                          <CancelIcon
                                              color="error"
                                          />
                                      )}</span>}
                                      onClick={navigateToBotDetails}
                                      enabled={true}
                            />
                            <div className="p-1 mb-1">{strings.usedCookies}: {sessionInstanceReduced.usedCookieCollection ? sessionInstanceReduced.usedCookieCollection.name : (
                                <CancelIcon
                                    color="error"
                                />
                            )}</div>
                            <div className="p-1">{strings.performedLogin}: {sessionInstanceReduced.performedLogin ? (
                                <CheckCircleIcon
                                    color="success"
                                />
                                ) : (
                                <CancelIcon
                                    color="error"
                                />
                            )}</div>
                        </>
                    )
                }
            </CenteredCardLayout>
        </>
    )
}

