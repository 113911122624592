import {strings} from "../../localization/Localization";
import React, {useEffect, useState} from "react";
import {SideBarPage} from "../../components/SideBarPage";
import {useParams} from "react-router-dom";
import {CenteredCardLayout} from "../../components/CenteredCardLayout";
import {
    Alert,
    Button, CardActions,
    FormControl,
    InputLabel,
    MenuItem, OutlinedInput,
    Select,
    Snackbar,
    TextField
} from "@mui/material";
import {Controller, useForm} from "react-hook-form";
import {useNavigateWithParams} from "../../router/NavigateWithParams";
import {PaymentAccountDTO} from "../../models/PaymentAccountDTO";
import {PaymentAccountTypeDTO} from "../../models/PaymentAccountTypeDTO";
import {Clear, Edit} from "@mui/icons-material";
import {Routes} from "../../router/Routes";
import {PaymentAccountsService} from "../../services/PaymentAccountsService";
import {PaymentAccountCreationDTO} from "../../models/PaymentAccountCreationDTO";
import {VisibilityEye} from "../../components/VisibilityEye";
import {PaymentAccountTypeService} from "../../services/PaymentAccountTypeService"
import {snackbarDurationInMilliseconds} from "../../common/Constants";
import IconButton from "@mui/material/IconButton";


class PaymentAccountFormData {
    username: string | undefined;
    password: string | undefined;
    phoneNumber: string | undefined;
    paymentAccountType: string | undefined;
    accountId: string | undefined;


    constructor(json: any) {
        this.username = json.username;
        this.password = json.password;
        this.phoneNumber = json.phoneNumber;
        this.paymentAccountType = json.paymentAccountType;
        this.accountId = json.accountId;
    }

    toPaymentAccountCreationDTO(): PaymentAccountCreationDTO {
        return new PaymentAccountCreationDTO({
            username: this.username,
            password: this.password,
            phoneNumber: this.phoneNumber,
            paymentAccountTypeId: this.paymentAccountType,
            accountId: this.accountId,
        })
    }
}

export function PaymentAccountDetails() {
    let {id, accountId} = useParams();
    const isAddMode = !id;
    const listLinks = [
        { label:  strings.dashboard, currentlyOpened: false, href:Routes.HOME},
        { label:  strings.accounts, currentlyOpened: false, href:Routes.ACCOUNTS},
        { label:  strings.editAccount, currentlyOpened: false, href:Routes.ACCOUNT_DETAILS.replace(":id", accountId ? accountId.toString() : "")},
        { label:  isAddMode ? strings.addPaymentAccount : strings.editPaymentAccount, currentlyOpened: true},
      ]
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [errorAccount, setErrorAccount] = useState<boolean>(false);
    const [paymentAccountTypes, setPaymentAccountTypes] = useState<PaymentAccountTypeDTO[] | undefined>([]);
    const [paymentAccountData, setPaymentAccountData] = useState<PaymentAccountDTO | null>(null);
    const navigate = useNavigateWithParams();
    const {
        control,
        watch,
        setValue,
        handleSubmit,
        getValues,
        formState: {errors}
    } = useForm<PaymentAccountFormData>({
        defaultValues: {
            username: "",
            password: "",
            phoneNumber: "",
            paymentAccountType: "",
            accountId: "",
        },
        mode: "onChange"
    });
    watch();

    function onSubmit() {
        return isAddMode ? addPaymentAccount() : editPaymentAccount()
    }

    function buildAccount(): PaymentAccountCreationDTO {
        return new PaymentAccountFormData({
            ...getValues(),
            accountId: accountId,
        }).toPaymentAccountCreationDTO();
    }

    function addPaymentAccount() {
        PaymentAccountsService.createPaymentAccount(buildAccount()).then(() => {
            navigate(Routes.ACCOUNT_DETAILS.replace(":id",accountId!.toString()), {
                successMessage: strings.paymentAccountAddedSuccessfully,
            });
        })
            .catch((error: any) => {
                if (error) {
                    setSnackbarOpen(true);
                    setErrorAccount(true);
                }
            }
            );
    }

    function editPaymentAccount() {
        const paymentAccount = buildAccount();
        if (paymentAccountData === null) {
            return;
        }
        PaymentAccountsService.updatePaymentAccount(paymentAccount, paymentAccountData?.id)
            .then(() => {
                navigate(Routes.ACCOUNT_DETAILS.replace(":id", paymentAccountData!.accountId.toString()), {
                    successMessage: strings.paymentAccountEditedSuccessfully,
                });
            })
            .catch((error) => {
                if (error) {
                    setSnackbarOpen(true);
                    setErrorAccount(true);
                }
            });
    }
    async function getPaymentAccountById(id: number) {
        await PaymentAccountsService.getPaymentAccountById(id).then((res) => {
            setPaymentAccountData(res);
            setValue("username", res?.username ?? "");
            setValue("password", res?.password ?? "");
            setValue("phoneNumber", res.phoneNumber ?? "");
            setValue("paymentAccountType", res.paymentAccountType?.id.toString());
        })
    }


    async function getAllPaymentAccountsType() {
        await PaymentAccountTypeService.getAllPaymentAccountsType().then((data) => {
            setPaymentAccountTypes(data);
        })
    }

    useEffect(() => {
        getAllPaymentAccountsType();
    }, [])
    useEffect(() => {
        if (id) {
            getPaymentAccountById(parseInt(id));
        }
    }, [])
    return (
        <>
            <SideBarPage pageTitle={isAddMode ? strings.addPaymentAccount : strings.editPaymentAccount} breadcrumbs={listLinks} component={
                <CenteredCardLayout minWidth={"40%"}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Controller
                            name="username"
                            rules={{required: true}}
                            control={control}
                            render={({field}) => (
                                <TextField
                                    InputLabelProps={{
                                        shrink: !!getValues("username"),
                                    }}
                                    {...field}
                                    value={field.value}
                                    id={"username"}
                                    label={"Username"}
                                    className={"w-100"}
                                    style={{marginBottom: 15}}
                                    error={errors.username !== undefined}
                                />
                            )}
                        />
                        <Controller
                            name="phoneNumber"
                            control={control}
                            render={({field: {onChange, value}}) => (
                                <TextField
                                    InputLabelProps={{
                                        shrink: !!getValues("phoneNumber"),
                                    }}
                                    className={"w-100"}
                                    label={"Phone number"}
                                    style={{marginBottom: 15}}
                                    value={value}
                                    error={errors.phoneNumber !== undefined}
                                    onChange={onChange}
                                />
                            )}
                        />

                        <Controller
                            name="password"
                            control={control}
                            render={({field: {onChange, value}}) => (
                                <VisibilityEye
                                    getValue={!!getValues("password")}
                                    value={value}
                                    className={"w-100"}
                                    required={false}
                                    onChange={onChange}
                                    label={"Password"}
                                />
                            )}
                        />

                        <FormControl className={"w-100"}>
                            <InputLabel
                                shrink={!!getValues("paymentAccountType")}
                            >
                                {strings.paymentAccountType}
                            </InputLabel>
                            <Controller
                                name="paymentAccountType"
                                control={control}
                                render={({field: {onChange, value}}) => (
                                    <Select
                                        value={
                                            getValues("paymentAccountType")  ? String(getValues("paymentAccountType")) : ""
                                        }
                                        onChange={onChange}
                                        input={
                                            <OutlinedInput
                                                label={strings.region}
                                                notched={
                                                    !!(!isAddMode || (isAddMode && getValues("paymentAccountType")))
                                                }
                                            />
                                        }
                                    >
                                        {paymentAccountTypes!.map((pat) => (
                                            <MenuItem key={pat.id} value={pat.id}>
                                                {pat.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                        </FormControl>
                        <CardActions className={"p-0 mt-4"}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                startIcon={!isAddMode && <Edit/>}>
                                {isAddMode ? strings.addPaymentAccount : strings.saveChanges}
                            </Button>
                        </CardActions>
                    </form>
                </CenteredCardLayout>
            }/>
            {errorAccount && (
                <Snackbar
                    open={snackbarOpen}
                    onClose={() => setSnackbarOpen(false)}
                    autoHideDuration={snackbarDurationInMilliseconds}
                >
                    <Alert severity="error">
                        {isAddMode
                            ? strings.paymentAccountAddedUnsuccessfully
                            : strings.paymentAccountEditedUnsuccessfully}
                    </Alert>
                </Snackbar>
            )}
        </>
    )
}
