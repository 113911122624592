export class Routes {
    public static ACCOUNTS = "/accounts";
    public static ADD_SESSION = "/add-session";
    public static CONFIRM_RESET_PASSWORD = "/confirm-password-reset"
    public static BOTS = "/bots";
    public static BOT_DETAILS = "/bot-details/:id";
    public static ACCOUNT_GROUPS = "/account-groups";
    public static ACCOUNT_GROUPS_DETAILS = "/account-groups/:id";
    public static CLIENTS = "/clients";
    public static CLIENTS_NEW = "/clients/new";
    public static CLIENT_DETAILS = "/clients/:id";
    public static CREATE_ACC_GROUP = "/create-group";
    public static LOGIN = "/login";
    public static HOME = "/";
    public static PROXIES = "/proxies";
    public static PROXY_NEW = "/proxies/new";
    public static PROXY_DETAILS = "/proxies/:id";
    public static PROXY_MASSIVE_EDIT = "/proxies/proxies-massive-edit";
    public static PROXY_GROUPS = "/proxies/proxy-groups";
    public static PROXY_GROUP_DETAILS = "/proxies/proxy-groups/:id"
    public static PROXY_GROUP_NEW = "/proxies/proxy-groups/new";
    public static REGIONS = "/regions";
    public static REGION_DETAILS = "/regions/details/:id";
    public static REGION_NEW = "/regions/new";
    public static REPORTS = "/reports";
    public static REPORT_TOTAL_EXECUTED_SESSION_INSTANCES = "/reports/total-executed-session-instances";
    public static REPORT_ACCOUNTS_NO_LISTENED_TRACKS = "/reports/accounts-no-listened-tracks";
    public static REPORT_ACCOUNTS_LAST_SUCCESSFUL_SESSION = "/reports/accounts-last-successful-session";
    public static REPORT_ACCOUNTS_AT_LEAST_ONE_LISTENED_TRACK = "/reports/accounts-at-least-one-listened-track";
    public static REPORT_ACCOUNT_SWITCHED_ACCOUNT_GROUP = "/reports/accounts-switched-account-group";
    public static ACCOUNT_MANAGEMENT = "/account-management";
    public static RESET_PASSWORD = "/reset-password";
    public static SESSIONS = "/sessions";
    public static ACCOUNT_DETAILS = "/accounts/:id";
    public static ACCOUNT_NEW = "/accounts/new";
    public static PAYMENT_ACCOUNT_DETAILS = "/accounts/:accountId/payment-accounts/:id";
    public static PAYMENT_ACCOUNT_NEW = "/accounts/:accountId/payment-accounts/new";
    public static USERS = "/users";
    public static USER_NEW = "/users/new";
    public static USER_EDIT = "/users/edit/:id";
    public static SESSION_INSTANCES = "/session-instances";
    public static SESSION_DETAILS = "/sessions/:id";
    public static CONFIRMATION_PAGE = "/confirm-registration";
    public static SESSION_INSTANCE_DETAILS = "/session-instance/:id";
    public static LISTENED_TRACKS = "/listened-tracks";
    public static LISTENED_TRACKS_DETAILS = "/listened-tracks/details";
    public static SHARED_ACTIONS = "/shared-actions";
    public static NOT_FOUND = "/not-found";
    public static CLIENT_DASHBOARD = "/dashboard";
    public static PROBLEMATIC_ACCOUNTS = "/problematic-accounts"
    public static PROBLEMATIC_ACCOUNT_DETAILS = "/problematic-accounts/:id";
    public static MOBILE_PHONES = "/mobile-phones";
    public static MOBILE_PHONES_DETAILS = "/mobile-phones/:id";
    public static MOBILE_PROXIES = "/proxies/mobile-proxies";
    public static QUEUES = "/queues";
    public static QUEUE_NEW = "/queues/add";
    public static EDIT_QUEUE = "/queues/:id";
    public static REPORT_LISTENED_TRACKS_PER_ARTIST = "/reports/tracks-per-artist"
    public static LISTENED_TRACKS_BY_HOUR = "/reports/tracks-by-hour";
    public static PLAYLISTS_FOR_GENERATION = "/playlists-for-generation";
    public static ADD_NEW_TARGET_PLAYLIST = "/playlists-for-generation/new";
    public static PLAYLIST_DETAILS = "/playlists-for-generation/:id";
    public static PROBLEMATIC_BOTS = "/reports/problematic-bots";
    public static PLAYLISTS = "/playlists"
    public static GENRES = "/playlists/genres"
    public static ADD_GENRE = "/playlists/genres/new"
    public static EDIT_GENRE = "/playlists/genres/edit/:id"
    public static RECORD_LABEL = "/playlists/record-labels"
    public static ADD_RECORD_LABEL = "/playlists/record-labels/new"
    public static EDIT_RECORD_LABEL= "/playlists/record-labels/edit/:id"
    public static PROXY_TO_PROXY = "/proxies/proxy-to-proxy"
    public static ADD_PROXY_TO_PROXY = "/proxies/proxy-to-proxy/new"
    public static EDIT_PROXY_TO_PROXY= "/proxies/proxy-to-proxy/edit/:id"
}

