import { Clear } from "@mui/icons-material";
import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { defaultRowsPerPageOptions } from "../../common/Constants";
import { GuardedComponent } from "../../components/GuardedComponent";
import { SideBarPage } from "../../components/SideBarPage";
import { TableHeader } from "../../components/TableHeader";
import { strings } from "../../localization/Localization"
import { ListenedTrackClientInfoDTO } from "../../models/ListenedTrackClientInfoDTO";
import { ServiceDTO } from "../../models/ServiceDTO";
import { ClientService } from "../../services/ClientService";
import { ServicesService } from "../../services/ServicesService";
import { StorageService } from "../../storage/StorageService";
import { NumberParser } from "../../utils/NumberParser";
import { SortingConfiguration } from "../../utils/SortingUtils";
import DownloadIcon from '@mui/icons-material/Download';
import { PrivilegeGuard, PrivilegeGuardMode } from "../../router/guards/PrivilegeGuard";
import { Privileges } from "../../models/nomenclatures/Privileges";
import { ErrorHandler } from "../../utils/ErrorHandler";
import { showErrorDialog } from "../../common/Dialogs";
export function ListenedTrackStatistics() {
  const columns = [
    { label: strings.trackName, sortBy: "name", sortable: true },
    { label: strings.service, sortBy: "service.label", sortable: true },
    { label: strings.timeOfListening, sortBy: "timeElapsedInSeconds", sortable: false },
    { label: strings.viewsCount, sortBy: "totalViews", sortable: false },
  ]

  const [searchParams, setSearchParams] = useSearchParams();
  const page = NumberParser.parseNumber(searchParams.get("page"), 0);
  const rowsPerPage = NumberParser.parseNumber(searchParams.get("rowsPerPage"), 25);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [listenedTracksClient, setListenedTracksClient] = useState<ListenedTrackClientInfoDTO[]>([]);
  const [services, setServices] = useState<ServiceDTO[]>([]);
  const sortingConfigurationString = searchParams.get("sort");
  const serviceId = searchParams.get("serviceId")
  const trackName = searchParams.get("trackName") ?? "";
  let clientId = StorageService.getJWTPayload()?.clientId;


  function setSearchParam(key: string, value: string | null | undefined): void {
    if (value) {
      searchParams.set(key, value);
    } else {
      searchParams.delete(key);
    }

    setSearchParams(searchParams)
  }
  function setPage(page: number) {
    setSearchParam("page", page.toString());
  }

  function updateSort(sortingConfiguration: SortingConfiguration): void {
    setSearchParam("sort", sortingConfiguration.toSearchString());
  }

  function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchParam("rowsPerPage", event.target.value);
    setPage(0);
  }
  function handleChangeTrackName(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchParam("trackName", event.target.value);
    setPage(0);
  }

  function handleChangeServiceId(event: SelectChangeEvent) {
    const serviceIdString = event.target.value;
    setSearchParam("serviceId", serviceIdString.toString())
    setPage(0);
  }

  async function downloadReport() {
    await ClientService.getStatisticReportForClient(clientId?.toString()).catch((error) => {
      const message = ErrorHandler.parseErrorMessage(error);
      showErrorDialog(strings.error, message, strings.ok).then(_ => { });
    });
  }
  useEffect(() => {
    async function getListenedTrackClientPaged(page: number): Promise<void> {
      const response = await ClientService.getListenedTracksClientPaged(
        page,
        rowsPerPage,
        clientId,
        serviceId?.toString(),
        trackName?.toString(),
        SortingConfiguration.fromSearchString(sortingConfigurationString),
      );
      setTotalElements(response.totalElements);
      setListenedTracksClient(response.content);
    }

    async function getAllServices(): Promise<void> {
      const services = await ServicesService.getAllServices();
      setServices(services);
      if (!serviceId && (services?.length ?? 0) > 0) {
          setSearchParam("serviceId",services[0].id.toString());
      }
    }
    async function loadData(): Promise<void> {
     getAllServices();
    }
    loadData().then((_) => {
    });

    getAllServices();
    if (StorageService.getJWTPayload()?.clientId) {
      getListenedTrackClientPaged(page).then(_ => { });
    }
  }, [trackName, serviceId, rowsPerPage, page, sortingConfigurationString, searchParams]);

  return (
    <>
      <SideBarPage
        pageTitle={strings.listenedTracks}
        component={
          <Box>
            <GuardedComponent
              component={
                <Button className="btn btn-sm mb-4 d-flex" onClick={downloadReport} variant="contained" style={{ whiteSpace: "nowrap", float: "right" }}><DownloadIcon /> {" "} {strings.downloadReport}</Button>
              }
              guards={
                [
                  new PrivilegeGuard(
                    [Privileges.DOWNLOAD_CLIENT_REPORT, Privileges.DOWNLOAD_CLIENT_REPORT_FOR_CLIENT],
                    PrivilegeGuardMode.hasAny,
                  )
                ]
              }
              fallback={
                <Box />
              }
            />
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 500 }}
                aria-label="custom pagination table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TextField
                        variant="standard"
                        value={trackName}
                        label={strings.trackName}
                        name="trackName"
                        fullWidth
                        onChange={handleChangeTrackName}
                      />
                    </TableCell>
                    <TableCell>
                      <FormControl fullWidth variant="standard">
                        <InputLabel>{strings.service}</InputLabel>
                        <Select
                          defaultValue={""}
                          value={services.length > 0 && serviceId ? String(serviceId) : ""}
                          onChange={handleChangeServiceId}
                          label={strings.service}
                          labelId="serviceLabel"
                        >
                          {services?.map((service) => (
                            <MenuItem disableRipple key={service.id} value={service.id}>
                              {service.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell>

                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableHeader columns={columns} sortConfigurationString={sortingConfigurationString} updateSort={updateSort}></TableHeader>
                  <TableRow>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listenedTracksClient.map((listenedTrack, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {listenedTrack.trackName}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {listenedTrack.serviceName}
                      </TableCell>
                      <TableCell>{listenedTrack.totalListeningTimeInSeconds}</TableCell>
                      <TableCell>
                        {listenedTrack.totalViews}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={defaultRowsPerPageOptions}
              component="div"
              count={totalElements}
              rowsPerPage={rowsPerPage}
              page={totalElements <= 0 ? 0 : page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        }
      />
    </>
  )
}