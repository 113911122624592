import axios from "../network/AxiosInstance";
import {AuthService} from "./AuthService";
import {BotPlatformDTO} from "../models/BotPlatformDTO";

export class BotPlatformService {
    static async getAllBotPlatforms(): Promise<Array<BotPlatformDTO>> {
        const response = await axios.get<Array<BotPlatformDTO>>(
            `${AuthService.PREFIX}/bot-session/bot-platform/all`
        );
        const data = response.data;
        return data.map((service) => new BotPlatformDTO(service));
    }
}
