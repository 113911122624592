export class BotsDTO {
    id: number;
    name?: string;
    lastLog?: string;
    lastActionDate?: string;
    dateCreated?: string;
    dateModified?: string;
    status? : string | undefined;
    executionTotalUpTime?: number;
    totalScenarios?:number;

    constructor(json: any) {
        this.id = json.id;
        this.name = json.name;
        this.lastLog = json.lastLog;
        this.dateCreated = json.dateCreated;
        this.dateModified = json.dateModified;
        this.lastActionDate = json.lastActionDate;
        this.status = json.status;
        this.executionTotalUpTime = json.executionTotalUpTime;
        this.totalScenarios = json.totalScenarios;
    }
}
