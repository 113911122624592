import { AccountDTO } from "./AccountDTO";

export class AccountListeningInfoDTO{
    account: AccountDTO;
    timeElapsedInSeconds:number;
    constructor(json:AccountListeningInfoDTO){
        this.account = new AccountDTO(json.account);
        this.timeElapsedInSeconds =  json.timeElapsedInSeconds;
    }

}