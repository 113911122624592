
export class BotMinimalDTO {
    id:number;
    dateCreated:Date;
    dateModified:Date;
    name:string;
    lastLog:string;
    lastActionDate:Date;

    constructor(json:BotMinimalDTO){
        this.id = json.id;
        this.dateCreated = new Date(json.dateCreated);
        this.dateModified = new Date(json.dateModified);
        this.name=json.name;
        this.lastLog=json.lastLog;
        this.lastActionDate = new Date(json.lastActionDate);

    }
}