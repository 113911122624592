import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";

interface Props<T>{
    columnNames:string[],
    rows:[T],
    emptyRowsMessage:string
}

export function TableViewWithoutPagination<T>(props: Props<T>){
    return(
        <>
            {props.rows?.length > 0 ? (
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {props.columnNames.map((name)=>{
                                    return(
                                        <TableCell
                                            key={name}>
                                            {name}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.rows?.map((row:any, index)=>{
                                    return(
                                        <TableRow key={row.id}>
                                            {row.values?.map((value:any, index:number)=>{
                                                return(
                                                    <TableCell key={index}>
                                                        {value.value}
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    )
                                }
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (<div style={{textAlign:"center"}}>{props.emptyRowsMessage}</div>)}

        </>
    )
}