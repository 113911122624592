import { Clear } from "@mui/icons-material";
import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { defaultRowsPerPageOptions } from "../../common/Constants";
import { GuardedComponent } from "../../components/GuardedComponent";
import { SideBarPage } from "../../components/SideBarPage";
import { TableHeader } from "../../components/TableHeader";
import { strings } from "../../localization/Localization"
import { ClientService } from "../../services/ClientService";
import { StorageService } from "../../storage/StorageService";
import { NumberParser } from "../../utils/NumberParser";
import { SortingConfiguration } from "../../utils/SortingUtils";
import DownloadIcon from '@mui/icons-material/Download';
import { PrivilegeGuard, PrivilegeGuardMode } from "../../router/guards/PrivilegeGuard";
import { Privileges } from "../../models/nomenclatures/Privileges";
import { RegionDTO } from "../../models/RegionDTO";
import { RegionService } from "../../services/RegionService";
import { ServicesService } from "../../services/ServicesService";
import { ServiceDTO } from "../../models/ServiceDTO";
import { TimeElapsedGeographicReportDTO } from "../../models/TimeElapsedGeographicReportDTO";
import { ErrorHandler } from "../../utils/ErrorHandler";
import { showErrorDialog } from "../../common/Dialogs";
export function GeographicTimeElapsed() {

    const [searchParams, setSearchParams] = useSearchParams();
    const page = NumberParser.parseNumber(searchParams.get("page"), 0);
    const rowsPerPage = NumberParser.parseNumber(searchParams.get("rowsPerPage"), 25);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [listenedTracksClient, setListenedTracksClient] = useState<TimeElapsedGeographicReportDTO[]>([]);
    const [service, setService] = useState<ServiceDTO[]>([]);
    const [region, setRegion] = useState<RegionDTO[]>([])
    const sortingConfigurationString = searchParams.get("sort");
    const serviceId = searchParams.get("serviceId")
    const trackName = searchParams.get("trackName") ?? "";
    const artistName = searchParams.get("artistName") ?? "";
    let clientId = StorageService.getJWTPayload()?.clientId;
    const columns = [
        { label: strings.trackName, sortBy: "name", sortable: true },
        { label: strings.artist, sortBy: "artistName", sortable: true },
    ]

    listenedTracksClient.forEach((r, index) => {
        r.regionTimeElapsedDTO.map(region=>{
         const column = {
             label : region.regionDTO.label,
             sortBy : `region.${region.regionDTO.label}`,
             sortable: false
         }
         if (index === 0){
             columns.push(column)
         }
        })
     });

     columns.push ({label: strings.total, sortBy:"totalTimeElapsed", sortable:true})

    function setSearchParam(key: string, value: string | null | undefined): void {
        if (value) {
            searchParams.set(key, value);
        } else {
            searchParams.delete(key);
        }

        setSearchParams(searchParams)
    }
    function setPage(page: number) {
        setSearchParam("page", page.toString());
    }

    function updateSort(sortingConfiguration: SortingConfiguration): void {
        setSearchParam("sort", sortingConfiguration.toSearchString());
    }

    function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("rowsPerPage", event.target.value);
        setPage(0);
    }
    function handleChangeTrackName(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("trackName", event.target.value);
        setPage(0);
    }

    function handleChangeArtistName(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("artistName", event.target.value);
        setPage(0);
    }

    function handleChangeServiceId(event: SelectChangeEvent) {
        const serviceIdString = event.target.value;
        setSearchParam("serviceId", serviceIdString.toString())
        setPage(0);
    }

      async function downloadReport() {
        await ClientService.getGeographicReportForClient(clientId?.toString()).catch((error) => {
          const message = ErrorHandler.parseErrorMessage(error);
          showErrorDialog(strings.error, message, strings.ok).then(_ => { });
        });
      }

    useEffect(() => {
        async function getListenedTrackClientPaged(page: number): Promise<void> {
            const response = await ClientService.getGeographicReportListenedTrackTimeElapsed(
                page,
                rowsPerPage,
                clientId,
                serviceId?.toString(),
                trackName?.toString(),
                artistName?.toString(),
                SortingConfiguration.fromSearchString(sortingConfigurationString),
            );
            setTotalElements(response.totalElements);
            setListenedTracksClient(response.content);
        }

        async function getAllServices(): Promise<void> {
            const services = await ServicesService.getAllServices();
            setService(services);
            if (!serviceId && (services?.length ?? 0) > 0) {
                setSearchParam("serviceId",services[0].id.toString());
            }
          }
          async function loadData(): Promise<void> {
           getAllServices();
          }
          loadData().then((_) => {
          });

        function getAllRegions() {
            RegionService.getRegions().then((res) => {
                setRegion(res)
            })
        }

        getAllRegions();
        getAllServices();

        if (StorageService.getJWTPayload()?.clientId) {
            getListenedTrackClientPaged(page).then(_ => { });
        }
    }, [trackName, artistName, serviceId, rowsPerPage, page, sortingConfigurationString, searchParams, ]);

    return (
        <>
            <SideBarPage
                pageTitle={strings.listenedTracks}
                component={
                    <Box>
                        <GuardedComponent
                            component={
                                <Button className="btn btn-sm mb-4 d-flex" variant="contained" style={{ whiteSpace: "nowrap", float: "right" }} onClick={downloadReport}><DownloadIcon /> {" "} {strings.downloadReport}</Button>
                            }
                            guards={
                                [
                                    new PrivilegeGuard(
                                        [Privileges.DOWNLOAD_CLIENT_REPORT, Privileges.DOWNLOAD_CLIENT_REPORT_FOR_CLIENT],
                                        PrivilegeGuardMode.hasAny,
                                    )
                                ]
                            }
                            fallback={
                                <Box />
                            }
                        />
                        <TableContainer component={Paper} sx={{maxWidth: 1780}} style={{ overflowX: "auto" }}>
                            <Table
                                sx={{ minWidth: "500px" }}
                                aria-label="custom pagination table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <TextField
                                                variant="standard"
                                                value={trackName}
                                                label={strings.trackName}
                                                name="trackName"
                                                onChange={handleChangeTrackName}
                                            />
                                        </TableCell>
                                        <TableCell>
                                        <TextField
                                                variant="standard"
                                                value={artistName}
                                                label={strings.artist}
                                                name="artistName"
                                                onChange={handleChangeArtistName}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <FormControl 
                                            variant="standard"
                                            >
                                                <InputLabel>{strings.service}</InputLabel>
                                                <Select
                                                    defaultValue={""}
                                                    value={service.length > 0 && serviceId ? String(serviceId) : ""}
                                                    onChange={handleChangeServiceId}
                                                    label={strings.service}
                                                    labelId="serviceLabel"
                                                >
                                                    {service?.map((service) => (
                                                        <MenuItem disableRipple key={service.id} value={service.id}>
                                                            {service.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </TableCell>
                                     
                                    </TableRow>
                                    <TableHeader columns={columns} sortConfigurationString={sortingConfigurationString} updateSort={updateSort}></TableHeader>
                                    <TableRow>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {listenedTracksClient.map((listenedTrack, index) => (
                                        <TableRow key={index}>
                                            <TableCell component="th" scope="row">
                                                <strong>{listenedTrack.trackName}</strong>
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                               <strong>{listenedTrack.artistName}</strong> 
                                            </TableCell>
                                            {
                                               
                                               listenedTrack.regionTimeElapsedDTO.map((region, index)=>
                                                    <TableCell key={index} style={{textAlign:"center"}}> {region.timeElapsed}</TableCell>
                                                )
                                        }
                                        <TableCell>{listenedTrack.totalTimeElapsed}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={defaultRowsPerPageOptions}
                            component="div"
                            count={totalElements}
                            rowsPerPage={rowsPerPage}
                            page={totalElements <= 0 ? 0 : page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Box>
                }
            />
        </>
    )
}