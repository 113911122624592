export class AccountStatus {
    id: number;
    worksProperly: boolean;
    errorMessage: string;


    constructor(json: {id: number, worksProperly: boolean, errorMessage: string}) {
        this.id = json.id;
        this.worksProperly = json.worksProperly;
        this.errorMessage = json.errorMessage;
    }
}