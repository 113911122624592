export class ProxyToProxyCreationDTO {
    name?: string;
    countryId?: number;
    url: string;
    provider?: string;
    asn?: number;

    constructor(name: string, countryId: number, url: string, provider: string, asn: number) {
        this.name = name;
        this.countryId = countryId;
        this.url = url;
        this.provider = provider;
        this.asn = asn;
    }
}
