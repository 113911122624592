

import {AccountDetails} from "./AccountDetails";

export function NewAccount() {

    return (

        <> <AccountDetails/> </>

    )
}
