import {SideBarPage} from "../../../components/SideBarPage";
import {strings} from "../../../localization/Localization";
import {Button, Grid, Input, Paper, TablePagination} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import React, {useEffect, useState} from "react";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {NumberParser} from "../../../utils/NumberParser";
import {TableHeader} from "../../../components/TableHeader";
import {SortingConfiguration} from "../../../utils/SortingUtils";
import {PaginationPlus} from "../../../components/PaginationPlus";
import {defaultRowsPerPageOptions} from "../../../common/Constants";
import TableBody from "@mui/material/TableBody";
import {ProxyGroupService} from "../../../services/ProxyGroupService";
import {ProxyGroupReducedDTO} from "../../../models/ProxyGroupReducedDTO";
import {guardedCallback, guardedObject} from "../../../router/guards/GuardedCallback";
import {PrivilegeGuard, PrivilegeGuardMode} from "../../../router/guards/PrivilegeGuard";
import {Privileges} from "../../../models/nomenclatures/Privileges";
import {Routes} from "../../../router/Routes";

export function ProxyGroups() {
    const listLinks = [
        { label: strings.dashboard, currentlyOpened: false, href:"/"},
        { label: strings.proxyGroups,  currentlyOpened: true },
    ]

    const columns = [
        {label: strings.name, sortBy: "name", sortable: true},
        {label: "", sortBy: "", sortable: false}
    ];

    const [searchParams, setSearchParams] = useSearchParams();
    const [proxyGroups, setProxyGroups] = useState<ProxyGroupReducedDTO[]>([])
    const name = searchParams.get("name") ?? "";
    const [totalElements, setTotalElements] = useState(0);
    const rowsPerPage = NumberParser.parseNumber(searchParams.get("rowsPerPage"), 25);
    const page = NumberParser.parseNumber(searchParams.get("page"), 0);
    const sortingConfigurationString = searchParams.get("sort");
    const navigate = useNavigate();

    function setSearchParam(key: string, value: string | null | undefined) {
        if (value) {
            searchParams.set(key, value);
        } else {
            searchParams.delete(key);
        }

        setSearchParams(searchParams)
    }

    function setPage(page: number) {
        setSearchParam("page", page.toString());
    }

    function updateSort(sortingConfiguration: SortingConfiguration): void {
        setSearchParam("sort", sortingConfiguration.toSearchString());
    }

    function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("rowsPerPage", event.target.value);
        setPage(0);
    }

    function handleNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("name", event.target.value);
        setPage(0);
    }

    async function getProxyGroups(page: number): Promise<void> {
        ProxyGroupService.getAllProxyGroupsPaged(page, rowsPerPage,name).then((data) => {
            setProxyGroups(data.content);
            setTotalElements(data.totalElements);
        })
    }

    useEffect(() => {
        getProxyGroups(page).then(_ => {});
    }, [page, rowsPerPage, name]);

    function getRowLink(entry: ProxyGroupReducedDTO): string {
        return guardedObject([
            new PrivilegeGuard(
                [Privileges.MANAGE_PROXY, Privileges.READ_REGIONS],
                PrivilegeGuardMode.hasAll
            )
        ], Routes.PROXY_GROUP_DETAILS.replace(":id", entry.id.toString())) ?? "";
    }

    function navigateProxyRow(id: number): any {
        navigate(Routes.PROXY_GROUP_DETAILS.replace(":id", id.toString()));
    }

    function navigateAddProxyGroup(){
        navigate(Routes.PROXY_GROUP_NEW)
    }

    return (
        <SideBarPage pageTitle={strings.proxyGroups} breadcrumbs={listLinks} component={
            <Grid>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Input
                                        name="name"
                                        value={name}
                                        onChange={handleNameChange}
                                        placeholder={strings.nameFilter}
                                        style={{width: "auto"}}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Button variant={"contained"} style={{float: "right"}} onClick={navigateAddProxyGroup}>{strings.addProxyGroup}</Button>
                                </TableCell>
                            </TableRow>
                            <TableHeader columns={columns} sortConfigurationString={sortingConfigurationString}
                                         updateSort={updateSort}></TableHeader>
                        </TableHead>
                        <TableBody>
                            {proxyGroups?.map((proxyGroup) => (
                                <TableRow component={getRowLink(proxyGroup) ? Link : TableRow} to={getRowLink(proxyGroup)}
                                          key={proxyGroup.id}
                                          hover={true}
                                          className="cursor-pointer"
                                          onClick={guardedCallback([
                                              new PrivilegeGuard(
                                                  [Privileges.MANAGE_PROXY, Privileges.READ_REGIONS],
                                                  PrivilegeGuardMode.hasAll
                                              )
                                          ],() => navigateProxyRow(proxyGroup.id))}
                                >
                                    <TableCell width={"100%"}>{proxyGroup.name}</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid sx={{display: 'flex', flexDirection: 'column', alignItems: 'end', marginTop: "10px"}}>
                    <PaginationPlus totalElements={totalElements} rowsPerPage={rowsPerPage} page={page} onChangePage={(page) => handleChangePage(null, page)}/>
                    <TablePagination
                        rowsPerPageOptions={defaultRowsPerPageOptions}
                        component="div"
                        count={totalElements}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Grid>
            </Grid>
        }  />
    )
}