export class QueueDTO  {
    key: string;
    id: number;
    dateCreated?: Date;
    dateModified?: Date;
    
    constructor(json: any) {
        this.key = json.key;
        this.id = json.id;
        this.dateCreated = json.dateCreated ? new Date(json.dateCreated) : undefined;
        this.dateModified = json.dateModified ? new Date(json.dateModified) : undefined;
    };
}
