import { AccountGroupReducedDTO } from "./AccountGroupReducedDTO";

export class ListenedTracksPerDayPerGroupDTO {
    accountGroup: AccountGroupReducedDTO[];
    listenedTracks: number;

    constructor(json: any) {
        this.accountGroup = json.accountGroup;
        this.listenedTracks = json.listenedTracks;
    }
}