import {MobilePhoneDTO} from "./MobilePhoneDTO";
import {CountryDTO} from "./CountryDTO";

export class MobileProxyStatusDTO {
    mobilePhone: MobilePhoneDTO
    serverName: string
    countryDTO: CountryDTO | undefined;
    proxyUrl: string;
    status: string;
    dateModified: string;
    rotateUrl: string | undefined;

    constructor(json: any) {
        this.mobilePhone = new MobilePhoneDTO(json.mobilePhone);
        this.serverName = json.serverName;
        this.countryDTO = json.countryDTO ? new CountryDTO(json.countryDTO) : undefined;
        this.proxyUrl = json.proxyUrl;
        this.status = json.status;
        this.dateModified = json.dateModified;
        this.rotateUrl = json.rotateUrl;
    }
}
