import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {Button, CardActions, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";
import {strings} from '../../../localization/Localization';
import {CenteredCardLayout} from "../../../components/CenteredCardLayout";
import {SideBarPage} from "../../../components/SideBarPage";
import ConfirmationModal from "../../../components/ConfirmationModal";
import {ErrorHandler} from "../../../utils/ErrorHandler";
import {showErrorDialog, showSuccessDialog} from "../../../common/Dialogs";
import {Routes} from "../../../router/Routes";
import {ProxyToProxyCreationDTO} from '../../../models/ProxyToProxyCreationDTO';
import {ProxyToProxyService} from "../../../services/ProxyToProxyService";
import {ProxyToProxyDTO} from "../../../models/ProxyToProxyDTO";
import {CountryDTO} from "../../../models/CountryDTO";
import {CountryService} from "../../../services/CountryService";
import {PaginatedSelect} from "../../../components/PaginatedSelect";

export function ProxyToProxyDetails() {
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [proxyToProxyData, setProxyToProxyData] = useState<ProxyToProxyDTO | null>(null);

    let {id} = useParams();

    function onSubmit() {
        const proxyToProxy = new ProxyToProxyCreationDTO(
            getValues("name") ?? "",
            Number(getValues("country.id")),
            getValues("url"),
            getValues("provider") ?? "",
            Number(getValues("asn"))
        );

        if (isAddMode) {
            addProxyToProxy(proxyToProxy);
        } else {
            editProxyToProxy(proxyToProxy);
        }
    }

    const {
        handleSubmit,
        setValue,
        control,
        watch,
        getValues,
        formState: {errors}
    } = useForm<ProxyToProxyDTO>({
        defaultValues: {
            name: "",
            country: undefined,
            url: "",
            provider: "",
            asn: undefined
        },
        mode: "onChange"
    });
    watch();

    const isAddMode = !id;
    const listLinks = [
        {label: strings.dashboard, currentlyOpened: false, href: Routes.HOME},
        {label: strings.proxyToProxy, currentlyOpened: false, href: Routes.PROXY_TO_PROXY},
        {label: isAddMode ? strings.addProxyToProxy : strings.editProxyToProxy, currentlyOpened: true}
    ];

    const navigate = useNavigate();

    useEffect(() => {
        function getProxyToProxyById(id: number) {
            ProxyToProxyService.getProxyToProxy(id).then((res) => {
                setProxyToProxyData(res);
                setValue("name", res.name ?? "");
                setValue("country", res.country ?? undefined);
                setValue("url", res.url);
                setValue("provider", res.provider ?? "");
                setValue("asn", res.asn ?? undefined);
            })
        }

        async function getData() {
            if (id) {
                getProxyToProxyById(parseInt(id));
            }
        }

        getData().then(_ => {
        });
    }, [id, setValue])

    function addProxyToProxy(proxyToProxy: ProxyToProxyCreationDTO) {
        ProxyToProxyService.createProxyToProxy(proxyToProxy).then(() => {
            showSuccessDialog(strings.success, strings.proxyToProxyAddedSuccessfully, strings.ok).then(_ => {
            });

            navigate(Routes.PROXY_TO_PROXY);
        }).catch(e => {
            const errorMessage = ErrorHandler.parseErrorMessage(e);
            showErrorDialog(strings.error, errorMessage, strings.ok).then(_ => {
            });
        });
    }

    function editProxyToProxy(proxyToProxy: ProxyToProxyCreationDTO) {
        ProxyToProxyService.editProxyToProxy(proxyToProxy, proxyToProxyData?.id).then(() => {
            showSuccessDialog(strings.success, strings.proxyToProxyEditedSuccessfully, strings.ok).then(_ => {
            });

            navigate(Routes.PROXY_TO_PROXY);
        }).catch(e => {
            const errorMessage = ErrorHandler.parseErrorMessage(e);
            showErrorDialog(strings.error, errorMessage, strings.ok).then(_ => {
            });
        });
    }

    async function deleteProxyToProxy(): Promise<void> {
        setOpenModal(false);

        if (proxyToProxyData === null) {
            return;
        }

        try {
            await ProxyToProxyService.deleteProxyToProxy(proxyToProxyData.id);
            showSuccessDialog(strings.success, strings.proxyToProxyDeletedSuccessfully, strings.ok).then(_ => {
                navigate(Routes.PROXY_TO_PROXY);
            });
        } catch (e: any) {
            const errorMessage = ErrorHandler.parseErrorMessage(e);
            showErrorDialog(strings.error, errorMessage, strings.ok).then(_ => {
            });
        }
    }

    function handleCancel(): void {
        navigate(Routes.PROXY_TO_PROXY, {});
    }

    function handleClickOpen(): any {
        setOpenModal(true);
    }

    function handleClose(): any {
        setOpenModal(false);
    }

    return (
        <SideBarPage pageTitle={isAddMode ? strings.addProxyToProxy : strings.editProxyToProxy}
                     breadcrumbs={listLinks}
                     component={
                         <>
                             <CenteredCardLayout>
                                 <form onSubmit={handleSubmit(onSubmit)}>
                                     <Controller
                                         name="name"
                                         control={control}
                                         render={({field}) => (
                                             <TextField
                                                 InputLabelProps={{
                                                     shrink: !!getValues("name"),
                                                 }}
                                                 {...field}
                                                 value={field.value}
                                                 id={"key"}
                                                 label={strings.proxyToProxyName}
                                                 className={"w-100"}
                                                 style={{marginBottom: 15}}
                                                 error={errors.name !== undefined}
                                             />
                                         )}
                                     />
                                     <div className="mb-3">
                                         <Controller
                                             name="country"
                                             rules={{required: false}}
                                             control={control}
                                             render={({field, fieldState}) => (
                                                 <PaginatedSelect<CountryDTO>
                                                     variant="outlined"
                                                     value={field.value}
                                                     onChange={field.onChange}
                                                     label={strings.country}
                                                     valueMapper={(item) => item.id.toString()}
                                                     keyMapper={(item) => item.id.toString()}
                                                     itemMapper={(item) => <>{item.commonName}</>}
                                                     labelMapper={(item) => item.commonName}
                                                     dataFetcher={(page, size, filter) =>
                                                         CountryService.findAllCountriesPaged(page, size, filter)
                                                     }
                                                 />
                                             )}
                                         />
                                     </div>
                                     <Controller
                                         name="url"
                                         rules={{required: true}}
                                         control={control}
                                         render={({field}) => (
                                             <TextField
                                                 InputLabelProps={{
                                                     shrink: !!getValues("url"),
                                                 }}
                                                 {...field}
                                                 value={field.value}
                                                 id={"key"}
                                                 label={strings.url}
                                                 className={"w-100"}
                                                 style={{marginBottom: 15}}
                                                 error={errors.url !== undefined}
                                             />
                                         )}
                                     />
                                     <Controller
                                         name="provider"
                                         control={control}
                                         render={({field}) => (
                                             <TextField
                                                 InputLabelProps={{
                                                     shrink: !!getValues("provider"),
                                                 }}
                                                 {...field}
                                                 value={field.value}
                                                 id={"key"}
                                                 label={strings.provider}
                                                 className={"w-100"}
                                                 style={{marginBottom: 15}}
                                                 error={errors.provider !== undefined}
                                             />
                                         )}
                                     />
                                     <Controller
                                         name="asn"
                                         control={control}
                                         render={({field}) => (
                                             <TextField
                                                 InputLabelProps={{
                                                     shrink: !!getValues("asn"),
                                                 }}
                                                 {...field}
                                                 value={field.value}
                                                 id={"key"}
                                                 label={strings.asn}
                                                 type="number"
                                                 onKeyPress={(event) => {
                                                     if (!/[0-9]/.test(event.key)) {
                                                         event.preventDefault();
                                                     }
                                                 }}
                                                 className={"w-100"}
                                                 style={{marginBottom: 15}}
                                                 error={errors.asn !== undefined}
                                             />
                                         )}
                                     />
                                     <CardActions className="d-flex p-0 mt-4">
                                         {isAddMode ? (
                                             <>
                                                 <Button
                                                     variant="contained"
                                                     onClick={handleCancel}
                                                     color={"warning"}
                                                     className="w-50"
                                                 >
                                                     {strings.cancel}
                                                 </Button>
                                                 <Button
                                                     variant="contained"
                                                     type="submit"
                                                     startIcon={<EditIcon/>}
                                                     color={"primary"}
                                                     className="w-50"
                                                 >
                                                     {strings.saveChanges}
                                                 </Button>
                                             </>
                                         ) : (
                                             <>
                                                 <Button
                                                     variant="contained"
                                                     startIcon={<DeleteIcon/>}
                                                     onClick={handleClickOpen}
                                                     color={"error"}
                                                     className="w-50"
                                                 >
                                                     {strings.deleteProxyToProxyM}
                                                 </Button>
                                                 <Button
                                                     variant="contained"
                                                     type="submit"
                                                     startIcon={<EditIcon/>}
                                                     color={"primary"}
                                                     className="w-50"
                                                 >
                                                     {strings.saveChanges}
                                                 </Button>
                                             </>
                                         )}
                                         <ConfirmationModal
                                             open={openModal}
                                             handleClose={handleClose}
                                             handleConfirm={deleteProxyToProxy}
                                             title={strings.deleteProxyToProxy}
                                             content={strings.deleteProxyToProxySure}
                                             actionButtonName={strings.deleteProxyToProxyM}
                                         />
                                     </CardActions>
                                 </form>
                             </CenteredCardLayout>
                         </>
                     }/>
    );
}
