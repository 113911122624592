export class ViewsCountPerDayDTO {
    date: string;
    viewCount: number;
    uniqueListeners: number;
    constructor(json: any) {
        this.date = json.date;
        this.viewCount = json.viewCount;
        this.uniqueListeners = json.uniqueListeners;
    }
}
