import { BehaviorAlgorithm } from "./BehaviorAlgorithm";
import { BehaviorConfiguration } from "./BehaviorConfiguration";

export class BehaviorDTO {
id:number;
name:string;
behaviorAlgorithm: BehaviorAlgorithm;
parameters: BehaviorConfiguration;
constructor(json:{id:number;name:string, behaviorAlgorithm: BehaviorAlgorithm, parameters:BehaviorConfiguration }){
    this.id = json.id;
    this.name = json.name;
    this.behaviorAlgorithm = new BehaviorAlgorithm(json.behaviorAlgorithm);
    this.parameters = json.parameters;

}
}