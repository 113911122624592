export class RegionCreationDTO {
    key: string
    label: string
    countryId?: number

    constructor(json: any) {
        this.key = json.key;
        this.label = json.label;
        this.countryId = json.countryId;
    }
}