import {Guard} from "./Guard";

type CallbackFunction = () => void;

export function guardedCallback(guards: Guard[], callback: CallbackFunction, fallback?: CallbackFunction): CallbackFunction {
    for (const guard of guards) {
        if (!guard.check()) {
            return fallback ?? (() => {});
        }
    }

    return callback;
}

export function guardedObject<T>(guards: Guard[], onPass: T, onFail?: T): T | undefined {
    for (const guard of guards) {
        if (!guard.check()) {
            return onFail;
        }
    }

    return onPass;
}