import {Page} from "../../components/Page";
import {FormEvent, useState} from "react";
import {AppController} from "../../controllers/AppController";
import {useNavigate} from "react-router-dom";
import './Login.css';
import {Localization, strings} from "../../localization/Localization";
import {Button, Link, TextField} from "@mui/material";
import {CenteredCardLayout} from "../../components/CenteredCardLayout";
import {Routes} from "../../router/Routes";
import {VisibilityEye} from "../../components/VisibilityEye";

export function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    async function login(event: FormEvent): Promise<void> {
            event.preventDefault();
            try {
                return await AppController.login(navigate, email, password);
            } catch (e) {
                alert('Invalid username or password.');
            }
    }

    function handleResetPassword() {
        navigate(Routes.RESET_PASSWORD);
    }

    function setLanguage(language: string): void {
        Localization.setLanguage(language);
    }

    return (
        <Page title="Login"
              component={
            <CenteredCardLayout minWidth={"30%"} maxWidth={"30%"} className={"mt-5"}>
                <form onSubmit={login}>
                          <div className="d-flex justify-content-md-center align-items-center">STREAMING BOTS</div>
                          <div>
                              <div className="mt-3 mb-3 d-flex justify-content-md-center align-items-center">
                                  <Button className="btn-sm" onClick={() => setLanguage(Localization.LANGUAGE_EN)}>{strings.english}</Button>
                                  <Button className="btn-sm" onClick={() => setLanguage(Localization.LANGUAGE_PT_BR)}>{strings.portuguese}</Button>
                                  <Button className="btn-sm" onClick={() => setLanguage(Localization.LANGUAGE_ES_BR)}>{strings.spanish}</Button>
                              </div>
                              <div>
                                  <TextField
                                      onChange={(event) => setEmail(event.target.value)}
                                      className="mb-3"
                                      sx = {{width:'100%'}}
                                      fullWidth
                                      required
                                      id="email"
                                      label="Email"
                                  />
                                  <VisibilityEye
                                      onChange={setPassword}
                                      className={"w-100"}
                                      label={"Password *"}
                                  />
                                  <Link onClick={handleResetPassword} className="float-end mt-2 d-inline cursor-pointer">
                                      Forgot your password?
                                  </Link>
                                  <Button variant="contained" className="btn-round w-100 mt-3 btn btn-primary"
                                          type="submit" onClick={login}> {strings.login}</Button>
                              </div>
                          </div>
                </form>
            </CenteredCardLayout>
              }
        />
    );
}
