import {RegionDTO} from "../models/RegionDTO";
import axiosInstance from "../network/AxiosInstance";
import {SortingConfiguration} from "../utils/SortingUtils";
import {RegionPageDTO} from "../models/RegionPageDTO";
import {Page} from "../pagination/Page";
import {ProxyCreationDTO} from "../models/ProxyCreationDTO";
import {ProxyDTO} from "../models/ProxyDTO";
import {RegionCreationDTO} from "../models/RegionCreationDTO";

export class RegionService {
    static async getRegions(): Promise<Array<RegionDTO>> {
        const response = await axiosInstance.get<Array<RegionDTO>>(`/rest/bot-session/region/all`);
        const data =  response.data;
        return data.map(region => new RegionDTO(region))
    }

    static async getAllRegionsPaged(page: number, size: number, key?: string, label?: string,
                                    countryCommonName?: string, sortingConfiguration?: SortingConfiguration | undefined): Promise<Page<RegionPageDTO>> {
        const params = new URLSearchParams({
            page: page.toString(),
            size: size.toString()
        });

        if(key){
            params.append("key", key)
        }

        if(label){
            params.append("label", label)
        }

        if(countryCommonName){
            params.append("countryCommonName", countryCommonName)
        }

        sortingConfiguration?.appendToBackEndSearchParams(params);

        const response = await axiosInstance.get<Page<RegionPageDTO>>(
            `/rest/bot-session/region/all-paged`, {params: params}
        )

        const data = response.data

        return new Page<RegionPageDTO>(
            data.content.map(region => new RegionPageDTO(region)),
            data.totalElements
        )
    }

    static async getRegionById(id: number): Promise<RegionPageDTO> {
        const response = await axiosInstance.get(`/rest/bot-session/region/${id}`,)
        const data = response.data;

        return new RegionPageDTO(data);
    }

    static async createRegion(region: RegionCreationDTO): Promise<RegionPageDTO> {
        const response = await axiosInstance.post<RegionPageDTO>(
            `/rest/bot-session/region/create`, region
        )

        const data = response.data;
        return new RegionPageDTO(data);
    }

    static async editRegion(region: RegionCreationDTO, id: number | undefined): Promise<RegionPageDTO> {
        const response = await axiosInstance.post<RegionPageDTO>(
            `/rest/bot-session/region/update/${id}`, region,
        );

        const data = response.data;
        return new RegionPageDTO(data);
    }

    static async deleteRegion(id: number): Promise<void> {
        await axiosInstance.delete<RegionDTO>(`/rest/bot-session/region/delete/${id}`, {params: {id}});
    }
}