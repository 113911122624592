import { SessionActionDTO } from "./SessionActionDTO";

export class SessionInstanceActionDTO {
    id:number;
    sessionAction:SessionActionDTO;
    targetSongOrder:number;
    actualSongOrder:number;
    timeElapsedInSeconds:number;
    durationInSeconds:number;
    numberOfListenedTracks: number | undefined;
    success:boolean;
    order:number;
    dateFinished:string;
    songTitle:string;
    errorMessage:string;
    dateCreated:string;
    isPlaying:boolean;
    actualSourceKey: string | undefined;
    constructor(json:SessionInstanceActionDTO){
        this.id = json.id;
        this.sessionAction = new SessionActionDTO(json.sessionAction);
        this.targetSongOrder = json.targetSongOrder;
        this.actualSongOrder = json.actualSongOrder;
        this.timeElapsedInSeconds = json.durationInSeconds;
        this.durationInSeconds = json.durationInSeconds;
        this.numberOfListenedTracks = json.numberOfListenedTracks;
        this.success = json.success;
        this.order = json.order;
        this.dateFinished = json.dateFinished;
        this.songTitle = json.songTitle;
        this.errorMessage = json.errorMessage;
        this.dateCreated = json.dateCreated;
        this.isPlaying = json.isPlaying;
        this.actualSourceKey = json.actualSourceKey;
    }

    get sourceKey() {
        if (this.actualSourceKey) {
            return this.actualSourceKey;
        }

        return this.sessionAction.sourceKey;
    }
}
