import axios from "../network/AxiosInstance";
import { AuthService } from "./AuthService";
import { AccountListeningInfoDTO } from "../models/AccountListeningInfoDTO";
import {Page} from "../pagination/Page";
import { SortingConfiguration } from "../utils/SortingUtils";
import { ListenedTrackInfoDTO } from "../models/ListenedTrackInfoDTO";
export class ListenedTracksService {
    static async getAllListenedTracksPaged(page: number, size: number,name:string | undefined, artist:string | undefined, serviceId:string | number | undefined, sort:SortingConfiguration):Promise<Page<ListenedTrackInfoDTO>>{
      let params = new URLSearchParams();
      params.append("page", page.toString());
      params.append("size", size.toString());
      if(name){
        params.append("name", name);
      }
      if(artist){
        params.append("artist", artist);
      }
      if (serviceId) {
        params.append("serviceId", serviceId?.toString());
      }
      sort.appendToBackEndSearchParams(params);
        const request = {
            params: params, 
        };
        const response = await axios.get<Page<ListenedTrackInfoDTO>>(
          `${AuthService.PREFIX}/bot-session/listened-track/all-paged`, request
      );
      const data = response.data;
      return new Page<ListenedTrackInfoDTO>(
        data.content.map((track) => new ListenedTrackInfoDTO(track)),
        data.totalElements
      );
    }
    static async getAccountsOfListenedTracks(page: number, size: number, accountEmail:string | undefined, accountRegionId:number | string | undefined, accountAgeGroupId:number | string | undefined, name:string, artist:string, serviceId:number | string, accountGroupName:string | undefined, sort: SortingConfiguration): Promise<Page<AccountListeningInfoDTO>>  {
        let params = new URLSearchParams();
        params.append("page", page.toString());
        params.append("size", size.toString());
        if(accountEmail){
          params.append("accountEmail", accountEmail);
        }
        if(accountRegionId){
          params.append("accountRegionId", accountRegionId?.toString());
        }
        if (accountGroupName) {
          params.append("accountGroupName", accountGroupName);
        }
        if(accountAgeGroupId){
          params.append("accountAgeGroupId",accountAgeGroupId.toString())
        }
        params.append("name", name);
        params.append("artist", artist);
        params.append("serviceId", serviceId.toString())
        sort.appendToBackEndSearchParams(params);
        const request = {
            params: params, 
        };
        const response = await axios.get<Page<AccountListeningInfoDTO>>(
            `${AuthService.PREFIX}/bot-session/listened-track/listening-accounts`, request
        );
        const data = response.data;
        return new Page<AccountListeningInfoDTO>(
          data.content.map((account) => new AccountListeningInfoDTO(account)),
          data.totalElements
        );
    }
}
