import {AddEditUser} from "./AddEditUser";
import {useEffect} from "react";
import {InterComponentExchange} from "../../../models/InterComponentExchange";
import {InterComponentExchangeConstants} from "../../../models/InterComponentExchangeConstants";
export function AddUser(){
    useEffect(() => {
    }, []);
    return(
        <AddEditUser
            info={{interComponentExchange:new InterComponentExchange(undefined, new InterComponentExchangeConstants().ADD_COMPONENT)}}
        />
    )
}