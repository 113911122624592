import {SideBarPage} from "../../components/SideBarPage";
import {useNavigate, useParams} from "react-router-dom";
import {strings} from "../../localization/Localization";
import {Routes} from "../../router/Routes";
import {Controller, useForm} from "react-hook-form";
import {RegionCreationDTO} from "../../models/RegionCreationDTO";
import {showErrorDialog, showSuccessDialog} from "../../common/Dialogs";
import {ErrorHandler} from "../../utils/ErrorHandler";
import React, {useEffect, useState} from "react";
import {RegionDTO} from "../../models/RegionDTO";
import {CenteredCardLayout} from "../../components/CenteredCardLayout";
import {Button, CardActions, TextField} from "@mui/material";
import {CountryDTO} from "../../models/CountryDTO";
import {CountryService} from "../../services/CountryService";
import {PaginatedSelect} from "../../components/PaginatedSelect";
import {RegionService} from "../../services/RegionService";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ConfirmationModal from "../../components/ConfirmationModal";
class RegionFormData {
    id: number
    dateCreated: string
    dateModified: string
    key: string
    label: string
    country: CountryDTO | undefined


    constructor(json: any) {
        this.id = json.id;
        this.dateCreated = json.dateCreated;
        this.dateModified = json.dateModified;
        this.key = json.key;
        this.label = json.label;
        this.country = json.country;
    }

    toRegionCreationDTO(): RegionCreationDTO {
        return new RegionCreationDTO({
            key: this.key,
            label: this.label,
            countryId: this.country?.id
        })
    }
}

export function RegionDetails() {
    let {id} = useParams();
    const isAddMode = !id;
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [regionData, setRegionData] = useState<RegionDTO | null>(null);

    const listLinks = [
        { label: strings.dashboard, currentlyOpened: false, href:Routes.HOME},
        { label: strings.regions,  currentlyOpened: false, href:Routes.REGIONS },
        {label: isAddMode ? strings.addRegion : strings.editRegionButton, currentlyOpened:true}
    ]

    const {
        handleSubmit,
        setValue,
        control,
        watch,
        getValues,
        formState: {errors}
    } = useForm<RegionFormData>({
        defaultValues: {
            key: "",
            label: "",
            country: undefined
        },
        mode: "onChange"
    });
    watch();

    const navigate = useNavigate();

    function buildRegion(): RegionCreationDTO {
        console.log(getValues())
        return new RegionFormData(getValues()).toRegionCreationDTO();
    }

    function onSubmit() {
        return isAddMode ? addRegion() : editRegion()
    }

    async function deleteRegion(): Promise<void> {
        setOpenModal(false);

        if (regionData === null) {
            return;
        }

        try {
            await RegionService.deleteRegion(regionData.id);
            showSuccessDialog(strings.success, strings.regionDeletedSuccessfully, strings.ok).then(_ => {
                navigate(Routes.REGIONS);
            });
        } catch (e: any) {
            const errorMessage = ErrorHandler.parseErrorMessage(e);
            showErrorDialog(strings.error, errorMessage, strings.ok).then(_ => {});
        }
    }

    function addRegion() {
        RegionService.createRegion(buildRegion()).then((newRegion) => {
            showSuccessDialog(strings.success,strings.RegionAddedSuccessfully,strings.ok).then(_=> {
                navigate(Routes.REGION_DETAILS.replace(":id", newRegion?.id!.toString()))
            });
        }).catch((error) => {
            const message = ErrorHandler.parseErrorMessage(error);
            showErrorDialog(strings.error, message, strings.ok).then(_ => {});
        })
    }

    function editRegion() {
        if (regionData === null) {
            return;
        }

        RegionService.editRegion(buildRegion(), regionData?.id).then(() => {
            showSuccessDialog(strings.success, strings.RegionUpdatedSuccessfully, strings.ok).then(_ => {
            });

            navigate(Routes.REGIONS);
        }).catch(e => {
            const errorMessage = ErrorHandler.parseErrorMessage(e);
            showErrorDialog(strings.error, errorMessage, strings.ok).then(_ => {
            });
        });
    }

    useEffect(() => {
        function getRegionById(id: number) {
            RegionService.getRegionById(id).then((res) => {
                setRegionData(res);
                setValue("key", res.key ?? "")
                setValue("label", res.label ?? "")
                setValue("country", res.country)
            })
        }

        async function getData() {
            if(id) {
                await getRegionById(parseInt(id));
            }
        }

        getData().then(_ => {})

    }, [id, setValue])

    function handleClose(): any {
        setOpenModal(false);
    }

    function handleCancel(): void {
        navigate(Routes.REGIONS, {});
    }

    function handleClickOpen(): any {
        setOpenModal(true);
    }

    return (
        <SideBarPage pageTitle={isAddMode ? strings.addRegion : strings.regions} breadcrumbs={listLinks} component={
            <CenteredCardLayout>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                        name="key"
                        rules={{required: true}}
                        control={control}
                        render={({field}) => (
                            <TextField
                                InputLabelProps={{
                                    shrink: !!getValues("key"),
                                }}
                                {...field}
                                value={field.value}
                                id={"name"}
                                label={strings.key}
                                className={"w-100"}
                                style={{marginBottom: 15}}
                                error={errors.key !== undefined}
                            />
                        )}
                    />
                    <Controller
                        name="label"
                        rules={{required: true}}
                        control={control}
                        render={({field}) => (
                            <TextField
                                InputLabelProps={{
                                    shrink: !!getValues("label"),
                                }}
                                {...field}
                                value={field.value}
                                id={"name"}
                                label={strings.label}
                                className={"w-100"}
                                style={{marginBottom: 15}}
                                error={errors.label !== undefined}
                            />
                        )}
                    />
                    <Controller
                        name="country"
                        rules={{required: false}}
                        control={control}
                        render={({field}) => (
                            <PaginatedSelect<CountryDTO>
                                variant={"outlined"}
                                value={field.value}
                                onChange={field.onChange}
                                label={strings.country}
                                valueMapper={(item) => item.id.toString()}
                                keyMapper={(item) => item.id.toString()}
                                itemMapper={(item) => <>{item.commonName}</>}
                                labelMapper={(item) => item.commonName}
                                dataFetcher={(page, size, filter) => {
                                    return CountryService.findAllCountriesPaged(
                                        page,
                                        size,
                                        filter
                                    );
                                }}/>
                        )}
                    />
                    <CardActions className="d-flex p-0 mt-4">
                        {isAddMode ? <Button
                            variant="contained"
                            onClick={handleCancel}
                            color={"warning"}
                            className="w-50"
                        >
                            {strings.cancel}
                        </Button> : <Button
                            variant="contained"
                            startIcon={<DeleteIcon/>}
                            onClick={handleClickOpen}
                            color={"error"}
                            className="w-50"
                        >
                            {strings.deleteRegionButton}
                        </Button>
                        }
                        <Button
                            variant="contained"
                            type="submit"
                            startIcon={<EditIcon/>}
                            color={"primary"}
                            className="w-50"
                        >
                            {strings.saveChanges}
                        </Button>
                        <ConfirmationModal
                            open={openModal}
                            handleClose={handleClose}
                            handleConfirm={deleteRegion}
                            title={strings.deleteRegion}
                            content={strings.deleteRegionDialog}
                            actionButtonName={strings.deleteRegionButton}
                        />
                    </CardActions>
                </form>
            </CenteredCardLayout>
        } />
    )
}