export class AgeGroupDTO {
    id: number;
    key: string;
    label: string;

    constructor(json: { id: number, key: string, label: string }) {
        this.id = json.id;
        this.key = json.key;
        this.label = json.label;
    }

    toString(): string {
        return this.label;
    }
}
