import { RegionTimeElapsedDTO } from "./RegionTimeElapsedDTO";

export class TimeElapsedGeographicReportDTO{
    trackName : string;
    artistName : string;
    totalTimeElapsed: number;
    regionTimeElapsedDTO: RegionTimeElapsedDTO[];

    constructor (json:TimeElapsedGeographicReportDTO){
        this.trackName = json.trackName;
        this.artistName = json.artistName;
        this.totalTimeElapsed = json.totalTimeElapsed;
        this.regionTimeElapsedDTO = json.regionTimeElapsedDTO;
    }
}