export class QueueCreationDTO {
    key: string | undefined;

    constructor(
        key: string | undefined,
    ) {
        this.key = key;
    }

    static empty(): QueueCreationDTO {
        return new QueueCreationDTO(
            "",
        );
    };
};
