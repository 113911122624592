import axiosInstance from "../network/AxiosInstance";
import {ProxyDTO} from "../models/ProxyDTO";
import {Page} from "../pagination/Page";
import { BehaviorDTO } from "../models/BehaviorDTO";



export class BehaviorService {
    static async getAllBehaviorPaged(page: number, size: number, name: string | undefined, id:number | undefined, dateCreated:string | undefined, dateModified:string | undefined, behaviorAlgorithmId: number | undefined): Promise<Page<BehaviorDTO>> {

        const response = await axiosInstance.get<Page<BehaviorDTO>>(
            `/rest/bot-session/behavior/all-paged`, {params: {page, size, name, id, dateCreated, dateModified, behaviorAlgorithmId}}
        );

        const data = response.data;

        return new Page(
            data.content.map(behavior => new BehaviorDTO(behavior)),
            data.totalElements,
        )
    }
   
}
