import axiosInstance from "../network/AxiosInstance";
import {AuthService} from "./AuthService";
import {SortingConfiguration} from "../utils/SortingUtils";
import {Page} from "../pagination/Page";
import {RecordLabelDTO} from "../models/RecordLabelDTO";
import {RecordLabelCreationDTO} from "../models/RecordLabelCreationDTO";

export class RecordLabelsService {

    static async createRecordLabel(recordLabel: RecordLabelCreationDTO): Promise<RecordLabelCreationDTO> {
        const response = await axiosInstance.post<RecordLabelDTO>(
            `/rest/playlists/record-label/create`, recordLabel,
        );

        const data: RecordLabelDTO = response.data;
        return new RecordLabelDTO(data);
    }

    static async editRecordLabel(recordLabel: RecordLabelCreationDTO, id: number | undefined): Promise<RecordLabelDTO> {
        const response = await axiosInstance.post<RecordLabelDTO>(
            `/rest/playlists/record-label/update/${id}`, recordLabel,
        );

        const data: RecordLabelDTO = response.data;
        return new RecordLabelDTO(data);
    };

    static async getRecordLabel(id: number): Promise<RecordLabelDTO> {
        const response = await axiosInstance.get<RecordLabelDTO>(`${AuthService.PREFIX}/playlists/record-label/${id}`);
        return response.data;
    };

    static async deleteRecordLabel(id: number): Promise<void> {
        await axiosInstance.delete<RecordLabelDTO>(`/rest/playlists/record-label/${id}`, {params: {id}});
    }

    static async getAllRecordLabelsPaged(page: number, size: number, key?: string | null, name?: string | undefined, sortingConfiguration?: SortingConfiguration | undefined): Promise<Page<RecordLabelDTO>> {
        const params = new URLSearchParams({
            page: page.toString(),
            size: size.toString(),
        });

        if (key) {
            params.append("key", key);
        }

        if (name) {
            params.append("name", name);
        }

        sortingConfiguration?.appendToBackEndSearchParams(params);

        const response = await axiosInstance.get<Page<RecordLabelDTO>>(
            `/rest/playlists/record-label/all-paged`, { params: params },
        );

        const data = response.data;

        return new Page<RecordLabelDTO>(
            data.content.map(recordLabel => new RecordLabelDTO(recordLabel)),
            data.totalElements,
        )
    };

    static async getAllRecordLabels(): Promise<RecordLabelDTO[]> {
        const response = await axiosInstance.get<RecordLabelDTO[]>(
            `/rest/playlists/record-label/all`,
        );

        const data = response.data;
        return data.map(recordLabel => new RecordLabelDTO(recordLabel));
    }
}
