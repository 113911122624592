import {AuthService} from "../services/AuthService";
import {StorageService} from "../storage/StorageService";
import {NavigateFunction} from "react-router-dom";
import {Routes} from "../router/Routes";

export class AppController {
    static async login(navigate: NavigateFunction, username: string, password: string): Promise<void> {
        const token = await AuthService.login(username, password);
        StorageService.saveAuthToken(token);

        navigate(Routes.HOME);
    }

    static async logout(navigate: NavigateFunction): Promise<void> {
        StorageService.clearAuthToken();
        navigate(Routes.LOGIN);
    }
}
