import {Page} from "../../components/Page";
import {CenteredCardLayout} from "../../components/CenteredCardLayout";
import {Button, TextField} from "@mui/material";
import {useState} from "react";
import {ErrorHandler} from "../../utils/ErrorHandler";
import {AuthService} from "../../services/AuthService";
import {useForm, Controller} from "react-hook-form";
import {strings} from "../../localization/Localization";

interface IFormInputs {
    email: string
}

export function ResetPassword() {
    const [linkSent, setLinkSet] = useState<boolean>(false);
    const [submitError, setSubmitError] = useState<string>("");

    const {
        handleSubmit,
        control,
        watch,
        getValues,
        formState: {errors}
    } = useForm<IFormInputs>({
        defaultValues: {
            email: ""
        },
        mode: "onSubmit"
    });
    watch();

    async function resetPassword(): Promise<void> {
        try {
            await AuthService.resetPassword(getValues("email"));
            setLinkSet(true)
        } catch (e) {
            setSubmitError(ErrorHandler.parseErrorMessage(e));
        }
    }

    function validateEmail(email : string){
        let reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w\w+)+$/;
        return email && reg.test(email);
    }

    return (
        <Page title="Reset Password"
              component={
                  <div>
                      {
                          !linkSent ? (
                                  <form onSubmit={handleSubmit(resetPassword)}>
                              <CenteredCardLayout minWidth={"30%"} maxWidth={"30%"} className={"mt-5"}>
                                  <div className="d-flex justify-content-md-center align-items-center">{strings.resetPassword}</div>
                                  <br/>
                                  <div className="text-center">{strings.resetPasswordLink}</div>
                                  <br/>
                                  <Controller name="email" rules={{required: true, validate: validateEmail}}
                                              control={control} render={({field}) => (
                                      <TextField
                                          {...field}
                                          className="mb-3"
                                          sx = {{width:'100%'}}
                                          fullWidth
                                          required
                                          id="email"
                                          label="Email"
                                          error={errors.email !== undefined}
                                          helperText={errors.email ? "Invalid email" : undefined}
                                      />
                                  )}
                                  />

                                  {submitError && <div style={{color: "red"}}
                                       className={"alert alert-danger mt-2 text-center"}>{submitError}</div>}

                                  <Button variant="contained" className="btn-round w-100 mt-3 btn btn-primary"
                                          type="submit"> {strings.resetPassword} </Button>
                              </CenteredCardLayout>
                                  </form>) : null
                      }

                      {linkSent ?
                          <CenteredCardLayout minWidth={"30%"} maxWidth={"30%"} className={"mt-5"}>
                              <div className="d-flex justify-content-md-center align-items-center mb-3">{strings.resetPassword}</div>
                              <div className={"text-center alert alert-success"}>{strings.resetPasswordLinkGenerated}
                              </div>
                              <div className={"text-center alert alert-warning"}>
                                  {strings.resetPasswordNoCode}
                                  <Button onClick={resetPassword} variant={"outlined"} style={{marginLeft: "10px", height: "20px"}}>{strings.resendCode}</Button>
                              </div>

                          </CenteredCardLayout> : null}
                  </div>

              }

        />

    );
}
