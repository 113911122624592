import React, {useEffect, useState} from "react";
import {strings} from "../../localization/Localization";
import {SessionService} from "../../services/SessionService";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import {FormControl, Grid, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, TextField} from "@mui/material";
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel";
import {Box} from "@mui/system";
import {SideBarPage} from "../../components/SideBarPage";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import {Session} from "../../models/Session";
import {DateTimeUtils} from "../../utils/DateTimeUtils";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {Routes} from "../../router/Routes"
import {defaultRowsPerPageOptions} from "../../common/Constants";
import {ServiceDTO} from "../../models/ServiceDTO";
import {ServicesService} from "../../services/ServicesService";
import {Clear} from "@mui/icons-material";
import {NullableCheckbox} from "../../components/NullableCheckbox";
import {TableHeader} from "../../components/TableHeader";
import {SortingConfiguration} from "../../utils/SortingUtils";
import {NumberParser} from "../../utils/NumberParser";
import {BooleanParser} from "../../utils/BooleanParser";
import {GuardedComponent} from "../../components/GuardedComponent";
import {PrivilegeGuard, PrivilegeGuardMode} from "../../router/guards/PrivilegeGuard";
import {Privileges} from "../../models/nomenclatures/Privileges";
import {PaginationPlus} from "../../components/PaginationPlus";
import LanguageIcon from "@mui/icons-material/Language";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import SmartToyOutlinedIcon from "@mui/icons-material/SmartToyOutlined";
import {BotPlatformConstants} from "../../common/BotPlatformConstants";
import {guardedObject} from "../../router/guards/GuardedCallback";

export function Sessions() {
  const columns = [
    { label: strings.sessionId, sortBy: "id", sortable: true },
    { label: strings.key, sortBy: "key", sortable: true },
    { label: strings.name, sortBy: "name", sortable: true },
    { label: strings.service, sortBy: "accountGroup.service", sortable: true },
    { label: strings.active, sortBy: "active", sortable: false },
    { label: strings.accountGroup, sortBy: "accountGroup.name", sortable: true },
    { label: strings.client, sortBy: "client.name", sortable: true },
    { label: strings.schedule, sortBy: "schedule", sortable: false },
    { label: strings.useProxy, sortBy: "useProxy", sortable: false },
    { label: strings.numberOfAccounts, sortBy: "numberOfAccounts", sortable: false },
  ]
  const listLinks = [
    { label: strings.dashboard, currentlyOpened: false, href:Routes.HOME},
    { label: strings.sessions,  currentlyOpened: true },
  ]

  const [sessions, setSessions] = useState<Session[]>([]);
  const [totalElements, setTotalElements] = useState<number>(0);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [services, setServices] = useState<ServiceDTO[]>([]);
  const sortingConfigurationString = searchParams.get("sort");
  const serviceId = searchParams.get("serviceId")
  const key = searchParams.get("key") ?? "";
  const name = searchParams.get("name") ?? "";
  const clientName = searchParams.get("clientName") ?? "";
  const accountGroupName = searchParams.get("accountGroupName") ?? "";
  const rowsPerPage = NumberParser.parseNumber(searchParams.get("rowsPerPage"), 25);
  const page = NumberParser.parseNumber(searchParams.get("page"), 0);
  const active = BooleanParser.parseBooleanNullable(searchParams.get("active"));

  function setSearchParam(key: string, value: string | null | undefined):void {
    if (value) {
      searchParams.set(key, value);
    } else {
      searchParams.delete(key);
    }

    setSearchParams(searchParams)
  }
  function setPage(page: number) {
    setSearchParam("page", page.toString());
  }

  function updateSort(sortingConfiguration: SortingConfiguration): void {
    setSearchParam("sort", sortingConfiguration.toSearchString());
  }

  function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchParam("rowsPerPage", event.target.value);
    setPage(0);
  }

  function handleChangeName(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchParam("name", event.target.value);
    setPage(0);
  }

  function handleChangeServiceId(event: SelectChangeEvent) {
    const serviceIdString = event.target.value;
    setSearchParam("serviceId", serviceIdString.toString())
    setPage(0);
  }

  function handleChangeKey(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchParam("key", event.target.value)
    setPage(0);
  }

  function setActive(value: boolean | undefined): void {
    setSearchParam("active", value?.toString() ?? "");
    setPage(0);
  }

  function handleChangeAccountGroupName(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchParam("accountGroupName", event.target.value);
    setPage(0);
  }

  function handleChangeClient(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchParam("clientName", event.target.value)
    setPage(0);
  }
  function navigateAddSession() {
    navigate(Routes.ADD_SESSION)
  }
  function navigateSessionRow(id: number): any {
    navigate(Routes.SESSION_DETAILS.replace(":id", id.toString()));
  }

  useEffect(() => {
    async function getAllSessionsPaged(page: number): Promise<void> {
      const response = await SessionService.getAllSessionsPaged(
        page,
        rowsPerPage,
        serviceId,
        active,
        key,
        name,
        SortingConfiguration.fromSearchString(sortingConfigurationString),
        clientName,
        accountGroupName,
      );
      setTotalElements(response.totalElements);
      setSessions(response.content);
    }

    getAllSessionsPaged(page).then(_ => {});

    function getAllServices() {
      ServicesService.getAllServices().then((res) => {
        setServices(res)
      })
    }
    getAllServices();
  }, [name, key, active, serviceId, rowsPerPage, page, sortingConfigurationString, clientName, accountGroupName, searchParams]);

  function botPlatformIdCheck(e: Session){
    if(e.accountGroup?.botPlatform?.type === BotPlatformConstants.browser){
      return(
          <LanguageIcon fontSize={'medium'} className={"mx-1"}/>
      )
    } else if(e.accountGroup?.botPlatform?.type === BotPlatformConstants.mobile){
      return(
          <PhoneAndroidIcon fontSize={'medium'} className={"mx-1"}/>
      )
    } else return <SmartToyOutlinedIcon fontSize={'medium'} className={"mx-1"}/>
  }

  function getRowLink(entry: Session): string {
    return guardedObject([
      new PrivilegeGuard(
          [Privileges.MANAGE_SESSION, Privileges.READ_SESSIONS],
          PrivilegeGuardMode.hasAll
      )
    ], Routes.SESSION_DETAILS.replace(":id", entry.id.toString())) ?? "";
  }

  return (
    <>
      <SideBarPage
        pageTitle={strings.sessions}
        breadcrumbs={listLinks}
        component={
          <Box>
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 500 }}
                aria-label="custom pagination table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>
                      <TextField
                        variant="standard"
                        value={key}
                        label={strings.key}
                        name="key"
                        fullWidth
                        onChange={handleChangeKey}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        name="name"
                        variant="standard"
                        value={name}
                        label={strings.name}
                        onChange={handleChangeName}
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      <FormControl fullWidth variant="standard">
                        <InputLabel>{strings.service}</InputLabel>
                        <Select
                          defaultValue={""}
                          value={services.length > 0 && serviceId ? String(serviceId) : ""}
                          onChange={handleChangeServiceId}
                          label={strings.service}
                          labelId="serviceLabel"
                          endAdornment={
                            serviceId && (
                              <IconButton
                                onClick={() =>
                                  setSearchParam("serviceId", null)
                                }
                              >
                                <Clear />
                              </IconButton>
                            )
                          }
                        >
                          {services?.map((service) => (
                            <MenuItem disableRipple key={service.id} value={service.id}>
                              {service.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      {" "}
                      <FormGroup>
                        <FormControlLabel
                          label={strings.active}
                          control={
                            <NullableCheckbox
                              value={active}
                              onValueChanged={setActive}
                            />
                          }
                        />
                      </FormGroup>
                    </TableCell>
                    <TableCell>
                      <TextField
                          name="accountGroupName"
                          variant="standard"
                          value={accountGroupName}
                          label={strings.accountGroup}
                          onChange={handleChangeAccountGroupName}
                          fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                          name="clientName"
                          variant="standard"
                          value={clientName}
                          label={strings.client}
                          onChange={handleChangeClient}
                          fullWidth
                      />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      {" "}
                      <GuardedComponent
                          component={
                            <Button
                                variant="contained"
                                className="btn btn-sm"
                                onClick={navigateAddSession}
                            >
                              {" "}
                              <AddIcon /> {strings.addSession}
                            </Button>
                          }
                          guards={
                            [
                                new PrivilegeGuard(
                                    [Privileges.MANAGE_SESSION],
                                  PrivilegeGuardMode.hasAny,
                                )
                            ]
                          }
                          fallback={
                            <Box/>
                          }
                      />
                    </TableCell>
                  </TableRow>
                  <TableHeader columns={columns} sortConfigurationString={sortingConfigurationString} updateSort={updateSort}></TableHeader>
                  <TableRow>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sessions.map((session) => (
                    <TableRow component={getRowLink(session) ? Link : TableRow} to={getRowLink(session)} key={session.id} onClick={() => navigateSessionRow(session.id)} hover={true} className="cursor-pointer">
                      <TableCell component="th" scope="row">
                        {session.id}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {session.key}
                      </TableCell>
                      <TableCell>{session.name}</TableCell>
                      <TableCell>
                        {session.accountGroup.service.label}
                      </TableCell>
                      <TableCell>
                        {session.active ? (
                          <CheckCircleIcon color={"primary"} />
                        ) : (
                          <CancelIcon color={"error"} />
                        )}
                      </TableCell>
                      <TableCell>{session.accountGroup.name}{botPlatformIdCheck(session)}</TableCell>
                      <TableCell>{session.client?.name}</TableCell>
                      <TableCell>
                        {session.accountGroup.schedules.map((schedule) => {
                          return (
                            <Box key={schedule.id}>
                              {DateTimeUtils.formatDateObject(
                                schedule.dateCreated
                              ) +
                                " " +
                                DateTimeUtils.formatTimeObject(
                                  schedule.startTime
                                )}
                            </Box>
                          );
                        })}
                      </TableCell>
                      <TableCell>
                        {session.useProxy ? (
                          <CheckCircleIcon color={"primary"} />
                        ) : (
                          <CancelIcon color={"error"} />
                        )}
                      </TableCell>
                      <TableCell>
                        {session.accountGroup?.accountIds?.length ?? 0}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid sx={{display: 'flex', flexDirection: 'column', alignItems: 'end', marginTop: "10px"}}>
              <PaginationPlus totalElements={totalElements} rowsPerPage={rowsPerPage} page={page} onChangePage={(page) => handleChangePage(null, page)}/>
              <TablePagination
              rowsPerPageOptions={defaultRowsPerPageOptions}
              component="div"
              count={totalElements}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            </Grid>
          </Box>
        }
      />
    </>
  );
}
