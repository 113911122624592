import {RegionDTO} from "./RegionDTO";
import {AgeGroupDTO} from "./AgeGroupDTO";
import {ServiceDTO} from "./ServiceDTO"
import {AccountGroupDTO} from "./AccountGroupDTO";
import { ProxyDTO } from "./ProxyDTO";
import {AccountStatusDTO} from "./AccountStatusDTO";


export class AccountDTO {
    id: number;
    email: string;
    parentAccountId: number | undefined;
    parentAccount: any | undefined;
    accountGroup? :AccountGroupDTO;
    ageGroup?:AgeGroupDTO;
    region?: RegionDTO;
    service?:ServiceDTO;
    actionNeeded:boolean|undefined;
    proxy: ProxyDTO | undefined;
    latestStatus: AccountStatusDTO|undefined;
    loginUsername?: string;
    loginPassword?: string;
    dateCreated?: string|undefined;
    dateModified?: string | undefined;
    created?: boolean;
    recoveryEmail?: string;
    activationDate?: Date;

    constructor(json: any) {
        this.id = json.id;
        this.email = json.email;
        this.parentAccountId = json.parentAccountId;
        this.actionNeeded = json.actionNeeded;
        if (json.parentAccount) {
            this.parentAccount = new AccountDTO(json.parentAccount);
        }
        this.accountGroup = json.accountGroup ? new AccountGroupDTO(json.accountGroup) : undefined;
        this.region = json.region ? new RegionDTO(json.region) : undefined;
        this.ageGroup = json.ageGroup ? new AgeGroupDTO(json.ageGroup) : undefined;
        this.service = json.service ? new ServiceDTO(json.service) : undefined;
        this.proxy = json.proxy ? new ProxyDTO(json.proxy) : undefined;
        this.latestStatus = json.latestStatus ? new AccountStatusDTO(json.latestStatus) : undefined;
        this.loginUsername = json.loginUsername;
        this.loginPassword = json.loginPassword;
        this.dateCreated = json.dateCreated;
        this.dateModified = json.dateModified;
        this.created = json.created;
        this.recoveryEmail = json.recoveryEmail;
        this.activationDate = json.activationDate;
    }
}
