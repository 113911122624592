import React, { useEffect, useState } from 'react';
import {
    Box, Button, CardActions, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, Dialog, DialogActions, DialogContent, DialogTitle,
    IconButton,
    Tooltip
} from "@mui/material";
import { CenteredCardLayout } from "../../components/CenteredCardLayout";
import { Controller, useForm } from "react-hook-form";
import { strings } from "../../localization/Localization";
import { ServiceDTO } from '../../models/ServiceDTO';
import { ServicesService } from "../../services/ServicesService";
import { TableHeader } from "../../components/TableHeader";
import { SortingConfiguration } from "../../utils/SortingUtils";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { TargetPlaylistService } from "../../services/TargetPlaylistService";
import { ErrorHandler } from "../../utils/ErrorHandler";
import { showErrorDialog, showSuccessDialog } from "../../common/Dialogs";
import { TargetPlaylistCreationDTO } from '../../models/TargetPlaylistCreationDTO';
import { TargetPlaylistDTO } from '../../models/TargetPlaylistDTO';
import { TargetPlaylistItemService } from '../../services/TargetPlaylistItemService';
import { TargetPlaylistItemDTO } from '../../models/TargetPlaylistItemDTO';
import DeleteIcon from '@mui/icons-material/Delete';
import { Routes } from "../../router/Routes";
import { useNavigate } from "react-router-dom";
import {RecordLabelDTO} from "../../models/RecordLabelDTO";
import {RecordLabelsService} from "../../services/RecordLabelsService";
import {TargetPlaylistTypes} from "../../common/TargetPlaylistTypes";


interface NewTargetPlaylist {
    key: string;
    service: string;
    name: string;
    description?: string;
    weight?: number;
    type: string;
}

interface TargetPlaylistItemFormData {
    id?: number;
    title: string;
    artist: string;
    url: string;
    recordLabelKey: string;
}

interface AddNewTargetPlaylistProps {
    id?: number;
}

export function AddNewTargetPlaylist(props: AddNewTargetPlaylistProps) {
    const [service, setService] = useState<ServiceDTO[] | undefined>([]);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [targetPlaylist, setTargetPlaylist] = useState<TargetPlaylistDTO>();
    const [editItem, setEditItem] = useState<TargetPlaylistItemFormData | null>(null);
    const [recordLabel, setRecordLabel] = useState<RecordLabelDTO[] | undefined>([]);
    const navigate = useNavigate();

    const { control, handleSubmit, formState: { errors }, getValues, reset } = useForm<NewTargetPlaylist>({
        defaultValues: {
            key: '',
            service: '',
            name: '',
            description: '',
            weight: undefined,
            type: TargetPlaylistTypes.PLAYLIST_GENERATION,
        }
    });

    const { control: dialogControl, handleSubmit: handleDialogSubmit, formState: { errors: dialogErrors, isValid: isDialogValid }, reset: resetDialog, setValue } = useForm<TargetPlaylistItemFormData>({
        mode: "onChange",
        defaultValues: {
            title: '',
            artist: '',
            url: '',
            recordLabelKey: ''
        }
    });

    const isAddMode = props.id === undefined;

    const columns = [
        { label: strings.title, sortBy: "key", sortable: false },
        { label: strings.artist, sortBy: "label", sortable: false },
        { label: strings.url, sortBy: "service", sortable: false },
        { label: strings.recordLabel, sortBy: "recordLabel", sortable: false },
        { label: '', sortBy: "", sortable: false }
    ];

    async function getAllServices() {
        const services = await ServicesService.getAllServices();
        setService(services);
    }

    async function getAllRecordLabels() {
        const recordLabels = await RecordLabelsService.getAllRecordLabels();
        setRecordLabel(recordLabels);
    }

    useEffect(() => {
        if (editItem) {
            setValue('title', editItem.title);
            setValue('artist', editItem.artist);
            setValue('url', editItem.url);
            setValue('recordLabelKey', editItem.recordLabelKey)
        }
    }, [editItem, setValue]);

    const fetchPlaylist = async () => {
        if (props.id !== undefined) {
            try {
                TargetPlaylistService.getTargetPlaylistById(props.id).then(response => {
                        setTargetPlaylist(response);
                        reset({
                            key: response.key,
                            service: response.service.type,
                            name: response.name,
                            description: response.description,
                            weight: response.weight,
                            type: response.type,
                        });
                    }
                );


            } catch (e) {
                const message = ErrorHandler.parseErrorMessage(e);
                showErrorDialog(strings.error, message, strings.ok);
            }
        }
    };

    useEffect(() => {
        getAllServices();
        getAllRecordLabels()
        fetchPlaylist();
    }, [props.id, reset, resetDialog]);

    function onSubmit(data: NewTargetPlaylist) {
        return isAddMode ? addTargetPlaylist(data) : editTargetPlaylist(data);
    }

    async function addTargetPlaylist(data: NewTargetPlaylist) {
        const newPlaylistData = new TargetPlaylistCreationDTO({
            key: data.key,
            service: data.service,
            name: data.name,
            description: data.description,
            weight: data.weight,
            type: data.type,
        });

        try {
            await TargetPlaylistService.addNewTargetPlaylist(newPlaylistData);
            showSuccessDialog(strings.success, strings.successfullyAddedTargetPlaylist, strings.ok).then(() => {
                reset();
                navigate(Routes.PLAYLISTS_FOR_GENERATION)
            });
        } catch (e) {
            const message = ErrorHandler.parseErrorMessage(e);
            showErrorDialog(strings.error, message, strings.ok);
        }
    }

    async function editTargetPlaylist(data: NewTargetPlaylist) {
        const targetPlaylistData = new TargetPlaylistCreationDTO({
            key: data.key || '',
            service: data.service || '',
            name: data.name || '',
            description: data.description,
            weight: data.weight,
            type: data.type,
        });

        if (props.id === undefined) {
            return;
        }

        try {
            await TargetPlaylistService.editTargetPlaylist(props.id, targetPlaylistData);
            showSuccessDialog(strings.success, strings.successfullyEditedTargetPlaylist, strings.ok).then(() => {
                reset();
            });
        } catch (e) {
            const message = ErrorHandler.parseErrorMessage(e);
            showErrorDialog(strings.error, message, strings.ok);
        }
        fetchPlaylist();
    }

    const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            setSelectedFile(event.target.files[0]);
        }
    }

    async function importTargetPlaylist() {
        const key = getValues("key") || '';
        try {
            if (key && selectedFile) {
                await TargetPlaylistService.importTargetPlaylist(targetPlaylist?.key, selectedFile);
                showSuccessDialog(strings.success, strings.targetPlaylistUploadedSuccessfully, strings.ok).then(_ => {
                });
            }
        } catch (e) {
            const message = ErrorHandler.parseErrorMessage(e);
            showErrorDialog(strings.error, message, strings.ok).then(_ => { });
        }
        fetchPlaylist();
    }

    const openAddTargetPlaylistPopup = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setEditItem(null);
        resetDialog();
    };

    async function handleDialogSubmitForm(data: TargetPlaylistItemFormData) {
        const newItem = {
            title: data.title,
            artist: data.artist,
            url: data.url,
            recordLabelKey: data.recordLabelKey
        };

        if (editItem?.id) {
            try {
                await TargetPlaylistItemService.editTargetPlaylistItem(editItem.id, newItem);
                showSuccessDialog(strings.success, strings.editedSuccessfully, strings.ok);
            } catch (e) {
                const message = ErrorHandler.parseErrorMessage(e);
                showErrorDialog(strings.error, message, strings.ok);
            }
        } else {
            const key = getValues("key") || '';
            try {
                await TargetPlaylistService.addNewItem(key, newItem);
                showSuccessDialog(strings.success, strings.itemSuccess, strings.ok);
            } catch (e) {
                const message = ErrorHandler.parseErrorMessage(e);
                showErrorDialog(strings.error, message, strings.ok);
            }
        }

        handleDialogClose();
        fetchPlaylist();
    }

    async function handleDeleteItem(itemId: string) {
        try {
            await TargetPlaylistItemService.deleteTargetPlaylistItem(Number(itemId));
            showSuccessDialog(strings.success, strings.itemDeletedSuccessfully, strings.ok).then(() => {
                setTargetPlaylist((prev) => ({
                    ...prev!,
                    items: prev!.items.filter(item => item.id !== itemId)
                }));
            });
        } catch (e) {
            const message = ErrorHandler.parseErrorMessage(e);
            showErrorDialog(strings.error, message, strings.ok);
        }
    }


    function handleRowClick(item: TargetPlaylistItemDTO) {
        setEditItem({
            id: Number(item.id),
            title: item.title,
            artist: item.artist,
            url: item.url,
            recordLabelKey: item.recordLabel.key
        });
        setDialogOpen(true);
    }

    const isEditMode = Boolean(editItem);

    return (
        <Box id="test">
            <CenteredCardLayout minWidth={!isAddMode ? "70%" : "40%"}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                        <Grid item xs={12} sm={4} md={!isAddMode ? 6 : 12}>
                            <Controller
                                name="key"
                                rules={{ required: true }}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        id="key"
                                        label={strings.key}
                                        className="mb-3"
                                        error={!!errors.key}
                                    />
                                )}
                            />
                            <FormControl fullWidth className="mb-3" error={!!errors.service}>
                                <InputLabel>{strings.service}</InputLabel>
                                <Controller
                                    name="service"
                                    rules={{ required: true }}
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <Select
                                            value={value}
                                            onChange={onChange}
                                            input={<OutlinedInput label={strings.service} />}
                                        >
                                            {service?.map((s) => (
                                                <MenuItem key={s.type} value={s.type}>
                                                    {s.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                            </FormControl>
                            <Controller
                                name="name"
                                rules={{ required: true }}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        id="name"
                                        label={strings.name}
                                        className="mb-3"
                                        error={!!errors.name}
                                    />
                                )}
                            />
                            <Controller
                                name="description"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        id="description"
                                        label={strings.description}
                                        className="mb-3"
                                        error={!!errors.description}
                                    />
                                )}
                            />
                            <Controller
                                name="weight"
                                rules={{ required: true }}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: !!getValues("weight"),
                                        }}
                                        type='number'
                                        id="weight"
                                        label={strings.weight}
                                        className="mb-3"
                                        error={!!errors.weight}
                                        InputProps={{
                                            inputProps: { min: 0 },
                                        }}
                                    />
                                )}
                            />
                            <FormControl fullWidth className="mb-3" error={!!errors.type}>
                                <InputLabel>{strings.type}</InputLabel>
                                <Controller
                                    name="type"
                                    rules={{ required: true }}
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            fullWidth
                                            value={field.value}
                                            onChange={field.onChange}
                                            input={<OutlinedInput label={strings.type} />}
                                        >
                                            <MenuItem key={TargetPlaylistTypes.PLAYLIST_GENERATION} value={TargetPlaylistTypes.PLAYLIST_GENERATION}>{strings.playlistGeneration}</MenuItem>
                                            <MenuItem key={TargetPlaylistTypes.SEARCH_AND_PLAY} value={TargetPlaylistTypes.SEARCH_AND_PLAY}>{strings.searchAndPlay}</MenuItem>
                                        </Select>
                                    )} />
                            </FormControl>
                            <CardActions className="d-flex p-0 mt-4">
                                <Button variant="contained" onClick={() => { navigate(Routes.PLAYLISTS_FOR_GENERATION) }} color={"error"} fullWidth>
                                    {strings.cancel}
                                </Button>
                                <Button type="submit" fullWidth variant="contained">
                                    {strings.saveChanges}
                                </Button>
                            </CardActions>
                        </Grid>
                        {!isAddMode &&
                            <Grid item xs={12} sm={4} md={6}>
                                <Box>
                                    <Grid container spacing={2} direction="column">
                                        <Grid item style={{ height: 'calc(100vh - 400px)', overflow: 'auto' }}>
                                            <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>{strings.targetPlaylist}</TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                    <TableHeader
                                                        columns={columns}
                                                        updateSort={function (sortConfiguration: SortingConfiguration): void {
                                                            throw new Error("Function not implemented.");
                                                        }}
                                                        sortConfigurationString={undefined}
                                                    />
                                                </TableHead>
                                                <TableBody>
                                                    {targetPlaylist?.items.map((item) => (
                                                        <TableRow
                                                            key={item.id}
                                                            onClick={() => handleRowClick(item)}
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            <TableCell>{item.title}</TableCell>
                                                            <TableCell>{item.artist}</TableCell>
                                                            <TableCell>{item.url}</TableCell>
                                                            <TableCell>{item.recordLabel?.name}</TableCell>
                                                            <TableCell>
                                                                <Tooltip title={strings.delete} arrow>
                                                                    <IconButton
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            handleDeleteItem(item.id);
                                                                        }}
                                                                        color="error"
                                                                        aria-label="delete"
                                                                        size="large"
                                                                    >
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                        <Grid item>
                                            <CardActions className="d-flex p-0 mt-4">
                                                <Button onClick={openAddTargetPlaylistPopup} fullWidth variant="contained">
                                                    {strings.addNew}
                                                </Button>
                                            </CardActions>
                                            <input type="file" accept=".xls, .xlsx" onChange={handleFileSelect} className="mb-3" />
                                            <Button variant="contained" startIcon={<FileUploadIcon />}
                                                    disabled={!selectedFile} onClick={importTargetPlaylist} className="mb-3 mt-3">{strings.importTargetPlaylist}</Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        }
                    </Grid>
                </form>
            </CenteredCardLayout>
            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>{isEditMode ? strings.editTargetPlaylistItem : strings.addNewTargetPlaylistItem}</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleDialogSubmit(handleDialogSubmitForm)}>
                        <Controller
                            name="title"
                            control={dialogControl}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    fullWidth
                                    id="title"
                                    label={strings.title}
                                    className="mb-3 mt-3"
                                    error={!!dialogErrors.title}
                                />
                            )}
                        />
                        <Controller
                            name="artist"
                            control={dialogControl}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    fullWidth
                                    id="artist"
                                    label={strings.artist}
                                    className="mb-3"
                                    error={!!dialogErrors.artist}
                                />
                            )}
                        />
                        <Controller
                            name="url"
                            control={dialogControl}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    fullWidth
                                    id="url"
                                    label={strings.url}
                                    className="mb-3"
                                    error={!!dialogErrors.url}
                                />
                            )}
                        />
                        <FormControl fullWidth className="mb-3" error={!!dialogErrors.recordLabelKey}>
                            <InputLabel>{strings.recordLabel}</InputLabel>
                            <Controller
                                name="recordLabelKey"
                                control={dialogControl}
                                render={({ field: { onChange, value } }) => (
                                    <Select
                                        value={value}
                                        onChange={onChange}
                                        input={<OutlinedInput label={strings.recordLabel} />}
                                    >
                                        {recordLabel?.map((r) => (
                                            <MenuItem key={r.key} value={r.key}>
                                                {r.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                        </FormControl>
                        <DialogActions>
                            <Button variant={"contained"} onClick={handleDialogClose}>{strings.cancel}</Button>
                            <Button variant={"contained"} type="submit" disabled={!isDialogValid}> {isEditMode ? strings.saveChanges : strings.addNew}</Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>
        </Box>
    );
}
