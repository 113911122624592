import {SideBarPage} from "../../../components/SideBarPage";
import {strings} from "../../../localization/Localization";
import {Box} from "@mui/system";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {Grid, Input, Pagination} from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TableBody from "@mui/material/TableBody";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import {DateTimeUtils} from "../../../utils/DateTimeUtils";
import TablePagination from "@mui/material/TablePagination";
import React, {useEffect, useState} from "react";
import {UserService} from "../../../services/UserService";
import {UserDTO} from "../../../models/UserDTO";
import {UserFilter} from "../../../models/UserFilter";
import Button from "@mui/material/Button";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {Routes} from "../../../router/Routes";
import AddIcon from "@mui/icons-material/Add";
import {SortingConfiguration} from "../../../utils/SortingUtils";
import {TableHeader} from "../../../components/TableHeader";
import {GuardedComponent} from "../../../components/GuardedComponent";
import {Privileges} from "../../../models/nomenclatures/Privileges";
import {PrivilegeGuard, PrivilegeGuardMode} from "../../../router/guards/PrivilegeGuard";
import {guardedCallback, guardedObject} from "../../../router/guards/GuardedCallback";
import {PaginationPlus} from "../../../components/PaginationPlus";
import {MobileProxyStatusDTO} from "../../../models/MobileProxyStatusDTO";


export function Users(){
    const columns = [
        { label: "#", sortBy: "id", sortable: true },
        { label: strings.email, sortBy: "email", sortable: true },
        { label: strings.firstName, sortBy: "p.first_name", sortable: true },
        { label: strings.lastName, sortBy: "p.last_name", sortable: true },
        { label: strings.phoneNumber, sortBy: "p.phone_number", sortable: true },
        { label: strings.roles, sortBy: "", sortable: false },
        { label: strings.client, sortBy: "client.name", sortable: true },
        { label: strings.enabled, sortBy: "enabled", sortable: true },
        { label: strings.dateCreated, sortBy: "date_created", sortable: true },
        { label: strings.dateModified, sortBy: "date_modified", sortable: true }
    ];
    const listLinks = [
        { label: strings.dashboard, currentlyOpened: false, href:Routes.HOME},
        { label: strings.users,  currentlyOpened: true },
    ]


    const [searchParams, setSearchParams] = useSearchParams();
    const email = searchParams.get("email") ?? "";
    const firstName = searchParams.get("firstName") ?? "";
    const lastName = searchParams.get("lastName") ?? "";
    const phoneNumber = searchParams.get("phoneNumber") ?? "";
    const client = searchParams.get("client") ?? "";
    const sortingConfigurationString = searchParams.get("sort");
    const [users, setUsers] = useState<UserDTO[]>([]);
    const [enabled, setEnabled] = useState<boolean | undefined>(undefined);
    const [checked, setChecked] = useState<boolean>(false);
    const [role, setRole] = useState<string | undefined>(undefined)
    const [totalElements, setTotalElements] = useState(0);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const navigate = useNavigate();
    useEffect(() => {
        async function getAllUsersPaged(page: number): Promise<void> {
            const response = await UserService.getAllUsersPaged(
                page,
                rowsPerPage,
                new UserFilter(email, enabled, firstName, lastName, phoneNumber, role,client),
                SortingConfiguration.fromSearchString(sortingConfigurationString)
            );
            setTotalElements(response.totalElements);
            setUsers(response.content);
            setPage(page)
        }

        getAllUsersPaged(page).then(_ => {
        });
    }, [email, enabled, firstName, lastName, phoneNumber, role,client, rowsPerPage, page, searchParams,sortingConfigurationString]);

    function setSearchParam(key: string, value: string | null | undefined) {
        if (value) {
            searchParams.set(key, value);
        } else {
            searchParams.delete(key);
        }

        setSearchParams(searchParams)
    }
    function updateSort(sortingConfiguration: SortingConfiguration): void {
        setSearchParam("sort", sortingConfiguration.toSearchString());
    }
    function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }
    function handleChangeFirstName(event: React.ChangeEvent<HTMLInputElement>){
        setSearchParam("firstName", event.target.value !== "" ? event.target.value : undefined)
        setPage(0);
    }
    function handleChangeLastName(event: React.ChangeEvent<HTMLInputElement>){
        setSearchParam("lastName", event.target.value !== "" ? event.target.value : undefined)
        setPage(0);
    }
    function handleChangeEmail(event: React.ChangeEvent<HTMLInputElement>){
        setSearchParam("email", event.target.value !== "" ? event.target.value : undefined)
        setPage(0);
    }
    function handleChangePhoneNumber(event: React.ChangeEvent<HTMLInputElement>){
        setSearchParam("phoneNumber", event.target.value !== "" ? event.target.value : undefined)
        setPage(0);
    }
    function handleChangeRole(event: React.ChangeEvent<HTMLInputElement>){
        setRole(event.target.value !== "" ? event.target.value : undefined)
        setPage(0);
    }
    function handleChangeClient(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("client", event.target.value)
        setPage(0);
    }
    function handleChangeEnabled(event: React.ChangeEvent<HTMLInputElement>){
        setChecked(event.target.checked);
        if (enabled === true) {
            setEnabled(false);
        } else if (enabled === false) {
            setEnabled(undefined);
        } else if (enabled === undefined) {
            setEnabled(true);
            setChecked(true);
        }
        setPage(0);
    }

    function indeterminateState() {
        if (enabled === undefined) return true;
        if (enabled) return false;
        if (!enabled) return false;
    }
    function navigateUserNew(){
        navigate(Routes.USER_NEW,{state:{isAdd:true}})
    }
    function navigateUserDetails(id: number): any {
        navigate(Routes.USER_EDIT.replace(":id", id.toString()), {state:{isAdd:false}});
    }

    function getRowLink(entry: UserDTO): string {
        return guardedObject([
            new PrivilegeGuard(
                [Privileges.UPDATE_USER, Privileges.DELETE_USER,
                    Privileges.ADMINISTER_USER, Privileges.READ_ROLES, Privileges.READ_CLIENTS],
                PrivilegeGuardMode.hasAll
            )
        ], Routes.USER_EDIT.replace(":id", entry.id.toString())) ?? "";
    }
    return (
        <>
        <SideBarPage
            pageTitle={strings.users}
            breadcrumbs={listLinks}
            component={
                <Box>
                    <TableContainer component={Paper}>
                        <Table sx={{minWidth: 500}} aria-label="custom pagination table">
                            <TableHead>
                                <TableRow>
                                    <TableCell/>
                                    <TableCell>
                                        <Input name="email" placeholder = {strings.email} fullWidth onChange={handleChangeEmail}/>
                                    </TableCell>
                                    <TableCell>
                                        <Input name="firstName" placeholder ={strings.firstName} fullWidth onChange={handleChangeFirstName}/>
                                    </TableCell>
                                    <TableCell>
                                        <Input name="lastName" placeholder = {strings.lastName} fullWidth onChange={handleChangeLastName}/>
                                    </TableCell>
                                    <TableCell>
                                        <Input name="phoneNumber" placeholder = {strings.phoneNumber} fullWidth onChange={handleChangePhoneNumber}/>
                                    </TableCell>
                                    <TableCell>
                                        <Input name="roles" placeholder = {strings.roles} fullWidth onChange={handleChangeRole}/>
                                    </TableCell>
                                    <TableCell>
                                        <Input name="client" placeholder ={strings.client} fullWidth onChange={handleChangeClient}/>
                                    </TableCell>

                                    <TableCell>
                                        {" "}
                                        <FormGroup>
                                            <FormControlLabel
                                                label={strings.enabled}
                                                control={
                                                    <Checkbox
                                                        indeterminate={indeterminateState()}
                                                        checked={checked}
                                                        value={enabled}
                                                        onChange={handleChangeEnabled}
                                                    />
                                                }
                                            />
                                        </FormGroup>
                                    </TableCell>
                                    <TableCell/>
                                    <TableCell>
                                        <GuardedComponent component={
                                            <Button onClick={navigateUserNew} aria-label="add" variant={"contained"} className={"float-end"}>
                                                <AddIcon />{strings.addUser}
                                            </Button>
                                        } guards={
                                            [
                                                new PrivilegeGuard(
                                                    [Privileges.CREATE_USER, Privileges.ADMINISTER_USER, Privileges.READ_ROLES, Privileges.READ_USERS],
                                                    PrivilegeGuardMode.hasAll
                                                )
                                            ]
                                        } fallback={
                                            <Box/>
                                        } />

                                    </TableCell>

                                </TableRow>
                                <TableHeader columns={columns} sortConfigurationString={sortingConfigurationString} updateSort={updateSort}></TableHeader>

                            </TableHead>
                            <TableBody>
                                {
                                    users.map((user)=>(
                                        <TableRow key = {user.id}
                                                  component={getRowLink(user) ? Link : TableRow} to={getRowLink(user)}
                                                  onClick={guardedCallback([
                                                      new PrivilegeGuard(
                                                          [Privileges.UPDATE_USER, Privileges.DELETE_USER,
                                                              Privileges.ADMINISTER_USER, Privileges.READ_ROLES, Privileges.READ_CLIENTS],
                                                          PrivilegeGuardMode.hasAll
                                                      )
                                                  ], () => navigateUserDetails(user.id))}
                                                  hover={true}
                                                  className="cursor-pointer"
                                        >
                                            <TableCell>
                                                {user.id}
                                            </TableCell>
                                            <TableCell>
                                                {user.email}
                                            </TableCell>
                                            <TableCell>
                                                {user.person !== null ? user.person.firstName : ""}
                                            </TableCell>
                                            <TableCell>
                                                {user.person !== null ? user.person.lastName : ""}
                                            </TableCell>
                                            <TableCell>
                                                {user.person !== null ? user.person.phoneNumber : ""}
                                            </TableCell>
                                            <TableCell>
                                                {
                                                    user.roles !==null ? user.roles.map((role)=>(
                                                        <div key = {role.id}>
                                                            {role.label}
                                                        </div>
                                                    )) : ""
                                                }
                                            </TableCell>
                                            <TableCell>
                                                {user.client !== null ? user.client?.name : ""}
                                            </TableCell>
                                            <TableCell>
                                                {
                                                    user.enabled ? <CheckCircleIcon color={"primary"}/> : <CancelIcon color={"error"}/>
                                                }
                                            </TableCell>
                                            <TableCell>
                                                {DateTimeUtils.formatDateObject(user.dateCreated)}
                                            </TableCell>
                                            <TableCell>
                                                {DateTimeUtils.formatDateObject(user.dateModified)}
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Grid sx={{display: 'flex', flexDirection: 'column', alignItems: 'end', marginTop: "10px"}}>
                        <PaginationPlus totalElements={totalElements} rowsPerPage={rowsPerPage} page={page} onChangePage={(page) => handleChangePage(null, page)}/>
                        <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={totalElements}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    </Grid>
                </Box>
            }
        />
        </>
    );
}
