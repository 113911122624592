import axios from "../network/AxiosInstance";
import { AuthService } from "./AuthService";
import { ServiceDTO } from "../models/ServiceDTO";

export class ServicesService {
  static async getAllServices(): Promise<Array<ServiceDTO>> {
    const response = await axios.get<Array<ServiceDTO>>(
      `${AuthService.PREFIX}/bot-session/services/all`
    );
    const data = response.data;
    return data.map((service) => new ServiceDTO(service));
  }
}
