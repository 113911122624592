import {Page} from "../../components/Page";
import {CenteredCardLayout} from "../../components/CenteredCardLayout";
import {Button} from "@mui/material";
import {useState} from "react";
import {AuthService} from "../../services/AuthService";
import {ErrorHandler} from "../../utils/ErrorHandler";
import {useNavigate, useSearchParams} from "react-router-dom";
import {VisibilityEye} from "../../components/VisibilityEye";
import {useForm, Controller} from "react-hook-form";
import {strings} from "../../localization/Localization";
import {showErrorDialog} from "../../common/Dialogs";

interface IFormInputs {
    password: string;
    confirmPassword: string;
}

export function ConfirmResetPassword() {
    const [searchParams] = useSearchParams();
    const [resetSuccessful, setResetSuccessful] = useState<boolean>(false);
    const token = searchParams.get('token');
    const navigate = useNavigate();

    const {
        handleSubmit,
        control,
        watch,
        getValues,
    } = useForm<IFormInputs>({
        defaultValues: {
            password: "",
            confirmPassword: ""
        },
        mode: "onSubmit"
    })
    watch();

    async function confirmResetPassword(): Promise<void> {
        if (getValues("password") === getValues("confirmPassword")) {
            try {
                await AuthService.confirmResetPassword(token, getValues("password"));
                setResetSuccessful(true);
            } catch (e) {
                const message = ErrorHandler.parseErrorMessage(e);
                showErrorDialog(strings.error, message, strings.ok).then(_ => {});
            }
        }
    }

    function handleRedirect() {
        navigate(`/login`);
    }

    return (
        <Page title="Reset Password"
              component={
                  <div>
                      {!resetSuccessful ?
                          <form onSubmit={handleSubmit(confirmResetPassword)}>
                              <CenteredCardLayout minWidth={"30%"} maxWidth={"30%"} className={"mt-5"}>
                                  <div className="d-flex justify-content-md-center align-items-center">{strings.resetPassword}
                                  </div>
                                  <br/>
                                  <div className="text-center">Please enter your new password</div>
                                  <br/>
                                  <Controller name="password" rules={{required: true}}
                                              control={control} render={({field}) => (
                                      <VisibilityEye
                                          {...field}
                                          className="mb-3 w-100"
                                          required
                                          label={"New Password"}
                                      />
                                  )}
                                  />

                                  <Controller name="confirmPassword" rules={{required: true}}
                                              control={control} render={({field}) => (
                                      <VisibilityEye
                                          {...field}
                                          className="mb-3 w-100"
                                          required
                                          label={"Confirm New Password"}
                                      />
                                  )}
                                  />

                                  {getValues("password") !== getValues("confirmPassword") ? <div style={{color: "red"}}
                                                                       className={"alert alert-danger mt-2 text-center"}>{strings.passwordsDontMatch}</div> : null}
                                  <Button variant="contained" className="btn-round w-100 mt-3 btn btn-primary"
                                          type="submit"> {strings.resetPassword} </Button>

                              </CenteredCardLayout>
                          </form> : null}
                      {resetSuccessful ?
                          <CenteredCardLayout minWidth={"30%"} maxWidth={"30%"} className={"mt-5"}>
                              <div className="d-flex justify-content-md-center align-items-center mb-3">{strings.resetPassword}
                              </div>
                              <div className={"text-center alert alert-success"}>{strings.passwordResetSuccess}
                              </div>
                              <Button onClick={handleRedirect} variant={"contained"} fullWidth
                              >Back to Login</Button>
                          </CenteredCardLayout> : null}
                  </div>


              }

        />

    );
}
