import {SideBarPage} from "../../../components/SideBarPage";
import {strings} from "../../../localization/Localization";
import {
    Button,
    Grid,
    IconButton,
    Input, Pagination,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {defaultRowsPerPageOptions} from "../../../common/Constants";
import {NumberParser} from "../../../utils/NumberParser";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {Dayjs} from "dayjs";
import {DateTimeUtils} from "../../../utils/DateTimeUtils";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {Clear} from "@mui/icons-material";
import {ClientDTO} from "../../../models/ClientDTO";
import {ClientService} from "../../../services/ClientService";
import {Routes} from "../../../router/Routes";
import {TableHeader} from "../../../components/TableHeader";
import {SortingConfiguration} from "../../../utils/SortingUtils";
import {GuardedComponent} from "../../../components/GuardedComponent";
import {Box} from "@mui/system";
import {PrivilegeGuard, PrivilegeGuardMode} from "../../../router/guards/PrivilegeGuard";
import {Privileges} from "../../../models/nomenclatures/Privileges";
import {guardedCallback, guardedObject} from "../../../router/guards/GuardedCallback";
import {PaginationPlus} from "../../../components/PaginationPlus";
import {MobileProxyStatusDTO} from "../../../models/MobileProxyStatusDTO";

export function Clients() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [clients, setClients] = useState<ClientDTO[]>([]);
    const name = searchParams.get("name") ?? "";
    const dateCreated = searchParams.get("dateCreated") ?? null;
    const dateModified = searchParams.get("dateModified") ?? null;
    const rowsPerPage = NumberParser.parseNumber(searchParams.get("rowsPerPage"), 25);
    const page = NumberParser.parseNumber(searchParams.get("page"), 0);
    const [totalElements, setTotalElements] = useState(0);
    const sortingConfigurationString = searchParams.get("sort");

    const listLinks = [
        { label: strings.dashboard, currentlyOpened: false, href: Routes.HOME},
        { label: strings.clients, currentlyOpened: true, href: Routes.CLIENTS},
    ]

    const columns = [
        {label: strings.clientId, sortBy: "id", sortable: false},
        {label: strings.proxyName, sortBy: "name", sortable: true},
        {label: strings.dateCreated, sortBy: "dateCreated", sortable: true},
        {label: strings.dateModified, sortBy: "dateModified", sortable: true},
        {label: "", sortBy: "", sortable: false}
    ];

    const navigate = useNavigate();

    function setSearchParam(key: string, value: string | null | undefined) {
        if (value) {
            searchParams.set(key, value);
        } else {
            searchParams.delete(key);
        }
        setSearchParams(searchParams)
    }

    function setPage(page: number) {
        setSearchParam("page", page.toString());
    }

    function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("rowsPerPage", event.target.value);
        setPage(0);
    }

    function handleNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("name", event.target.value);
        setPage(0);
    }

    function handleChangeDateCreated(date: Dayjs | null) {
        setSearchParam("dateCreated", DateTimeUtils.formatDateDayJs(date));
        setPage(0);
    }

    function handleChangeDateModified(date: Dayjs | null) {
        setSearchParam("dateModified", DateTimeUtils.formatDateDayJs(date));
        setPage(0);
    }

    useEffect(() => {
        async function getClients(page: number) {
            const data = await ClientService.getAllClientsPaged(page, rowsPerPage, name, dateCreated, dateModified, SortingConfiguration.fromSearchString(sortingConfigurationString));

            setClients(data.content);
            setTotalElements(data.totalElements);
        }
        getClients(page).then(_ => {});
    }, [page, rowsPerPage, name, dateCreated, dateModified, sortingConfigurationString]);

    function updateSort(sortingConfiguration: SortingConfiguration): void {
        setSearchParam("sort", sortingConfiguration.toSearchString());
    }

    function navigateToAddClient(): any {
        navigate(Routes.CLIENTS_NEW);
    }

    function navigateClientRow(id: number): any {
        navigate(Routes.CLIENT_DETAILS.replace(":id", id.toString()));
    }

    function getRowLink(entry: ClientDTO): string {
        return guardedObject([
            new PrivilegeGuard(
                [Privileges.MANAGE_CLIENTS],
                PrivilegeGuardMode.hasAll
            )
        ], Routes.CLIENT_DETAILS.replace(":id", entry.id.toString())) ?? "";
    }

    return (
        <SideBarPage pageTitle={strings.clients} breadcrumbs={listLinks} component={
                <Grid>
                    <TableContainer component={Paper}>
                        <Table sx={{minWidth: 650}} aria-label="custom pagination table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                    </TableCell>
                                    <TableCell>
                                        <Input
                                            name="name"
                                            value={name}
                                            onChange={handleNameChange}
                                            placeholder={strings.clientNameFilter}
                                            style={{width: "auto"}}
                                        />
                                    </TableCell>
                                    <TableCell width={"20%"}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                onChange={handleChangeDateCreated}
                                                value={dateCreated}
                                                label={strings.dateCreated}
                                                inputFormat="DD.MM.YYYY"
                                                renderInput={(params: any) => (
                                                    <TextField
                                                        {...params}
                                                        variant="standard"
                                                        onKeyDown={(e)=>e.preventDefault()}
                                                    />
                                                )}
                                                componentsProps={{
                                                    actionBar: {
                                                        actions: ["today"],
                                                    },
                                                }}
                                                InputProps={{
                                                    endAdornment: dateCreated && (
                                                        <IconButton sx={{height: "10px", width: "10px"}}
                                                                    onClick={() => {
                                                                        setSearchParam("dateCreated", null);
                                                                        setPage(0);
                                                                    }}>
                                                            <Clear/>
                                                        </IconButton>
                                                    ),
                                                }}
                                                InputAdornmentProps={{
                                                    position: "start",
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </TableCell>
                                    <TableCell width={"20%"}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                onChange={handleChangeDateModified}
                                                value={dateModified}
                                                label={strings.dateModified}
                                                inputFormat="DD.MM.YYYY"
                                                renderInput={(params: any) => (
                                                    <TextField
                                                        {...params}
                                                        variant="standard"
                                                        onKeyDown={(e)=>e.preventDefault()}
                                                    />
                                                )}
                                                componentsProps={{
                                                    actionBar: {
                                                        actions: ["today"],
                                                    },
                                                }}
                                                InputProps={{
                                                    endAdornment: dateModified && (
                                                        <IconButton sx={{height: "10px", width: "10px"}}
                                                                    onClick={() => {
                                                                        setSearchParam("dateModified", null);
                                                                        setPage(0);
                                                                    }}>
                                                            <Clear/>
                                                        </IconButton>
                                                    ),
                                                }}
                                                InputAdornmentProps={{
                                                    position: "start",
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </TableCell>
                                    <TableCell>
                                        <GuardedComponent component={
                                            <Button onClick={navigateToAddClient} aria-label="add" variant={"contained"}
                                                    className={"float-end"}>
                                                {strings.addClient}
                                            </Button>
                                        } guards={
                                            [
                                                new PrivilegeGuard(
                                                    [Privileges.MANAGE_CLIENTS],
                                                    PrivilegeGuardMode.hasAll
                                                )
                                            ]
                                        } fallback={
                                            <Box/>
                                        } />

                                    </TableCell>
                                </TableRow>
                                <TableHeader columns={columns} sortConfigurationString={sortingConfigurationString}
                                             updateSort={updateSort}></TableHeader>
                            </TableHead>
                            <TableBody>
                                {clients?.map((client) => (
                                    <TableRow
                                        key={client.id}
                                        component={getRowLink(client) ? Link : TableRow} to={getRowLink(client)}
                                        hover={true}
                                        className="cursor-pointer"
                                        onClick={guardedCallback([
                                            new PrivilegeGuard(
                                                [Privileges.MANAGE_CLIENTS],
                                                PrivilegeGuardMode.hasAll
                                            )
                                        ], () => navigateClientRow(client.id))}
                                    >
                                        <TableCell width={"10%"}>{client.id}</TableCell>
                                        <TableCell className={"truncate-table-cell"}>{client.name}</TableCell>
                                        <TableCell>{DateTimeUtils.formatDate(client.dateCreated)}</TableCell>
                                        <TableCell>{DateTimeUtils.formatDate(client.dateModified)}</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Grid sx={{display: 'flex', flexDirection: 'column', alignItems: 'end', marginTop: "10px"}}>
                        <PaginationPlus totalElements={totalElements} rowsPerPage={rowsPerPage} page={page} onChangePage={(page) => handleChangePage(null, page)}/>
                        <TablePagination
                        rowsPerPageOptions={defaultRowsPerPageOptions}
                        component="div"
                        count={totalElements}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    </Grid>
                </Grid>
        }/>
    )
}
