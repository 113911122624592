export class NumberParser {
    static parseNumberNullable(value: string | null | undefined) : number | undefined {
        if (!value) {
            return undefined;
        }

        const parsedValue = +value;

        if (isNaN(parsedValue)) {
            return undefined;
        }

        return parsedValue;
    }

    static parseNumber(value: string | null | undefined, defaultValue: number) : number {
        const parsedValue = this.parseNumberNullable(value);

        if (parsedValue === undefined) {
            return defaultValue;
        }

        return parsedValue;
    }
}
