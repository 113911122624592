import { AccountDTO } from "./AccountDTO";
import { BotsDTO } from "./BotsDTO";
import { ProxyDTO } from "./ProxyDTO";
import { SessionInstanceActionDTO } from "./SessionInstanceActionDTO";
import { SessionReducedDTO } from "./SessionReducedDTO";
import {BotPlatformDTO} from "./BotPlatformDTO";
import {MobilePhoneDTO} from "./MobilePhoneDTO";
import { FingerprintDTO } from "./FingerprintDTO";

export class SessionInstance{
    id:number;
    dateCreated:Date;
    dateModified:Date;
    session:SessionReducedDTO | undefined;
    account:AccountDTO | undefined;
    cookieCollectionId:number;
    proxy:ProxyDTO | undefined;
    totalActions:number;
    finishedActions: number;
    bot:BotsDTO | undefined;
    actions:SessionInstanceActionDTO[] | undefined;
    successfullyFinished:number;
    unsuccessfullyFinished:number;
    successfullyExecuted:boolean;
    sessionInstanceConditionType:string;
    unfinished:number;
    profile:string;
    botPlatform:BotPlatformDTO | undefined;
    initialized: boolean | null;
    mobilePhone: MobilePhoneDTO | undefined;
    usedProxy:ProxyDTO | undefined;
    fingerprint: FingerprintDTO | undefined;

    constructor(json:SessionInstance){
        this.id = json.id;
        this.dateCreated = new Date(json.dateCreated);
        this.dateModified = new Date(json.dateModified);
        this.session =  json.session ?  new SessionReducedDTO(json.session):undefined;
        this.account  = json.account ?  new AccountDTO(json.account) : undefined;
        this.cookieCollectionId = json.cookieCollectionId;
        this.proxy = json.proxy ? new ProxyDTO(json.proxy) : undefined; 
        this.totalActions = json.totalActions;
        this.finishedActions = json.finishedActions;
        this.bot = json.bot ? new BotsDTO(json.bot) : undefined;
        this.actions =  json.actions?.map((item) => new SessionInstanceActionDTO(item)) ?? [];
        this.successfullyFinished = json.successfullyFinished;
        this.unsuccessfullyFinished = json.unsuccessfullyFinished;
        this.successfullyExecuted=json.successfullyExecuted;
        this.sessionInstanceConditionType=json.sessionInstanceConditionType;
        this.unfinished = json.unfinished;
        this.profile = json.profile;
        this.botPlatform = json.botPlatform ? new BotPlatformDTO(json.botPlatform) : undefined;
        this.initialized = json.initialized;
        this.mobilePhone = json.mobilePhone ? new MobilePhoneDTO(json.mobilePhone) : undefined;
        this.usedProxy = json.usedProxy ? new ProxyDTO(json.usedProxy) : undefined;
        this.fingerprint = json.fingerprint;
    }
}
