import {CountryDTO} from "../models/CountryDTO";
import {Page} from "../pagination/Page";
import axiosInstance from "../network/AxiosInstance";

export class CountryService{
    static async findAllCountriesPaged(page: number, size: number, commonName: string | undefined): Promise<Page<CountryDTO>>{
        const params = new URLSearchParams({
            page: page.toString(),
            size: size.toString()
        })

        if(commonName){
            params.append("commonName", commonName);
        }

        const response = await axiosInstance.get<Page<CountryDTO>>(`/rest/bot-session/countries/all-paged`, {params: params})
        const data = response.data;

        return new Page<CountryDTO>(
            data.content.map(country => new CountryDTO(country)),
            data.totalElements
        )
    }
}