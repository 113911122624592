export class BooleanParser {
    static parseBooleanNullable(value: string | null | undefined) : boolean | undefined {
        if (!value) {
            return undefined;
        }

        switch (value.toLowerCase()) {
            case "true":
                return true;
            case "false":
                return false;
            default: return undefined;
        }
    }
}
