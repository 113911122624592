import {ProxyDTO} from "./ProxyDTO";

export class ProxyGroupReducedDTO {
    id: number;
    dateCreated: number;
    dateModified: number;
    name: string;
    proxies: ProxyDTO[];
    
    constructor(json: any) {
        this.id = json.id;
        this.dateCreated = json.dateCreated;
        this.dateModified = json.dateModified;
        this.name = json.name;
        this.proxies = json.proxies;
    }
}