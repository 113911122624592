import React from "react";

type OnClickCallback = {
    (): void
};

interface LinkTextProps {
    text: React.ReactElement;
    onClick?: OnClickCallback;
    enabled?: boolean;
    className?: string;
}

export function LinkText(props: LinkTextProps) {
    function buildClasses(): string {
        let classes = "p-1";

        if (props.enabled) {
            classes += " navigateLink";
        }

        if (props.className) {
            classes += ` ${props.className}`
        }

        return classes;
    }

    return (
        <div className={buildClasses()} style={{cursor: props.enabled ? "pointer" : undefined}}
             onClick={props.enabled ? props.onClick : undefined}>{props.text}</div>
    );
}
