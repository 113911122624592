export class CollectionUtils {
    static containsAll<T>(list: T[], target: T[]): boolean {
        const setOfAll = new Set<T>(list);

        for (const value of target) {
            if (!setOfAll.has(value)) {
                return false;
            }
        }

        return true;
    }

    static containsAny<T>(list: T[], target: T[]): boolean {
        const setOfAll = new Set<T>(list);

        for (const value of target) {
            if (setOfAll.has(value)) {
                return true;
            }
        }

        return false;
    }
}
