import {AddEditUser} from "./AddEditUser";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {InterComponentExchange} from "../../../models/InterComponentExchange";
import {InterComponentExchangeConstants} from "../../../models/InterComponentExchangeConstants";

export function EditUser(){
    const {id} = useParams();
    const [interComponentExchange, setInterComponentExchange] = useState<InterComponentExchange | undefined>(undefined)
    useEffect(() =>{
        if(id){
            setInterComponentExchange(new InterComponentExchange(parseInt(id), new InterComponentExchangeConstants().EDIT_COMPONENT))
        }
    }, [id]);
    return(
        <>
            {
                interComponentExchange ? (<AddEditUser info = {{interComponentExchange:interComponentExchange}}/>) : (<div></div>)
            }
        </>
    )
}
