import {BotPlatformDTO} from "../models/BotPlatformDTO";
import {BotPlatformConstants} from "../common/BotPlatformConstants";
import LanguageIcon from "@mui/icons-material/Language";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import SmartToyOutlinedIcon from "@mui/icons-material/SmartToyOutlined";
import React from "react";

interface BotPlatformIconProps {
    botPlatform?: BotPlatformDTO;
}

export function BotPlatformIcon(props: BotPlatformIconProps) {
    const botPlatform = props.botPlatform;

    if (botPlatform?.type === BotPlatformConstants.browser) {
        return (
            <LanguageIcon fontSize={'medium'} className={"mx-1"}/>
        )
    } else if (botPlatform?.type === BotPlatformConstants.mobile) {
        return (
            <PhoneAndroidIcon fontSize={'medium'} className={"mx-1"}/>
        )
    } else {
        return (
            <SmartToyOutlinedIcon fontSize={'medium'} className={"mx-1"}/>
        )
    }
}
