import { ProxyDTO } from "./ProxyDTO";
import { RegionDTO } from "./RegionDTO";

export class SessionPreferredProxy  {
    region: RegionDTO;
    proxy:ProxyDTO;
    constructor(json:SessionPreferredProxy) {
        this.region = json.region;
        this.proxy = json.proxy;
    }
}
