import dayjs, { Dayjs } from "dayjs";
import { ListenedTrackDTO } from "../models/ListenedTrackDTO";
import { SessionInstance } from "../models/SessionInstance";
import { SessionInstanceActionDTO } from "../models/SessionInstanceActionDTO";
import axios from "../network/AxiosInstance";
import { Page } from "../pagination/Page";
import { SortingConfiguration } from "../utils/SortingUtils";
import { AuthService } from "./AuthService";

export class SessionInstanceService{
    static async getAllSessionInstancesPaged(page: number, size: number, sessionId: number | string | undefined, accountGroupName: string | undefined, accountEmail: string | undefined, accountRegionId: number | string | undefined, accountAgeGroupId: number | string | undefined, proxyName: string | undefined, sessionKey: string | undefined, sessionServiceId: number | string | undefined, sessionName: string | undefined, usesProxy: boolean | undefined, dateCreated: string | dayjs.Dayjs | null, sort: SortingConfiguration | null, proxyId?: number | undefined, botName?: string | undefined, client?: string, sessionInstanceConditionType?: string, botPlatformId?: number|undefined): Promise<Page<SessionInstance>> {
      let params = new URLSearchParams();
      params.append("page", page.toString());
      params.append("size", size.toString());
      if(sessionId) {
        params.append("sessionId", sessionId?.toString());
      }
      if(accountGroupName) {
        params.append("accountGroupName", accountGroupName);
      }
      if(accountEmail) {
        params.append("accountEmail", accountEmail);
      }
      if(accountRegionId){
        params.append("accountRegionId", accountRegionId?.toString())
      }
      if(accountAgeGroupId){
        params.append("accountAgeGroupId", accountAgeGroupId?.toString());
      }
      if(proxyName){
        params.append("proxyName", proxyName);
      }
      if(sessionKey){
        params.append("sessionKey", sessionKey)
      }
      if(sessionName){
        params.append("sessionName",sessionName)
      }
      if(sessionServiceId){
        params.append("sessionServiceId", sessionServiceId?.toString())
      }
      if(usesProxy !== undefined){
        params.append("usesProxy", usesProxy.toString())
      }
      if(dateCreated){
        params.append("dateCreated", dateCreated.toString())
      }
      if(sort){
        sort.appendToBackEndSearchParams(params);
      }
      if(proxyId){
        params.append("proxyId", proxyId.toString())
      }
      if(botName) {
        params.append("botName", botName)
      }
      if(client) {
        params.append("client", client)
      }
      if(botPlatformId){
        params.append("botPlatformId", botPlatformId.toString())
      }
      if(sessionInstanceConditionType) {
        params.append("sessionInstanceConditionType", sessionInstanceConditionType)
      }

      const request = {
        params: params, 
      };
      
        const response = await axios.get<Page<SessionInstance>>(
          `${AuthService.PREFIX}/bot-session/session-instance/all-paged/`, request,
        );
        const data = response.data
    
        return new Page<SessionInstance>(
            data.content.map(sessionInstance => new SessionInstance(sessionInstance)),
            data.totalElements,
        )
      }

      static async getSessionInstanceById(id: number): Promise<SessionInstance> {
        const response = await axios.get<SessionInstance>(
          `/rest/bot-session/session-instance/${id}`
        );
        const data = response.data;
    
        return new SessionInstance(data);
      }


      static async getSessionInstanceActions(page: number, size: number, id:number | string | undefined, actionTypeId:number | string | undefined, success:boolean | undefined, finished:boolean | undefined, sort: SortingConfiguration): Promise<Page<SessionInstanceActionDTO>> {
        let params = new URLSearchParams();
        params.append("page", page.toString());
        params.append("size", size.toString());
        if(actionTypeId) {
          params.append("actionTypeId", actionTypeId.toString());
        }
        if(success!==undefined) {
          params.append("success", success.toString());
        }
        if(finished!==undefined) {
          params.append("finished", finished.toString());
        }
       
          sort.appendToBackEndSearchParams(params);
        
  
        const request = {
          params: params, 
        };
        
          const response = await axios.get<Page<SessionInstanceActionDTO>>(
            `${AuthService.PREFIX}/bot-session/session-instance/${id}/actions`, request,
          );
          const data = response.data
      
          return new Page<SessionInstanceActionDTO>(
              data.content.map(sessionInstanceAction => new SessionInstanceActionDTO(sessionInstanceAction)),
              data.totalElements,
          )
        }

        static async getListenedTracksOfSessionInstance(page: number, size: number, id:number | string | undefined, listenedTrackId:number | string | undefined, sessionInstanceActionId:number | string | undefined, name:string | undefined,url:string | undefined, startDate:string | null, timeElapsedInSeconds:number | string | undefined,artist:string | undefined, sortTrack: SortingConfiguration): Promise<Page<ListenedTrackDTO>> {
          let params = new URLSearchParams();
          params.append("page", page.toString());
          params.append("size", size.toString());
          if(listenedTrackId) {
            params.append("listenedTrackId", listenedTrackId.toString());
          }
          if(sessionInstanceActionId) {
            params.append("sessionInstanceActionId", sessionInstanceActionId.toString());
          }
          if(name) {
            params.append("name", name);
          }
          if(url) {
            params.append("url", url);
          }
          if(startDate) {
            params.append("startDate", startDate);
          }
          if(timeElapsedInSeconds) {
            params.append("timeElapsedInSeconds", timeElapsedInSeconds.toString());
          }
          if(artist){
            params.append("artist", artist)
          }
         
            sortTrack.appendToBackEndSearchParams(params);
          
    
          const request = {
            params: params, 
          };
          
            const response = await axios.get<Page<ListenedTrackDTO>>(
              `${AuthService.PREFIX}/bot-session/session-instance/${id}/listened-tracks`, request,
            );
            const data = response.data
        
            return new Page<ListenedTrackDTO>(
                data.content.map(track => new ListenedTrackDTO(track)),
                data.totalElements,
            )
          }
  static async getNumberOfActiveSessionInstances(): Promise<Number> {
    const response = await axios.get<Number>(`${AuthService.PREFIX}/bot-session/session-instance/active-count`, {});
    const data = response.data;

    return data;
  }

  static async downloadReport(id:number | string | undefined): Promise<void> {
    const response = await axios.get(`${AuthService.PREFIX}/bot-session/session-instance/${id}/report`, {responseType: 'blob'})
    const blob = response.data;
    let fileName = response.headers['content-disposition']?.split("filename=")[1].split('.')[0].replace(/"/g, '');
    const fileURL = window.URL.createObjectURL(blob);
    let alink = document.createElement('a');
    alink.href = fileURL;
    alink.download = fileName ? fileName : `session_instance_${id}`;
    alink.click();
  }

  static async downloadScreenshots(sessionInstanceId:number | string, sessionInstanceActionId?: number | string): Promise<void> {
    const params = new URLSearchParams();

    if (sessionInstanceActionId) {
      params.append("sessionInstanceActionId", sessionInstanceActionId.toString());
    }

    const response = await axios.get(`${AuthService.PREFIX}/bot-session/session-instance/${sessionInstanceId}/screenshots`, {
      responseType: 'blob',
      params: params,
    });
    const blob = response.data;
    let fileName = response.headers['content-disposition']?.split("filename=")[1].split('.')[0].replace(/"/g, '');
    const fileURL = window.URL.createObjectURL(blob);
    let alink = document.createElement('a');
    alink.href = fileURL;
    alink.download = fileName ? fileName : `${sessionInstanceId}_screenshots.zip`;
    alink.click();
  }

}
