import { Page } from "../pagination/Page";
import axiosInstance from "../network/AxiosInstance";
import { BotsDTO } from "../models/BotsDTO";
import { SortingConfiguration } from "../utils/SortingUtils";
import { BotListeningInfoDTO } from "../models/BotListeningInfoDTO";
import { ListenedTrackDTO } from "../models/ListenedTrackDTO";
import { ProblematicBotResponseDTO } from "../models/ProblematicBotResponseDTO";
import { ProblematicBotsResponse } from "../models/ProblematicBotsResponse";

export class BotsService {
    static async getAllBotsPaged(id: number | undefined, page: number, size: number, name: string | null, lastLog: string | null,
        lastActionDate: string | null,
        dateCreated: string | null, dateModified: string | null, sortingConfiguration: SortingConfiguration | undefined): Promise<Page<BotsDTO>> {

        const params = new URLSearchParams({
            page: page.toString(),
            size: size.toString(),
        });

        if (id !== undefined) {
            params.append("id", id.toString());
        }
        if (name) {
            params.append("name", name);
        }
        if (lastLog) {
            params.append("lastLog", lastLog);
        }
        if (lastActionDate) {
            params.append("lastActionDate", lastActionDate);
        }
        if (dateCreated) {
            params.append("dateCreated", dateCreated);
        }
        if (dateModified) {
            params.append("dateModified", dateModified);
        }

        sortingConfiguration?.appendToBackEndSearchParams(params);

        const response = await axiosInstance.get<Page<BotsDTO>>(
            `/rest/bot-session/bot/all-paged`, { params: params },
        );

        const data = response.data;

        return new Page(
            data.content.map(bots => new BotsDTO(bots)),
            data.totalElements,
        )
    }

    static async getTrackTimeElapsed(id: string | undefined, page: number, size: number): Promise<Page<BotListeningInfoDTO>> {
        const params = new URLSearchParams({
            page: page.toString(),
            size: size.toString(),
        });

        const response = await axiosInstance.get<Page<BotListeningInfoDTO>>(
            `/rest/bot-session/bot/tracks-time-elapsed/${id}`, { params: params }
        );

        const data = response.data;

        return new Page(
            data.content.map(b => new BotListeningInfoDTO(b)),
            data.totalElements,
        )
    }

    static async stopAllBots() {
        return await axiosInstance.post('/rest/bot-session/bot/stop-all')
    }

    static async stopBot(id: number): Promise<void> {
        return await axiosInstance.post(`/rest/bot-session/bot/stop/${id}`)
    }

    static async getLastTracks(id: number, page: number, size: number): Promise<Page<ListenedTrackDTO>> {
        const params = new URLSearchParams({
            page: page.toString(),
            size: size.toString(),
        })

        const response = await axiosInstance.get<Page<ListenedTrackDTO>>(
            `/rest/bot-session/bot/last-tracks/${id}`, { params: params }
        );

        const data = response.data;

        return new Page(
            data.content.map(lt => new ListenedTrackDTO(lt)),
            data.totalElements,
        )

    }

    static async findAllProblematicBots(dateModified: string, name?: string, isMobile?: boolean, page?: number, size?: number): Promise<ProblematicBotsResponse> {
        const response = await axiosInstance.get('/rest/bot-session/bot/problematic-bots', {
            params: {
                dateModified,
                name,
                isMobile,
                page,
                size,
            }
        });
        return {
            bots: response.data.content,
            totalElements: response.data.totalElements
        };
    }
}
