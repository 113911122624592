import axiosInstance from "../network/AxiosInstance";
import {ProxyDTO} from "../models/ProxyDTO";
import {Page} from "../pagination/Page";
import {ProxyCreationDTO} from "../models/ProxyCreationDTO";
import {SortingConfiguration} from "../utils/SortingUtils";
import {ProxyStatsDTO} from "../models/ProxyStatsDTO";
import {Dayjs} from "dayjs";
import {ProxyUpdateDTO} from "../models/ProxyUpdateDTO";
import axios from "../network/AxiosInstance";
import {AuthService} from "./AuthService";

export class ProxyService {
    static async getAllProxiesPaged(page: number, size: number, id?: string | number | undefined, name?: string | null, url?: string | null, regionId?: number | string | undefined, hasAuth?: boolean | undefined,
                                    dateModified?: string | null | Dayjs, sortingConfiguration?: SortingConfiguration | undefined): Promise<Page<ProxyDTO>> {
        const params = new URLSearchParams({
            page: page.toString(),
            size: size.toString(),
        });

        if (id !== undefined) {
            params.append("id", id.toString());
        }

        if (name) {
            params.append("name", name);
        }

        if (url) {
            params.append("url", url);
        }

        if (regionId !== undefined) {
            params.append("regionId", regionId.toString());
        }

        if (hasAuth !== undefined) {
            params.append("hasAuth", hasAuth.toString());
        }

        if(dateModified) {
            params.append("dateModified", dateModified.toString());
        }

        sortingConfiguration?.appendToBackEndSearchParams(params);

        const response = await axiosInstance.get<Page<ProxyDTO>>(
            `/rest/bot-session/proxy/all-paged`, {params: params},
        );

        const data = response.data;

        return new Page<ProxyDTO>(
            data.content.map(proxy => new ProxyDTO(proxy)),
            data.totalElements,
        )
    }

    static async getAllProxies(id?: string | number | undefined, name?: string | null, url?: string | null, regionId?: number | string | undefined, hasAuth?: boolean | undefined,
                                    dateModified?: string | null | Dayjs, proxyGroupId?: number | string | undefined, sortingConfiguration?: SortingConfiguration | undefined): Promise<Array<ProxyDTO>> {
        const params = new URLSearchParams();

        if (id !== undefined) {
            params.append("id", id.toString());
        }

        if (name) {
            params.append("name", name);
        }

        if (url) {
            params.append("url", url);
        }

        if (regionId !== undefined) {
            params.append("regionId", regionId.toString());
        }

        if (hasAuth !== undefined) {
            params.append("hasAuth", hasAuth.toString());
        }

        if (proxyGroupId !== undefined) {
            params.append("proxyGroupId", proxyGroupId.toString());
        }

        if(dateModified) {
            params.append("dateModified", dateModified.toString());
        }

        sortingConfiguration?.appendToBackEndSearchParams(params);
        const response = await axiosInstance.get<Array<ProxyDTO>>(`/rest/bot-session/proxy/all`, {params: params});
        const data =  response.data;
        return data.map(region => new ProxyDTO(region))
    }

    static async getAllProxiesPaged2(page: number, size: number, name: string | null | undefined, url: string | null, regionId: number | null | string | undefined): Promise<Page<ProxyDTO>> {

        const response = await axiosInstance.get<Page<ProxyDTO>>(
            `/rest/bot-session/proxy/all-paged`, {params: {page, size, name, url, regionId}}
        );

        const data = response.data;

        return new Page(
            data.content.map(proxy => new ProxyDTO(proxy)),
            data.totalElements,
        )
    }
    static async getProxyById(id: number): Promise<ProxyDTO> {
        const response = await axiosInstance.get<ProxyDTO>(`/rest/bot-session/proxy/${id}`,);
        const data = response.data;

        return new ProxyDTO(data);
    }

    static async getProxyStats(id: number): Promise<ProxyStatsDTO> {
        const response = await axiosInstance.get<ProxyStatsDTO>(
            `/rest/bot-session/proxy/${id}/stats`,);
        const data = response.data;
        return new ProxyStatsDTO(data);
    }

    static async deleteProxy(id: number): Promise<void> {
        await axiosInstance.delete<ProxyDTO>(`/rest/bot-session/proxy/delete/${id}`, {params: {id}});
    }

    static async createProxy(proxy: ProxyCreationDTO): Promise<ProxyDTO> {
        const response = await axiosInstance.post<ProxyDTO>(
            `/rest/bot-session/proxy/create`, proxy,
        );

        const data = response.data;
        return new ProxyDTO(data);
    }

    static async editProxy(proxy: ProxyCreationDTO, id: number | undefined): Promise<ProxyDTO> {
        const response = await axiosInstance.post<ProxyDTO>(
            `/rest/bot-session/proxy/update/${id}`, proxy,
        );

        const data = response.data;
        return new ProxyDTO(data);
    }

    static async downloadProxyReport(id: number, dateFrom?: string | null, dateTo?: string | null): Promise<void> {
        const params = {dateFrom, dateTo}
        const response = await  axiosInstance.get(`/rest/bot-session/proxy/${id}/statistics-report`, {responseType: 'blob', params: params})
        const blob = response.data;
        let fileName = response.headers['content-disposition']?.split("filename=")[1].split('.')[0].replace(/"/g, '');
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = fileName ? fileName : `proxy_${id}_${dateFrom}-${dateTo}`;
        alink.click();
    }

    static async downloadProxyAccountsReport(id: number): Promise<void> {
        const response = await  axiosInstance.get(`/rest/bot-session/proxy/${id}/linked-accounts-report`, {responseType: 'blob'});
        const blob = response.data;
        let fileName = response.headers['content-disposition']?.split("filename=")[1].split('.')[0].replace(/"/g, '');
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = fileName ? fileName : `proxy_${id}_linked_accounts.xlsx`;
        alink.click();
    }

    static async downloadAccountsPerProxyReport(): Promise<void> {
        const response = await axiosInstance.get(`/rest/bot-session/proxy/linked-accounts-count-per-proxy`, {responseType: 'blob'});
        const blob = response.data;
        let fileName = response.headers['content-disposition']?.split("filename=")[1].split('.')[0].replace(/"/g, '');
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = fileName ? fileName : `proxy_linked_accounts_count_per_proxy.xlsx`;
        alink.click();
    }

    static async downloadActiveProxiesReport(): Promise<void> {
        const response = await axiosInstance.get(`/rest/bot-session/proxy/active-proxies-report`, {responseType: 'blob'});
        const blob = response.data;
        let fileName = response.headers['content-disposition']?.split("filename=")[1].split('.')[0].replace(/"/g, '');
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = fileName ? fileName : `active_proxies.xlsx`;
        alink.click();
    }

    static async importProxies(namePrefix: string, regionId: number, proxiesToImport: File): Promise<void> {
        const formData = new FormData();
        formData.append("proxiesToImport", proxiesToImport);
    
        const params = {
            namePrefix,
            regionId: regionId.toString(),
        };
    
        const config = {
            params,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
    
        const response = await axiosInstance.post<void>(
            `/rest/bot-session/proxy/import-proxies`,
            formData,
            config
        );  
        return response.data;
    }

    static async updateMultipleProxies(proxyToUpdate: ProxyUpdateDTO[]): Promise<void> {
        await axiosInstance.post<ProxyUpdateDTO[]>(
            `/rest/bot-session/proxy/update-multiple-proxies`, proxyToUpdate,
        );
    }


    static async getAllOfGroup(proxyGroupId: number): Promise<ProxyDTO[]> {
        const response = await axios.get<ProxyDTO[]>(`${AuthService.PREFIX}/bot-session/proxy/all-of-group/${proxyGroupId}`);
        const data = response.data;
        return data.map(proxy => new ProxyDTO(proxy));
    }
}