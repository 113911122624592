import {SideBarPage} from "../../components/SideBarPage";
import {strings} from "../../localization/Localization";
import {
    Button,
    Grid,
    Input,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import React, {useEffect, useState} from "react";
import {TableHeader} from "../../components/TableHeader";
import {Link, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {SortingConfiguration} from "../../utils/SortingUtils";
import {RegionPageDTO} from "../../models/RegionPageDTO";
import {RegionService} from "../../services/RegionService";
import {NumberParser} from "../../utils/NumberParser";
import {Routes} from "../../router/Routes";
import {PaginationPlus} from "../../components/PaginationPlus";
import {defaultRowsPerPageOptions} from "../../common/Constants";
import {GuardedComponent} from "../../components/GuardedComponent";
import {PrivilegeGuard, PrivilegeGuardMode} from "../../router/guards/PrivilegeGuard";
import {Privileges} from "../../models/nomenclatures/Privileges";
import {Box} from "@mui/system";
import {guardedCallback, guardedObject} from "../../router/guards/GuardedCallback";
import {MobileProxyStatusDTO} from "../../models/MobileProxyStatusDTO";

export function Regions() {
    const listLinks = [
        { label: strings.dashboard, currentlyOpened: false, href:"/"},
        { label: strings.regions,  currentlyOpened: true },
    ]

    const [searchParams, setSearchParams] = useSearchParams();
    const sortingConfigurationString = searchParams.get("sort");

    const columns = [
        {label: "Key", sortBy: "key", sortable: true},
        {label: "Label", sortBy: "label", sortable: true},
        {label: "Country", sortBy: "c.commonName", sortable: true},
        {label: "", sortBy: "", sortable: false}
    ];

    const page = NumberParser.parseNumber(searchParams.get("page"), 0);
    const rowsPerPage = NumberParser.parseNumber(searchParams.get("rowsPerPage"), 25);
    const [totalElements, setTotalElements] = useState(0);
    const key = searchParams.get("key") ?? "";
    const label = searchParams.get("label") ?? "";
    const countryCommonName = searchParams.get("countryCommonName") ?? "";
    const [regions, setRegions] = useState<RegionPageDTO[]>([])
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        RegionService.getAllRegionsPaged(page, rowsPerPage, key, label, countryCommonName, SortingConfiguration.fromSearchString(sortingConfigurationString)).then((data) => {
            setRegions(data.content);
            setTotalElements(data.totalElements);
        })
    }, [page, rowsPerPage, key, label, countryCommonName, sortingConfigurationString])

    function setSearchParam(key: string, value: string | null | undefined) {
        if (value) {
            searchParams.set(key, value);
        } else {
            searchParams.delete(key);
        }

        setSearchParams(searchParams)
    }

    function setPage(page: number) {
        setSearchParam("page", page.toString());
    }

    function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("rowsPerPage", event.target.value);
        setPage(0);
    }

    function handleKeyChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("key", event.target.value);
        setPage(0);
    }

    function handleLabelChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("label", event.target.value);
        setPage(0);
    }

    function handleCountryCommonNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("countryCommonName", event.target.value);
        setPage(0);
    }

    function updateSort(sortingConfiguration: SortingConfiguration): void {
        setSearchParam("sort", sortingConfiguration.toSearchString());
    }

    function navigateWithNavigatedFrom(pathname: string) {
        navigate({
            pathname: pathname,
            search: `?navigatedFrom=${location.pathname}`
        });
    }

    function navigateRegionRow(id: number): any {
        navigateWithNavigatedFrom(Routes.REGION_DETAILS.replace(":id", id.toString()));
    }

    function navigateToAddRegion(): any {
        navigate(Routes.REGION_NEW);
    }

    function getRowLink(entry: RegionPageDTO): string {
        return guardedObject([
            new PrivilegeGuard(
                [Privileges.MANAGE_REGIONS, Privileges.READ_REGIONS],
                PrivilegeGuardMode.hasAll
            )
        ], Routes.REGION_DETAILS.replace(":id", entry.id.toString())) ?? "";
    }

    return (
        <SideBarPage pageTitle={strings.regions} breadcrumbs={listLinks} component={
            <Grid>
                <TableContainer component={Paper}>
                    <Table sx={{minWidth: 650}} aria-label="custom pagination table">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Input
                                        name="key"
                                        value={key}
                                        onChange={handleKeyChange}
                                        placeholder={strings.keyFilter}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Input
                                        name="label"
                                        value={label}
                                        onChange={handleLabelChange}
                                        placeholder={strings.labelFilter}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Input
                                        name="countryCommonName"
                                        value={countryCommonName}
                                        onChange={handleCountryCommonNameChange}
                                        placeholder={strings.countryCommonNameFilter}
                                    />
                                </TableCell>
                                <TableCell style={{width: "12.5%"}}>
                                    <GuardedComponent component={
                                        <Button onClick={navigateToAddRegion} aria-label="add" variant={"contained"}
                                                className={"float-end"}>
                                            {strings.addRegion}
                                        </Button>
                                    } guards={
                                        [
                                            new PrivilegeGuard(
                                                [Privileges.MANAGE_REGIONS, Privileges.READ_REGIONS],
                                                PrivilegeGuardMode.hasAll
                                            )
                                        ]
                                    } fallback={
                                        <Box/>
                                    } />
                                </TableCell>
                            </TableRow>
                            <TableHeader columns={columns} sortConfigurationString={sortingConfigurationString}
                                         updateSort={updateSort}></TableHeader>
                        </TableHead>
                        <TableBody>
                                {regions?.map((region) => (
                                    <TableRow key={region.id}
                                              component={getRowLink(region) ? Link : TableRow} to={getRowLink(region)}
                                              hover className="cursor-pointer" onClick={guardedCallback([
                                        new PrivilegeGuard(
                                            [Privileges.MANAGE_REGIONS, Privileges.READ_REGIONS],
                                            PrivilegeGuardMode.hasAll
                                        )
                                    ],() => navigateRegionRow(region.id))}>
                                        <TableCell>{region?.key}</TableCell>
                                        <TableCell>{region?.label}</TableCell>
                                        <TableCell>{region?.country?.commonName}</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                    </Table>
                </TableContainer>
                <Grid sx={{display: 'flex', flexDirection: 'column', alignItems: 'end', marginTop: "10px"}}>
                    <PaginationPlus totalElements={totalElements} rowsPerPage={rowsPerPage} page={page} onChangePage={(page) => handleChangePage(null, page)}/>
                    <TablePagination
                        rowsPerPageOptions={defaultRowsPerPageOptions}
                        component="div"
                        count={totalElements}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Grid>
            </Grid>
        }/>
    )
}