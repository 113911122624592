import {Guard} from "./Guard";
import React from "react";
import {StorageService} from "../../storage/StorageService";
import {Navigate} from "react-router-dom";
import {Routes} from "../Routes";
import {CollectionUtils} from "../../utils/CollectionUtils";

export class PrivilegeGuardData {
    privileges: string[];
    mode: PrivilegeGuardMode;

    constructor(privileges: string[], mode: PrivilegeGuardMode) {
        this.privileges = privileges;
        this.mode = mode;
    }
}

export enum PrivilegeGuardMode {
    hasAny,
    hasAll
}

export class PrivilegeGuard extends Guard {
    privileges: string[];
    mode: PrivilegeGuardMode;

    constructor(privileges: string[], mode: PrivilegeGuardMode) {
        super((
            <Navigate replace={true} to={Routes.NOT_FOUND}/>
        ));

        this.privileges = privileges;
        this.mode = mode;
    }

    check(): boolean {
        if (!StorageService.hasAuthToken()) {
            return false;
        }

        const jwtPayload = StorageService.getJWTPayload();

        if (!jwtPayload) {
            return false;
        }

        if (this.mode === PrivilegeGuardMode.hasAny) {
            return CollectionUtils.containsAny(jwtPayload.privileges, this.privileges);
        } else if (this.mode === PrivilegeGuardMode.hasAll) {
            return CollectionUtils.containsAll(jwtPayload.privileges, this.privileges);
        }

        return false;
    }
}
