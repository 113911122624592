import axiosInstance from "../network/AxiosInstance";
import { QueueDTO } from "../models/QueueDTO";
import { Page } from "../pagination/Page";
import { SortingConfiguration } from "../utils/SortingUtils";
import { QueueCreationDTO } from "../models/QueueCreationDTO";

export class QueueService {
    static async getAllQueuesPaged(page: number, size: number, key?: string | null, sortingConfiguration?: SortingConfiguration | undefined): Promise<Page<QueueDTO>> {
        const params = new URLSearchParams({
            page: page.toString(),
            size: size.toString(),
        });

        if (key) {
            params.append("key", key);
        }

        sortingConfiguration?.appendToBackEndSearchParams(params);

        const response = await axiosInstance.get<Page<QueueDTO>>(
            `/rest/bot-session/queue/all-paged`, { params: params },
        );

        const data = response.data;

        return new Page<QueueDTO>(
            data.content.map(queue => new QueueDTO(queue)),
            data.totalElements,
        )
    };

    static async getQueueById(id: number): Promise<QueueDTO> {
        const response = await axiosInstance.get<QueueDTO>(`/rest/bot-session/queue/${id}`);
        const data = response.data;

        return new QueueDTO(data);
    };

    static async deleteQueue(id: number): Promise<void> {
        await axiosInstance.delete<QueueDTO>(`/rest/bot-session/queue/delete/${id}`, { params: { id } });
    };

    static async createQueue(queue: QueueCreationDTO): Promise<QueueDTO> {
        const response = await axiosInstance.post<QueueDTO>(
            `/rest/bot-session/queue/create`, queue,
        );

        const data = response.data;
        return new QueueDTO(data);
    };

    static async editQueue(queue: QueueCreationDTO, id: number | undefined): Promise<QueueDTO> {
        const response = await axiosInstance.post<QueueDTO>(
            `/rest/bot-session/queue/update/${id}`, queue,
        );

        const data = response.data;
        return new QueueDTO(data);
    };
};
