import {Page} from "../pagination/Page";
import {AuthService} from "./AuthService";
import {CookieCollectionDTO} from "../models/CookieCollectionDTO";
import axiosInstance from "../network/AxiosInstance";
import axios from "../network/AxiosInstance";
import {AccountDTO} from "../models/AccountDTO";

export class CookieService {
    static async getAllCollectionsPaged(page: number, size: number, accountId: number | undefined): Promise<Page<CookieCollectionDTO>> {
        const response = await axiosInstance.get<Page<CookieCollectionDTO>>(
            `${AuthService.PREFIX}/bot-session/cookie-collection/all-paged/${accountId}`, {
                params: {
                    page,
                    size,
                }
            },
        );
        const data = response.data;

        return new Page<CookieCollectionDTO>(
            data.content.map((cookies) => new CookieCollectionDTO(cookies)),
            data.totalElements,
        );
    }

    static async importCookieCollections(accountId: number, file: any) {
        let formData = new FormData();
        formData.append("file", file)

        return await axiosInstance.post(`${AuthService.PREFIX}/bot-session/cookie-collection/import/${accountId}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
    }

    static async exportCookieCollection(cookieCollectionId: number | undefined): Promise<CookieCollectionDTO>{
        const response = await axiosInstance.post<CookieCollectionDTO>(
            `${AuthService.PREFIX}/bot-session/cookie-collection/export/${cookieCollectionId}`, {params: {cookieCollectionId}});
        const data = response.data;

        return new CookieCollectionDTO(data);
    }
}