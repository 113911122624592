import {Grid, IconButton, SelectChangeEvent, Tooltip,} from "@mui/material";
import {Box} from "@mui/system";
import React, {useEffect, useState} from "react";
import {SideBarPage} from "../../components/SideBarPage";
import {strings} from "../../localization/Localization";
import {AgeGroupDTO} from "../../models/AgeGroupDTO";
import {RegionDTO} from "../../models/RegionDTO";
import {ServiceDTO} from "../../models/ServiceDTO";
import {SessionInstance} from "../../models/SessionInstance";
import {AgeGroupService} from "../../services/AgeGroupService";
import {RegionService} from "../../services/RegionService";
import {ServicesService} from "../../services/ServicesService";
import {SessionInstanceService} from "../../services/SessionInstanceService";
import {DateTimeUtils} from "../../utils/DateTimeUtils";
import {CircularProgressbar} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorCircleIcon from '@mui/icons-material/Error';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {Dayjs} from "dayjs";
import {NavigateFunction, useNavigate, useSearchParams} from "react-router-dom";
import {SortingConfiguration} from "../../utils/SortingUtils";
import {TableHeader} from "../../components/TableHeader";
import {NumberParser} from "../../utils/NumberParser";
import {BooleanParser} from "../../utils/BooleanParser";
import {Routes} from "../../router/Routes";
import {PageTable, Row} from "../../components/PageTable";
import {SessionInstanceController} from "../../controllers/SessionInstanceController";
import {EnumType} from "../../utils/EnumType";
import "../sessionInstances/SessionInstances.css"
import {SessionInstanceConditionType} from "../../models/SessionInstanceConditionType";
import ConfirmationModal from "../../components/ConfirmationModal";
import {BotsService} from "../../services/BotsService";
import {showErrorDialog, showSuccessDialog} from "../../common/Dialogs";
import StopIcon from '@mui/icons-material/Stop';
import {ErrorHandler} from "../../utils/ErrorHandler";
import {BotPlatformDTO} from "../../models/BotPlatformDTO";
import {BotPlatformService} from "../../services/BotPlatformService";
import {BotPlatformIcon} from "../../components/BotPlatformIcon";

export function SessionInstances() {
    const columns = [
        { label: strings.sessionId, sortBy: "session.id", sortable: true },
        { label: strings.name, sortBy: "session.name", sortable: true },
        { label: strings.bot, sortBy: "bot.name", sortable: true },
        { label: strings.service, sortBy: "account.service.label", sortable: true },
        { label: strings.botPlatform, sortBy: "botPlatform.label", sortable: true },
        { label: strings.accountGroup, sortBy: "account.accountGroup.name", sortable: true },
        { label: strings.email, sortBy: "account.email", sortable: true },
        { label: strings.client, sortBy: "client.name", sortable: true },
        { label: strings.region, sortBy: "account.region.label", sortable: true },
        { label: strings.ageGroup, sortBy: "account.ageGroup.label", sortable: true },
        { label: strings.labelProxyName, sortBy: "proxy.name", sortable: true },
        { label: strings.dateCreated, sortBy: "dateCreated", sortable: true },
        { label: strings.initialized, sortBy: "initialized", sortable: false },
        { label: strings.completed, sortBy: "completed", sortable: false },
        { label: strings.action, sortBy: "stopSessions", sortable: false },

      ]
      const listLinks = [
        { label: strings.dashboard, currentlyOpened: false, href:Routes.HOME},
        { label: strings.sessionInstances,  currentlyOpened: true },
      ]
    const [searchParams, setSearchParams] = useSearchParams();
    const [sessionInstances, setSessionInstances] = useState<SessionInstance[]>([]);
    const sessionName = searchParams.get("sessionName") ?? "";
    const sessionServiceId = searchParams.get("sessionServiceId") ?? "";
    const botPlatformId = NumberParser.parseNumberNullable(searchParams.get("botPlatformId"))
    const usesProxy = BooleanParser.parseBooleanNullable(searchParams.get("usesProxy"))
    const initialized = BooleanParser.parseBooleanNullable(searchParams.get("initialized"))
    const sessionId = searchParams.get("sessionId") ?? "";
    const sessionKey = searchParams.get("sessionKey") ?? "";
    const accountGroupName = searchParams.get("accountGroupName") ?? "";
    const accountEmail = searchParams.get("accountEmail") ?? "";
    const client = searchParams.get("client") ?? "";
    const accountRegionId = searchParams.get("accountRegionId") ?? "";
    const accountAgeGroupId = searchParams.get("accountAgeGroupId") ?? "";
    const proxyName = searchParams.get("proxyName") ?? "";
    const botName = searchParams.get("botName") ?? "";
    const dateCreated = searchParams.get("dateCreated") ?? null;
    const sessionInstanceConditionType = searchParams.get("sessionInstanceConditionType") ?? "";
    const rowsPerPage = NumberParser.parseNumber(searchParams.get("rowsPerPage"), 25);
    const page = NumberParser.parseNumber(searchParams.get("page"), 0);
    const [totalElements, setTotalElements] = useState(0);
    const [services, setServices] = useState<ServiceDTO[]>([]);
    const [botPlatforms, setbotPlatforms] = useState<BotPlatformDTO[]>([]);
    const [regions, setRegions] = useState<RegionDTO[]>([]);
    const [ageGroups, setAgeGroups] = useState<AgeGroupDTO[]>([]);
    const [sessionInstanceConditionTypes] = useState<SessionInstanceConditionType[]>(SessionInstanceConditionType.sessionInstanceConditionTypes);
    const sortingConfigurationString = searchParams.get("sort");
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [numberActiveSessions, setNumberActiveSessions] = useState<Number>(0);

    const [sessionInstanceToStop, setSessionInstanceToStop] = useState<SessionInstance | undefined>(undefined);
    const [showSessionInstanceStopDialog, setShowSessionInstanceStopDialog] = useState<boolean>(false);

    function setSearchParam(key: string, value: string | null | undefined) {
      if (value) {
        searchParams.set(key, value);
      } else {
        searchParams.delete(key);
      }
  
      setSearchParams(searchParams)
    }
    function setPage(page: number) {
        setSearchParam("page", page.toString());
      }
    const navigate = useNavigate();
    function getActiveSessions() {
        SessionInstanceService.getNumberOfActiveSessionInstances().then(x => setNumberActiveSessions(x))
    }
    
    function updateSort(sortingConfiguration: SortingConfiguration): void {
        setSearchParam("sort", sortingConfiguration.toSearchString());
      }

    function handleChangePage(
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("rowsPerPage", event.target.value);
        setPage(0);
    }

    function handleChangeSessionId(event: React.ChangeEvent<HTMLInputElement>) {
       setSearchParam("sessionId", event.target.value);
       setPage(0)
    }

    function handleChangeSessionName(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("sessionName", event.target.value);
        setPage(0)
    }

    function handleChangeServiceId(event: SelectChangeEvent) {
        setSearchParam("sessionServiceId", event.target.value)
        setPage(0)
    }

    function handleChangeBotPlatformId(event: SelectChangeEvent) {
        setSearchParam("botPlatformId", event.target.value)
        setPage(0)
    }

    function handleChangeRegionId(event: SelectChangeEvent) {
        setSearchParam("accountRegionId", event.target.value);
        setPage(0);
    }

    function handleChangeAgeGroup(event: SelectChangeEvent) {
        setSearchParam("accountAgeGroupId", event.target.value)
        setPage(0);
    }

    function handleSessionInstanceConditionType(event: SelectChangeEvent) {
        setSearchParam("sessionInstanceConditionType", event.target.value)
        setPage(0);
    }

    function handleSessionInstanceInitialized(event: SelectChangeEvent) {
        setSearchParam("initialized", event.target.value)
        setPage(0);
    }

    function handleChangeAccountGroup(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        setSearchParam("accountGroupName", event.target.value)
        setPage(0);
    }

    function handleChangeEmail(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("accountEmail", event.target.value)
        setPage(0);
    }
    function handleChangeClient(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("client", event.target.value)
        setPage(0);
    }

    function handleChangeBot(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("botName", event.target.value)
        setPage(0);
   }

    function handleChangeProxyName(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("proxyName", event.target.value)
        setPage(0);
    }

    function handleChangeDateCreated(date: Dayjs | null) {
        setSearchParam("dateCreated", DateTimeUtils.formatDateDayJs(date));
        setPage(0);
    }
    function handleClickOpen(): void {
        setOpenModal(true);
    }

    function setUsesProxy(value: boolean | undefined): void {
        setSearchParam("usesProxy", value?.toString() ?? "");
        setPage(0);
      }
    function stopSessions(){
        setOpenModal(false);

        BotsService.stopAllBots().then(() => {
            showSuccessDialog(strings.success, strings.sessionsStoppedSuccessfully, strings.ok).then(_ => {});
            navigate(Routes.SESSION_INSTANCES);
        }).catch((e) => {
            const message = ErrorHandler.parseErrorMessage(e);
            showErrorDialog(strings.error, message, strings.ok).then(_ => {});
        });
    }

    function handleClose(): any {
        setOpenModal(false);
    }

    function handleCloseSessionInstanceDialog(): void {
        setShowSessionInstanceStopDialog(false);
    }

    async function stopSessionInstance(): Promise<void> {
        setShowSessionInstanceStopDialog(false);

        if (!sessionInstanceToStop?.bot?.id) {
            return;
        }

        try {
            await BotsService.stopBot(sessionInstanceToStop.bot.id);

            showSuccessDialog(strings.success, strings.sessionInstanceStoppedSuccessfully, strings.ok).then(_ => {});
        } catch (e) {
            const message = ErrorHandler.parseErrorMessage(e);
            showErrorDialog(strings.error, message, strings.ok).then(_ => {});
        }
    }

    function returnPercentage(item: SessionInstance) {
        let percentage: number = 0.0;
        if (item.finishedActions < item.totalActions) {
            percentage = parseFloat(((item.finishedActions / item.totalActions) * 100).toFixed(2));
        }
        return percentage;
    }

    function onStopSessionInstance(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, sessionInstance: SessionInstance): void {
        event.stopPropagation();
        setShowSessionInstanceStopDialog(true);
        setSessionInstanceToStop(sessionInstance);
    }

    useEffect(() => {
        async function getAllServices(): Promise<void> {
            const services = await ServicesService.getAllServices();
            setServices(services);
        }

        async function getAllBotPlatforms(): Promise<void> {
            const botPlatforms = await BotPlatformService.getAllBotPlatforms();
            setbotPlatforms(botPlatforms);
        }

        async function getAllRegions(): Promise<void> {
            const regions = await RegionService.getRegions();
            setRegions(regions);
        }

        async function getAllAgeGroups(): Promise<void> {
            const ageGroups = await AgeGroupService.getAllAgeGroups();
            setAgeGroups(ageGroups);
        }

        async function loadData(): Promise<void> {
            await getAllRegions();
            await getAllServices();
            await getAllBotPlatforms();
            await getAllAgeGroups();
            await getActiveSessions();
        }


        loadData().then((_) => {
        });
    }, []);

    useEffect(() => {
        async function getAllSessionInstancesPaged(page: number): Promise<void> {
            const response = await SessionInstanceService.getAllSessionInstancesPaged(
                page,
                rowsPerPage,
                sessionId,
                accountGroupName,
                accountEmail,
                accountRegionId,
                accountAgeGroupId,
                proxyName,
                sessionKey,
                sessionServiceId,
                sessionName,
                usesProxy,
                dateCreated,
                SortingConfiguration.fromSearchString(sortingConfigurationString),
                undefined, 
                botName,
                client,
                sessionInstanceConditionType,
                botPlatformId
            );
            setTotalElements(response.totalElements);
            setSessionInstances(response.content);
            // setPagination({...pagination, count: response.totalElements})
        }

        getAllSessionInstancesPaged(page).then((_) => {
        });
    }, [
        accountEmail,
        proxyName,
        accountGroupName,
        sessionServiceId,
        sessionName,
        accountRegionId,
        usesProxy,
        sessionKey,
        dateCreated,
        accountAgeGroupId,
        sessionId,
        page,
        rowsPerPage,
        botName,
        client,
        sessionInstanceConditionType,
        sortingConfigurationString,
        botPlatformId,
        // pagination.from, pagination.to
    ]);

      const searchRows: Row[] = [
        {
          id: "id",
          label: strings.sessionId,
          search: true,
          type: EnumType.string,
          handleChange:handleChangeSessionId,
          variant:"standard",
          value:sessionId,
        },
       
        {
          id: "name",
          label: strings.sessionName,
          search: true,
          type: EnumType.string,
          handleChange: handleChangeSessionName,
          variant:"standard",
          value:sessionName
        },
        {
          id: "bot",
          label: strings.bot,
          search: true,
          type: EnumType.string,
          handleChange:handleChangeBot,
          variant:"standard",
          value:botName,
        },
        {
          id: "service",
          label: strings.service,
          search: true,
          type: EnumType.dropdown,
          value:sessionServiceId,
          handleRemoveFilter:()=>{setSearchParam("sessionServiceId", undefined); setPage(0)},
          elements:services,
          handleChange: handleChangeServiceId,
          variant: "standard",
        },
          {
              id: "botPlatform",
              label: strings.botPlatform,
              search: true,
              type: EnumType.dropdown,
              value:botPlatformId,
              handleRemoveFilter:()=>{setSearchParam("botPlatformId", undefined); setPage(0)},
              elements:botPlatforms,
              handleChange: handleChangeBotPlatformId,
              variant: "standard",
          },
        {
          id: "accountGroup",
          label: strings.accountGroup,
          search: true,
          type: EnumType.string,
          variant:"standard",
          handleChange: handleChangeAccountGroup,
          value:accountGroupName
        },
        {
          id: "email",
          label: strings.email,
          search: true,
          type: EnumType.string,
          handleChange: handleChangeEmail,
          variant: "standard",
          value:accountEmail
        },
          {
              id: "client",
              label: strings.client,
              search: true,
              type: EnumType.string,
              handleChange: handleChangeClient,
              variant: "standard",
              value:client
          },
        {
          id: "region",
          label: strings.region,
          search: true,
          type: EnumType.dropdown,
          elements:regions,
          value:accountRegionId,
          handleChange: handleChangeRegionId,
          handleRemoveFilter:()=>{setSearchParam("accountRegionId", undefined);setPage(0)},
          variant: "standard",
        },
        {
          id: "ageGroup",
          label: strings.ageGroup,
          search: true,
          type: EnumType.dropdown,
          elements:ageGroups,
          value:accountAgeGroupId,
          handleRemoveFilter:()=>{setSearchParam("accountAgeGroupId", undefined);setPage(0)},
          handleChange: handleChangeAgeGroup,
          variant: "standard",
         
        },
        {
          id: "proxyName",
          label: strings.labelProxyName,
          search: true,
          type: EnumType.string,
          handleChange:handleChangeProxyName,
          variant: "standard",
          value:proxyName
        },
        {
          id: "dateCreated",
          label: strings.dateCreated,
          search: true,
          value:dateCreated,
          type: EnumType.date,
          handleChange:handleChangeDateCreated,
          
        },
        {
            id: "initialized",
            label: strings.initialized,
            search: false,
            type: EnumType.empty,
            // elements:initialized,
            value:initialized,
            handleRemoveFilter:()=>{setSearchParam("initialized", undefined);setPage(0)},
            handleChange: handleSessionInstanceInitialized,
            variant: "standard"
        },
        {
          id: "sessionInstanceConditionType",
          label: strings.sessionInstanceConditionType,
          search: true,
          type: EnumType.dropdown,
          elements:sessionInstanceConditionTypes,
          value:sessionInstanceConditionType,
          handleRemoveFilter:()=>{setSearchParam("sessionInstanceConditionType", undefined);setPage(0)},
          handleChange: handleSessionInstanceConditionType,
          variant: "standard"
        },
    ];

      if (numberActiveSessions > 0) {
          searchRows.push({
              id: "stopSessions",
              label: strings.stopSessions,
              search: false,
              type: EnumType.button,
              value: undefined,
              handleChange: (_) => {},
              handleClick: (_) => handleClickOpen(),
          });
      } else {
          searchRows.push({
              id: "stopSessions",
              label: strings.stopSessions,
              search: false,
              value: undefined,
              type: EnumType.empty,
              handleChange:(_) => {},
          });
      }

    const upperHeader: Row[] = [
        {
            id: "useProxy",
            label: strings.useProxy,
            search: true,
            type: EnumType.boolean,
            value:usesProxy,
            handleChange:setUsesProxy,
        }
    ];

      const rows = sessionInstances.map((element) => {
        return {
          id: element.id,
          values: [
            {
              type: EnumType.string,
              name: "scenarioId",
              value: element.session?.id,
            },
            {
              type: EnumType.string,
              name: "scenarioName",
              value: element.session?.name,
            },
            {
              type: EnumType.string,
              name: "botName",
              value: element.bot ? element.bot?.name : "/"
            },
            {
              type: EnumType.string,
              name: "service",
              value: element.account?.service?.label,
            },
              {
                  type: EnumType.string,
                  name: "botPlatform",
                  value: element.botPlatform && (<>
                      {element.botPlatform?.label}
                          <BotPlatformIcon botPlatform={element.botPlatform}/>
                      </>)
              },
            {
              type: EnumType.string,
              name: "accountGroup",
              value: element.account?.accountGroup?.name,
            },
            {
              type: EnumType.string,
              name: "email",
              value: element.account?.email,
            },
            {
               type: EnumType.string,
               name: "client",
               value: element.session?.client?.name,
            },
            {
              type: EnumType.string,
              name: "region",
              value: element.account?.region?.label,
            },
            {
              type: EnumType.string,
              name: "ageGroup",
              value: element.account?.ageGroup?.label,
            },
            {
              type: EnumType.string,
              name: "proxyName",
              value: element.usedProxy?.name,
            },
            {
              type: EnumType.string,
              name: "dateCreated",
              value: DateTimeUtils.formatDate(element?.dateCreated?.toString()),
            },
            {
                type: EnumType.string,
                name: "initialized",
                value: element?.initialized ? <CheckCircleIcon
                    color="success"
                    sx={{fontSize: "65px", width: "65px"}}
                /> : element?.initialized === false ? <ErrorCircleIcon
              color="error"
              sx={{fontSize: "65px", width: "65px"}}
          /> : <WarningAmberIcon
              color="warning"
              sx={{fontSize: "65px", width: "65px"}}
          />
            },
            {
              type: EnumType.string,
                name: "completed",
                value: element.sessionInstanceConditionType === SessionInstanceConditionType.finishedType && element.successfullyExecuted ? (
                    <div>
                        <Tooltip title={strings.conditionFinishedSuccessfully}>
                            <CheckCircleIcon
                                color="success"
                                sx={{fontSize: "65px", width: "65px"}}
                            />
                        </Tooltip>
                    </div>
                ) : element.sessionInstanceConditionType === SessionInstanceConditionType.finishedType && !element.successfullyExecuted ? (
                    <div>
                        <Tooltip title={strings.conditionFinishedUnsuccessfully}>
                            <ErrorCircleIcon
                                color="error"
                                sx={{fontSize: "65px", width: "65px"}}
                            />
                        </Tooltip>
                    </div>
                ) : element.sessionInstanceConditionType === SessionInstanceConditionType.stalledType ? (
                    <div>
                        <Tooltip title={strings.conditionStalled}>
                            <WarningAmberIcon
                                color="warning"
                                sx={{fontSize: "65px", width: "65px"}}
                            />
                        </Tooltip>
                    </div>
                ) : element.sessionInstanceConditionType === SessionInstanceConditionType.activeType ? (
                    <Tooltip title={strings.conditionActive}>
                        <div style={{maxWidth: "65px"}}>
                            <CircularProgressbar
                                value={returnPercentage(element)}
                                text={`${returnPercentage(element)}%`}
                                strokeWidth={7}
                            />
                        </div>
                    </Tooltip>
                ) : (<div></div>)
              },
              {
                  type: EnumType.button,
                  name: "stopSessionInstance",
                  value: (
                      element.sessionInstanceConditionType === SessionInstanceConditionType.activeType && element.bot?.id !== undefined ?
                          (
                              <Tooltip title={strings.stopSessionInstance}>
                                  <IconButton
                                      color="error"
                                      size="large"
                                      onClick={(event) => onStopSessionInstance(event, element)}
                                  >
                                      <StopIcon/>
                                  </IconButton>
                              </Tooltip>
                          ) : <Box/>
                  )
              },
          ],
        };
      });

    function navigateToSessionInstanceDetails(navigate: NavigateFunction, sessionInstanceId: number) {
        return SessionInstanceController.navigateToSessionInstanceDetails(navigate, sessionInstanceId)
    }

    return (
        <>
            <ConfirmationModal
                open={openModal}
                handleClose={handleClose}
                handleConfirm={stopSessions}
                title={strings.stopSessions}
                content={strings.stopSessionsConfirmation}
                actionButtonName={strings.stopSessions}/>
            <ConfirmationModal
                open={showSessionInstanceStopDialog}
                handleClose={handleCloseSessionInstanceDialog}
                handleConfirm={stopSessionInstance}
                title={strings.stopSessionInstance}
                content={strings.stopSessionInstanceConfirmation}
                actionButtonName={strings.stopSessionInstance}/>
            <SideBarPage
                pageTitle={strings.sessionInstances}
                breadcrumbs={listLinks}
                component={
                    <Box>
                        <Grid sx={{display: 'flex', flexDirection: 'column', alignItems: 'end', marginTop: "10px"}}>
                        <PageTable
                            searchRows={searchRows}
                            upperHeader={upperHeader}
                            headerRows={<TableHeader columns={columns}
                                                     sortConfigurationString={sortingConfigurationString}
                                                     updateSort={updateSort}/>}
                            rows={rows}
                            page={totalElements <= 0 ? 0 : page}
                            rowsPerPage={rowsPerPage}
                            totalElements={totalElements}
                            handleChangePage={handleChangePage}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            handleOpen={navigateToSessionInstanceDetails}
                            linkBuilder={(row: any) => Routes.SESSION_INSTANCE_DETAILS.replace(":id", row.id.toString())}
                            clickable={true}></PageTable>
                        </Grid>
                    </Box>
                }
            />
        </>
    );
}
