import {Cancel, StopCircle} from "@mui/icons-material";
import {
  Button, Grid, Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React from "react";
import "react-circular-progressbar/dist/styles.css";

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { TableFilter } from "./TableFilter";
import { NavigateFunction, useNavigate } from "react-router";
import { EnumType } from "../utils/EnumType";
import {Link} from "react-router-dom";

type Element = {
  id:number | string;
  label:string;
}
export type Row= {
  id:  string | number;
  label: string;
  search: boolean;
  type:EnumType;
  handleChange: (event: any) => void;
  variant?:string;
  value?:string | number | null | boolean | undefined;
  handleRemoveFilter?:() => void;
  elements?:Element[];
  handleClick?: (event: any) => void;

}
interface Props<T>  {
  rows: T[];
  handleOpen?: (navigate: NavigateFunction,id:number) => void;
  upperHeader?:Row[];
  searchRows: Row[];
  headerRows:JSX.Element|JSX.Element[];
  page:number;
  totalElements:number;
  rowsPerPage:number;
  clickable?:boolean;
  handleChangePage:(event: React.MouseEvent<HTMLButtonElement> | null,newPage: number) => void;
  handleChangeRowsPerPage:(event: React.ChangeEvent<HTMLInputElement>) => void;
  linkBuilder?: (row: any) => string;
}

export function PageTable<T>(props: Props<T>) {
  const navigate = useNavigate();
  function handleOpen(navigate:NavigateFunction,id: number) {
    if(props.handleOpen) {
    props.handleOpen(navigate,id);
    }
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table stickyHeader aria-labelledby="tableTitle">
          <TableHead>
            <TableRow>
            {
              props.upperHeader?.map((row)=>{
                if (row.type === EnumType.empty) {
                  return (<TableCell sx={{border: 'none'}}></TableCell>);
                } else if (row.type !== EnumType.button) {
                  return (<TableCell
                          className="p-4 md:p-16 upperHeader"
                          key={row.id}
                      >
                        <TableFilter search={row.search} row={row} type={row.type} label={row.label} value={row.value}
                                     id={row.id} elements={row.elements} handleChange={row.handleChange}
                                     handleRemoveFilter={row.handleRemoveFilter ? row.handleRemoveFilter : undefined}/>
                      </TableCell>
                  );
                } else {
                  return (
                      <TableCell sx={{
                        border: 'none',
                      }}>
                        <Button onClick={row.handleClick} startIcon={<StopCircle/>} size={"small"}
                                style={{fontSize: "11px"}} className={"float-end"} variant="contained" color={"error"}>
                          {row.label}
                        </Button>
                      </TableCell>
                  );
                }
              })
            }
            </TableRow>
          </TableHead>
          <TableHead>
            <TableRow>
              {props.searchRows?.map((row) => {
                if (row.type === EnumType.empty) {
                  return (<TableCell key={row.id}></TableCell>);
                } else if (row.type !== EnumType.button) {
                  return (
                      <TableCell
                          className="p-4 md:p-16"
                          key={row.id}
                      >
                        <TableFilter search={row.search} row={row} type={row.type} label={row.label} value={row.value}
                                     id={row.id} elements={row.elements} handleChange={row.handleChange}
                                     handleRemoveFilter={row.handleRemoveFilter ? row.handleRemoveFilter : undefined}/>
                      </TableCell>
                  );
                } else {
                  return <TableCell key={row.id}>
                    <Button onClick={row.handleClick} startIcon={<StopCircle/>} size={"small"}
                            style={{fontSize: "11px"}} className={"float-end"} variant="contained" color={"error"}>
                      {row.label}
                    </Button>
                  </TableCell>;
                }
              })}
            </TableRow>
            {props.headerRows}
            <TableRow className="h-48 sm:h-64">
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rows.map((row: any, index: number) => {
              return (
                <TableRow
                  className="h-72 cursor-pointer"
                  hover
                  tabIndex={-1}
                  key={index}
                  component={props.linkBuilder ? Link : TableRow}
                  to={props.linkBuilder ? props.linkBuilder(row) : ""}
                  onClick={() => (props.clickable ? handleOpen(navigate,row.id) : null)}
                >
                  {row.values?.map((value: any) => {
                    return (
                      <React.Fragment key={value.name}>
                        {value.type === EnumType.string && (
                          <TableCell className="p-4 sm:p-16"
                            component="th"
                            scope="row"
                          >
                            {value.value}
                          </TableCell>
                        )}
                        {value.type === EnumType.boolean && (
                          <TableCell
                            className="p-4 sm:p-16"
                            component="th"
                            scope="row"
                            align="left"
                          >
                            {value.value ? (
                              <CheckCircleIcon />
                            ) : (
                              <Cancel />
                            )}
                          </TableCell>
                        )}
                        {
                            value.type === EnumType.button && (
                                <TableCell
                                    className="p-4 sm:p-16"
                                    component="th"
                                    scope="row"
                                    align="left"
                                >
                                  {value.value}
                                </TableCell>
                            )
                        }
                      </React.Fragment>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>

        </Table>

      </TableContainer>
      <Grid sx={{display: 'flex', flexDirection: 'column', alignItems: 'end', marginTop: "10px"}}>
        <Pagination  count={Math.ceil(props.totalElements / props.rowsPerPage)} showFirstButton showLastButton page={props.page + 1}
                    onChange={(e, page) => props.handleChangePage(null, page - 1)}/>
        <br/>
        <TablePagination
            component="div"
            rowsPerPageOptions={[5, 10, 25]}
            count={props.totalElements}
            rowsPerPage={props.rowsPerPage}
            page={props.page}
            onPageChange={props.handleChangePage}
            onRowsPerPageChange={props.handleChangeRowsPerPage}
        />
      </Grid>


    </>
  );
}
