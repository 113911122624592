import {AccountReportResponseDTO} from "./AccountReportResponseDTO";

export class SessionInstanceDailyReportDTO {
    executedSessionInstances: number
    accounts: AccountReportResponseDTO[]

    constructor(json: any) {
        this.executedSessionInstances = json.executedSessionInstances;
        this.accounts = json.accounts?.map((account: any) => new AccountReportResponseDTO(account)) ?? [];
    }
}