import { ActionType } from "./ActionType";

export class SessionActionDTO {
    id?:number;
    actionTypeId?: number | string;
    sourceKey?: string | undefined;
    minDurationInSeconds?: number;
    maxDurationInSeconds?: number;
    actionType?:string;
    actionTypeData: ActionType;
    addToFavorites?: boolean;
    followArtist? : boolean;
    playlistGenerationGenres?: string;
  
    constructor(json: SessionActionDTO) {
      this.id = json.id;
      this.actionTypeId = json.actionTypeId;
      this.sourceKey = json.sourceKey;
      this.minDurationInSeconds = json.minDurationInSeconds;
      this.maxDurationInSeconds = json.maxDurationInSeconds;
      this.actionType = json.actionType;
      this.actionTypeData =  new ActionType(json.actionTypeData);
      this.addToFavorites = json.addToFavorites;
      this.followArtist = json.followArtist;
      this.playlistGenerationGenres = json.playlistGenerationGenres;
    }
  }
