import React, {ReactElement} from "react";

class PageProps {
    constructor(
        public title: string,
        public component: ReactElement,
    ) {}
}

export function Page(props: PageProps) {
    React.useEffect(() => {
        document.title = props.title;
    }, [props.title]);

    return props.component;
}
