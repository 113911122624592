import axiosInstance from "../network/AxiosInstance";
import {AgeGroupDTO} from "../models/AgeGroupDTO";
import {AuthService} from "./AuthService";

export class AgeGroupService {
    static async getAllAgeGroups(): Promise<Array<AgeGroupDTO>> {
        const response = await axiosInstance.get<Array<AgeGroupDTO>>(`${AuthService.PREFIX}/bot-session/age-group/all`);
        const data =  response.data;
        return data.map(ageGroup => new AgeGroupDTO(ageGroup))
    }
}
