import React, { useEffect, useState } from "react";
import { Dialog,
   DialogTitle, 
   DialogContent, 
   DialogActions, 
   FormControl, 
   IconButton, 
   InputLabel, 
   MenuItem, 
   Select, 
   TextField, 
   Button} from "@mui/material";
import { Clear } from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";
import { strings } from "../../localization/Localization";
import { ProxyCreationDTO } from "../../models/ProxyCreationDTO";
import { RegionDTO } from "../../models/RegionDTO";
import { RegionService } from "../../services/RegionService";
import { Input } from "@mui/material";
import { AttachFile } from "@mui/icons-material";
import { ProxyService } from "../../services/ProxyService";
import { showSuccessDialog} from "../../common/Dialogs";

interface ProxyImportModalProps {
  isOpen: boolean;
  onClose: () => void;
  getProxies: (page: number) => Promise<void>;
}


export function ProxyImportModal({ isOpen, onClose, getProxies }: ProxyImportModalProps) {
  const [regions, setRegions] = useState<RegionDTO[] | undefined>([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  useEffect(() => {
    async function getAllRegions() {
      const regions = await RegionService.getRegions();
      setRegions(regions);
    }
    getAllRegions();
  }, []);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setSelectedFile(event.target.files[0]);
    } else {
      event.target.value = "";
      setSelectedFile(null);
    }
  };

  const resetForm = () => {
    setSelectedFile(null);
    reset({
      name: "",
      regionId: "",
       });
  };

  const onSubmit = async (data: ProxyCreationDTO) => {
    try {
      if (selectedFile) {
        const regionId = data.regionId ? parseInt(data.regionId.toString()) : 0;
        await ProxyService.importProxies(data.name || "", regionId, selectedFile);
        onClose();
        showSuccessDialog(strings.success, strings.proxiesImportedSuccessfully, strings.ok)
        getProxies(0);
        resetForm();
      }
    } catch (error) {     
    }
  };
  
  const {
    handleSubmit,
    control,
    getValues,
    reset,
    formState: { errors },
  } = useForm<ProxyCreationDTO>({   
      defaultValues: {
        name: "",
        regionId: "",    
    },
    mode: "onChange",
});

  return (
    <Dialog open={isOpen} onClose={() => { onClose(); resetForm(); }} fullWidth aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle>{strings.importProxies}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="name"
            rules={{ required: true }}
            control={control}
            render={({ field }) => (
              <TextField
                InputLabelProps={{
                  shrink: !!getValues("name"),
                }}
                {...field}
                value={field.value}
                id="name"
                label={strings.namePrefix}
                className="w-100"
                style={{ marginBottom: 15 }}
                error={errors.name !== undefined}
              />
            )}
          />
          <FormControl className="w-100">
            <InputLabel id="regionLabel">{strings.region}</InputLabel>
            <Controller
              name="regionId"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  labelId="regionLabel"
                  id="regionLabel"
                  onChange={onChange}
                  style={{ marginBottom: 5 }}
                  label={strings.region}
                  value={value}
                  inputProps={{ shrink: getValues("regionId") }}
                  endAdornment={
                    !!getValues("regionId") && (
                      <IconButton onClick={() => onChange("")}>
                        <Clear />
                      </IconButton>
                    )
                  }
                >
                  {regions?.map((r) => (
                    <MenuItem key={r.id} value={r.id.toString()}>
                      {r.label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
          <Input
            key={selectedFile ? selectedFile.name : 'default'}
            type="file"
            name="file"
            id="file"
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
          <label htmlFor="file">
            <Button
              component="span"
              startIcon={<AttachFile />}
              className="mt-3"
              variant="outlined"
            >
              {strings.chooseFile}
            </Button>
          </label>
          {selectedFile && (
               <div style={{ display: 'flex', alignItems: 'center' }}>
                  <p style={{ margin: '0', flex: '1' }}>{selectedFile.name}</p>
                 <div style={{ marginLeft: '10px' }}>
                    <IconButton onClick={() => setSelectedFile(null)}>
                      <Clear />
                    </IconButton>
                 </div>
               </div>
          )}
          <DialogActions>
            <Button
              fullWidth
              className="mt-3"
              variant={"contained"}
              color={"warning"}
              onClick={() => { onClose(); resetForm(); }}>
              {strings.cancel}
            </Button>
            <Button onClick={handleSubmit(onSubmit)}  type="submit" color="primary" variant="contained" fullWidth
              className="mt-3">
              {strings.import}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}
