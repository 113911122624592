import {UserService} from "../services/UserService";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import React, {useState, useEffect} from 'react';
import {
    Button, Card, CardContent, Grid
} from "@mui/material";
import {strings} from "../localization/Localization";
import {Link, useLocation} from "react-router-dom";


export function ConfirmationPage() {
    const [tokenError, setTokenError] = useState<boolean>(true);
    const {search} = useLocation();
    const token = new URLSearchParams(search).get('token');
    useEffect(() => {
        async function confirmRegistration(){
            if (token !== null) {
                await UserService.confirmRegistration(token).then(response => {
                    setTokenError(false);
                }).catch((error) => {
                    if (error) {
                        setTokenError(true);
                    }
                });
            }
        }
        confirmRegistration().then(_ => {});
    }, [])


    return (
        <>
            <div>
                <br></br>
                <br></br>
                <Grid direction={"column"} container justifyContent={"center"} alignItems={"center"}>
                    <Card sx={{
                        minWidth: "40%",
                        maxWidth: "40%",
                        textAlign: "center",
                    }} className="mt-5 mb-5">
                        {
                            !tokenError ?
                                <CardContent className={'container-fluid'}>
                                    <CheckCircleIcon color={"success"} sx={{fontSize: "110px"}}/>
                                    <h2 className="status-text font-semibold">{strings.emailConfirmation}</h2>
                                    <p>{strings.confirmationText}</p>

                                    <div className="row">
                                        <div className="col-3"/>
                                        <div>
                                            <Button
                                                sx={{mb: '5rem', mt: '1.875rem', width: '30%'}}
                                                component={Link}
                                                to="/login"
                                                role="button"
                                                variant="contained"
                                            >
                                                {strings.login}
                                            </Button>
                                        </div>
                                        <div className="col-3"/>
                                    </div>
                                </CardContent>

                                :
                                <CardContent className={'container-fluid'}>
                                    <ErrorIcon color={"error"} sx={{fontSize: "110px"}}/>
                                    <h2 className="status-text font-semibold mt-3">{strings.error}</h2>
                                    <p>{strings.confirmationError}</p>

                                </CardContent>
                        }
                    </Card>
                </Grid>
            </div>
        </>
    )
}
