import {SessionInstanceReducedDTO} from "./SessionInstanceReducedDTO";

export class AccountDetailedStatusDTO {
    lastSessionInstance: SessionInstanceReducedDTO|undefined;
    lastFailedSessionInstance:SessionInstanceReducedDTO|undefined;
    lastSessionInstanceWithoutProfile: SessionInstanceReducedDTO|undefined;
    lastSessionInstanceWithProfile: SessionInstanceReducedDTO|undefined;
    lastSuccessfulSessionInstance: SessionInstanceReducedDTO|undefined;
    constructor(json: AccountDetailedStatusDTO) {
        this.lastSessionInstance=json.lastSessionInstance ? new SessionInstanceReducedDTO(json.lastSessionInstance) : undefined;
        this.lastFailedSessionInstance=json.lastFailedSessionInstance ? new SessionInstanceReducedDTO(json.lastFailedSessionInstance) : undefined;
        this.lastSessionInstanceWithoutProfile=json.lastSessionInstanceWithoutProfile ? new SessionInstanceReducedDTO(json.lastSessionInstanceWithoutProfile) : undefined;
        this.lastSessionInstanceWithProfile=json.lastSessionInstanceWithProfile ? new SessionInstanceReducedDTO(json.lastSessionInstanceWithProfile) : undefined;
        this.lastSuccessfulSessionInstance=json.lastSuccessfulSessionInstance ? new SessionInstanceReducedDTO(json.lastSuccessfulSessionInstance) : undefined;
    }
}