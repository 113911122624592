import {CountryDTO} from "./CountryDTO";

export class RegionPageDTO {
    id: number
    dateCreated: string
    dateModified: string
    key: string
    label: string
    country: CountryDTO | undefined

    constructor(json: any) {
        this.id = json.id;
        this.dateCreated = json.dateCreated;
        this.dateModified = json.dateModified;
        this.key = json.key;
        this.label = json.label;
        this.country = json.country ? new CountryDTO(json.country) : undefined;
    }
}