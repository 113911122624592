import axiosInstance from "../network/AxiosInstance";
import { AuthService } from "./AuthService";
import { GenreDTO } from "../models/GenreDTO";
import { GenreCreationDTO } from "../models/GenreCreationDTO";
import { SortingConfiguration } from "../utils/SortingUtils";
import { Page } from "../pagination/Page";

export class GenresService {

static async getGenre(id: number): Promise<GenreDTO> {
        const response = await axiosInstance.get<GenreDTO>(`${AuthService.PREFIX}/playlists/genre/${id}`);
        return response.data;
    };

    static async createGenre(genre: GenreCreationDTO): Promise<GenreDTO> {
        const response = await axiosInstance.post<GenreDTO>(
            `/rest/playlists/genre/create`, genre,
        );

        const data = response.data;
        return new GenreDTO(data);
    }

    static async editGenre(genre: GenreCreationDTO, id: number | undefined): Promise<GenreDTO> {
        const response = await axiosInstance.post<GenreDTO>(
            `/rest/playlists/genre/update/${id}`, genre,
        );

        const data = response.data;
        return new GenreDTO(data);
    };

    static async deleteGenre(id: number): Promise<void> {
        await axiosInstance.delete<GenreDTO>(`/rest/playlists/genre/${id}`, {params: {id}});
    }

    static async getAllGenresPaged(page: number, size: number, key?: string | null, name?: string | undefined, sortingConfiguration?: SortingConfiguration | undefined): Promise<Page<GenreDTO>> {
        const params = new URLSearchParams({
            page: page.toString(),
            size: size.toString(),
        });

        if (key) {
            params.append("key", key);
        }

        if (name) {
            params.append("name", name);
        }

        sortingConfiguration?.appendToBackEndSearchParams(params);

        const response = await axiosInstance.get<Page<GenreDTO>>(
            `/rest/playlists/genre/all-paged`, { params: params },
        );

        const data = response.data;

        return new Page<GenreDTO>(
            data.content.map(genre => new GenreDTO(genre)),
            data.totalElements,
        )
    };

    static async getAllGenres(): Promise<GenreDTO[]> {
        const response = await axiosInstance.get<GenreDTO[]>(
            `/rest/playlists/genre/all`,
        );

        const data = response.data;
        return data.map(genre => new GenreDTO(genre));
    }
}