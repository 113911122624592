import {Card, CardContent, Grid} from "@mui/material";
import React from "react";

type LayoutProps = {
    children?: React.ReactNode;
    minWidth?: string | number;
    maxWidth?: string | number;
    minHeight?: string | number;
    maxHeight?: string;
    className? : string;
    style?: React.CSSProperties
}

export function CenteredCardLayout(props: LayoutProps) {
    return (
        <Grid direction={"column"} container justifyContent={"center"} alignItems={"center"} className={props.className} style={props.style}>
            <Card sx={{
                minWidth: props.minWidth ?? "40%",
                maxWidth: props.maxWidth ?? "40%",
                minHeight: props.minHeight ?? "40%",
                maxHeight: props.maxHeight ?? "40%",
                textAlign: "left",
            }}>
                <CardContent className={"container-fluid"}>
                    {props.children}
                </CardContent>
            </Card>
        </Grid>
    )
}