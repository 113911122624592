import React, { useEffect, useState } from "react";
import {
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { strings } from "../../localization/Localization";
import { NullableDatePicker } from "../../components/NullableDatePicker";
import { useSearchParams } from "react-router-dom";
import { Dayjs } from "dayjs";
import { Clear } from "@mui/icons-material";
import { SideBarPage } from "../../components/SideBarPage";
import { ServicesService } from "../../services/ServicesService";
import { ServiceDTO } from "../../models/ServiceDTO";
import { SessionInstanceReportService } from "../../services/SessionInstanceReportService";
import { ListenedTracksPerArtistDateResponse } from "../../models/ListenedTracksPerArtistDateResponse";

export function ListenedTracksPerArtist() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [services, setServices] = useState<ServiceDTO[] | undefined>([]);
    const [serviceId, setServiceId] = useState<string>("");
    const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
    const [listenedTracksData, setListenedTracksData] = useState<
        ListenedTracksPerArtistDateResponse[]
    >([]);

    const listLinks = [
        { label: strings.dashboard, currentlyOpened: false, href: "/" },
        { label: strings.reports, currentlyOpened: true },
    ];

    useEffect(() => {
        getAllServices();
    }, []);

    useEffect(() => {
        if (selectedDate && serviceId) {
            getAllListenedTracksPerArtist();
        }
    }, [selectedDate, serviceId]);

    function setSearchParam(
        key: string,
        value: string | null | undefined
    ) {
        if (value) {
            searchParams.set(key, value);
        } else {
            searchParams.delete(key);
        }
        setSearchParams(searchParams);
    }

    function setPage(page: number) {
        setSearchParam("page", page.toString());
    }

    function setValue<T>(
        setter: React.Dispatch<React.SetStateAction<T>>,
        value: T
    ): void {
        setter(value);
        setPage(0);
    }

    function handleDateChange(date: Dayjs | null) {
        setSelectedDate(date);
    }

    function getAllServices() {
        ServicesService.getAllServices().then((data) => {
            setServices(data);
        });
    }

    async function getAllListenedTracksPerArtist() {
        try {
            const response = await SessionInstanceReportService.listenedTracksPerArtist(
                selectedDate?.format("YYYY-MM-DD") || "",
                parseInt(serviceId)
            );

            if (Array.isArray(response)) {
                setListenedTracksData(response);
            } else {
                setListenedTracksData([response]);
            }
        } catch (error) {
            console.error("Error fetching listened tracks per artist:", error);
        }
    }

    return (
        <SideBarPage
            breadcrumbs={listLinks}
            pageTitle={strings.listenedTracksPerArtist}
            component={
                <>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ maxWidth: "20%" }}>
                                        <NullableDatePicker
                                            label={strings.date}
                                            value={selectedDate}
                                            onChange={handleDateChange}
                                        />
                                    </TableCell>
                                    <TableCell style={{ maxWidth: "20%" }}>
                                        <FormControl fullWidth variant="standard">
                                            <InputLabel>{strings.service}</InputLabel>
                                            <Select
                                                defaultValue={""}
                                                required
                                                value={serviceId}
                                                onChange={(event) =>
                                                    setValue(setServiceId, event.target.value as string)
                                                }
                                                label={strings.service}
                                                labelId="serviceLabel"
                                                endAdornment={
                                                    serviceId && (
                                                        <IconButton onClick={() => setServiceId("")}>
                                                            <Clear />
                                                        </IconButton>
                                                    )
                                                }
                                            >
                                                {services?.map((service) => (
                                                    <MenuItem key={service.id} value={service.id}>
                                                        {service.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ width: "50%" }}>{strings.artist}</TableCell>
                                    <TableCell sx={{ width: "50%" }}>
                                        {strings.listenedTracks}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {listenedTracksData?.length > 0 && listenedTracksData.map((artistData) => (
                                    <TableRow key={artistData.artist}>
                                        <TableCell>{artistData.artist}</TableCell>
                                        <TableCell>{artistData.listenedTracks}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            }
        />
    );
}
