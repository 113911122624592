import {
    Box, Grid, Pagination,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import {DateTimeUtils} from "../../utils/DateTimeUtils";
import React, {useEffect, useState} from "react";
import {ProxyDTO} from "../../models/ProxyDTO";
import {ProxyService} from "../../services/ProxyService";
import {strings} from "../../localization/Localization";
import {LightTooltip} from "../../components/LightTooltip";
import "../dashboard/TableCSS.css";
import {Routes} from "../../router/Routes";
import {useNavigate} from "react-router-dom";
import {PaginationPlus} from "../../components/PaginationPlus";

export function DashboardProxies() {
    const [proxies, setProxies] = useState<ProxyDTO[]>([]);
    const [page, setPage] = useState<number>(0);
    const [name] = useState<string | null>(null);
    const [url] = useState<string | null>(null);
    const [totalElements, setTotalElements] = useState(4);
    const [rowsPerPage] = useState(4);

    function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
        setPage(newPage);
    }

    const navigate = useNavigate();
    function navigateProxyRow(id: number): any {
        navigate(Routes.PROXY_DETAILS.replace(":id", id.toString()));
    }

    useEffect(() => {
        function getProxies(page: number) {
            ProxyService.getAllProxiesPaged(page, rowsPerPage, undefined, name, url, undefined, undefined, null, undefined).then((data) => {
                setProxies(data.content);
                setTotalElements(data.totalElements);
            })
        }
        getProxies(page);
    }, [page, url, rowsPerPage, name, undefined]);

    return(
        <Box className={"tableCSS"}>
            <div className={"mt-3"}>
                <div>{strings.proxies}</div>
                <TableContainer component={Paper}>
                    <Table aria-label="custom pagination table" sx={{ minWidth: 750}}>
                        <TableHead>
                            <TableRow>
                                <TableCell>{strings.proxyName}</TableCell>
                                <TableCell>{strings.url}</TableCell>
                                <TableCell>{strings.region}</TableCell>
                                <TableCell>{strings.hasAuth}</TableCell>
                                <TableCell>{strings.dateModified}</TableCell>
                            </TableRow>

                        </TableHead>
                        <TableBody>
                            {proxies?.map((proxy) => (
                                <TableRow
                                    onClick={() => navigateProxyRow(proxy.id)}
                                    key={proxy.id}
                                    hover={true}
                                    className="cursor-pointer"
                                >
                                    <LightTooltip title={proxy.name}><TableCell className="truncate-table-cell" width={"20%"} >{proxy.name}</TableCell></LightTooltip>
                                    <LightTooltip title={proxy.url}><TableCell className="truncate-table-cell" width={"30%"}>{proxy.url}</TableCell></LightTooltip>
                                    <TableCell className="truncate-table-cell">{proxy.region?.label}</TableCell>
                                    <TableCell className="truncate-table-cell">
                                        {proxy.hasAuth ? <CheckCircleIcon color={"primary"}/> :
                                            <CancelIcon color={"error"}/>}
                                    </TableCell>
                                    <LightTooltip title={proxy.dateModified.toString()}><TableCell className="truncate-table-cell" >{DateTimeUtils.formatDate(proxy.dateModified)}</TableCell></LightTooltip>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <Grid sx={{display: 'flex', flexDirection: 'column', alignItems: 'end', marginTop: "10px"}}>
                <PaginationPlus totalElements={totalElements} rowsPerPage={rowsPerPage} page={page} onChangePage={(page) => handleChangePage(null, page)}/>
                <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={totalElements}
        rowsPerPage={4}
        page={page}
        onPageChange={handleChangePage}
        sx={{
            ".MuiTablePagination-displayedRows": {
                marginTop: "15px"
            }
        }}
    />
            </Grid>
        </Box>
    )
}
