import {Clear} from "@mui/icons-material";
import {
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem, Pagination,
    Paper,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
} from "@mui/material";
import {Box} from "@mui/system";
import React, {useEffect, useState} from "react";
import {strings} from "../../localization/Localization";
import {AgeGroupDTO} from "../../models/AgeGroupDTO";
import {RegionDTO} from "../../models/RegionDTO";
import {ServiceDTO} from "../../models/ServiceDTO";
import {SessionInstance} from "../../models/SessionInstance";
import {AgeGroupService} from "../../services/AgeGroupService";
import {RegionService} from "../../services/RegionService";
import {ServicesService} from "../../services/ServicesService";
import {SessionInstanceService} from "../../services/SessionInstanceService";
import {DateTimeUtils} from "../../utils/DateTimeUtils";
import {CircularProgressbar} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {Dayjs} from "dayjs";
import {useSearchParams} from "react-router-dom";
import { SortingConfiguration } from "../../utils/SortingUtils";
import { TableHeader } from "../../components/TableHeader";
import { NumberParser } from "../../utils/NumberParser";
import { BooleanParser } from "../../utils/BooleanParser";
import {PaginationPlus} from "../../components/PaginationPlus";

interface props {
    proxyId: number | undefined
}

export function SessionInstancesComponent({proxyId} : props) {
    const columns = [
        { label: strings.sessionId, sortBy: "session.id", sortable: true },
        { label: strings.key, sortBy: "session.key", sortable: true },
        { label: strings.name, sortBy: "session.name", sortable: true },
        { label: strings.service, sortBy: "account.service.label", sortable: true },
        { label: strings.accountGroup, sortBy: "account.accountGroup.name", sortable: true },
        { label: strings.email, sortBy: "account.email", sortable: true },
        { label: strings.region, sortBy: "account.region.label", sortable: true },
        { label: strings.ageGroup, sortBy: "account.ageGroup.label", sortable: true },
        { label: strings.dateCreated, sortBy: "dateCreated", sortable: true },
        { label: strings.completed, sortBy: "completed", sortable: false },
    ]
    const [searchParams, setSearchParams] = useSearchParams();
    const [sessionInstances, setSessionInstances] = useState<SessionInstance[]>([]);
    const sessionName = searchParams.get("sessionName") ?? "";
    const sessionServiceId = searchParams.get("sessionServiceId") ?? "";
    const usesProxy = BooleanParser.parseBooleanNullable(searchParams.get("usesProxy"))
    const sessionId = searchParams.get("sessionId") ?? "";
    const sessionKey = searchParams.get("sessionKey") ?? "";
    const accountGroupName = searchParams.get("accountGroupName") ?? "";
    const accountEmail = searchParams.get("accountEmail") ?? "";
    const accountRegionId = searchParams.get("accountRegionId") ?? "";
    const accountAgeGroupId = searchParams.get("accountAgeGroupId") ?? "";
    const dateCreated = searchParams.get("dateCreated") ?? null;
    const rowsPerPage = NumberParser.parseNumber(searchParams.get("rowsPerPage"), 5);
    const page = NumberParser.parseNumber(searchParams.get("page"), 0);
    const [totalElements, setTotalElements] = useState(0);
    const [services, setServices] = useState<ServiceDTO[]>([]);
    const [regions, setRegions] = useState<RegionDTO[]>([]);
    const [ageGroups, setAgeGroups] = useState<AgeGroupDTO[]>([]);
    const sortingConfigurationString = searchParams.get("sort");

    function setSearchParam(key: string, value: string | null | undefined) {
        if (value) {
            searchParams.set(key, value);
        } else {
            searchParams.delete(key);
        }

        setSearchParams(searchParams)
    }
    function setPage(page: number) {
        setSearchParam("page", page.toString());
    }

    function updateSort(sortingConfiguration: SortingConfiguration): void {
        setSearchParam("sort", sortingConfiguration.toSearchString());
    }

    function handleChangePage(
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("rowsPerPage", event.target.value);
        setPage(0);
    }

    function handleChangeSessionId(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("sessionId", event.target.value);
        setPage(0)
    }

    function handleChangeSessionKey(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("sessionKey", event.target.value);
        setPage(0)
    }

    function handleChangeSessionName(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("sessionName", event.target.value);
        setPage(0)
    }

    function handleChangeServiceId(event: SelectChangeEvent) {
        setSearchParam("sessionServiceId", event.target.value)
        setPage(0)
    }

    function handleChangeRegionId(event: SelectChangeEvent) {
        setSearchParam("accountRegionId", event.target.value);
        setPage(0);
    }

    function handleChangeAgeGroup(event: SelectChangeEvent) {
        setSearchParam("accountAgeGroupId", event.target.value)
        setPage(0);
    }

    function handleChangeAccountGroup(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        setSearchParam("accountGroupName", event.target.value)
        setPage(0);
    }

    function handleChangeEmail(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("accountEmail", event.target.value)
        setPage(0);
    }

    function handleChangeDateCreated(date: Dayjs | null) {
        setSearchParam("dateCreated", DateTimeUtils.formatDateDayJs(date));
        setPage(0);
    }

    function checkIsActionIsFinished(item: SessionInstance) {
        if (item.finishedActions < item.totalActions) {
            return false;
        } else if (item.finishedActions === 0 && item.totalActions === 0) {
            return false;
        } else {
            return true;
        }
    }

    function returnPercentage(item: SessionInstance) {
        let percentage: number = 0.0;
        if (item.finishedActions < item.totalActions) {
            percentage = parseFloat(((item.finishedActions / item.totalActions) * 100).toFixed(2));
        }
        return percentage;
    }

    useEffect(() => {
        async function getAllServices(): Promise<void> {
            const services = await ServicesService.getAllServices();
            setServices(services);
        }

        async function getAllRegions(): Promise<void> {
            const regions = await RegionService.getRegions();
            setRegions(regions);
        }

        async function getAllAgeGroups(): Promise<void> {
            const ageGroups = await AgeGroupService.getAllAgeGroups();
            setAgeGroups(ageGroups);
        }

        async function loadData(): Promise<void> {
            await getAllRegions();
            await getAllServices();
            await getAllAgeGroups();
        }

        loadData().then((_) => {
        });
    }, []);

    useEffect(() => {
        async function getAllSessionInstancesPaged(page: number): Promise<void> {
            const response = await SessionInstanceService.getAllSessionInstancesPaged(page, rowsPerPage, sessionId, accountGroupName, accountEmail, accountRegionId, accountAgeGroupId, undefined, sessionKey, sessionServiceId, sessionName, usesProxy, dateCreated, SortingConfiguration.fromSearchString(sortingConfigurationString), proxyId);
            setTotalElements(response.totalElements);
            setSessionInstances(response.content);
        }

        getAllSessionInstancesPaged(page).then((_) => {
        });
    }, [
        accountEmail,
        accountGroupName,
        sessionServiceId,
        sessionName,
        accountRegionId,
        usesProxy,
        sessionKey,
        dateCreated,
        accountAgeGroupId,
        sessionId,
        page,
        rowsPerPage,
        sortingConfigurationString,
        proxyId
    ]);
    return (
        <Box>
            <TableContainer component={Paper}>
                <Table
                    sx={{minWidth: 500}}
                    aria-label="custom pagination table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                {" "}
                                <TextField
                                    value={sessionId}
                                    name="sessionId"
                                    variant="standard"
                                    label={strings.sessionId}
                                    onChange={handleChangeSessionId}
                                    fullWidth
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    value={sessionKey}
                                    name="sessionKey"
                                    variant="standard"
                                    label={strings.key}
                                    onChange={handleChangeSessionKey}
                                    fullWidth
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    value={sessionName}
                                    name="sessioName"
                                    variant="standard"
                                    label={strings.name}
                                    onChange={handleChangeSessionName}
                                    fullWidth
                                />
                            </TableCell>
                            <TableCell>
                                {" "}
                                <FormControl fullWidth variant="standard">
                                    <InputLabel>{strings.service}</InputLabel>
                                    <Select
                                        defaultValue={""}
                                        value={
                                            sessionServiceId ? String(sessionServiceId) : ""
                                        }
                                        onChange={handleChangeServiceId}
                                        label={strings.service}
                                        labelId="serviceLabel"
                                        endAdornment={
                                            sessionServiceId && (
                                                <IconButton
                                                    onClick={() => {
                                                        setSearchParam("sessionServiceId", undefined);
                                                        setPage(0);
                                                    }}
                                                >
                                                    <Clear/>
                                                </IconButton>
                                            )
                                        }
                                    >
                                        {services?.map((service) => (
                                            <MenuItem
                                                disableRipple
                                                key={service.id}
                                                value={service.id}
                                            >
                                                {service.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </TableCell>
                            <TableCell>
                                {" "}
                                <TextField
                                    value={accountGroupName}
                                    name="accountGroupName"
                                    variant="standard"
                                    label={strings.accountGroup}
                                    onChange={handleChangeAccountGroup}
                                    fullWidth
                                />
                            </TableCell>
                            <TableCell>
                                {" "}
                                <TextField
                                    value={accountEmail}
                                    name="accountEmail"
                                    variant="standard"
                                    label={strings.email}
                                    onChange={handleChangeEmail}
                                    fullWidth
                                />
                            </TableCell>
                            <TableCell>
                                <FormControl
                                    style={{width: "100px"}}
                                    variant="standard"
                                >
                                    <InputLabel>{strings.region}</InputLabel>
                                    <Select
                                        defaultValue={""}
                                        value={accountRegionId ? String(accountRegionId) : ""}
                                        onChange={handleChangeRegionId}
                                        label={strings.region}
                                        labelId="regionLabel"
                                        endAdornment={
                                            accountRegionId && (
                                                <IconButton
                                                    onClick={() => {
                                                        setSearchParam("accountRegionId", undefined);
                                                        setPage(0);
                                                    }}
                                                >
                                                    <Clear/>
                                                </IconButton>
                                            )
                                        }
                                    >
                                        {regions?.map((region) => (
                                            <MenuItem
                                                disableRipple
                                                key={region.id}
                                                value={region.id}
                                            >
                                                {region.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </TableCell>
                            <TableCell>
                                <FormControl
                                    style={{width: "100px"}}
                                    variant="standard"
                                >
                                    <InputLabel>{strings.ageGroup}</InputLabel>
                                    <Select
                                        defaultValue={""}
                                        value={
                                            accountAgeGroupId ? String(accountAgeGroupId) : ""
                                        }
                                        onChange={handleChangeAgeGroup}
                                        label={strings.ageGroup}
                                        labelId="ageGroupLabel"
                                        endAdornment={
                                            accountAgeGroupId && (
                                                <IconButton
                                                    onClick={
                                                        () => {
                                                            setSearchParam("accountAgeGroupId", undefined);
                                                            setPage(0);
                                                        }
                                                    }
                                                >
                                                    <Clear/>
                                                </IconButton>
                                            )
                                        }
                                    >
                                        {ageGroups?.map((ageGroup) => (
                                            <MenuItem
                                                disableRipple
                                                key={ageGroup.id}
                                                value={ageGroup.id}
                                            >
                                                {ageGroup.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </TableCell>
                            <TableCell>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        onChange={handleChangeDateCreated}
                                        value={dateCreated}
                                        label={strings.dateCreated}
                                        inputFormat="DD.MM.YYYY"
                                        renderInput={(params: any) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                onKeyDown={(e)=>e.preventDefault()}
                                            />
                                        )}
                                        componentsProps={{
                                            actionBar: {
                                                actions: ["today"],
                                            },
                                        }}
                                        InputProps={{
                                            endAdornment: dateCreated && (
                                                <IconButton sx={{height: "10px", width: "10px"}}
                                                            onClick={() => {
                                                                setSearchParam("dateCreated", null);
                                                                setPage(0);
                                                            }}>
                                                    <Clear/>
                                                </IconButton>
                                            ),
                                        }}
                                        InputAdornmentProps={{
                                            position: "start",
                                        }}
                                    />
                                </LocalizationProvider>
                            </TableCell>
                            <TableCell>
                            </TableCell>
                        </TableRow>
                        <TableHeader columns={columns} sortConfigurationString={sortingConfigurationString} updateSort={updateSort} />
                    </TableHead>
                    <TableBody>
                        {sessionInstances.map((sessionInstance) => (
                            <TableRow key={sessionInstance.id}>
                                <TableCell component="th" scope="row">
                                    {sessionInstance.session?.id}
                                </TableCell>
                                <TableCell>{sessionInstance.session?.key}</TableCell>
                                <TableCell>{sessionInstance.session?.name}</TableCell>
                                <TableCell>
                                    {sessionInstance.account?.service?.label}
                                </TableCell>
                                <TableCell>
                                    {sessionInstance.account?.accountGroup?.name}
                                </TableCell>
                                <TableCell>{sessionInstance?.account?.email}</TableCell>
                                <TableCell>
                                    {sessionInstance.account?.region?.label
                                        ? sessionInstance.account?.region?.label
                                        : "/"}
                                </TableCell>
                                <TableCell>
                                    {sessionInstance.account?.ageGroup
                                        ? sessionInstance.account.ageGroup.toString()
                                        : "/"}
                                </TableCell>
                                <TableCell>
                                    {DateTimeUtils.formatDateObject(
                                        sessionInstance.dateCreated
                                    )}
                                </TableCell>
                                <TableCell>
                                    {checkIsActionIsFinished(sessionInstance) ? (
                                        <CheckCircleIcon
                                            color="success"
                                            sx={{fontSize: "65px", width: "65px"}}
                                        />
                                    ) : (
                                        <div style={{maxWidth: "65px"}}>
                                            <CircularProgressbar
                                                value={returnPercentage(sessionInstance)}
                                                text={`${returnPercentage(sessionInstance)}%`}
                                                strokeWidth={7}
                                            />
                                        </div>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid sx={{display: 'flex', flexDirection: 'column', alignItems: 'end', marginTop: "10px"}}>
                <PaginationPlus totalElements={totalElements} rowsPerPage={rowsPerPage} page={page} onChangePage={(page) => handleChangePage(null, page)}/>
                <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={totalElements}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            </Grid>
        </Box>
    );
}
