import { ServiceDTO } from "./ServiceDTO";

export class ClientScraperDataDTO {
    id: number | string | undefined;
    dateCreated: string | undefined;
    dateModified: string | undefined;
    clientId:number | string | undefined;
    service:ServiceDTO | undefined;
    username:string  | undefined;
    password: string  | undefined;
    referenceKey: string | undefined;

    constructor(json:ClientScraperDataDTO) {
        this.id = json.id;
        this.dateCreated = json.dateCreated;
        this.dateModified = json.dateModified;
        this.clientId = json.clientId;
        this.service = json.service ? new ServiceDTO(json.service) : undefined;
        this.username = json.username;
        this.password = json.password;
        this.referenceKey = json.referenceKey;
    }
}