import {Page} from "../pagination/Page";
import {ProxyGroupReducedDTO} from "../models/ProxyGroupReducedDTO";
import axiosInstance from "../network/AxiosInstance";
import {ProxyGroupCreationDTO} from "../models/ProxyGroupCreationDTO";
import axios from "../network/AxiosInstance";

export class ProxyGroupService {
    static async getAllProxyGroupsPaged(page: number, size: number, name?: string | undefined): Promise<Page<ProxyGroupReducedDTO>> {
        const params = new URLSearchParams({
            page: page.toString(),
            size: size.toString()
        });

        if (name) {
            params.append("name", name);
        }

        const response = await axiosInstance.get<Page<ProxyGroupReducedDTO>>(
            `/rest/bot-session/proxy-group/all-paged`, {params: params}
        )

        const data = response.data;

        return new Page<ProxyGroupReducedDTO>(
            data.content.map((proxyGroup) => new ProxyGroupReducedDTO(proxyGroup)),
            data.totalElements,
        );
    }

    static async createProxyGroup(proxyGroupCreationDTO: ProxyGroupCreationDTO): Promise<ProxyGroupReducedDTO> {
        const response = await axiosInstance.post<ProxyGroupReducedDTO>(`/rest/bot-session/proxy-group/create`, proxyGroupCreationDTO);
        const data = response.data;
        return new ProxyGroupReducedDTO(data);
    }

    static async editProxyGroup(id: number, proxyGroup: ProxyGroupCreationDTO): Promise<ProxyGroupReducedDTO> {
        const response = await axiosInstance.put<ProxyGroupReducedDTO>(`/rest/bot-session/proxy-group/update/${id}`, proxyGroup)
        const data = response.data;
        return new ProxyGroupReducedDTO(data);
    }

    static async deleteProxyGroup(id: number): Promise<void> {
        await axiosInstance.delete<ProxyGroupReducedDTO>(`/rest/bot-session/proxy-group/${id}`, {})
    }

    static async getProxyGroupById(id: number): Promise<ProxyGroupReducedDTO> {
        const response = await axios.get<ProxyGroupReducedDTO>(`/rest/bot-session/proxy-group/${id}`);
        const data = response.data;

        return new ProxyGroupReducedDTO(data);
    }
}
