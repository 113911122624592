import {BotConditionDTO} from "../models/BotConditionDTO";
import axios from "../network/AxiosInstance";
import {AuthService} from "./AuthService";

export class BotConditionService {
    static async botCondition() : Promise<BotConditionDTO> {
        const response = await axios.get<BotConditionDTO>(`${AuthService.PREFIX}/bot-session/bot/condition-count`, {params: {

            }});
        return response.data;
    }
}