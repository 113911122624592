import { SharedActionCreationDTO } from "../models/SharedActionCreationDTO";
import { SharedActionDTO } from "../models/SharedActionDTO";
import axiosInstance from "../network/AxiosInstance";
import { Page } from "../pagination/Page";
import { SortingConfiguration } from "../utils/SortingUtils";
import { AuthService } from "./AuthService";

export class SharedActionService {
    static async getAllSharedActionsPaged(page: number,size: number,actionTypeId: number | string | null | undefined,sourceKey: string | undefined,regionId: number | string |  null | undefined,serviceId: number | string | undefined | null, dateCreated:string | null, dateModified:string | null, sort: SortingConfiguration,
    ): Promise<Page<SharedActionDTO>> {
      let params = new URLSearchParams();
      params.append("page", page.toString());
      params.append("size", size.toString());
      if(regionId){
        params.append("regionId", regionId.toString());
      }
      if(actionTypeId){
        params.append("actionTypeId", actionTypeId?.toString());
      }
      if(serviceId){
      params.append("serviceId", serviceId.toString());
      }
      if(sourceKey){
        params.append("sourceKey",sourceKey)
      }
      if(dateModified) {
        params.append("dateModified", dateModified)
      }
      if(dateCreated){
        params.append("dateCreated", dateCreated)
      }
  
      sort.appendToBackEndSearchParams(params);
  
      const request = {
        params: params, 
      };
      const response = await axiosInstance.get<Page<SharedActionDTO>>(
        `${AuthService.PREFIX}/bot-session/shared-action/all-paged/`, request,
      );
      const data = response.data;
      return new Page<SharedActionDTO>(
        data.content.map((action) => new SharedActionDTO(action)),
        data.totalElements
      );
    }  

    static async createSharedAction(sharedActionCreationDTO: SharedActionCreationDTO): Promise<SharedActionDTO> {
      const response = await axiosInstance.post<SharedActionDTO>(`${AuthService.PREFIX}/bot-session/shared-action/create`,sharedActionCreationDTO);
      const data = response.data;
      return new SharedActionDTO(data);
    }

    static async editSharedActions(sharedActionCreationDTO: SharedActionCreationDTO, id: number): Promise<SharedActionDTO> {
      const response = await axiosInstance.put<SharedActionDTO>( `/rest/bot-session/shared-action/update/${id}`,sharedActionCreationDTO);
      const data = response.data;
      return new SharedActionDTO(data);
    }

    static async deleteSharedAction(id: number): Promise<void> {
      await axiosInstance.delete<SharedActionDTO>(`/rest/bot-session/shared-action/delete/${id}`);
  }
}