
export class AccountStatusDTO  {
    id: number;
    dateCreated: number;
    dateModified: number;
    accountId:number;
    premium:boolean|undefined;
    profile: string|undefined;

    constructor(json: any) {
        this.id = json.id;
        this.dateCreated = json.dateCreated;
        this.dateModified = json.dateModified;
        this.accountId = json.accountId;
        this.premium = json.premium;
        this.profile = json.profile;


    }
}
