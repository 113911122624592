import {
  Button,
  Checkbox,
  FormControl, FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {CenteredCardLayout} from "../../components/CenteredCardLayout";
import {SideBarPage} from "../../components/SideBarPage";
import {strings} from "../../localization/Localization";
import {RegionDTO} from "../../models/RegionDTO";
import {ServiceDTO} from "../../models/ServiceDTO";
import {AccountGroupService} from "../../services/AccountGroupService";
import {RegionService} from "../../services/RegionService";
import {ServicesService} from "../../services/ServicesService";
import {AgeGroupService} from "../../services/AgeGroupService";
import {AgeGroupDTO} from "../../models/AgeGroupDTO";
import {PaginatedSelect} from "../../components/PaginatedSelect";
import {AccountService} from "../../services/AccountService";
import {AccountFilter} from "../../models/filters/AccountFilter";
import {showErrorDialog, showSuccessDialog} from "../../common/Dialogs";
import {Routes} from "../../router/Routes";
import {useNavigateWithParams} from "../../router/NavigateWithParams";
import {ErrorHandler} from "../../utils/ErrorHandler";
import {useParams, useSearchParams} from "react-router-dom";
import {AccountDTO} from "../../models/AccountDTO";
import {v4 as uuidv4} from "uuid"
import {Clear} from "@mui/icons-material";
import {AccountGroupsScheduleTable} from "./AccountGroupsScheduleTable";
import TableHead from "@mui/material/TableHead";
import {SortingConfiguration} from "../../utils/SortingUtils";
import {NumberParser} from "../../utils/NumberParser";
import ConfirmationModal from "../../components/ConfirmationModal";
import {PaginationPlus} from "../../components/PaginationPlus";
import {BotPlatformDTO} from "../../models/BotPlatformDTO";
import {BotPlatformService} from "../../services/BotPlatformService";
import {ProxyGroupReducedDTO} from "../../models/ProxyGroupReducedDTO";
import {ProxyGroupService} from "../../services/ProxyGroupService";
import {QueueDTO} from "../../models/QueueDTO";
import {QueueService} from "../../services/QueueService";

export class AccountGroupData {
  name: string;
  serviceId: number | null;
  accountIds: AccountDTO | undefined;
  ageGroupId: number | null;
  parentAccountId: AccountDTO | undefined;
  regionId: number | string | null | undefined;
  email: string;
  accountGroupId: number | null;
  regionIdFilter : number | string;
  botPlatformId: number | null;
  perRegionScheduling: boolean | null;
  perServiceScheduling: boolean | null;
  reservePoolProxyGroupId: ProxyGroupReducedDTO | undefined;
  queueId: QueueDTO | undefined;
  resetPassword: boolean;

  constructor(json: any) {
    // eslint-disable-next-line no-lone-blocks
    {
      this.accountGroupId = json.accountGroupId;
      this.name = json.name;
      this.serviceId = json.serviceId;
      this.accountIds = json.accountIds;
      this.ageGroupId = json.ageGroupId;
      this.parentAccountId = json.parentAccountId;
      this.regionId = json.regionId;
      this.email = json.email;
      this.regionIdFilter = json.regionIdFilter;
      this.botPlatformId = json.botPlatformId;
      this.perRegionScheduling = json.perRegionScheduling;
      this.perServiceScheduling = json.perServiceScheduling;
      this.reservePoolProxyGroupId = json.reservePoolProxyGroupId;
      this.queueId = json.queueId;
      this.resetPassword = json.resetPassword;
    }
  }
  toAccountCreationGroupDTO(): AccountGroupData {
    return new AccountGroupData({
      name: this.name,
      serviceId: this.serviceId,
      accountIds: this.accountIds?.id,
      ageGroupId: this.ageGroupId,
      parentAccountId: this.parentAccountId?.id,
      regionId: this.regionId,
      email: this.email,
      accountGroupId: this.accountGroupId,
      regionIdFilter: this.regionIdFilter,
      botPlatformId: this.botPlatformId,
      perRegionScheduling: this.perRegionScheduling,
      perServiceScheduling: this.perServiceScheduling,
      reservePoolProxyGroupId: this.reservePoolProxyGroupId?.id,
      queueId: this.queueId,
      resetPassword: this.resetPassword
    });
  }
}

export function CreateAccountGroup() {
  const [service, setService] = useState<ServiceDTO[] | undefined>([]);
  const [regions, setRegions] = useState<RegionDTO[] | undefined>([]);
  const [botPlatforms, setBotPlatforms] = useState<BotPlatformDTO[] | undefined>([]);
  const [ageGroups, setAgeGroups] = useState<AgeGroupDTO[]>([]);
  const [newAccounts, setNewAccounts] = useState<any>([]);
  const [existingAccounts, setExistingAccounts] = useState<any>([]);
  const [email, setEmail] = useState<string>("");
  const [totalElements, setTotalElements] = useState(5);
  const [allAccounts, setAllAccounts] = useState<AccountDTO[]>([])
  const [checkedAccounts, setCheckedAccounts] = useState<any>([])
  const [searchParams, setSearchParams] = useSearchParams();

  const page = NumberParser.parseNumber(searchParams.get("page"), 0);
  const rowsPerPage = NumberParser.parseNumber(searchParams.get("rowsPerPage"), 5);
  const accountService = NumberParser.parseNumberNullable(searchParams.get("serviceId"));


  const sortingConfigurationString = searchParams.get("sort");
  const [openModal, setOpenModal] = useState<boolean>(false);

  let { id } = useParams();
  const isAddMode = !id;
  const navigate = useNavigateWithParams();

  const {
    control,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<AccountGroupData>({
    defaultValues: {
      perRegionScheduling: false,
      perServiceScheduling: false,
    }
  });
  watch();
  async function getAllServices(): Promise<void> {
    const data = await ServicesService.getAllServices();
    setService(data);
  }

  async function getAllBotPlatforms(): Promise<void> {
    const data = await BotPlatformService.getAllBotPlatforms();
    setBotPlatforms(data);
  }

  async function getAllRegions(): Promise<void> {
    const data = await RegionService.getRegions();
    setRegions(data);
  }

  function buildAccountGroupDTO() {
    return new AccountGroupData(getValues()).toAccountCreationGroupDTO();
  }

  function addAccount() {
    const accountGroupDetails: AccountGroupData = buildAccountGroupDTO();

    const object: any = {
      name: accountGroupDetails.name,
      serviceId: accountGroupDetails.serviceId,
      accountIds: checkedAccounts.map((acc: any) => acc.id),
      accounts: newAccounts,
      botPlatformId: accountGroupDetails.botPlatformId,
      perRegionScheduling: accountGroupDetails.perRegionScheduling,
      perServiceScheduling: accountGroupDetails.perServiceScheduling,
      reservePoolProxyGroupId: accountGroupDetails.reservePoolProxyGroupId,
      queueId: accountGroupDetails.queueId?.id,
      resetPassword: accountGroupDetails.resetPassword
    }
    AccountGroupService.createAccountGroup(object)
      .then(() => {
        showSuccessDialog(
          strings.success,
          strings.accountGroupAddedSuccessfully,
          strings.ok
        ).then((_) => {});
        navigate(Routes.ACCOUNT_GROUPS);
      })
      .catch((error) => {
        const message = ErrorHandler.parseErrorMessage(error);
        showErrorDialog(strings.error, message, strings.ok).then((_) => { });
      });
  }

  function editAccount(id: any) {

    const combinedIds = [...checkedAccounts, ...existingAccounts].map(item => item.id)

    const accountGroup = buildAccountGroupDTO();
    const account: any = {
      name: accountGroup?.name,
      serviceId: accountGroup?.serviceId,
      accountIds: combinedIds,
      accounts: newAccounts,
      botPlatformId: accountGroup.botPlatformId,
      perRegionScheduling: accountGroup.perRegionScheduling,
      perServiceScheduling: accountGroup.perServiceScheduling,
      reservePoolProxyGroupId: accountGroup.reservePoolProxyGroupId,
      queueId: accountGroup.queueId?.id,
      resetPassword: accountGroup.resetPassword
    };

    AccountGroupService.editAccount(id, account)
      .then(() => {
        showSuccessDialog(
          strings.success,
          strings.accountGroupUpdatedSuccessfully,
          strings.ok
        ).then((_) => {});
        navigate(Routes.ACCOUNT_GROUPS);
      })
      .catch((error) => {
        const message = ErrorHandler.parseErrorMessage(error);
        showErrorDialog(strings.error, message, strings.ok).then((_) => { });
      });
  }

  function addNewAccountsInList() {
    const newValues = buildAccountGroupDTO();
    setNewAccounts([
      ...newAccounts,
      {
        email: newValues?.email,
        ageGroupId: newValues?.ageGroupId,
        regionId: newValues?.regionId,
        serviceId: newValues?.serviceId,
        parentAccountId: newValues?.parentAccountId,
        id: uuidv4(),
      },
    ]);
    setValue("email", "");
    setValue("regionId", null);
    setValue("parentAccountId", undefined);
    setValue("ageGroupId", null);
  }

  function onSubmit() {
    isAddMode ? addAccount() : editAccount(id);
  }

  function setSearchParam(key: string, value: string | null | undefined) {
    if (value) {
      searchParams.set(key, value);
    } else {
      searchParams.delete(key);
    }

    setSearchParams(searchParams)
  }
  useEffect(() => {
    async function fetchData(): Promise<void> {
      async function getAccountGroupById(id: number): Promise<void> {
        const accountGroup = await AccountGroupService.getAccountGroupById(id);
        setValue("name", accountGroup.name);
        setValue("serviceId", accountGroup.service.id);
        setValue("botPlatformId", accountGroup.botPlatform?.id ?? null);
        setValue("perRegionScheduling", accountGroup.perRegionScheduling ?? false);
        setValue("perServiceScheduling", accountGroup.perServiceScheduling ?? false);
        setValue("reservePoolProxyGroupId", accountGroup.reverseProxyGroup);
        setValue("queueId", accountGroup.queue);
        setValue("resetPassword", accountGroup.resetPassword);
      }
      async function getAllAgeGroups(): Promise<void> {
        const ageGroups = await AgeGroupService.getAllAgeGroups();
        setAgeGroups(ageGroups);
      }
      await getAllServices();
      await getAllBotPlatforms();
      await getAllRegions();
      await getAllAgeGroups();
      async function getAllOfGroupById(id: number): Promise<void> {
        await AccountService.getAllOfGroup(id).then((data) => {
          setExistingAccounts(data)
        });
      }

      if (id) {
        await getAccountGroupById(parseInt(id));
        await getAllOfGroupById(parseInt(id));
      }
    }

    fetchData().then((_) => { });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, setValue, getValues]);

    useEffect(() => {
    function getAccounts(page: number) {
      AccountService.getAllAccountsPaged
      (page, rowsPerPage, new AccountFilter(email, getValues("serviceId"), undefined, getValues("regionIdFilter")? getValues("regionIdFilter").toString(): null), SortingConfiguration.fromSearchString(sortingConfigurationString)).then((data) => {
        setAllAccounts(data.content);
        setTotalElements(data.totalElements);
      })
    }
    if (getValues("serviceId") !== undefined){
      getAccounts(page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues("serviceId"), rowsPerPage, email, page, accountService, sortingConfigurationString, searchParams, getValues("regionIdFilter")])

  function handleCancel(): void {
        navigate(Routes.ACCOUNT_GROUPS, {});
    }

  function handleEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
    setEmail(event.target.value);
    setPage(0);
  }

  function setPage(page: number) {
    setSearchParam("page", page.toString());
  }

  function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchParam("rowsPerPage", event.target.value);
    setPage(0);
  }
    function handleClickOpen(): any {
        setOpenModal(true);
    }

    function handleClose(): any {
        setOpenModal(false);
    }

    async function deleteAccountGroup(): Promise<void> {
        setOpenModal(false);

        if (!id) {
            return;
        }

        try {
            await AccountGroupService.deleteAccountGroup(+id);
            showSuccessDialog(strings.success, strings.accountGroupDeletedSuccessfully, strings.ok).then(_ => {
                navigate(Routes.ACCOUNT_GROUPS);
                window.location.reload();
            });
        } catch (e: any) {
            const errorMessage = ErrorHandler.parseErrorMessage(e);
            showErrorDialog(strings.error, errorMessage, strings.ok).then(_ => {});
        }
    }

  const perRegionSchedulingOptions = [
    {
      label: strings.enabled,
      value: true,
    },
    {
      label: strings.disabled,
      value: false,
    }
  ];

  const perServiceSchedulingOptions = [
    {
      label: strings.enabled,
      value: true,
    },
    {
      label: strings.disabled,
      value: false,
    }
  ];

  return (
    <>
      <SideBarPage
        pageTitle={
          isAddMode ? strings.addAccountGroup : strings.editAccountGroup
        }
        breadcrumbs={[
          { label: strings.dashboard, currentlyOpened: false, href: Routes.HOME },
          { label: strings.accountGroupsTitle, currentlyOpened: false, href: Routes.ACCOUNT_GROUPS },
          { label: isAddMode ? strings.addAccountGroup : strings.editAccountGroup, currentlyOpened: true },
        ]}
        component={
          <CenteredCardLayout minWidth={"70%"} maxWidth={"90%"}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} sm={4} md={6}>
                <FormControl fullWidth>
                  <Controller
                    name="name"
                    rules={{
                      required: true,
                    }}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        value={field.value ?? ""}
                        className="mb-3"
                        sx={{ width: "100%" }}
                        onChange={field.onChange}
                        name="name"
                        label={strings.name}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={6}>
                <FormControl fullWidth>
                  <Controller
                    name="serviceId"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <>
                        <InputLabel
                          shrink={getValues("serviceId") ? true : false}
                        >
                          {strings.service}
                        </InputLabel>
                        <Select
                          disabled={(newAccounts.length>0 || checkedAccounts.length>0 || existingAccounts.length>0)}
                          value={field.value ?? ""}
                          onChange={field.onChange}
                          className="mb-3"
                          sx={{ width: "100%" }}
                          label="Service"
                          input={
                            <OutlinedInput
                              label={strings.service}
                              notched={getValues("serviceId") ? true : false}
                            />
                          }
                        >
                          {service?.map((srv) => (
                            <MenuItem
                              key={srv.id.toString()}
                              value={srv.id.toString()}
                            >
                              {srv.label}
                            </MenuItem>
                          ))}
                        </Select>
                        {getValues("serviceId") === undefined &&
                         <span style={{color:"#6b6b6b", fontSize:"14px"}}>*To add accounts in the group, select service first</span>

                        }
                        {
                          (newAccounts.length>0 || checkedAccounts.length>0 || existingAccounts.length>0)
                          &&
                          <div>
                          <span style={{color:"#6b6b6b", fontSize:"14px"}}>*{strings.changeServiceMsg}</span>
                        </div>
                        }
                        
                      </>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={6}>
                <FormControl fullWidth>
                  <Controller
                      name="botPlatformId"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                          <>
                            <InputLabel
                                shrink={getValues("botPlatformId") ? true : false}
                            >
                              {strings.botPlatform}
                            </InputLabel>
                            <Select
                                value={field.value ?? ""}
                                onChange={field.onChange}
                                className="mb-3"
                                sx={{ width: "100%" }}
                                label={strings.botPlatform}
                                input={
                                  <OutlinedInput
                                      label={strings.botPlatform}
                                      notched={getValues("botPlatformId") ? true : false}
                                  />
                                }
                            >
                              {botPlatforms?.map((item) => (
                                  <MenuItem
                                      key={item.id.toString()}
                                      value={item.id.toString()}
                                  >
                                    {item.label}
                                  </MenuItem>
                              ))}
                            </Select>
                          </>
                      )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={6}>
                <FormControl fullWidth>
                  <Controller
                    name="perRegionScheduling"
                    control={control}
                    render={({ field }) => (
                      <>
                        <InputLabel
                          shrink={getValues("perRegionScheduling") !== null ? true : false}
                        >
                          {strings.perRegionScheduling}
                        </InputLabel>
                        <Select
                          value={field.value ?? ""}
                          onChange={field.onChange}
                          className="mb-3"
                          sx={{ width: "100%" }}
                          label={strings.perRegionScheduling}
                          input={
                            <OutlinedInput
                              label={strings.perRegionScheduling}
                              notched={getValues("perRegionScheduling") !== null ? true : false}
                            />
                          }
                        >
                          {
                            perRegionSchedulingOptions.map((option) => (
                              <MenuItem
                                key={option.label}
                                value={`${option.value}`}
                              >
                                {option.label}
                              </MenuItem>
                            ))
                          }
                        </Select>
                      </>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={6}>
                <FormControl fullWidth>
                  <Controller
                    name="perServiceScheduling"
                    control={control}
                    render={({ field }) => (
                      <>
                        <InputLabel
                          shrink={getValues("perServiceScheduling") !== null ? true : false}
                        >
                          {strings.perServiceScheduling}
                        </InputLabel>
                        <Select
                          value={field.value ?? ""}
                          onChange={field.onChange}
                          className="mb-3"
                          sx={{ width: "100%" }}
                          label={strings.perServiceScheduling}
                          input={
                            <OutlinedInput
                              label={strings.perServiceScheduling}
                              notched={getValues("perServiceScheduling") !== null ? true : false}
                            />
                          }
                        >
                          {
                            perServiceSchedulingOptions.map((option) => (
                              <MenuItem
                                key={option.label}
                                value={`${option.value}`}
                              >
                                {option.label}
                              </MenuItem>
                            ))
                          }
                        </Select>
                      </>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={6}>
                <FormControl fullWidth>
                  <Controller
                      name="reservePoolProxyGroupId"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                          <PaginatedSelect<ProxyGroupReducedDTO>
                              value={value}
                              onChange={onChange}
                              label={strings.reservePoolProxyGroup}
                              valueMapper={(item) => item.id.toString()}
                              keyMapper={(item) => item.id.toString()}
                              itemMapper={(item) => <> {item.name} </>}
                              labelMapper={(item) => item.name}
                              closeOnSelect={true}
                              dataFetcher={(page, size, filter) => {
                                return ProxyGroupService.getAllProxyGroupsPaged(
                                    page,
                                    size,
                                    filter
                                );
                              }}
                              inputProps={{
                                variant: undefined,
                                error: errors.reservePoolProxyGroupId !== undefined,
                                helperText: errors.reservePoolProxyGroupId
                                    ? errors.reservePoolProxyGroupId.message?.toString()
                                    : "",
                              }}
                          />
                      )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={6}>
                <FormControl fullWidth>
                  <Controller
                      name="queueId"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                          <PaginatedSelect<QueueDTO>
                              value={value}
                              onChange={onChange}
                              label={strings.queue}
                              valueMapper={(item) => item.id.toString()}
                              keyMapper={(item) => item.id.toString()}
                              itemMapper={(item) => <> {item.key} </>}
                              labelMapper={(item) => item.key}
                              closeOnSelect={true}
                              dataFetcher={(page, size, filter) => {
                                return QueueService.getAllQueuesPaged(
                                    page,
                                    size,
                                    filter
                                );
                              }}
                              inputProps={{
                                variant: undefined,
                              }}
                          />
                      )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={6}>
                <FormControlLabel
                    control={
                      <Controller
                          name="resetPassword"
                          control={control}
                          defaultValue={true}
                          render={({ field }) => (
                              <Checkbox
                                  onChange={field.onChange}
                                  checked={!!field.value}
                                  value={field.value}
                                  color="primary"
                                  size={"medium"}
                                  disableRipple
                              />
                          )}
                      />
                    }
                    label={strings.resetPassword}
                    labelPlacement="end"
                />
              </Grid>
            </Grid>
            <br />
            <br />
          {
            getValues("serviceId") !== undefined &&
            <>
            <h4 className="d-flex justify-content-center">{strings.addAccount}</h4>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} sm={4} md={6}>
                <FormControl fullWidth>
                  <Controller
                    name="email"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        value={field.value !== undefined ? field.value : ""}
                        className="mb-3"
                        onChange={field.onChange}
                        label={strings.email}
                        sx={{ width: "100%" }}
                        name="email"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={6}>
                <FormControl fullWidth>
                  <Controller
                    name="regionId"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <>
                        <InputLabel id="demo-simple-select-label">
                          Region
                        </InputLabel>
                        <Select
                          value={field.value ?? ""}
                          onChange={field.onChange}
                          className="mb-3"
                          sx={{ width: "100%" }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label={strings.region}
                          name="regionId"
                          endAdornment={
                            field.value && (
                              <IconButton
                                onClick={() => {
                                  field.onChange(undefined)
                                }}
                              >
                                <Clear />
                              </IconButton>
                            )
                          }
                        >
                          {regions?.map((reg) => (
                            <MenuItem key={reg.id} value={reg.id}>{reg.label}</MenuItem>
                          ))}
                        </Select>
                      </>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={6}>
                <FormControl fullWidth>
                  <Controller
                    name="parentAccountId"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <PaginatedSelect<AccountDTO>
                        value={value}
                        onChange={onChange}
                        label={strings.parentAccount}
                        valueMapper={(item) => item.id.toString()}
                        keyMapper={(item) => item.id.toString()}
                        itemMapper={(item) => <> {item.email} </>}
                        labelMapper={(item) => item.email}
                        dataFetcher={(page, size, filter) => {
                          return AccountService.getAllAccountsPaged(
                            page,
                            size,
                            new AccountFilter(filter, getValues("serviceId")),
                            null
                          );
                        }}
                        inputProps={{
                          variant: undefined,
                          error: errors.accountIds !== undefined,
                          helperText: errors.accountIds
                            ? errors.accountIds.message?.toString()
                            : "",
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={6}>
                <FormControl fullWidth>
                  <Controller
                    name="ageGroupId"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <>
                        <InputLabel id="demo-simple-select-label">
                          {strings.ageGroup}
                        </InputLabel>
                        <Select
                          value={field.value ?? ""}
                          onChange={field.onChange}
                          className="mb-3"
                          sx={{ width: "100%" }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="ageGroupId"
                          label={strings.ageGroup}
                          endAdornment={
                            field.value && (
                              <IconButton
                                onClick={() => {
                                  field.onChange(undefined)
                                }}
                              >
                                <Clear />
                              </IconButton>
                            )
                          }
                        >
                          {ageGroups?.map((ageG) => (
                            <MenuItem key={ageG.id.toString()} value={ageG.id}>{ageG.label}</MenuItem>
                          ))}
                        </Select>
                      </>
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <div className="d-flex justify-content-center">
              <Button
                onClick={() => {
                  addNewAccountsInList();
                }}
                className="m-3"
                variant="contained"
                disabled={getValues("email") === undefined || getValues("email") === ""}
              >
                <strong>+</strong>
              </Button>
            </div>
            </>
          }
            <br />
            <br/>
            <br/>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
        <TableContainer component={Paper} style={{ width: "45%" }}>
          <Table sx={{ minWidth: 200 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
              <TableCell>{strings.availableAccounts}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <TextField
                    name="email"
                    variant="standard"
                    label={strings.email}
                    onChange={handleEmailChange}
                    sx={{position:"relative", bottom:'8px'}}
                    style={{width:"80%"}}
                  />
                </TableCell>
                </TableRow>
                <TableRow>
                <TableCell>
                <FormControl variant="standard" style={{width:"80%"}}>
                  <Controller
                    name="regionIdFilter"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <>
                        <InputLabel id="demo-simple-select-label">
                          {strings.region}
                        </InputLabel>
                        <Select
                          value={field.value ?? ""}
                          onChange={field.onChange}
                          className="mb-3"
                          sx={{ width: "100%" ,position:"relative", maxHeight:"60px"}}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label={strings.region}
                          name="regionIdFilter"
                          endAdornment={
                            field.value && (
                              <IconButton
                                onClick={() => {
                                  field.onChange(undefined)
                                }}
                              >
                                <Clear />
                              </IconButton>
                            )
                          }
                        >
                          {regions?.map((reg) => (
                            <MenuItem key={reg.id} value={reg.id}>{reg.label}</MenuItem>
                          ))}
                        </Select>
                      </>
                    )}
                  />
                </FormControl>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ width: '40%' }}>{strings.email}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allAccounts?.map((e: any) => (
                <TableRow
                  hover={true}
                  key={e.id}
                >
                  <TableCell>
                    <Checkbox
                      checked={checkedAccounts.find((item: any) => item.id === e.id) !== undefined
                       ||  existingAccounts.some((acc: any) => acc.id === e.id)
                      }
                      disabled={existingAccounts.some((acc: any) => acc.id === e.id)}
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                      onClick={() => {
                          setCheckedAccounts([...checkedAccounts, { id: e.id, email: e.email, serviceId: e.service.id}]);
                        if (checkedAccounts.some((acc: any) => acc.id === e.id)) {
                          setCheckedAccounts(checkedAccounts.filter((acc: any) => acc.id !== e.id));
                        }
                      }}
                    />{e.email !== undefined && e.email}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Grid sx={{display: 'flex', flexDirection: 'column', alignItems: 'end', marginTop: "10px"}}>
            <PaginationPlus totalElements={totalElements} rowsPerPage={rowsPerPage} page={page} onChangePage={(page) => handleChangePage(null, page)}/>
            <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalElements}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          </Grid>
        </TableContainer>

        <TableContainer component={Paper} style={{ width: "45%", overflowY: "scroll" }} sx={{ maxHeight: 740 }}>
          <Table sx={{ minWidth: 200, maxHeight: 200 }}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '40%' }}>{strings.email}</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ overflow: "scroll" }}>
              {
                existingAccounts.length !== 0 &&
                <TableRow>
                <TableCell><strong>{strings.accountsInGroup}: </strong></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                </TableRow>
              }
              {
                existingAccounts.length !== 0 && existingAccounts.map((ex: any) =>
                (
                  <TableRow
                    hover={true}
                    key={ex.id}
                  >
                    <TableCell sx={{ fontSize: 13 }}>
                      {ex.email !== undefined && ex.email}
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell><Button variant="contained" style={{ padding: "1%" }} onClick={() => {
                      setExistingAccounts(
                        existingAccounts?.filter(
                          (a: { id: number }) => a.id !== ex.id
                        )
                      );
                    }}>x</Button></TableCell>
                  </TableRow>
                )
                )
              }
               {
                newAccounts.length !== 0 &&
                <TableRow>
                <TableCell><strong>{strings.newAccounts}: </strong></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                </TableRow>
              }
              {
                newAccounts.length !== 0 && newAccounts.map((newAcc: any) =>
                (
                  <TableRow
                    hover={true}
                    key={newAcc.id}
                  >
                    <TableCell sx={{ fontSize: 13 }}>
                      {newAcc.email !== undefined && newAcc.email}
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell><Button variant="contained" style={{ padding: "1%" }} onClick={() => {
                      setNewAccounts(
                        newAccounts?.filter(
                          (a: { id: number }) => a.id !== newAcc.id
                        )
                      );
                    }}>x</Button></TableCell>
                  </TableRow>
                )
                )
              }
               {
                checkedAccounts.length !== 0 &&
                <TableRow>
                <TableCell><strong>{strings.existingAccounts}: </strong></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                </TableRow>
              }
              {
                checkedAccounts.length === 0 && existingAccounts.length === 0 && newAccounts.length === 0 ? (
                  <TableRow>
                    <TableCell><span style={{ color: "gray" }}>{strings.emptyTableMessage}</span></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                )
                  :
                  checkedAccounts?.map((e: any, index: number) =>
                      <TableRow
                        hover={true}
                        key={e.id}>
                        <TableCell>
                          {e.email !== undefined && e.email}
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell><Button variant="contained" style={{ padding: "1%" }} onClick={() => {
                          setCheckedAccounts(
                            checkedAccounts?.filter(
                              (a: { id: number }) => a.id !== e.id
                            )
                          );
                        }}>x</Button></TableCell>
                        </TableRow>
                  )
              }
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <br/>
      <div className="d-flex justify-content-evenly">
              {" "}
                {!isAddMode ?
                <Button
                style={{width:"40%"}}
                className="mt-3"
                variant={"contained"}
                color={"error"}
                onClick={handleClickOpen}
              >
                {strings.delete}
              </Button>
                    :
                 <Button
                    variant="contained"
                    style={{width:"40%"}}
                    onClick={handleCancel}
                    color={"warning"}
                    className="mt-3"
                    fullWidth
                 >
                    {strings.cancel}
                </Button>
                }
                    <Button
                        type="submit"
                        onClick={() => {
                            onSubmit();
                        }}
                        fullWidth
                        style={{width:"40%"}}
                        variant="contained"
                        className="mt-3"
                    >
                        {isAddMode ? strings.addAccountGroup : strings.save}
                    </Button>
            </div>
          </CenteredCardLayout>
        }
      />
      {
        !isAddMode &&
        <>
          <ConfirmationModal
          open={openModal}
          handleClose={handleClose}
          handleConfirm={deleteAccountGroup}
          title={strings.deleteAccountGroup}
          content={strings.deleteAccountGroupConsent}
          actionButtonName={strings.deleteAccountGroup}/>
          <AccountGroupsScheduleTable/>
        </>

      }

    </>
  );
}
