import { ServicesService } from "../../services/ServicesService";
import React, { useEffect, useState } from "react";
import { ServiceDTO } from "../../models/ServiceDTO";
import {
    Autocomplete,
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    Input,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { NumberParser } from "../../utils/NumberParser";
import { strings } from "../../localization/Localization";
import { RegionService } from "../../services/RegionService";
import { RegionDTO } from "../../models/RegionDTO";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { Chart } from "react-google-charts";
import { ClientDashboardService } from "../../services/ClientDashboardService";
import { DateTimeUtils } from "../../utils/DateTimeUtils";
import { StorageService } from "../../storage/StorageService";
import { SideBarPage } from "../../components/SideBarPage";
import { ViewsCountPerDayDTO } from "../../models/ViewsCountPerDayDTO";
import { ListenedArtistClientInfoDTO } from "../../models/ListenedArtistClientInfoDTO";
import { ArtistNameDTO } from "../../models/ArtistNameDTO";

export function ComparingViews() {
    const d = new Date();
    const dd = new Date();
    dd.setDate(dd.getDate() - 10);

    const [listenedArtistInfo, setListenedArtistInfo] = useState<ListenedArtistClientInfoDTO[]>();
    const [services, setServices] = useState<ServiceDTO[] | undefined>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const service = NumberParser.parseNumberNullable(searchParams.get("serviceId"));
    const [regions, setRegions] = useState<RegionDTO[]>([]);
    const [selectedRegionsString, setSelectedRegionsString] = useState<string>("");
    const [dateToValue, setDateToValue] = useState<Dayjs | null>(dayjs(d));
    const [dateFromValue, setDateFromValue] = useState<Dayjs | null>(dayjs(dd));
    const page = NumberParser.parseNumber(searchParams.get("page"), 0);
    const [totalElements, setTotalElements] = useState(0);
    const rowsPerPage = NumberParser.parseNumber(searchParams.get("rowsPerPage"), 25);
    const [checked, setChecked] = useState<ListenedArtistClientInfoDTO[]>([]);
    const trackName = searchParams.get("trackName") ?? "";
    const artistName = searchParams.get("artistName") ?? "";
    const [artistNameDTO, setArtistNameDTO] = useState<ArtistNameDTO | {}>({})
    const [viewsCountPerDayDTO, setViewsCountPerDayDTO] = useState<ViewsCountPerDayDTO[]>([]);
    let id = StorageService.getJWTPayload()?.clientId;
    const [listenedArtistsCounts, setListenedArtistsCounts] = useState<number | undefined>(undefined);
    const [scrapedViewsCountPerDay, setScrapedViewsCountPerDay] = useState<ViewsCountPerDayDTO[]>([]);
    const [scrapedViewsCount, setScrapedViewsCount] = useState<number | undefined>(undefined);
    const [sameAxis, setSameAxis] = useState<boolean>(true);
    const [sameAxisUniqueListeners, setSameAxisUniqueListeners] = useState<boolean>(true);

    useEffect(() => {

        async function getListenedArtistClientInfo(page: number): Promise<void> {
            await ClientDashboardService.getListenedArtistClientInfo(page, rowsPerPage, id, service, selectedRegionsString, DateTimeUtils.formatDateDayJs(dateFromValue), DateTimeUtils.formatDateDayJs(dateToValue), artistName).then((data) => {
                setListenedArtistInfo(data.content);
                setTotalElements(data.totalElements);
            })
        }
        async function getAllServices(): Promise<void> {
            const services = await ServicesService.getAllServices();
            setServices(services);
            if (!service && (services?.length ?? 0) > 0) {
                setService(services[0].id.toString());
            }
        }
        async function getListenedArtistsCountingView() {
            await ClientDashboardService.getListenedArtistsCountingView(id, service, selectedRegionsString, DateTimeUtils.formatDateDayJs(dateFromValue),
                DateTimeUtils.formatDateDayJs(dateToValue), artistNameDTO!).then((data) => {
                    setListenedArtistsCounts(data);
                })
        }

        async function getArtistsViewsCountPerDay() {
            await ClientDashboardService.getArtistsViewsCountPerDay(id, service, selectedRegionsString, DateTimeUtils.formatDateDayJs(dateFromValue),
                DateTimeUtils.formatDateDayJs(dateToValue), artistNameDTO).then((data) => {
                    setViewsCountPerDayDTO(data);
                })
        }

        async function getScrapedViewsCountPerDay() {
            const data = await ClientDashboardService.getScrapedViewsCountPerDay(id, service, selectedRegionsString, DateTimeUtils.formatDateDayJs(dateFromValue),
                DateTimeUtils.formatDateDayJs(dateToValue));

            setScrapedViewsCountPerDay(data);
        }

        async function getTotalScrapedViewsCount() {
            const totalViews = await ClientDashboardService.getTotalScrapedViewsCount(id, service, selectedRegionsString, DateTimeUtils.formatDateDayJs(dateFromValue),
                DateTimeUtils.formatDateDayJs(dateToValue));

            setScrapedViewsCount(totalViews);
        }

        getAllServices();
        if (StorageService.getJWTPayload()?.clientId) {
            getListenedArtistClientInfo(page).then(_ => { });
            getListenedArtistsCountingView().then(_ => { });
            getArtistsViewsCountPerDay().then(_ => { });
            getScrapedViewsCountPerDay().then(_ => { });
            getTotalScrapedViewsCount().then(_ => { });
        }
    }, [service, dateFromValue, dateToValue, selectedRegionsString, trackName, artistName, page, rowsPerPage, artistNameDTO])


    function setPage(page: number) {
        setSearchParam("page", page.toString());
    }

    function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("rowsPerPage", event.target.value);
        setPage(0);
    }

    function createRegionIdsString(regionDTOs: RegionDTO[]) {
        let regionIdsString = "";
        for (let i = 0; i < regionDTOs.length; i++) {
            if (i === regionDTOs.length - 1) {
                regionIdsString += regionDTOs[i].id;
            } else {
                regionIdsString += regionDTOs[i].id + ",";
            }
        }

        setSelectedRegionsString(regionIdsString)
    }

    function setSearchParam(key: string, value: string | null | undefined) {
        if (value) {
            searchParams.set(key, value);
        } else {
            searchParams.delete(key);
        }

        setSearchParams(searchParams)
    }

    const handleServiceChange = (event: SelectChangeEvent) => {
        setService(event.target.value)
    };

    function setService(value: string | null): void {
        setSearchParam("serviceId", value);
    }

    const handleDateFromChange = (newValue: Dayjs | null) => {
        setDateFromValue(newValue);
    };

    const handleDateToChange = (newValue: Dayjs | null) => {
        setDateToValue(newValue);
    };

    useEffect(() => {
        let artistNameList = checked.map((t) => {
            return t.artist + ""
        });
        let artistNameDTO = new ArtistNameDTO(artistNameList);
        setArtistNameDTO(artistNameDTO);

        async function getAllRegions(): Promise<void> {
            const regions = await RegionService.getRegions();
            setRegions(regions);
        }
        async function loadData(): Promise<void> {
            await getAllRegions();
        }

        loadData().then((_) => {
        });

    }, [selectedRegionsString, checked])

    const sameAxisOptions = {
        chart: {
            title: strings.views,
        },
        hAxis: { title: strings.date, titleTextStyle: { color: "#333" } },
        vAxis: { minValue: 0, format: '#' },
    };

    const differentAxisOptions = {
        chart: {
            title: strings.views,
        },
        series: {
            0: { axis: "viewsBot" },
            1: { axis: "viewsActual" },
        },
        axes: {
            y: {
                viewsBot: { label: strings.viewsBot },
                viewsActual: { label: strings.viewsActual },
            }
        }
    };

    function handleArtistNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("artistName", event.target.value);
        setPage(0);
    }

    function isChecked(track: ListenedArtistClientInfoDTO): boolean {
        return checked.find((item) => item.artistEquals(track)) !== undefined;
    }

    function onSameAxisChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSameAxis(event.target.checked);
    }

    function onSameAxisUniqueListenersChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSameAxisUniqueListeners(event.target.checked);
    }

    function getChartData() {
        const data = [];

        const elements = Math.max(viewsCountPerDayDTO.length, scrapedViewsCountPerDay.length);

        for (let idx = 0; idx < elements; ++idx) {
            const botViews = idx < viewsCountPerDayDTO.length ? viewsCountPerDayDTO[idx] : undefined;
            const scrapedViews = idx < scrapedViewsCountPerDay.length ? scrapedViewsCountPerDay[idx] : undefined;

            data.push([botViews?.date ?? scrapedViews?.date, botViews?.viewCount ?? 0, scrapedViews?.viewCount ?? 0]);
        }

        return data;
    }

    function getUniqueListenersChartData() {
        const data = [];

        const elements = Math.max(viewsCountPerDayDTO.length, scrapedViewsCountPerDay.length);

        for (let idx = 0; idx < elements; ++idx) {
            const botViews = idx < viewsCountPerDayDTO.length ? viewsCountPerDayDTO[idx] : undefined;
            const scrapedViews = idx < scrapedViewsCountPerDay.length ? scrapedViewsCountPerDay[idx] : undefined;

            data.push([botViews?.date ?? scrapedViews?.date, botViews?.uniqueListeners ?? 0, scrapedViews?.uniqueListeners ?? 0]);
        }

        return data;
    }

    function getUniqueListenersChartOptions() {
        if (sameAxisUniqueListeners) {
            return {
                chart: {
                    title: strings.uniqueListeners,
                },
                hAxis: { title: strings.date, titleTextStyle: { color: "#333" } },
                vAxis: { minValue: 0, format: '#' },
            };
        } else {
            return {
                chart: {
                    title: strings.uniqueListeners,
                },
                series: {
                    0: { axis: "uniqueListenersBot" },
                    1: { axis: "uniqueListenersActual" },
                },
                axes: {
                    y: {
                        uniqueListenersBot: { label: strings.uniqueListenersBot },
                        uniqueListenersActual: { label: strings.uniqueListenersActual },
                    }
                }
            };
        }
    }

    return (
        <SideBarPage pageTitle={strings.comparingViewsTab} component={
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid className={"container-fluid"} style={{ display: "flex", flexDirection: "row" }} columns={16}>
                    <Grid item xs={10} marginRight={5} style={{ borderStyle: "solid", borderRadius: 5, borderColor: "#e9ecef" }}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Service</InputLabel>
                            <Select
                                value={services!.length > 0 && service ? String(service) : ""}
                                label={strings.service}
                                onChange={handleServiceChange}
                            >
                                {services?.map((ser) => (
                                    <MenuItem key={ser.id} value={ser.id}>
                                        {ser.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={10} marginRight={5} style={{ borderStyle: "solid", borderRadius: 5, borderColor: "#e9ecef" }}>
                        <Autocomplete
                            multiple
                            id="tags-outlined"
                            options={regions}
                            getOptionLabel={(option) => option.label}
                            filterSelectedOptions
                            onChange={(event, value) => createRegionIdsString(value)} // prints the selected value
                            getOptionDisabled={(option) => !!option.disabled}
                            isOptionEqualToValue={(option, value) => option.label === value.label}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={strings.region}
                                    placeholder={strings.region}
                                    id={"regions"}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={10} marginRight={5} style={{ borderStyle: "solid", borderWidth: 2, borderRadius: 5, borderColor: "#e9ecef" }}>
                        <label>{strings.totalViewsExternal}</label>
                        <br />
                        <label style={{ fontSize: "20px", color: "#505f4e" }}>{scrapedViewsCount}</label>
                    </Grid>
                    <Grid item xs={10} marginRight={5} style={{ borderStyle: "solid", borderWidth: 2, borderRadius: 5, borderColor: "#e9ecef" }}>
                        <label>{strings.totalViewsInternal}</label>
                        <br />
                        <label style={{ fontSize: "20px", color: "#505f4e" }}>{listenedArtistsCounts}</label>
                    </Grid>
                </Grid>
                <Grid className={"container-fluid mt-4 justify-content-center"} style={{ display: "flex", flexDirection: "row" }} columns={8}>
                    <Grid item xs={3} marginRight={5} minWidth={"auto"}>
                    </Grid>
                    <Grid item xs={3} marginRight={5} style={{ borderStyle: "solid", borderWidth: 2, borderRadius: 5, borderColor: "#e9ecef" }}>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                onChange={handleDateFromChange}
                                value={dateFromValue}
                                label={strings.dateFrom}
                                inputFormat="DD.MM.YYYY"
                                renderInput={(params: any) => (
                                    <TextField
                                        {...params}
                                        onKeyDown={(e) => e.preventDefault()}
                                        fullWidth
                                    />
                                )}
                                componentsProps={{
                                    actionBar: {
                                        actions: ["today"],
                                    },
                                }}
                                InputAdornmentProps={{
                                    position: "start",
                                }}
                            />
                        </LocalizationProvider>

                    </Grid>
                    <Grid item xs={3} marginRight={5} style={{ borderStyle: "solid", borderWidth: 2, borderRadius: 5, borderColor: "#e9ecef" }} minWidth={"auto"}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                onChange={handleDateToChange}
                                value={dateToValue}
                                label={strings.dateTo}
                                inputFormat="DD.MM.YYYY"
                                renderInput={(params: any) => (
                                    <TextField
                                        {...params}
                                        onKeyDown={(e) => e.preventDefault()}
                                        fullWidth
                                    />
                                )}
                                componentsProps={{
                                    actionBar: {
                                        actions: ["today"],
                                    },
                                }}
                                InputAdornmentProps={{
                                    position: "start",
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={3} marginRight={5} minWidth={"auto"}>
                    </Grid>
                </Grid>
                <Grid className={"container-fluid mt-4"} style={{ display: "flex", flexDirection: "row" }} columns={16}>
                    <Box className={"tableCSS"} style={{ width: "100%" }}>
                        {/*<div className={"mt-3"}>*/}
                        <TableContainer component={Paper}>
                            <Table aria-label="custom pagination table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell style={{ width: "30%" }}>
                                            <Input
                                                name="artistName"
                                                value={artistName}
                                                onChange={handleArtistNameChange}
                                                placeholder={strings.artistNameFilter}
                                                style={{ width: "auto" }}
                                            />
                                        </TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                        </TableCell>
                                        <TableCell>{strings.artist}</TableCell>
                                        <TableCell>{strings.viewsCount}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {listenedArtistInfo?.map((lti, index) => (
                                        <TableRow
                                            key={lti.artist! + index}
                                            hover={true}
                                            className="cursor-pointer"
                                        >
                                            <TableCell className="truncate-table-cell" width={"20%"}>
                                                <Checkbox
                                                    checked={isChecked(lti)}
                                                    onClick={() => {
                                                        if (isChecked(lti)) {
                                                            setChecked(checked.filter((item) => !item.artistEquals(lti)));
                                                        } else {
                                                            setChecked([
                                                                ...checked,
                                                                lti,
                                                            ]);
                                                        }
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>{lti?.artist}</TableCell>
                                            <TableCell>
                                                {lti?.totalViews}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {/*</div>*/}
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={totalElements}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            sx={{
                                ".MuiTablePagination-displayedRows": {
                                    marginTop: "15px"
                                }
                            }}
                        />
                    </Box>
                </Grid>
                <Grid className={"container-fluid mt-4"} style={{ display: "flex", flexDirection: "row" }} columns={16}>
                    <Chart
                        chartType="Line"
                        width={"100%"}
                        height="400px"
                        data={[
                            [strings.date, strings.viewsBot, strings.viewsActual],
                            ...getChartData(),
                        ]}
                        options={sameAxis ? sameAxisOptions : differentAxisOptions}
                    />
                    <FormControlLabel
                        control={<Switch checked={sameAxis} onChange={onSameAxisChange} />}
                        label={strings.sameAxis}
                    />
                </Grid>
                <Grid className={"container-fluid mt-4"} style={{ display: "flex", flexDirection: "row" }} columns={16}>
                    <Chart
                        chartType="Line"
                        width={"100%"}
                        height="400px"
                        data={[
                            [strings.date, strings.uniqueListenersBot, strings.uniqueListenersActual],
                            ...getUniqueListenersChartData(),
                        ]}
                        options={getUniqueListenersChartOptions()}
                    />
                    <FormControlLabel
                        control={<Switch checked={sameAxisUniqueListeners} onChange={onSameAxisUniqueListenersChange} />}
                        label={strings.sameAxis}
                    />
                </Grid>
            </Grid>
        } />
    )
}
