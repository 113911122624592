export class AccountFilter {
    email?: string | null | undefined;
    serviceId: number | null | undefined;
    parentEmail: string | undefined;
    regionId?: number | string | null | undefined;
    premium: boolean | undefined;
    profile: boolean | undefined;
    ranSessions: boolean | undefined;
    accountGroupId?: number;
    dateCreated?:string | null | undefined;
    dateModified?: string | null | undefined
    created?: boolean;

    constructor(email: string | null | undefined, serviceId: number | null | undefined, parentEmail?: string | undefined, regionId?: number | string | undefined | null, premium?: boolean | undefined, profile?: boolean | undefined, ranSessions?: boolean | undefined, accountGroupId?: number | undefined, dateCreated?:string | null | undefined, dateModified?: string | null | undefined, created?: boolean) {
        this.email = email;
        this.serviceId = serviceId;
        this.parentEmail = parentEmail;
        this.regionId = regionId;
        this.premium = premium;
        this.profile = profile;
        this.ranSessions = ranSessions;
        this.accountGroupId = accountGroupId;
        this.dateCreated = dateCreated;
        this.dateModified = dateModified;
        this.created = created;
    }
}
