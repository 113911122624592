import React, {useEffect, useState} from "react";
import {strings} from "../../localization/Localization";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import {
    FormControl, Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField
} from "@mui/material";
import {Box} from "@mui/system";
import {SideBarPage} from "../../components/SideBarPage"
import {AccountService} from "../../services/AccountService";
import {AccountDTO} from "../../models/AccountDTO";
import {AccountFilter} from "../../models/filters/AccountFilter";
import {Routes} from "../../router/Routes";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {ServicesService} from "../../services/ServicesService";
import IconButton from "@mui/material/IconButton";
import {ServiceDTO} from "../../models/ServiceDTO";
import {Clear} from "@mui/icons-material";
import {NumberParser} from "../../utils/NumberParser";
import {SortingConfiguration} from "../../utils/SortingUtils";
import {TableHeader} from "../../components/TableHeader";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import {NullableCheckbox} from "../../components/NullableCheckbox";
import {BooleanParser} from "../../utils/BooleanParser";
import {PaginatedSelect} from "../../components/PaginatedSelect";
import {AccountGroupDTO} from "../../models/AccountGroupDTO";
import {AccountGroupService} from "../../services/AccountGroupService";
import {PaginationPlus} from "../../components/PaginationPlus";
import {Dayjs} from "dayjs";
import {DateTimeUtils} from "../../utils/DateTimeUtils";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {BotPlatformIcon} from "../../components/BotPlatformIcon";
import {RegionDTO} from "../../models/RegionDTO";
import {RegionService} from "../../services/RegionService";
import {guardedObject} from "../../router/guards/GuardedCallback";
import {PrivilegeGuard, PrivilegeGuardMode} from "../../router/guards/PrivilegeGuard";
import {Privileges} from "../../models/nomenclatures/Privileges";

export function ProblematicAccounts() {
    const columns = [
        {label: strings.email, sortBy: "email", sortable: false},
        {label: strings.service, sortBy: "service.label", sortable: false},
        {label: strings.accountGroup, sortBy: "account_group.name", sortable: false},
        {label: strings.ageGroup, sortBy: "age_group.label", sortable: false},
        {label: strings.region, sortBy: "region.label", sortable: false},
        {label: strings.dateCreated, sortBy: "date_created", sortable: false},
        {label: strings.dateModified, sortBy: "date_modified", sortable: false},
        {label: strings.premium, sortBy: "premium", sortable: false},
        {label: strings.workingCookies, sortBy: "workingCookies", sortable: false},
    ];
    const listLinks = [
        {label: strings.dashboard, currentlyOpened: false, href: Routes.HOME},
        {label: strings.problematicAccounts, currentlyOpened: true},
    ]
    const [services, setServices] = useState<ServiceDTO[] | undefined>([]);
    const [regions, setRegions] = useState<RegionDTO[]>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const email = searchParams.get("email") ?? "";
    const service = NumberParser.parseNumberNullable(searchParams.get("serviceId"));
    const page = NumberParser.parseNumber(searchParams.get("page"), 0);
    const [totalElements, setTotalElements] = useState(0);
    const rowsPerPage = NumberParser.parseNumber(searchParams.get("rowsPerPage"), 25);
    const premium = BooleanParser.parseBooleanNullable(searchParams.get("premium"));
    const dateCreated = searchParams.get("dateCreated") ?? null;
    const dateModified = searchParams.get("dateModified") ?? null;
    const [accounts, setAccounts] = useState<AccountDTO[]>([]);
    const [accountGroup, setAccountGroup] = useState<AccountGroupDTO | undefined>(undefined);
    const sortingConfigurationString = searchParams.get("sort");
    const regionId = NumberParser.parseNumberNullable(searchParams.get("regionId"));

    const navigate = useNavigate();

    function setSearchParam(key: string, value: string | null | undefined) {
        if (value) {
            searchParams.set(key, value);
        } else {
            searchParams.delete(key);
        }

        setSearchParams(searchParams)
    }

    useEffect(() => {
        function getAccounts(page: number) {
            AccountService.getAllAccountsPaged(page, rowsPerPage, new AccountFilter(email, service, undefined, regionId, premium, false, true, accountGroup?.id,dateCreated, dateModified), SortingConfiguration.fromSearchString(sortingConfigurationString)).then((data) => {
                setAccounts(data.content);
                setTotalElements(data.totalElements);
            })
        }

        getAccounts(page);
    }, [page, rowsPerPage, email, service, sortingConfigurationString, premium, accountGroup,dateCreated, dateModified, regionId]);

    function setPage(page: number) {
        setSearchParam("page", page.toString());
    }

    function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("rowsPerPage", event.target.value);
        setPage(0);
    }

    function handleEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("email", event.target.value);
        setPage(0);
    }

    function handleChangeDateCreated(date: Dayjs | null) {
        setSearchParam("dateCreated", DateTimeUtils.formatDateDayJs(date));
        setPage(0);
    }

    function handleChangeDateModified(date: Dayjs | null) {
        setSearchParam("dateModified", DateTimeUtils.formatDateDayJs(date));
        setPage(0);
    }

    function setService(value: string): void {
        setSearchParam("serviceId", value);
        setPage(0);
    }

    const handleServicesChange = (event: SelectChangeEvent) => {
        setService(event.target.value);
        setPage(0);
    };

    function setRegion(value: string): void {
        setSearchParam("regionId", value);
        setPage(0);
    }

    function handleRegionChange(event: SelectChangeEvent) {
        setRegion(event.target.value);
        setPage(0);
    }

    function navigateAccountRow(id: number): any {
        navigate(Routes.PROBLEMATIC_ACCOUNT_DETAILS.replace(":id", id.toString()));
    }

    useEffect(() => {
        async function getAllServices() {
            const data = await ServicesService.getAllServices();
            setServices(data);
        }

        async function getAllRegions() {
            const regions = await RegionService.getRegions();
            setRegions(regions);
        }

        async function loadData() {
            await getAllServices();
            await getAllRegions();
        }

        loadData().then(_ => {});
    }, []);


    function updateSort(sortingConfiguration: SortingConfiguration): void {
        setSearchParam("sort", sortingConfiguration.toSearchString());
    }

    function setPremium(value: boolean | undefined): void {
        setSearchParam("premium", value?.toString() ?? "");
        setPage(0);
    }

    function handleAccountGroupChange(value: AccountGroupDTO | undefined): void {
        setAccountGroup(value);
        setPage(0);
    }

    function buildAccountGroupComponent(accountGroup?: AccountGroupDTO) {
        if (accountGroup) {
            return (
                <>
                    {accountGroup.name} <BotPlatformIcon botPlatform={accountGroup.botPlatform}/>
                </>
            )
        }

        return (
            " "
        );
    }

    function getRowLink(entry: AccountDTO): string {
        return guardedObject([
            new PrivilegeGuard(
                [Privileges.MANAGE_ACCOUNT],
                PrivilegeGuardMode.hasAll
            )
        ], Routes.PROBLEMATIC_ACCOUNT_DETAILS.replace(":id", entry.id.toString())) ?? "";
    }

    return (
        <>
            <SideBarPage
                pageTitle={strings.problematicAccounts}
                breadcrumbs={listLinks}
                component={
                    <Box>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 500}} aria-label="custom pagination table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <TextField
                                                name="email"
                                                value={email}
                                                variant="standard"
                                                label={strings.email}
                                                onChange={handleEmailChange}
                                                fullWidth
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <FormControl className={"w-100"} fullWidth variant="standard">
                                                <InputLabel id="demo-simple-select-label">{strings.service}</InputLabel>
                                                <Select
                                                    defaultValue={""}
                                                    value={services && services.length > 0 ? service?.toString() ?? "" : ""}
                                                    onChange={handleServicesChange}
                                                    label={strings.service}
                                                    labelId="serviceLabel"
                                                    endAdornment={
                                                        service && (
                                                            <IconButton
                                                                onClick={() =>
                                                                    setService("")
                                                                }
                                                            >
                                                                <Clear/>
                                                            </IconButton>
                                                        )
                                                    }
                                                >
                                                    {services?.map((service) => (
                                                        <MenuItem disableRipple key={service.id} value={service.id}>
                                                            {service.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>

                                            </FormControl>
                                        </TableCell>
                                        <TableCell>
                                            <PaginatedSelect<AccountGroupDTO>
                                                value={accountGroup}
                                                label={strings.accountGroup}
                                                valueMapper={item => item.id.toString()}
                                                keyMapper={item => item.id.toString()}
                                                itemMapper={item => <>{item.name}</>}
                                                labelMapper={item => item.name}
                                                dataFetcher={(page, size, filter) => AccountGroupService.getAllAccountGroupsPaged(page, size, filter, service)}
                                                onChange={handleAccountGroupChange}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            {" "}
                                            <FormGroup>
                                                <FormControlLabel
                                                    label={strings.premium}
                                                    control={
                                                        <NullableCheckbox
                                                            value={premium}
                                                            onValueChanged={setPremium}
                                                        />
                                                    }
                                                />
                                            </FormGroup>
                                        </TableCell>
                                        <TableCell>
                                            <FormControl className={"w-100"} fullWidth variant="standard">
                                                <InputLabel id="demo-simple-select-label">{strings.region}</InputLabel>
                                                <Select
                                                    defaultValue={""}
                                                    value={regions && regions.length > 0 ? regionId?.toString() ?? "" : ""}
                                                    onChange={handleRegionChange}
                                                    label={strings.region}
                                                    labelId="regionLabel"
                                                    endAdornment={
                                                        service && (
                                                            <IconButton
                                                                onClick={() =>
                                                                    setRegion("")
                                                                }
                                                            >
                                                                <Clear/>
                                                            </IconButton>
                                                        )
                                                    }
                                                >
                                                    {regions.map((region) => (
                                                        <MenuItem disableRipple key={region.id} value={region.id}>
                                                            {region.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>

                                            </FormControl>
                                        </TableCell>
                                        <TableCell>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    onChange={handleChangeDateCreated}
                                                    value={dateCreated}
                                                    label={strings.dateCreated}
                                                    inputFormat="DD.MM.YYYY"
                                                    renderInput={(params: any) => (
                                                        <TextField
                                                            {...params}
                                                            variant="standard"
                                                            onKeyDown={(e)=>e.preventDefault()}
                                                        />
                                                    )}
                                                    componentsProps={{
                                                        actionBar: {
                                                            actions: ["today"],
                                                        },
                                                    }}
                                                    InputProps={{
                                                        endAdornment: dateCreated && (
                                                            <IconButton sx={{height: "10px", width: "10px"}}
                                                                        onClick={() => {
                                                                            handleChangeDateCreated(null);
                                                                            setPage(0);
                                                                        }}>
                                                                <Clear/>
                                                            </IconButton>
                                                        ),
                                                    }}
                                                    InputAdornmentProps={{
                                                        position: "start",
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        </TableCell>
                                        <TableCell>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    onChange={handleChangeDateModified}
                                                    value={dateModified}
                                                    label={strings.dateModified}
                                                    inputFormat="DD.MM.YYYY"
                                                    renderInput={(params: any) => (
                                                        <TextField
                                                            {...params}
                                                            variant="standard"
                                                            onKeyDown={(e)=>e.preventDefault()}
                                                        />
                                                    )}
                                                    componentsProps={{
                                                        actionBar: {
                                                            actions: ["today"],
                                                        },
                                                    }}
                                                    InputProps={{
                                                        endAdornment: dateModified && (
                                                            <IconButton sx={{height: "10px", width: "10px"}}
                                                                        onClick={() => {
                                                                            handleChangeDateModified(null);
                                                                            setPage(0);
                                                                        }}>
                                                                <Clear/>
                                                            </IconButton>
                                                        ),
                                                    }}
                                                    InputAdornmentProps={{
                                                        position: "start",
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        </TableCell>
                                        <TableCell/>
                                        <TableCell/>
                                        <TableCell/>
                                    </TableRow>
                                    <TableHeader columns={columns} sortConfigurationString={sortingConfigurationString}
                                                 updateSort={updateSort}></TableHeader>
                                </TableHead>
                                <TableBody>
                                    {accounts?.map((e: AccountDTO) => (
                                        <TableRow
                                            key={e.id}
                                            hover={true}
                                            component={getRowLink(e) ? Link : TableRow} to={getRowLink(e)}
                                            className={"cursor-pointer"}>
                                            <TableCell sx={{width: '15%'}}>{e.email}</TableCell>
                                            <TableCell sx={{width: '15%'}}>{e.service ? e.service.label : " "}</TableCell>
                                            <TableCell sx={{width: '10%'}}>{buildAccountGroupComponent(e.accountGroup)}</TableCell>
                                            <TableCell sx={{width: '10%'}}>{e.ageGroup ? e.ageGroup.label : " "} </TableCell>
                                            <TableCell sx={{width: '12%'}}>{e.region ? e.region.label : " "}</TableCell>
                                            <TableCell sx={{width: '18%'}}>{DateTimeUtils.formatDate(e.dateCreated)}</TableCell>
                                            <TableCell sx={{width: '18%'}}>{DateTimeUtils.formatDate(e.dateModified)}</TableCell>
                                            <TableCell sx={{width: '10%'}}
                                                       onClick={() => navigateAccountRow(e.id)}>{(e.latestStatus?.premium) ?
                                                <Box>
                                                    <div style={{maxWidth: "65px"}}>
                                                        <CheckCircleIcon
                                                            color="success"
                                                            sx={{fontSize: "65px", width: "50px"}}/>
                                                    </div>
                                                </Box>
                                                : (!(e.latestStatus?.premium)) ?
                                                    <Box>
                                                        <div style={{maxWidth: "65px"}}>
                                                            <CancelIcon
                                                                color="warning"
                                                                sx={{fontSize: "65px", width: "50px"}}/>
                                                        </div>
                                                    </Box>
                                                    : (e.latestStatus === null) ?
                                                        <Box>
                                                            <div style={{maxWidth: "65px"}}>
                                                            </div>
                                                        </Box> : null
                                            }
                                            </TableCell>
                                            <TableCell sx={{width: '10%'}}
                                                       onClick={() => navigateAccountRow(e.id)}>{(e.latestStatus?.profile) ?
                                                <Box>
                                                    <div style={{maxWidth: "65px"}}>
                                                        <CheckCircleIcon
                                                            color="success"
                                                            sx={{fontSize: "65px", width: "50px"}}/>
                                                    </div>
                                                </Box>
                                                : (!(e.latestStatus?.profile)) ?
                                                    <Box>
                                                        <div style={{maxWidth: "65px"}}>
                                                            <CancelIcon
                                                                color="error"
                                                                sx={{fontSize: "65px", width: "50px"}}/>
                                                        </div>
                                                    </Box>
                                                    : (e.latestStatus === null) ?
                                                        <Box>
                                                            <div style={{maxWidth: "65px"}}>
                                                            </div>
                                                        </Box> : null
                                            }</TableCell>

                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Grid sx={{display: 'flex', flexDirection: 'column', alignItems: 'end', marginTop: "10px"}}>
                            <PaginationPlus totalElements={totalElements} rowsPerPage={rowsPerPage} page={page} onChangePage={(page) => handleChangePage(null, page)}/>
                            <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={totalElements <= 0 ? 0 : totalElements}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                        </Grid>
                    </Box>
                }
            />
        </>
    );
}
