import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    Paper,
    TableCell,
    TablePagination
} from "@mui/material";
import {SideBarPage} from "../../components/SideBarPage";
import {strings} from "../../localization/Localization";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import React, {useEffect, useState} from "react";
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import DeleteIcon from '@mui/icons-material/Delete';
import {TableHeader} from "../../components/TableHeader";
import {SortingConfiguration} from "../../utils/SortingUtils";
import {useSearchParams} from "react-router-dom";
import {NumberParser} from "../../utils/NumberParser";
import {AccountGroupService} from "../../services/AccountGroupService";
import {AccountGroupDTO} from "../../models/AccountGroupDTO";
import {RegionService} from "../../services/RegionService";
import {RegionDTO} from "../../models/RegionDTO";
import {AccountService} from "../../services/AccountService";
import {AccountFilter} from "../../models/filters/AccountFilter";
import {BooleanParser} from "../../utils/BooleanParser";
import {AccountDTO} from "../../models/AccountDTO";
import {PaginationPlus} from "../../components/PaginationPlus";
import {defaultRowsPerPageOptions} from "../../common/Constants";
import {PaginatedSelect} from "../../components/PaginatedSelect";
import {NullableDatePicker} from "../../components/NullableDatePicker";
import {Dayjs} from "dayjs";
import {DateTimeUtils} from "../../utils/DateTimeUtils";
import {AccountDeletionDTO} from "../../models/AccountDeletionDTO";
import ConfirmationModal from "../../components/ConfirmationModal";
import {showErrorDialog, showSuccessDialog} from "../../common/Dialogs";
import {ErrorHandler} from "../../utils/ErrorHandler";
import {AccountSwitchDTO} from "../../models/AccountSwitchDTO";
import {ProxyGroupReducedDTO} from "../../models/ProxyGroupReducedDTO";
import {ProxyGroupService} from "../../services/ProxyGroupService";
import {AccountTransferDTO} from "../../models/AccountTransferDTO";
import Input from "@mui/material/Input";

export function AccountManagement() {
    const listLinks = [
        { label: strings.dashboard, currentlyOpened: false, href:"/"},
        { label: strings.accountManagement,  currentlyOpened: true },
    ]
    const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());

    const columns = [
        {label: strings.email, sortBy: "email", sortable: false},
        {label: strings.region, sortBy: "region", sortable: false},
        {label: strings.dateCreated, sortBy: "dateCreated", sortable: false},
        {label: "", sortBy: "empty", sortable: false, checkbox: true},
        {label: "Selected accounts: " + selectedRows.size, sortBy: "selectedAccounts", sortable: false}
    ];
    const [searchParams, setSearchParams] = useSearchParams();

    function setSearchParam(key: string, value: string | null | undefined) {
        if (value) {
            searchParams.set(key, value);
        } else {
            searchParams.delete(key);
        }

        setSearchParams(searchParams)
    }

    const sortingConfigurationString = searchParams.get("sort");
    const page = NumberParser.parseNumber(searchParams.get("page"), 0);
    const rowsPerPage = NumberParser.parseNumber(searchParams.get("rowsPerPage"), 5); //5 for testing purpose
    const [totalElements, setTotalElements] = useState(0);
    const [accountGroup, setAccountGroup] = useState<AccountGroupDTO | undefined>(undefined)
    const [accounts, setAccounts] = useState<AccountDTO[]>([]);
    const [region, setRegion] = useState<RegionDTO>();
    const email = searchParams.get("email") ?? "";
    const service = NumberParser.parseNumberNullable(searchParams.get("serviceId"));
    const premium = BooleanParser.parseBooleanNullable(searchParams.get("premium"));
    const profile = BooleanParser.parseBooleanNullable(searchParams.get("profile"));
    const created = BooleanParser.parseBooleanNullable(searchParams.get("created"));
    const dateCreated = searchParams.get("dateCreated") ?? null;
    const dateModified = searchParams.get("dateModified") ?? null;
    const [switchGroupPopupOpen, setSwitchGroupPopupOpen] = useState<boolean>(false);
    const [transferGroupPopupOpen, setTransferGroupPopupOpen] = useState<boolean>(false);
    const [selectedAccountGroupInModal, setSelectedAccountGroupInModal] = useState<AccountGroupDTO | undefined>(undefined);
    const [selectedProxyGroupInModal, setSelectedProxyGroupInModal] = useState<ProxyGroupReducedDTO | undefined>(undefined);
    const [maxAccountsPerProxy, setMaxAccountsPerProxy] = useState<number | null>(null);
    const [maxTransferredAccounts, setMaxTransferredAccounts] = useState<number | null>(null);

    function updateSort(sortingConfiguration: SortingConfiguration): void {
        setSearchParam("sort", sortingConfiguration.toSearchString());
    }

    useEffect(() => {
        async function getAllAccountsPage(): Promise<void> {
            if(accountGroup)
            AccountService.getAllAccountsPaged(page, rowsPerPage,
                new AccountFilter(email, service, undefined, region?.id, premium, profile, undefined, accountGroup?.id, dateCreated,dateModified, created),
                SortingConfiguration.fromSearchString(sortingConfigurationString)).then((res) => {
                setAccounts(res.content);
                setTotalElements(res.totalElements);
            });
        }

        getAllAccountsPage();

    }, [page, rowsPerPage, email, accountGroup, dateCreated, dateModified, created, region, service, premium, sortingConfigurationString, profile]);

    function setPage(page: number) {
        setSearchParam("page", page.toString());
    }

    function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("rowsPerPage", event.target.value);
        setPage(0);
    }

    const handleAccountGroupFilter = (event: AccountGroupDTO | undefined) => {
        setSelectedRows(new Set())
        setPage(0)
        setAccountGroup(event);
    }

    const handleRegionFilter = (event: RegionDTO | undefined) => {
        setRegion(event);
    };

    const handleMaxAccountsPerProxyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = parseInt(event.target.value, 10);
        setMaxAccountsPerProxy(newValue);
    };

    const handleMaxTransferredAccountsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = parseInt(event.target.value, 10);
        setMaxTransferredAccounts(newValue);
    };

    function handleDateCreatedChange(date: Dayjs | null) {
        setSearchParam("dateCreated",DateTimeUtils.formatDateDayJs(date));
        setPage(0);
    }

    function handleDateModifiedChange(date: Dayjs | null) {
        setSearchParam("dateModified",DateTimeUtils.formatDateDayJs(date));
        setPage(0);
    }

    const numSelectedAccounts = selectedRows.size;
    const confirmationContent = `Are you sure you want to delete ${numSelectedAccounts} selected accounts?`;

    const handleSelectAllWrapper = (checked: boolean) => {
        // handleSelectAllClick({ target: { checked } } as React.ChangeEvent<HTMLInputElement>);
        const allAccountIds = new Set(accounts.map(account => account.id));

        if (checked) {
            // Select all rows
            const newSelectedRows = new Set([
                ...selectedRows,
                ...allAccountIds,
            ]);

            setSelectedRows(newSelectedRows);
        } else {
            // Deselect all rows
            setSelectedRows(new Set([...selectedRows].filter(id => !allAccountIds.has(id))));
        }
    };

    // Function to handle the change of an individual row's checkbox
    const handleRowCheckboxClick = (isChecked: boolean, accountId: number) => {
        let newSelectedRows = new Set(selectedRows);
        if (isChecked) {
            newSelectedRows.add(accountId);
        } else {
            newSelectedRows.delete(accountId);
        }
        setSelectedRows(newSelectedRows);
    };

    const deleteMultipleAccounts = async () => {
        try {
            // Replace with your actual API call to delete accounts
            await AccountService.deleteMultipleAccounts(new AccountDeletionDTO({
                accountIds: [...selectedRows],
            }));
            // Clear the selection and the accountsToBeDeleted array after successful deletion
            setSelectedRows(new Set());
            showSuccessDialog(strings.success, strings.successfullyDeletedUser, strings.ok).then(_ => {
                window.location.reload();
            });
            setConfirmationModalOpen(false)
        } catch (error) {
            const errorMessage = ErrorHandler.parseErrorMessage(error);
            showErrorDialog(strings.error, errorMessage, strings.ok).then(_ => {});
            // Handle errors appropriately
        }
    };

    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

    const openConfirmationModal = () => {
        setConfirmationModalOpen(true);
    };

    const closeConfirmationModal = () => {
        setConfirmationModalOpen(false);
    };

    const openSwitchGroupPopup = () => {
        setSwitchGroupPopupOpen(true);
    };

    const closeSwitchGroupPopup = () => {
        setSwitchGroupPopupOpen(false);
        setSelectedAccountGroupInModal(undefined)
    };

    const openTransferGroupPopup = () => {
        setTransferGroupPopupOpen(true);
    };

    const closeTransferGroupPopup = () => {
        setTransferGroupPopupOpen(false);
        setSelectedAccountGroupInModal(undefined)
        setSelectedProxyGroupInModal(undefined)
    }

    const switchAccounts = async () => {

        const selectedAccountIds = Array.from(selectedRows);
        // Construct the AccountSwitchDTO object
        const accountsToSwitch = new AccountSwitchDTO({
            accountIds: selectedAccountIds,
            targetAccountGroupId: selectedAccountGroupInModal ? selectedAccountGroupInModal.id : undefined
        });

        try {
            // Replace with your actual API call to delete accounts
            await AccountService.switchAccountsToAccountGroup(accountsToSwitch);
            // Clear the selection and the accountsToBeDeleted array after successful deletion
            setSelectedRows(new Set());
            showSuccessDialog(strings.success, strings.successfullySwitchedAccounts, strings.ok).then(_ => {
                window.location.reload();
            });
            setSwitchGroupPopupOpen(false)
            setSelectedAccountGroupInModal(undefined)
        } catch (error) {
            const errorMessage = ErrorHandler.parseErrorMessage(error);
            showErrorDialog(strings.error, errorMessage, strings.ok).then(_ => {});
            // Handle errors appropriately
            setSwitchGroupPopupOpen(false)
            setSelectedAccountGroupInModal(undefined)
        }
    };

    const transferAccounts = async () => {
        const parsedMaxAccountsPerProxy = maxAccountsPerProxy !== null ? +maxAccountsPerProxy : null;
        const parsedMaxTransferredAccounts = maxTransferredAccounts !== null ? +maxTransferredAccounts : null;
    
        const accountsToTransfer = new AccountTransferDTO({
            sourceAccountGroupId: accountGroup?.id,
            targetAccountGroupId: selectedAccountGroupInModal?.id,
            proxyGroupId: selectedProxyGroupInModal?.id,
            maxAccountsPerProxy: parsedMaxAccountsPerProxy,
            maxTransferredAccounts: parsedMaxTransferredAccounts
        });
        try {
            await AccountService.transferAccountsToAccountGroup(accountsToTransfer);

            setSelectedRows(new Set());
            showSuccessDialog(strings.success, strings.successfullyTransferedAccounts, strings.ok).then(_ => {
                window.location.reload();
            });
            setTransferGroupPopupOpen(false)
            setSelectedAccountGroupInModal(undefined)
            setSelectedProxyGroupInModal(undefined)

        } catch (error) {
            const errorMessage = ErrorHandler.parseErrorMessage(error);
            showErrorDialog(strings.error, errorMessage, strings.ok).then(_ => {});
            // Handle errors appropriately
            setTransferGroupPopupOpen(false)
            setSelectedAccountGroupInModal(undefined)
            setSelectedProxyGroupInModal(undefined)
        }
    };

    const handleSelectedAccountGroupFilter = (selectedGroup: AccountGroupDTO | undefined) => {
        setSelectedAccountGroupInModal(selectedGroup);
    };

    const handleSelectedProxyGroupFilter = (selectedGroup: ProxyGroupReducedDTO | undefined) => {
        setSelectedProxyGroupInModal(selectedGroup);
    };

    function getNumberOfSelectedRowsInPage() {
        return accounts.filter(account => selectedRows.has(account.id)).length;
    }

    return (
        <SideBarPage pageTitle={strings.accountManagement} breadcrumbs={listLinks} component={
            <>
                <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{maxWidth: "20%"}}>
                                <FormControl required>
                                <PaginatedSelect<AccountGroupDTO>
                                                value={accountGroup}
                                                onChange={handleAccountGroupFilter}
                                                label={strings.accountGroup}
                                                valueMapper={(item) => item.id.toString()}
                                                keyMapper={(item) => item.id.toString()}
                                                itemMapper={(item) => <>{item.name}</>}
                                                labelMapper={(item) => item.name}
                                                dataFetcher={(page, size, filter) => {
                                                    return AccountGroupService.getAllAccountGroupsPaged(page, size, filter);
                                                }}
                                                closeOnSelect={true}
                                            />
                                </FormControl>
                            </TableCell>
                            <TableCell style={{maxWidth: "20%"}}>
                                <FormControl>
                                    <PaginatedSelect<RegionDTO>
                                        value={region}
                                        onChange={handleRegionFilter}
                                        label={strings.region}
                                        valueMapper={(item) => item.id.toString()}
                                        keyMapper={(item) => item.id.toString()}
                                        itemMapper={(item) => <>{item.label}</>}
                                        labelMapper={(item) => item.label}
                                        dataFetcher={(page, size, filter) => {
                                            return RegionService.getAllRegionsPaged(page, size, filter);
                                        }}
                                        closeOnSelect={true}

                                    />
                                </FormControl>
                            </TableCell>
                            <TableCell style={{maxWidth: "20%"}}>
                                <NullableDatePicker label={strings.dateCreated} value={dateCreated} onChange={handleDateCreatedChange} />
                            </TableCell>
                            <TableCell style={{maxWidth: "20%"}}>
                                <NullableDatePicker label={strings.dateModified} value={dateCreated} onChange={handleDateModifiedChange} />
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell>
                                <Button style={{float: "right", marginLeft: "10px"}}
                                        variant="contained" disabled={selectedRows.size ===  0 || !accountGroup} onClick={openConfirmationModal}><DeleteIcon/>{strings.deleteSelected}</Button>
                                <Button style={{float: "right", marginLeft: "10px"}} variant="contained" disabled={selectedRows.size ===  0 || !accountGroup} onClick={openSwitchGroupPopup}><SwapHorizIcon/>{strings.switchGroup}</Button>
                                <Button style={{float: "right"}} variant="contained" disabled={!accountGroup} onClick={openTransferGroupPopup}><SwapHorizIcon/>{strings.transferAccounts}</Button>

                            </TableCell>
                        </TableRow>

                        <TableHeader columns={columns} sortConfigurationString={sortingConfigurationString}
                                     updateSort={updateSort} selectAll={getNumberOfSelectedRowsInPage() === accounts.length}
                                     onSelectAll={handleSelectAllWrapper}></TableHeader>
                    </TableHead>
                    {accountGroup ? <TableBody>
                        {accounts?.map((row) => (
                            <TableRow key={row.id}
                                      hover={true}
                                      className={"cursor-pointer"}
                                      onClick={() => handleRowCheckboxClick(!selectedRows.has(row.id), row.id)}
                            >
                                <TableCell>{row.email}</TableCell>
                                <TableCell>{row.region ? row.region.label : " "}</TableCell>
                                <TableCell>{DateTimeUtils.formatDate(row.dateCreated)}</TableCell>
                                <TableCell>{DateTimeUtils.formatDate(row.dateModified)}</TableCell>
                                <TableCell>
                                    <Checkbox
                                        checked={selectedRows.has(row.id)}
                                        onChange={(event) => handleRowCheckboxClick(event.target.checked, row.id)}
                                    />
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        ))}
                    </TableBody> : null}


                </Table>
            </TableContainer>
                <Grid
                sx={{display: 'flex', flexDirection: 'column', alignItems: 'end', marginTop: "10px"}}>
                <PaginationPlus totalElements={totalElements} rowsPerPage={rowsPerPage} page={page}
                                onChangePage={(page) => handleChangePage(null, page)}/>
                <TablePagination
                    rowsPerPageOptions={defaultRowsPerPageOptions}
                    component="div"
                    count={totalElements}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}/>
                <ConfirmationModal
                    open={confirmationModalOpen}
                    handleClose={closeConfirmationModal}
                    handleConfirm={deleteMultipleAccounts}
                    title={strings.deleteSelectedAccounts}
                    content={confirmationContent}
                    actionButtonName={strings.delete}
                />
                <Dialog
                    open={switchGroupPopupOpen}
                    onClose={closeSwitchGroupPopup}
                    aria-labelledby="switch-group-popup-title"
                    aria-describedby="switch-group-popup-description"
                >
                    <DialogTitle id="switch-group-popup-title">
                        {strings.switchGroupShort}
                    </DialogTitle>
                    <DialogContent>
                        <FormControl fullWidth>
                            <PaginatedSelect<AccountGroupDTO>
                                value={selectedAccountGroupInModal}
                                onChange={handleSelectedAccountGroupFilter}
                                label={strings.accountGroup}
                                valueMapper={(item) => item.id.toString()}
                                keyMapper={(item) => item.id.toString()}
                                itemMapper={(item) => <>{item.name}</>}
                                labelMapper={(item) => item.name}
                                dataFetcher={(page, size, filter) => {
                                    return AccountGroupService.getAllAccountGroupsPaged(page, size, filter, accountGroup?.service?.id);
                                }}
                                closeOnSelect={true}
                            />
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeSwitchGroupPopup}>Cancel</Button>
                        <Button onClick={switchAccounts} disabled={!selectedAccountGroupInModal} color="primary">
                            {strings.switchAccounts}
                        </Button>
                    </DialogActions>
                </Dialog>
                    <Dialog
                        open={transferGroupPopupOpen}
                        onClose={closeTransferGroupPopup}
                        aria-labelledby="switch-group-popup-title"
                        aria-describedby="switch-group-popup-description"
                    >
                        <DialogTitle id="switch-group-popup-title">
                            {strings.transferAccountsH}
                        </DialogTitle>
                        <DialogContent>
                            <FormControl fullWidth>
                                <PaginatedSelect<AccountGroupDTO>
                                    value={selectedAccountGroupInModal}
                                    onChange={handleSelectedAccountGroupFilter}
                                    label={strings.targetAccountGroup}
                                    valueMapper={(item) => item.id.toString()}
                                    keyMapper={(item) => item.id.toString()}
                                    itemMapper={(item) => <>{item.name}</>}
                                    labelMapper={(item) => item.name}
                                    dataFetcher={(page, size, filter) => {
                                        return AccountGroupService.getAllAccountGroupsPaged(page, size, filter, accountGroup?.service?.id);
                                    }}
                                    closeOnSelect={true}
                                />
                            </FormControl>
                            <FormControl fullWidth style={{marginTop:"20px"}}>
                                <PaginatedSelect<ProxyGroupReducedDTO>
                                    value={selectedProxyGroupInModal}
                                    onChange={handleSelectedProxyGroupFilter}
                                    label={strings.proxyGroup}
                                    valueMapper={(item) => item.id.toString()}
                                    keyMapper={(item) => item.id.toString()}
                                    itemMapper={(item) => <>{item.name}</>}
                                    labelMapper={(item) => item.name}
                                    dataFetcher={(page, size, filter) => {
                                        return ProxyGroupService.getAllProxyGroupsPaged(page, size, filter);
                                    }}
                                    closeOnSelect={true}
                                />
                            </FormControl>
                            <FormControl style={{marginTop: "10px", width:"200px"}} >
                                <InputLabel htmlFor="max-accounts-per-proxy">{strings.maxAccountsPerProxy}</InputLabel>
                                <Input 
                                    id = "max-accounts-per-proxy"
                                    type = "number"
                                    inputProps={{ min: "0" }}
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    onChange={handleMaxAccountsPerProxyChange}
                                />
                            </FormControl>
                            <FormControl style={{marginTop: "10px", marginLeft: "150px", width: "200px"}} >
                                <InputLabel htmlFor="max-accounts-per-proxy">{strings.maxTransferredAccounts}</InputLabel>
                                <Input 
                                    id = "max-accounts-per-proxy"
                                    type = "number"
                                    inputProps={{ min: "0" }}
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    onChange={handleMaxTransferredAccountsChange}
                                />
                            </FormControl>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeTransferGroupPopup}>Cancel</Button>
                            <Button onClick={transferAccounts} disabled={!selectedAccountGroupInModal} color="primary">
                                {strings.transferAccountsShort}
                            </Button>
                        </DialogActions>
                    </Dialog>
            </Grid>
            </>
        } />
    )
}