import {strings} from "../../localization/Localization";
import {SideBarPage} from "../../components/SideBarPage";
import {
    Grid, IconButton, Input,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow, TextField
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Link, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {PaginationPlus} from "../../components/PaginationPlus";
import {defaultRowsPerPageOptions} from "../../common/Constants";
import {NumberParser} from "../../utils/NumberParser";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {Clear} from "@mui/icons-material";
import {Dayjs} from "dayjs";
import {DateTimeUtils} from "../../utils/DateTimeUtils";
import {SortingConfiguration} from "../../utils/SortingUtils";
import {TableHeader} from "../../components/TableHeader";
import {MobilePhoneService} from "../../services/MobilePhoneService";
import {MobilePhoneStatusDTO} from "../../models/MobilePhoneStatusDTO";
import {guardedCallback, guardedObject} from "../../router/guards/GuardedCallback";
import {PrivilegeGuard, PrivilegeGuardMode} from "../../router/guards/PrivilegeGuard";
import {Privileges} from "../../models/nomenclatures/Privileges";
import {Routes} from "../../router/Routes";
import {MobileProxyStatusDTO} from "../../models/MobileProxyStatusDTO";
import {MobilePhoneDTO} from "../../models/MobilePhoneDTO";

export function MobilePhones() {
    const listLinks = [
        { label: strings.dashboard, currentlyOpened: false, href:"/"},
        { label: strings.phones,  currentlyOpened: true },
    ]
    const columns = [
        {label: strings.serialNumber, sortBy: "mb.serialNumber", sortable: true},
        {label: strings.friendlyName, sortBy: "mb.friendlyName", sortable: true},
        {label: strings.server, sortBy: "serverName", sortable: true},
        {label: strings.statusDate, sortBy: "dateModified", sortable: false},
        {label: strings.status, sortBy: "status", sortable: true},
    ];

    const [searchParams, setSearchParams] = useSearchParams();
    const sortingConfigurationString = searchParams.get("sort");

    const serialNumber = searchParams.get("serialNumber") ?? "";
    const friendlyName = searchParams.get("friendlyName") ?? "";
    const server = searchParams.get("server") ?? "";
    const status = searchParams.get("status") ?? "";
    const dateModified = searchParams.get("dateModified") ?? null;
    const [totalElements, setTotalElements] = useState(0);
    const rowsPerPage = NumberParser.parseNumber(searchParams.get("rowsPerPage"), 25);
    const page = NumberParser.parseNumber(searchParams.get("page"), 0);
    const [mobilePhones, setMobilePhones] = useState<MobilePhoneStatusDTO[]>([])
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        MobilePhoneService.getAllMobilePhones(page, rowsPerPage, serialNumber, friendlyName, server, dateModified, status, SortingConfiguration.fromSearchString(sortingConfigurationString)).then((data) => {
            setMobilePhones(data.content);
            setTotalElements(data.totalElements)
        })
    }, [page, rowsPerPage, serialNumber, friendlyName, server, dateModified, status, sortingConfigurationString])

    function setSearchParam(key: string, value: string | null | undefined) {
        if (value) {
            searchParams.set(key, value);
        } else {
            searchParams.delete(key);
        }

        setSearchParams(searchParams)
    }

    function setPage(page: number) {
        setSearchParam("page", page.toString());
    }

    function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("rowsPerPage", event.target.value);
        setPage(0);
    }

    function handleSerialNumberChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("serialNumber", event.target.value);
        setPage(0);
    }

    function handleFriendlyNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("friendlyName", event.target.value);
        setPage(0);
    }

    function handleServerChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("server", event.target.value);
        setPage(0);
    }

    function handleChangeDateModified(date: Dayjs | null) {
        setSearchParam("dateModified", DateTimeUtils.formatDateDayJs(date));
        setPage(0);
    }

    function handleStatusChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("status", event.target.value);
        setPage(0);
    }

    function updateSort(sortingConfiguration: SortingConfiguration): void {
        setSearchParam("sort", sortingConfiguration.toSearchString());
    }

    function navigateWithNavigatedFrom(pathname: string) {
        navigate({
            pathname: pathname,
            search: `?navigatedFrom=${location.pathname}`
        });
    }

    function navigateMobilePhoneRow(id: number): any {
        navigateWithNavigatedFrom(Routes.MOBILE_PHONES_DETAILS.replace(":id", id.toString()));
    }

    function getRowLink(entry: MobilePhoneStatusDTO): string {
        return guardedObject([
            new PrivilegeGuard(
                [Privileges.MANAGE_MOBILE_PHONES],
                PrivilegeGuardMode.hasAll
            )
        ], Routes.MOBILE_PHONES_DETAILS.replace(":id", entry.mobilePhone.id.toString())) ?? "";
    }

    return(
        <SideBarPage
            pageTitle={strings.phones}
            breadcrumbs={listLinks}
            component={
            <Grid>
                <TableContainer component={Paper}>
                    <Table sx={{minWidth: 650}} aria-label="custom pagination table">
                        <TableHead>
                            <TableRow>
                                <TableCell width={"20%"}>
                                    <Input
                                        name="serialNumber"
                                        value={serialNumber}
                                        onChange={handleSerialNumberChange}
                                        placeholder={strings.serialNumberFilter}
                                        style={{width: "auto"}}
                                    />
                                </TableCell>
                                <TableCell width={"20%"}>
                                    <Input
                                        name="friendlyName"
                                        value={friendlyName}
                                        onChange={handleFriendlyNameChange}
                                        placeholder={strings.friendlyNumberFilter}
                                        style={{width: "auto"}}
                                    />
                                </TableCell>
                                <TableCell width={"20%"}>
                                    <Input
                                        name="server"
                                        value={server}
                                        onChange={handleServerChange}
                                        placeholder={strings.serverFilter}
                                        style={{width: "auto"}}
                                    />
                                </TableCell>
                                <TableCell width={"20%"}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            onChange={handleChangeDateModified}
                                            value={dateModified}
                                            label={strings.statusDateFilter}
                                            inputFormat="DD.MM.YYYY"
                                            renderInput={(params: any) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    onKeyDown={(e)=>e.preventDefault()}
                                                />
                                            )}
                                            componentsProps={{
                                                actionBar: {
                                                    actions: ["today"],
                                                },
                                            }}
                                            InputProps={{
                                                endAdornment: dateModified && (
                                                    <IconButton sx={{height: "10px", width: "10px"}}
                                                                onClick={() => {
                                                                    setSearchParam("dateModified", null);
                                                                    setPage(0);
                                                                }}>
                                                        <Clear/>
                                                    </IconButton>
                                                ),
                                            }}
                                            InputAdornmentProps={{
                                                position: "start",
                                            }}
                                        />
                                    </LocalizationProvider>
                                </TableCell>
                                <TableCell width={"20%"}>
                                    <Input
                                        name="status"
                                        value={status}
                                        onChange={handleStatusChange}
                                        placeholder={strings.statusFilter}
                                        style={{width: "auto"}}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableHeader columns={columns} sortConfigurationString={sortingConfigurationString}
                                         updateSort={updateSort}></TableHeader>
                        </TableHead>
                        <TableBody>
                            {mobilePhones?.map((mobilePhone) => (
                                <TableRow key={mobilePhone.mobilePhone.id}
                                          component={getRowLink(mobilePhone) ? Link : TableRow} to={getRowLink(mobilePhone)}
                                          hover={true}
                                          className="cursor-pointer"
                                          onClick={guardedCallback([
                                              new PrivilegeGuard(
                                                  [Privileges.MANAGE_MOBILE_PHONES],
                                                  PrivilegeGuardMode.hasAll
                                              )
                                          ],() => navigateMobilePhoneRow(mobilePhone.mobilePhone.id))}>
                                    <TableCell>{mobilePhone.mobilePhone.serialNumber}</TableCell>
                                    <TableCell>{mobilePhone.mobilePhone.friendlyName}</TableCell>
                                    <TableCell>{mobilePhone.server}</TableCell>
                                    <TableCell>{DateTimeUtils.formatDate(mobilePhone.dateModified)}</TableCell>
                                    <TableCell>{mobilePhone.status}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid sx={{display: 'flex', flexDirection: 'column', alignItems: 'end', marginTop: "10px"}}>
                    <PaginationPlus totalElements={totalElements} rowsPerPage={rowsPerPage} page={page} onChangePage={(page) => handleChangePage(null, page)}/>
                    <TablePagination
                        rowsPerPageOptions={defaultRowsPerPageOptions}
                        component="div"
                        count={totalElements}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Grid>
            </Grid>
            } />
    )
}
