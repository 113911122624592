import {Page} from "../pagination/Page";
import {MobilePhoneStatusDTO} from "../models/MobilePhoneStatusDTO";
import {Dayjs} from "dayjs";
import {SortingConfiguration} from "../utils/SortingUtils";
import axiosInstance from "../network/AxiosInstance";
import {MobilePhoneCreationDTO} from "../models/MobilePhoneCreationDTO";
import {MobilePhoneDTO} from "../models/MobilePhoneDTO";

export class MobilePhoneService {
    static async getAllMobilePhones(page: number, size: number, serialNumber: string | null, friendlyName: string | null,
                                    server: string | null, date: string | null | Dayjs, status: string | null, sortingConfiguration: SortingConfiguration | undefined): Promise<Page<MobilePhoneStatusDTO>> {
            const params = new URLSearchParams({
                page: page.toString(),
                size: size.toString(),
            });

            if(serialNumber){
                params.append("serialNumber", serialNumber);
            }

            if(friendlyName){
                params.append("friendlyName", friendlyName);
            }

            if(server){
                params.append("server", server);
            }

            if(date){
                params.append("date", date.toString());
            }

            if(status){
                params.append("status", status);
            }

            sortingConfiguration?.appendToBackEndSearchParams(params);

            const response = await axiosInstance.get<Page<MobilePhoneStatusDTO>>(
                `/rest/mobile-phones/mobile-phone-status/all-paged`, {params: params}
            )

            const data = response.data;

            return new Page<MobilePhoneStatusDTO>(
                data.content.map(mobilePhone => new MobilePhoneStatusDTO(mobilePhone)),
                data.totalElements
            )
    }

    static async getMobilePhoneById(id: number): Promise<MobilePhoneDTO> {
        const response = await axiosInstance.get<MobilePhoneDTO>(`/rest/mobile-phones/mobile-phone/${id}`,)
        const data = response.data;

        return new MobilePhoneDTO(data);
    }

    static async updateMobilePhone(id: number, mobilePhoneCreationDTO: MobilePhoneCreationDTO): Promise<MobilePhoneDTO>{
        const response = await axiosInstance.post<MobilePhoneDTO>(
            `/rest/mobile-phones/mobile-phone/update/${id}`, mobilePhoneCreationDTO
        );

        const data = response.data;
        return new MobilePhoneDTO(data);
    }
}