import { ClientScraperDataDTO } from "../models/ClientScraperDataDTO";
import axiosInstance from "../network/AxiosInstance";
import { Page } from "../pagination/Page";
import { SortingConfiguration } from "../utils/SortingUtils";
import { AuthService } from "./AuthService";

export class ScraperService {
    static async getAllOfClientPaged(
        page: number,
        size: number,
        clientId: number | string | undefined,
        serviceId: number | string | null | undefined,
        referenceKey: string | undefined,
        dateCreated: string | null,
        sort: SortingConfiguration,
      ): Promise<Page<ClientScraperDataDTO>> {
        let params = new URLSearchParams();
        params.append("page", page.toString());
        params.append("size", size.toString());
       
        if (serviceId){
          params.append("serviceId", serviceId?.toString());
        }
        if (referenceKey) {
          params.append("referenceKey", referenceKey);
        }
        if(dateCreated){
            params.append("dateCreated", dateCreated);
        }
       
    
        sort.appendToBackEndSearchParams(params);
    
        const request = {
          params: params, 
        };
        const response = await axiosInstance.get<Page<ClientScraperDataDTO>>(
          `${AuthService.PREFIX}/scraper/client-scraper-data/all-paged/${clientId}`, request,
        );
        const data = response.data;
        return new Page<ClientScraperDataDTO>(
          data.content.map((client) => new ClientScraperDataDTO(client)),
          data.totalElements
        );
      }
}