import React, { useEffect, useState } from "react";
import { strings } from "../../localization/Localization";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import {Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import { Box } from "@mui/system";
import {SideBarPage} from "../../components/SideBarPage"
import {ServicesService} from "../../services/ServicesService";
import IconButton from "@mui/material/IconButton";
import {ServiceDTO} from "../../models/ServiceDTO";
import {Clear} from "@mui/icons-material";
import { AccountGroupService } from "../../services/AccountGroupService";
import { AccountGroupDTO } from "../../models/AccountGroupDTO";
import {Link, useNavigate} from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { DateTimeUtils } from "../../utils/DateTimeUtils";
import { Routes } from "../../router/Routes";
import {GuardedComponent} from "../../components/GuardedComponent";
import {Privileges} from "../../models/nomenclatures/Privileges";
import {PrivilegeGuard, PrivilegeGuardMode} from "../../router/guards/PrivilegeGuard";
import {PaginationPlus} from "../../components/PaginationPlus";
import {BotPlatformDTO} from "../../models/BotPlatformDTO";
import {BotPlatformService} from "../../services/BotPlatformService";
import { BotPlatformIcon } from "../../components/BotPlatformIcon";
import {guardedObject} from "../../router/guards/GuardedCallback";

export function AccountGroups() {
    const listLinks = [
        { label: strings.dashboard, currentlyOpened: false, href:Routes.HOME},
        { label: strings.accountGroupsTitle,  currentlyOpened: true },
    ];
    const [services, setServices] = useState<ServiceDTO[] | undefined>([]);
    const [serviceId, setServiceId] = useState<string>("");
    const [botPlatformId, setBotPlatformId] = useState<string>("");
    const [botPlatforms, setBotPlatforms] = useState<BotPlatformDTO[] | undefined>([]);
    const [name, setName] = useState<string>("");
    const [page, setPage] = useState<number>(0);
    const [totalElements, setTotalElements] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [accounts, setAccounts] = useState<AccountGroupDTO[]>([]);
    const navigate=useNavigate();

    useEffect(() => {
        function getAccounts (page: number) {
            AccountGroupService.getAllAccountGroupsPaged(page, rowsPerPage, name, serviceId,botPlatformId).then((data) => {
                setAccounts(data.content);
                setTotalElements(data.totalElements);
                setPage(page);
            })
        }

        getAccounts(page);
    }, [page, rowsPerPage, name, serviceId,botPlatformId]);

    function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    function handleEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
        setName(event.target.value);
        setPage(0);
    }
    function getAllServices(){
        ServicesService.getAllServices().then((data)=>{
            setServices(data);
        })
    }

    function getAllBotPlatforms(){
        BotPlatformService.getAllBotPlatforms().then((data)=>{
            setBotPlatforms(data);
        })
    }

    function setValue<T>(setter: React.Dispatch<React.SetStateAction<T>>, value: T): void {
        setter(value);
        setPage(0);
    }

    function handleChangeBotPlatformId(botPlatformId: string | undefined) {
        setBotPlatformId(botPlatformId ?? "");
        setPage(0);
    }

    function navigateAddSession(){
        navigate(Routes.CREATE_ACC_GROUP)
    }

    useEffect(() => {
        getAllServices();
        getAllBotPlatforms();
    }, [name, page, rowsPerPage, serviceId])

    function navigateAccountRow(id: number): any {
        navigate(Routes.ACCOUNT_GROUPS_DETAILS.replace(":id", id.toString()));
    }

    function buildBotPlatformItem(accountGroup: AccountGroupDTO) {
        if (accountGroup.botPlatform) {
            return <>{accountGroup.botPlatform.label} <BotPlatformIcon botPlatform={accountGroup.botPlatform} /></>
        }
    }

    function getRowLink(entry: AccountGroupDTO): string {
        return guardedObject([
            new PrivilegeGuard(
                [Privileges.MANAGE_ACCOUNT_GROUP, Privileges.READ_ACCOUNTS, Privileges.READ_SERVICES, Privileges.READ_REGIONS, Privileges.READ_AGE_GROUPS],
                PrivilegeGuardMode.hasAll
            )
        ], Routes.ACCOUNT_GROUPS_DETAILS.replace(":id", entry.id.toString())) ?? "";
    }

    return (
        <SideBarPage
            pageTitle={strings.accountGroupsTitle}
            breadcrumbs={listLinks}
            component={
                <Box>
                    <div>
                    </div>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <TextField
                                            name="name"
                                            variant="standard"
                                            label={strings.name}
                                            onChange={handleEmailChange}
                                            fullWidth
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <FormControl fullWidth variant="standard">
                                            <InputLabel>{strings.service}</InputLabel>
                                            <Select
                                                defaultValue={""}
                                                value={serviceId}
                                                onChange={event => setValue(setServiceId, event.target.value)}
                                                label={strings.service}
                                                labelId="serviceLabel"
                                                endAdornment={
                                                    serviceId  && (
                                                        <IconButton
                                                            onClick={() =>
                                                                setServiceId("")
                                                            }
                                                        >
                                                            <Clear />
                                                        </IconButton>
                                                    )
                                                }
                                            >
                                                {services?.map((service) => (
                                                    <MenuItem  disableRipple key={service.id} value={service.id}>
                                                        {service.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell>
                                        <FormControl fullWidth variant="standard">
                                            <InputLabel>{strings.botPlatform}</InputLabel>
                                            <Select
                                                defaultValue={""}
                                                value={botPlatformId}
                                                onChange={event => handleChangeBotPlatformId(event.target.value)}
                                                label={strings.botPlatform}
                                                labelId="botPlatformLabel"
                                                endAdornment={
                                                    botPlatformId  && (
                                                        <IconButton
                                                            onClick={() =>
                                                                handleChangeBotPlatformId(undefined)
                                                            }
                                                        >
                                                            <Clear />
                                                        </IconButton>
                                                    )
                                                }
                                            >
                                                {botPlatforms?.map((botPlatform) => (
                                                    <MenuItem  disableRipple key={botPlatform.id} value={botPlatform.id}>
                                                        {botPlatform.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell/>
                                    <TableCell></TableCell>
                                    <TableCell>
                        {" "}
                                        <GuardedComponent component={
                                            <Button
                                                variant="contained"
                                                className="btn btn-sm float-end"
                                                onClick={navigateAddSession}
                                            >
                                                {" "}
                                                <AddIcon /> {strings.addAccountGroup}
                                            </Button>
                                        } guards={
                                            [new PrivilegeGuard(
                                                [Privileges.MANAGE_ACCOUNT_GROUP, Privileges.READ_ACCOUNTS, Privileges.READ_SERVICES, Privileges.READ_REGIONS, Privileges.READ_AGE_GROUPS],
                                                PrivilegeGuardMode.hasAll,
                                            )]
                                        } fallback={
                                            <Box/>
                                        } />

                      </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell sx={{width:'20%'}}>{strings.name}</TableCell>
                                    <TableCell sx={{width:'16%'}}>{strings.service}</TableCell>
                                    <TableCell sx={{width:'16%'}}>{strings.dateCreated}</TableCell>
                                    <TableCell sx={{width:'16%'}}>{strings.dateModified}</TableCell>
                                    <TableCell sx={{width:'16%'}}>{strings.accounts}</TableCell>
                                    <TableCell sx={{width:'16%'}}>{strings.botPlatform}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {accounts.map((e: AccountGroupDTO) => (
                                    <TableRow key={e.id}                                              
                                    onClick={() => navigateAccountRow(e.id)}
                                    hover={true}
                                              component={getRowLink(e) ? Link : TableRow}
                                              to={getRowLink(e)}
                                    className="cursor-pointer"
                                    >
                                        <TableCell>{e.name}</TableCell>
                                        <TableCell>{e.service!==null ? e.service.label: " "}</TableCell>
                                        <TableCell>{e.dateCreated!==null ? DateTimeUtils.formatDateObject(e.dateCreated):" "} </TableCell>
                                        <TableCell>{e.dateModified!==null ? DateTimeUtils.formatDateObject(e.dateModified) : " "}</TableCell>
                                        <TableCell>{e.accountIds!==null ? e.accountIds.length:" "}</TableCell>
                                        <TableCell>{buildBotPlatformItem(e)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Grid sx={{display: 'flex', flexDirection: 'column', alignItems: 'end', marginTop: "10px"}}>
                        <PaginationPlus totalElements={totalElements} rowsPerPage={rowsPerPage} page={page} onChangePage={(page) => handleChangePage(null, page)}/>
                        <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={totalElements}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    </Grid>
                </Box>
            }
        />
    );
}
