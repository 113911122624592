import axios from "../network/AxiosInstance";
import { AuthService } from "./AuthService";
import { Session } from "../models/Session";
import { Page } from "../pagination/Page";
import { SessionCreationDTO } from "../models/SessionCreationDTO";
import {SortingConfiguration} from "../utils/SortingUtils";
export class SessionService {
  static async getAllSessionsPaged(
    page: number,
    size: number,
    serviceId: number | string | null | undefined,
    active: boolean | undefined,
    key: string |  null | undefined,
    name: string | null | undefined,
    sort: SortingConfiguration | undefined,
    clientName: string | undefined,
    accountGroupName?: string,
  ): Promise<Page<Session>> {
    let params = new URLSearchParams();
    params.append("page", page.toString());
    params.append("size", size.toString());
    if (key){
      params.append("key", key);
    }
    if (serviceId){
      params.append("serviceId", serviceId?.toString());
    }
    if (name) {
      params.append("name", name);
    }
    if (active!==undefined){
      params.append("active",active.toString())
    }
    if (clientName) {
      params.append("clientName", clientName)
    }
    if (accountGroupName) {
      params.append("accountGroupName", accountGroupName);
    }

    if(sort){
      sort.appendToBackEndSearchParams(params);
    }

    const request = {
      params: params, 
    };
    const response = await axios.get<Page<Session>>(
      `${AuthService.PREFIX}/bot-session/session/all-paged/`, request,
    );
    const data = response.data;
    return new Page<Session>(
      data.content.map((session) => new Session(session)),
      data.totalElements
    );
  }
  static async createSession(
    sessionCreationDto: SessionCreationDTO
  ): Promise<Session> {
    const response = await axios.post<Session>(
      `${AuthService.PREFIX}/bot-session/session/create`,
      sessionCreationDto
    );
    const data = response.data;
    return new Session(data);
  }
  static async getSessionById(id: number): Promise<Session> {
    const response = await axios.get<Session>(
      `/rest/bot-session/session/get/${id}`
    );
    const data = response.data;

    return new Session(data);
  }
  static async editSession(
    session: SessionCreationDTO,
    id: number
  ): Promise<Session> {
    const response = await axios.post<Session>(
      `/rest/bot-session/session/update/${id}`,
      session
    );

    const data = response.data;
    return new Session(data);
  }

  static async versionHistoryOfSession(id:number | string | undefined):Promise<Array<Session>>{
    const response = await axios.get<Array<Session>>(`/rest/bot-session/session/version-history/${id}`);
    const data = response.data;
    return data.map((session)=> new Session(session))
  }
}
