export class RecordLabelDTO{
    id: number
    key: string
    name: string

    constructor(json: any) {
        this.id = json.id;
        this.key = json.key;
        this.name = json.name;
    }
}
