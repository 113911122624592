import IconButton from '@mui/material/IconButton';
import { Close } from "@mui/icons-material";
import { MenuItem, Select, FormControl, InputLabel } from "@mui/material";
type Element = {
    id:number | string;
    label:string;
}
interface Props {
    label:string;
    value:string;
    id:number | string;
    handleRemoveFilter?:()=>void;
    handleChange:(event:any)=>void;
    elements?:Element[];
}

export function CustomSelect(props:Props) {
    return (
        <>
            <FormControl fullWidth variant="standard">
            <InputLabel>{props.label}</InputLabel>
            <Select
                endAdornment = {
                    <> 
                        {
                            props.value && <IconButton onClick={props.handleRemoveFilter ? props.handleRemoveFilter : undefined}> 
                                <Close fontSize="small"/>
                            </IconButton>                      
                        }
                    </>
                }
                name={String(props.id)}
                label={props.label}
                onChange={props.handleChange}
                value={props.value ? props?.value.toString() : ""}
            >
                {props.elements?.map((element:any) => (
                    <MenuItem disableRipple key={element.id} value={element.id}>{element.label}</MenuItem>
                ))}
            </Select>
            </FormControl>
        </>
    );
}
