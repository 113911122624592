import { ServiceDTO } from "./ServiceDTO";

export class ListenedTrackInfoDTO{
    id:number;
    name:string;
    artist:string;
    service:ServiceDTO;
    timeElapsedInSeconds:number;
    constructor(json:ListenedTrackInfoDTO){
        this.id = json.id;
        this.name = json.name;
        this.artist = json.artist;
        this.service = json.service;
        this.timeElapsedInSeconds = json.timeElapsedInSeconds;
    }

}