import { Delete, Edit } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { ReactElement } from "react";
import { strings } from "../../localization/Localization";

interface IProps {
  open: boolean,
  isAddMode: boolean,
  content?: ReactElement;
  save?: () => void;
  handleClose: () => void,
  handleOpenDelete?: () => void,
}

export default function SharedActionModal({ open, handleClose, handleOpenDelete, isAddMode, content, save }: IProps) {
  return (
    <div>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {isAddMode ? strings.addSharedAction : strings.editSharedAction}
        </DialogTitle>
        <DialogContent>
          {content}
          <div className="d-flex justify-content-around">
            {isAddMode ?
              <Button
                fullWidth
                className="mt-3"
                variant={"contained"}
                color={"warning"}
                onClick={handleClose}
              >
                {strings.cancel}
              </Button>
              :
              <Button
                fullWidth
                className="mt-3"
                variant={"contained"}
                color={"error"}
                startIcon={<Delete />}
                onClick={handleOpenDelete}
              >
                {strings.delete}
              </Button>
            }
            <Button
              type="submit"
              onClick={save}
              fullWidth
              style={{ marginLeft: "10px" }}
              variant="contained"
              className="mt-3"
              startIcon={!isAddMode && <Edit />}
            >
              {strings.save}
            </Button>
          </div>
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
    </div>
  );
}
