export class GenreCreationDTO{
    key: string
    name: string
    relatedGenreKeys: string[]

    constructor(key: string, name: string, relatedGenreKeys: string[]) {
        this.key = key;
        this.name = name;
        this.relatedGenreKeys = relatedGenreKeys;
    }
}