import {strings} from "../../localization/Localization";
import {SideBarPage} from "../../components/SideBarPage";
import {
    Button, FormControl, FormControlLabel, FormGroup, Grid, IconButton,
    Input, InputLabel, MenuItem,
    Paper, Select, SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow, TextField,
} from "@mui/material";
import {ProxyService} from "../../services/ProxyService";
import React, {useEffect, useState} from "react";
import {ProxyDTO} from "../../models/ProxyDTO";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {Routes} from "../../router/Routes";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import {defaultRowsPerPageOptions} from "../../common/Constants";
import {DateTimeUtils} from "../../utils/DateTimeUtils";
import {NumberParser} from "../../utils/NumberParser";
import {TableHeader} from "../../components/TableHeader";
import {SortingConfiguration} from "../../utils/SortingUtils";
import {Clear} from "@mui/icons-material";
import {RegionDTO} from "../../models/RegionDTO";
import {RegionService} from "../../services/RegionService";
import {NullableCheckbox} from "../../components/NullableCheckbox";
import {BooleanParser} from "../../utils/BooleanParser";
import {Dayjs} from "dayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LightTooltip} from "../../components/LightTooltip";
import {Box} from "@mui/system";
import {PrivilegeGuard, PrivilegeGuardMode} from "../../router/guards/PrivilegeGuard";
import {GuardedComponent} from "../../components/GuardedComponent";
import {Privileges} from "../../models/nomenclatures/Privileges";
import {guardedCallback, guardedObject} from "../../router/guards/GuardedCallback";
import {PaginationPlus} from "../../components/PaginationPlus";
import DownloadIcon from "@mui/icons-material/Download";
import {ErrorHandler} from "../../utils/ErrorHandler";
import {showErrorDialog} from "../../common/Dialogs";
import { Add} from "@mui/icons-material";
import { ProxyImportModal } from "./ProxyImportModal";
export function Proxies() {
    const columns = [
        {label: strings.id, sortBy: "id", sortable: true},
        {label: strings.proxyName, sortBy: "name", sortable: true},
        {label: strings.url, sortBy: "url", sortable: true},
        {label: strings.region, sortBy: "region.label", sortable: true},
        {label: strings.hasAuth, sortBy: "hasAuth", sortable: false},
        {label: strings.dateModified, sortBy: "dateModified", sortable: true},
        {label: strings.connectedAccounts, sortBy: "connectedAccounts", sortable: false},
        {label: "", sortBy: "", sortable: false}
    ];
    const listLinks = [
        { label: strings.dashboard, currentlyOpened: false, href:"/"},
        { label: strings.proxies,  currentlyOpened: true },
      ]

    const [searchParams, setSearchParams] = useSearchParams();
    const [isModalOpen, setModalOpen] = useState(false);

    const [proxies, setProxies] = useState<ProxyDTO[]>([]);
    const id = searchParams.get("id") ?? "";
    const name = searchParams.get("name") ?? "";
    const url = searchParams.get("url") ?? "";
    const region = searchParams.get("regionId") ?? "";
    const [regions, setRegions] = useState<RegionDTO[]>([]);
    const rowsPerPage = NumberParser.parseNumber(searchParams.get("rowsPerPage"), 25);
    const page = NumberParser.parseNumber(searchParams.get("page"), 0);
    const [totalElements, setTotalElements] = useState(0);
    const hasAuth = BooleanParser.parseBooleanNullable(searchParams.get("hasAuth"));
    const dateModified = searchParams.get("dateModified") ?? null;

    const sortingConfigurationString = searchParams.get("sort");

    const navigate = useNavigate();

    function setSearchParam(key: string, value: string | null | undefined) {
        if (value) {
            searchParams.set(key, value);
        } else {
            searchParams.delete(key);
        }

        setSearchParams(searchParams)
    }

    function setPage(page: number) {
        setSearchParam("page", page.toString());
    }

    function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("rowsPerPage", event.target.value);
        setPage(0);
    }

    const handleButtonClick = () => {
        setModalOpen(true);
      };
    
      const handleCloseModal = () => {
        setModalOpen(false);
      };
    
    
    async function getProxies(page: number): Promise<void> {
            ProxyService.getAllProxiesPaged(page, rowsPerPage, id, name, url, region, hasAuth, dateModified, SortingConfiguration.fromSearchString(sortingConfigurationString)).then((data) => {
                setProxies(data.content);
                setTotalElements(data.totalElements);
            })
        }

    useEffect(() => {
        getProxies(page).then(_ => {});
    }, [page, url, rowsPerPage, id, name, region, hasAuth, dateModified, sortingConfigurationString]);

    useEffect(() => {
        async function getAllRegions(): Promise<void> {
            const regions = await RegionService.getRegions();
            setRegions(regions);
        }
        async function loadData(): Promise<void> {
            await getAllRegions();
        }

        loadData().then((_) => {
        });
    }, []);

    function setHasAuth(value: boolean | undefined): void {
        setSearchParam("hasAuth", value?.toString() ?? "");
        setPage(0);
    }

    function handleIdChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("id", event.target.value);
        setPage(0);
    }

    function handleNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("name", event.target.value);
        setPage(0);
    }

    function handleUrlChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("url", event.target.value);
        setPage(0);
    }

    function handleChangeRegionId(event: SelectChangeEvent) {
        setSearchParam("regionId", event.target.value);
        setPage(0);
    }

    function handleChangeDateModified(date: Dayjs | null) {
        setSearchParam("dateModified", DateTimeUtils.formatDateDayJs(date));
        setPage(0);
    }

    function navigateToAddProxy(): any {
        navigate(Routes.PROXY_NEW);
    }


    function navigateProxyRow(id: number): any {
        navigate(Routes.PROXY_DETAILS.replace(":id", id.toString()));
    }

    function updateSort(sortingConfiguration: SortingConfiguration): void {
        setSearchParam("sort", sortingConfiguration.toSearchString());
    }

    async function downloadAccountsPerProxyReport(): Promise<void> {
        await ProxyService.downloadActiveProxiesReport().catch((error) => {
            const message = ErrorHandler.parseErrorMessage(error);
            showErrorDialog(strings.error, message, strings.ok).then(_ => {});
        });
    }

    function getRowLink(entry: ProxyDTO): string {
        return guardedObject([
            new PrivilegeGuard(
                [Privileges.MANAGE_PROXY, Privileges.READ_REGIONS],
                PrivilegeGuardMode.hasAll
            )
        ], Routes.PROXY_DETAILS.replace(":id", entry.id.toString())) ?? "";
    }

    return (
        <SideBarPage
            pageTitle={strings.proxies}
            breadcrumbs={listLinks}
            component={
                <Grid>
                    <TableContainer component={Paper}>
                        <Table sx={{minWidth: 650}} aria-label="custom pagination table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                    </TableCell>
                                    <TableCell>
                                    </TableCell>
                                    <TableCell>
                                    </TableCell>
                                    <TableCell>
                                    </TableCell>
                                    <TableCell colSpan={2}>
                                        <Button  onClick={handleButtonClick} aria-label="add" variant={"contained"}
                                                className={"float-end"} style={{height: "58px"}}>
                                            <Add />  {strings.importProxies}
                                        </Button>
                                        <ProxyImportModal isOpen={isModalOpen} onClose={handleCloseModal} getProxies={getProxies}/> 
                                    </TableCell>
                                    <TableCell colSpan={2}>
                                        <Button onClick={downloadAccountsPerProxyReport} aria-label="add" variant={"contained"}
                                                className={"float-end"}>
                                            {strings.downloadAccountsPerProxyReport} <DownloadIcon style={{marginBottom: "2px",whiteSpace:"nowrap"}}/>
                                        </Button>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{width: "10.5%"}}>
                                        <Input
                                            name="id"
                                            value={id}
                                            onChange={handleIdChange}
                                            placeholder={strings.botIdFilter}
                                            style={{width: "auto"}}
                                            type="number"
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell style={{width: "7.5%"}}>
                                        <Input
                                            name="name"
                                            value={name}
                                            onChange={handleNameChange}
                                            placeholder={strings.nameFilter}
                                            style={{width: "auto"}}
                                        />
                                    </TableCell>
                                    <TableCell style={{width: "24.5%"}}>
                                        <Input
                                            name="url"
                                            value={url}
                                            onChange={handleUrlChange}
                                            placeholder={strings.urlFilter}
                                            style={{width: "auto"}}
                                        />
                                    </TableCell>
                                    <TableCell style={{width: "12.5%"}}>
                                            <FormControl
                                                style={{width: "125px"}}
                                                variant="standard"
                                            >
                                                <InputLabel>{strings.region}</InputLabel>
                                                <Select
                                                    style={{width: "auto"}}
                                                    defaultValue={""}
                                                    value={region ? String(region) : ""}
                                                    onChange={handleChangeRegionId}
                                                    label={strings.region}
                                                    labelId="regionLabel"
                                                    endAdornment={
                                                        region && (
                                                            <IconButton
                                                                onClick={() => {
                                                                    setSearchParam("regionId", undefined);
                                                                    setPage(0);
                                                                }}
                                                            >
                                                                <Clear/>
                                                            </IconButton>
                                                        )
                                                    }
                                                >
                                                    {regions?.map((region) => (
                                                        <MenuItem
                                                            disableRipple
                                                            key={region.id}
                                                            value={region.id}
                                                        >
                                                            {region?.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </TableCell>
                                    <TableCell style={{width: "12.5%"}}>
                                        {" "}
                                        <FormGroup>
                                            <FormControlLabel
                                                sx={{whiteSpace: "nowrap"}}
                                                label={strings.hasAuth}
                                                control={
                                                    <NullableCheckbox
                                                        value={hasAuth}
                                                        onValueChanged={setHasAuth}
                                                    />
                                                }
                                            />
                                        </FormGroup>
                                    </TableCell>
                                    <TableCell style={{width: "10.5%"}}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                onChange={handleChangeDateModified}
                                                value={dateModified}
                                                label={strings.dateModified}
                                                inputFormat="DD.MM.YYYY"
                                                renderInput={(params: any) => (
                                                    <TextField
                                                        {...params}
                                                        variant="standard"
                                                        onKeyDown={(e)=>e.preventDefault()}
                                                    />
                                                )}
                                                componentsProps={{
                                                    actionBar: {
                                                        actions: ["today"],
                                                    },
                                                }}
                                                InputProps={{
                                                    endAdornment: dateModified && (
                                                        <IconButton sx={{height: "10px", width: "10px"}}
                                                                    onClick={() => {
                                                                        setSearchParam("dateModified", null);
                                                                        setPage(0);
                                                                    }}>
                                                            <Clear/>
                                                        </IconButton>
                                                    ),
                                                }}
                                                InputAdornmentProps={{
                                                    position: "start",
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </TableCell>
                                    <TableCell style={{width: "12.5%"}}></TableCell>
                                    <TableCell style={{width: "12.5%"}}>
                                        <GuardedComponent component={
                                            <Button onClick={navigateToAddProxy} aria-label="add" variant={"contained"}
                                                    className={"float-end"}>
                                                {strings.addProxy}
                                            </Button>
                                        } guards={
                                            [
                                                new PrivilegeGuard(
                                                    [Privileges.MANAGE_PROXY, Privileges.READ_REGIONS],
                                                    PrivilegeGuardMode.hasAll
                                                )
                                            ]
                                        } fallback={
                                            <Box/>
                                        } />
                                    </TableCell>
                                </TableRow>
                                <TableHeader columns={columns} sortConfigurationString={sortingConfigurationString}
                                             updateSort={updateSort}></TableHeader>
                            </TableHead>
                            <TableBody>
                                {proxies?.map((proxy) => (
                                    <TableRow component={getRowLink(proxy) ? Link : TableRow} to={getRowLink(proxy)}
                                        key={proxy.id}
                                        hover={true}
                                        className="cursor-pointer"
                                        onClick={guardedCallback([
                                            new PrivilegeGuard(
                                                [Privileges.MANAGE_PROXY, Privileges.READ_REGIONS],
                                                PrivilegeGuardMode.hasAll
                                            )
                                        ],() => navigateProxyRow(proxy.id))}
                                    >
                                        <TableCell width={"2%"}>{proxy.id}</TableCell>
                                        <TableCell width={"20%"}>{proxy.name}</TableCell>
                                        <LightTooltip title={proxy.url}><TableCell className={"truncate-table-cell"}>{proxy.url}</TableCell></LightTooltip>
                                        <TableCell>{proxy.region?.label}</TableCell>
                                        <TableCell>
                                            {proxy.hasAuth ? <CheckCircleIcon color={"primary"}/> :
                                                <CancelIcon color={"error"}/>}
                                        </TableCell>
                                        <TableCell width={"20%"}>{DateTimeUtils.formatDate(proxy.dateModified)}</TableCell>
                                        <TableCell width={"20%"} align={"center"}>{proxy.connectedAccounts}</TableCell>
                                        <TableCell width={"20%"}></TableCell>
                                    </TableRow>
                                ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Grid sx={{display: 'flex', flexDirection: 'column', alignItems: 'end', marginTop: "10px"}}>
                        <PaginationPlus totalElements={totalElements} rowsPerPage={rowsPerPage} page={page} onChangePage={(page) => handleChangePage(null, page)}/>
                        <TablePagination
                        rowsPerPageOptions={defaultRowsPerPageOptions}
                        component="div"
                        count={totalElements}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    </Grid>
                </Grid>
            }
        />
    )
}
