export class BotListeningInfoDTO {
    trackName: string;
    trackTimeElapsed: number;
    serviceLabel: string

    constructor(json : any) {
        this.trackName = json.trackName;
        this.trackTimeElapsed = json.trackTimeElapsed;
        this.serviceLabel = json.serviceLabel;
    }
}