import {PaymentAccountTypeDTO} from "./PaymentAccountTypeDTO";

export class PaymentAccountDTO {
    id: number;
    dateCreated:Date;
    dateModified:Date;
    accountId:number;
    paymentAccountType? :PaymentAccountTypeDTO;
    username: string;
    password: string;
    phoneNumber: string;

    constructor(json: any) {
        this.id = json.id;
        this.dateCreated = new Date(json.dateCreated);
        this.dateModified = new Date(json.dateModified);
        this.accountId = json.accountId;
        this.paymentAccountType = json.paymentAccountType ? new PaymentAccountTypeDTO(json.paymentAccountType) : undefined;
        this.username = json.username;
        this.password = json.password;
        this.phoneNumber = json.phoneNumber;

    }
}