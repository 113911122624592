import React, {useEffect, useState} from "react";
import {Session} from "../../models/Session";
import {useNavigate, useSearchParams} from "react-router-dom";
import {ServiceDTO} from "../../models/ServiceDTO";
import {SortingConfiguration} from "../../utils/SortingUtils";
import {SessionService} from "../../services/SessionService";
import {ServicesService} from "../../services/ServicesService";
import {Box} from "@mui/system";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import {DateTimeUtils} from "../../utils/DateTimeUtils";
import TablePagination from "@mui/material/TablePagination";
import {strings} from "../../localization/Localization";
import {LightTooltip} from "../../components/LightTooltip";
import "../dashboard/TableCSS.css";
import {Routes} from "../../router/Routes";
import {Grid, Pagination} from "@mui/material";
import {PaginationPlus} from "../../components/PaginationPlus";

export function DashboardSessions() {
    const successMessageKey = "successMessage";
    const [sessions, setSessions] = useState<Session[]>([]);
    const [serviceId] = useState<number | undefined>(undefined);
    const [active] = useState<boolean | undefined>(undefined);
    const [key] = useState<string | undefined>(undefined);
    const [name] = useState<string | undefined>(undefined);
    const [client] = useState<string | undefined>(undefined);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage] = useState(4);
    const [searchParams] = useSearchParams();
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [services, setServices] = useState<ServiceDTO[]>([]);
    const sortingConfigurationString = searchParams.get("sort");
    const navigate = useNavigate();

    function navigateSessionRow(id: number): any {
        navigate(Routes.SESSION_DETAILS.replace(":id", id.toString()));
    }

    function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
        setPage(newPage);
    }

    useEffect(() => {
        async function getAllSessionsPaged(page: number): Promise<void> {
            const response = await SessionService.getAllSessionsPaged(
                page,
                rowsPerPage,
                serviceId,
                active,
                key,
                name,
                SortingConfiguration.fromSearchString(sortingConfigurationString),
                client
            );
            setTotalElements(response.totalElements);
            setSessions(response.content);
            setPage(page)
        }

        getAllSessionsPaged(page).then(_ => {
        });
        setSnackbarOpen(searchParams.has(successMessageKey));

        function getAllServices() {
            ServicesService.getAllServices().then((res) => {
                setServices(res)
            })
        }
        getAllServices();
    }, [name, key, active, serviceId, rowsPerPage, page, searchParams, undefined]);
    return (
                    <Box className={"tableCSS"}>
                        <div className={"mt-3"}>
                            <div>{strings.sessions}</div>
                            <TableContainer component={Paper}>
                                <Table
                                    sx={{ minWidth: 750 }}
                                    aria-label="custom pagination table"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{"Scn.ID"}</TableCell>
                                            <TableCell>{strings.key}</TableCell>
                                            <TableCell>{strings.name}</TableCell>
                                            <TableCell>{strings.service}</TableCell>
                                            <TableCell>{strings.active}</TableCell>
                                            <TableCell>{"Acc.Group"}</TableCell>
                                            <TableCell>{strings.schedule}</TableCell>
                                            <TableCell>{"Proxy"}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {sessions.map((session) => (
                                            <TableRow
                                                key={session.id}
                                                hover={true}
                                                className="cursor-pointer"
                                                onClick={() => navigateSessionRow(session.id)}>
                                                <TableCell component="th" scope="row">
                                                    {session.id}
                                                </TableCell>
                                                <LightTooltip title={session.key}><TableCell component="th" scope="row" className="truncate-table-cell" width={"20%"}>
                                                    {session.key}
                                                </TableCell></LightTooltip>
                                                <LightTooltip title={session.name}><TableCell className="truncate-table-cell" width={"15%"}>{session.name}</TableCell></LightTooltip>
                                                <TableCell>
                                                    {session.accountGroup.service.label}
                                                </TableCell>
                                                <TableCell>
                                                    {session.active ? (
                                                        <CheckCircleIcon color={"primary"} />
                                                    ) : (
                                                        <CancelIcon color={"error"} />
                                                    )}
                                                </TableCell>
                                                <LightTooltip title={session.accountGroup.name}><TableCell className="truncate-table-cell">{session.accountGroup.name}</TableCell></LightTooltip>
                                                <LightTooltip title={session.accountGroup.schedules.map((schedule) => {
                                                    return (
                                                        <Box key={schedule.id} >
                                                            {DateTimeUtils.formatDateObject(
                                                                    schedule.dateCreated
                                                                ) +
                                                                " " +
                                                                DateTimeUtils.formatTimeObject(
                                                                    schedule.startTime
                                                                )}
                                                        </Box>
                                                    );
                                                })}>
                                                    <TableCell width={"20%"}>
                                                        {session.accountGroup.schedules !== null && session.accountGroup.schedules.length !==0
                                                            ? DateTimeUtils.formatDateObject(session.accountGroup.schedules[0].dateCreated) +
                                                            " " + DateTimeUtils.formatTimeObject(session.accountGroup.schedules[0].startTime) : ""}
                                                    </TableCell>
                                                </LightTooltip>
                                                <TableCell>
                                                    {session.useProxy ? (
                                                        <CheckCircleIcon color={"primary"} />
                                                    ) : (
                                                        <CancelIcon color={"error"} />
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                        <Grid sx={{display: 'flex', flexDirection: 'column', alignItems: 'end', marginTop: "10px"}}>
                            <PaginationPlus totalElements={totalElements} rowsPerPage={rowsPerPage} page={page} onChangePage={(page) => handleChangePage(null, page)}/>
                            <TablePagination
                            rowsPerPageOptions={[]}
                            component="div"
                            count={totalElements}
                            rowsPerPage={4}
                            page={page}
                            onPageChange={handleChangePage}
                            sx={{
                                ".MuiTablePagination-displayedRows": {
                                    marginTop: "15px"
                                }
                            }}
                        />
                        </Grid>
                    </Box>
    );
}
