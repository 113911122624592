import {SideBarPage} from "../../components/SideBarPage";
import {CenteredCardLayout} from "../../components/CenteredCardLayout";
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {Routes} from "../../router/Routes";
import React, {useEffect, useState} from "react";
import {MobilePhoneService} from "../../services/MobilePhoneService";
import {MobilePhoneCreationDTO} from "../../models/MobilePhoneCreationDTO";
import {Button, CardActions, Checkbox, FormControlLabel, TextField} from "@mui/material";
import {strings} from "../../localization/Localization";
import {Controller, useForm} from "react-hook-form";
import {MobilePhoneDTO} from "../../models/MobilePhoneDTO";
import EditIcon from "@mui/icons-material/Edit";
import {showErrorDialog, showSuccessDialog} from "../../common/Dialogs";
import {ErrorHandler} from "../../utils/ErrorHandler";

export function MobilePhoneDetails() {
    let {id} = useParams();
    const navigate = useNavigate();
    const [mobilePhoneData, setMobilePhoneData] = useState<MobilePhoneDTO | null>(null)
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigatedFromSearchParam = searchParams.get("navigatedFrom") ?? Routes.MOBILE_PHONES

    function onSubmit() {
        return editMobilePhone();
    }

    const {
        handleSubmit,
        setValue,
        control,
        watch,
        getValues,
        formState: {errors}
    } = useForm<MobilePhoneCreationDTO>({
        defaultValues: {
            friendlyName: "",
        },
        mode: "onChange"
    });
    watch();

    function navigateWithNavigatedFrom(pathname: string) {
        navigate({
            pathname: pathname,
            search: `?navigatedFrom=${location.pathname}`
        });
    }

    useEffect(() => {
        function getMobilePhoneById(id: number) {
            MobilePhoneService.getMobilePhoneById(id).then((res) => {
                setMobilePhoneData(res);
                setValue("friendlyName", res.friendlyName);
                setValue("shouldReboot", res.shouldReboot);
            })
        }
        async function getData(){
            if (id) {
                await getMobilePhoneById(parseInt(id));
            }
        }

        getData().then(_ => {
        });
    }, [id])

    function handleCancel(): void {
        navigate(navigatedFromSearchParam);
    }

    function editMobilePhone(){
        const mobilePhone = new MobilePhoneCreationDTO(
            getValues("friendlyName"),
            getValues("shouldReboot")
        )

        if(mobilePhoneData === null){
            return;
        }

        MobilePhoneService.updateMobilePhone(mobilePhoneData?.id, mobilePhone).then(() => {
            showSuccessDialog(strings.success, strings.mobilePhoneUpdatedSuccessfully, strings.ok).then(_ => {
            });

            navigate(navigatedFromSearchParam)
    }).catch(e => {
            const errorMessage = ErrorHandler.parseErrorMessage(e);
            showErrorDialog(strings.error, errorMessage, strings.ok).then(_ => {
            });
    });
}
    return(
        <SideBarPage pageTitle={strings.mobilePhoneDetails} component={
            <CenteredCardLayout>
                <form onSubmit={handleSubmit(onSubmit)}>
                            <TextField
                                InputLabelProps={{
                                    shrink: !!mobilePhoneData?.serialNumber,
                                }}
                                id={"name"}
                                label={strings.serialNumber}
                                className={"w-100"}
                                style={{marginBottom: 15}}
                                disabled={true} value={mobilePhoneData?.serialNumber}
                            />
                    <Controller
                        name="friendlyName"
                        rules={{required: true}}
                        control={control}
                        render={({field}) => (
                            <TextField
                                InputLabelProps={{
                                    shrink: !!getValues("friendlyName"),
                                }}
                                {...field}
                                value={field.value}
                                id={"name"}
                                label={strings.friendlyName}
                                className={"w-100"}
                                style={{marginBottom: 15}}
                                error={errors.friendlyName !== undefined}
                            />
                        )}
                    />
                    <FormControlLabel
                        control={
                            <Controller
                                name={`shouldReboot`}
                                control={control}
                                defaultValue={false}
                                render={({ field: { value, ref, ...field } }) => (
                                    <Checkbox
                                        {...field}
                                        inputRef={ref}
                                        checked={!!value}
                                        color="primary"
                                        size={"medium"}
                                        disableRipple
                                    />
                                )}
                            />
                        }
                        label={strings.shouldReboot}
                        style={{marginBottom: 15}}
                        labelPlacement="end"
                    />
                    <CardActions className="d-flex p-0">
                        <Button
                            variant="contained"
                            onClick={handleCancel}
                            color={"warning"}
                            className="w-50"
                        >
                            {strings.cancel}
                        </Button>
                        <Button
                            variant="contained"
                            type="submit"
                            startIcon={<EditIcon/>}
                            color={"primary"}
                            className="w-50"
                        >
                            {strings.saveChanges}
                        </Button>
                    </CardActions>
                </form>
            </CenteredCardLayout>
        }/>
    )
}
