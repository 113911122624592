import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField, Typography
} from "@mui/material";
import {strings} from "../localization/Localization";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import React, {useState} from "react";
import {Dayjs} from "dayjs";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers";

interface IProps {
    open: boolean,
    handleClose: () => void,
    handleConfirm: (dateFrom: Dayjs | null, dateTo: Dayjs | null) => void,
    title: string,
    content:string,
    actionButtonName:string,
}

export default function ReportModal ({ open, handleClose, handleConfirm, title, actionButtonName }: IProps) {
    const [dateFromValue, setDateFromValue] = useState<Dayjs | null>(null);
    const [dateToValue, setDateToValue] = useState<Dayjs | null>(null);

    const handleDateFromChange = (newValue: Dayjs | null) => {
        setDateFromValue(newValue);
    };

    const handleDateToChange = (newValue: Dayjs | null) => {
        setDateToValue(newValue);
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            onChange={handleDateFromChange}
                            value={dateFromValue}
                            label={strings.dateFrom}
                            inputFormat="DD.MM.YYYY"
                            renderInput={(params: any) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                />
                            )}
                            componentsProps={{
                                actionBar: {
                                    actions: ["today"],
                                },
                            }}
                        />
                    </LocalizationProvider>
                    <br/>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            onChange={handleDateToChange}
                            value={dateToValue}
                            label={strings.dateTo}
                            inputFormat="DD.MM.YYYY"
                            renderInput={(params: any) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                />
                            )}
                            componentsProps={{
                                actionBar: {
                                    actions: ["today"],
                                },
                            }}
                        />
                    </LocalizationProvider>
                    {dateFromValue && dateToValue && dateFromValue > dateToValue ? <Typography className={"mt-1 text-danger"}>{strings.pleaseEnterValidDates}</Typography> : null}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color={"error"}>{strings.cancel}</Button>
                    {dateFromValue && dateToValue && dateFromValue > dateToValue ?
                        <Button onClick={() => handleConfirm(dateFromValue, dateToValue)} disabled color={"primary"}>
                            {actionButtonName}
                        </Button> : <Button onClick={() => handleConfirm(dateFromValue, dateToValue)} color={"primary"}>
                            {actionButtonName}
                        </Button>}

                </DialogActions>
            </Dialog>
        </>
    );
}
