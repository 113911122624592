import {PaymentAccountDetails} from "./PaymentAccountDetails";


export function NewPaymentAccount() {

    return (

        <> <PaymentAccountDetails/> </>

    )
}
