import { SessionInstanceActionDTO } from "./SessionInstanceActionDTO";

export class ListenedTrackDTO {
    id:number | undefined;
    sessionInstanceAction: SessionInstanceActionDTO;
    name:string;
    url:string;
    startDate:string;
    timeElapsedInSeconds:number;
    artist:string;
    serviceName: string;

    constructor(json:any){
        this.id = json.id;
        this.sessionInstanceAction = new SessionInstanceActionDTO(json.sessionInstanceAction);
        this.name = json.name;
        this.url = json.url;
        this.startDate = json.startDate;
        this.timeElapsedInSeconds = json.timeElapsedInSeconds;
        this.artist = json.artist;
        this.serviceName = json.serviceName;
    }

}