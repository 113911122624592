export class MobilePhoneCreationDTO{
    friendlyName: string;

    shouldReboot: boolean

    constructor(friendlyName: string, shouldReboot: boolean) {
        this.friendlyName = friendlyName;
        this.shouldReboot = shouldReboot;
    }
}
