import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {strings} from "../../localization/Localization";

interface IProps {
    open: boolean,
    handleClose: () => void,
    handleDelete: () => void,
}

export default function ScheduleModal ({ open, handleClose, handleDelete }: IProps) {

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {strings.deleteSchedule}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {strings.deleteThisSchedule}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{strings.cancel}</Button>
                    <Button onClick={handleDelete} color={"error"}>
                        {strings.deleteSchedule}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
