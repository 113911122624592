import axios from "../network/AxiosInstance";
import {AuthService} from "./AuthService";
import {DashboardDTO} from "../models/DashboardDTO";

export class DashboardService {

    static async totalCount(totalNumberOfAccounts: number | undefined, totalNumberOfRegisteredBots: number | undefined,
                            totalNumberOfExecutedSessions: number | undefined, totalNumberOfProxies: number | undefined): Promise<DashboardDTO> {
        const response = await axios.get<DashboardDTO>(`${AuthService.PREFIX}/bot-session/dashboard/total-count`, {params: {
                totalNumberOfAccounts, totalNumberOfRegisteredBots, totalNumberOfExecutedSessions, totalNumberOfProxies
            }});
        return response.data;
    }

}