import {SideBarPage} from "../../components/SideBarPage";
import {strings} from "../../localization/Localization";
import React from "react";
import {useParams} from "react-router-dom";
import {Routes} from "../../router/Routes";
import {AccountDetailsComponent} from "./AccountDetailsComponent";
import {NumberParser} from "../../utils/NumberParser";

export function AccountDetails() {
    let {id} = useParams();
    const isAddMode = !id;
    const listLinks = [
        {label: strings.dashboard, currentlyOpened: false, href: Routes.HOME},
        {label: strings.accounts, currentlyOpened: false, href: Routes.ACCOUNTS},
        {label: isAddMode ? strings.addAccount : strings.editAccount, currentlyOpened: true},
    ]
    return (
        <>
            <SideBarPage pageTitle={isAddMode ? strings.addAccount : strings.editAccount}
                         breadcrumbs={listLinks}
                         component={
                             <AccountDetailsComponent
                                 id={NumberParser.parseNumberNullable(id)}
                             />
                         }/>
        </>

    )
}
