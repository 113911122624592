import React, {useState} from "react";
import {IconButton, TextField} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {VisibilityOff} from "@mui/icons-material";

interface props {
    value?: string | null | undefined,
    onChange?: (value: string) => void | Promise<void>,
    required?: boolean,
    className?: string,
    label?: string,
    getValue?: boolean,
}

export function VisibilityEye({value, onChange, required, className, label, getValue}: props) {
    const [showPassword, setShowPassword] = useState<boolean>(false);

    function togglePassword(): any {
        setShowPassword(!showPassword);
    }

    const changeHandler = !onChange ? undefined : (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value);
    };

    return (
        <>
            <TextField type={showPassword ? "text" : "password"}
                       disabled={false}
                       value={value}
                       onChange={changeHandler}
                       required={required}
                       className={className}
                       label={label}
                       autoComplete="new-password"
                       style={{marginBottom: 15}}
                       InputProps={{
                           endAdornment: <IconButton onClick={togglePassword}>
                               {showPassword ? <VisibilityOff/> : <VisibilityIcon/>}
                           </IconButton>
                       }}
                       InputLabelProps={{
                           shrink: getValue,
                       }}
            />

        </>
    )
}
