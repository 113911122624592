import React, { useEffect, useState } from "react";
import { strings } from "../../localization/Localization";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/system";
import {AccountService} from "../../services/AccountService";
import {AccountDTO} from "../../models/AccountDTO";
import {AccountFilter} from "../../models/filters/AccountFilter";
import {ServicesService} from "../../services/ServicesService";
import {ServiceDTO} from "../../models/ServiceDTO";
import "../dashboard/TableCSS.css";
import {Routes} from "../../router/Routes";
import {useNavigate} from "react-router-dom";
import {Grid, Pagination} from "@mui/material";
import {PaginationPlus} from "../../components/PaginationPlus";

export function DashboardAccounts() {
    const [services, setServices] = useState<ServiceDTO[] | undefined>([]);
    const [service] = useState<any>("");
    const [email] = useState<string>("");
    const [page, setPage] = useState<number>(0);
    const [totalElements, setTotalElements] = useState(4);
    const [rowsPerPage] = useState(4);
    const [accounts, setAccounts] = useState<AccountDTO[]>([]);
    const navigate = useNavigate();

    function navigateAccountRow(id: number): any {
        navigate(Routes.ACCOUNT_DETAILS.replace(":id", id.toString()));
    }


    useEffect(() => {
        function getAccounts (page: number) {
            AccountService.getAllAccountsPaged(page, rowsPerPage, new AccountFilter(email, service), null).then((data) => {
                setAccounts(data.content);
                setTotalElements(data.totalElements);
                setPage(page);
            })
        }

        getAccounts(page);
    }, [page, rowsPerPage, email, service]);

    function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
        setPage(newPage);
    }

    function getAllServices(){
        ServicesService.getAllServices().then((data)=>{
            setServices(data);
        })
    }

    useEffect(() => {
        getAllServices();
    }, [])

    return (
                    <Box className={"tableCSS"}>
                        <div className={"mt-3"}>
                            <div>{strings.accounts}</div>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 750}} aria-label="custom pagination table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{width:'40%'}}>{strings.email}</TableCell>
                                            <TableCell sx={{width:'20%'}}>{strings.service}</TableCell>
                                            <TableCell sx={{width:'20%'}}>{strings.ageGroup}</TableCell>
                                            <TableCell sx={{width:'20%'}}>{strings.region}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {accounts.map((a) => (
                                            <TableRow key={a.id}
                                                      hover={true}
                                                      className={"cursor-pointer"}
                                                      onClick={() => navigateAccountRow(a.id)}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {a.email}
                                                </TableCell>
                                                <TableCell>{a.service!==null ? a.service?.label: " "}</TableCell>
                                                <TableCell>{a.ageGroup!==null ? a.ageGroup?.label:" "} </TableCell>
                                                <TableCell>{a.region!==null ? a.region?.label: " "}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                        <Grid sx={{display: 'flex', flexDirection: 'column', alignItems: 'end', marginTop: "10px"}}>
                            <PaginationPlus totalElements={totalElements} rowsPerPage={rowsPerPage} page={page} onChangePage={(page) => handleChangePage(null, page)}/>
                            <TablePagination
                            rowsPerPageOptions={[]}
                            component="div"
                            count={totalElements}
                            rowsPerPage={4}
                            page={page}
                            onPageChange={handleChangePage}
                            sx={{
                                ".MuiTablePagination-displayedRows": {
                                    marginTop: "15px"
                                }
                            }}
                        />
                        </Grid>
                    </Box>
    );
}
