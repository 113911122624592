import {
    Card,
    IconButton,
    MenuItem, Popover,
    StandardTextFieldProps,
    TablePagination,
    TextField
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Page} from "../pagination/Page";
import ClearIcon from "@mui/icons-material/Clear";
import {strings} from "../localization/Localization";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

type OnValueChangedFunction<T> = {
    (value: T[]): void
};

type ValueMapper<T> = {
    (value: T): string
};

type KeyMapper<T> = {
    (item: T): string
};

type ItemMapper<T> = {
    (value: T): React.ReactElement;
}

type Fetcher<T> = {
    (page: number, size: number, filter: string | undefined): Promise<Page<T>>;
}

type LabelMapper<T> = {
    (items: T): string;
}

interface PaginatedSelectProps<T> {
    label?: string;
    filterLabel?: string;
    value: T[];
    valueMapper: ValueMapper<T>;
    keyMapper: KeyMapper<T>;
    itemMapper: ItemMapper<T>;
    labelMapper: LabelMapper<T>;
    dataFetcher: Fetcher<T>;
    onChange: OnValueChangedFunction<T>;
    closeOnSelect?: boolean;
    maxSelectedItems?: number;
    inputProps?: StandardTextFieldProps;
    variant?: "standard" | "filled" | "outlined" | undefined;
    disabled?: boolean;
}

export function PaginatedMultiSelect<T>(props: PaginatedSelectProps<T>) {
    const [page, setPage] = useState<number>(0);
    const [size, setSize] = useState<number>(10);
    const [data, setData] = useState<Page<T>>(new Page([], 0));
    const [filter, setFilter] = useState<string>("");
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        async function fetchAndSetData(): Promise<void> {
            const newData = await props.dataFetcher(page, size, filter ? filter : undefined);
            setData(newData);
        }

        if (open) {
            fetchAndSetData().then(_ => {
            });
        }
    }, [page, size, filter, props, open]);

    function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number): void {
        setPage(newPage);
    }

    function handleChangeSize(event: React.ChangeEvent<HTMLInputElement>): void {
        setSize(parseInt(event.target.value, 10));
        setPage(0);
    }

    function handleFilterChanged(event: React.ChangeEvent<HTMLInputElement>): void {
        setFilter(event.target.value);
        setPage(0);
    }

    function openDropdown(event: React.MouseEvent<HTMLElement>): void {
        if (props.disabled) {
            return;
        }
        setAnchorEl(event.currentTarget);
    }

    function closeDropdown(): void {
        if (anchorEl) {
            anchorEl.focus();
        }

        setAnchorEl(null);
    }

    function handleValueSelected(item: T): void {
        const updatedItems = props.value ? [...props.value] : [];
        const index = updatedItems.findIndex(presentItem => props.valueMapper(presentItem) === props.valueMapper(item));

        if (index === -1) {
            updatedItems.push(item);
        } else {
            updatedItems.splice(index, 1);
        }

        if (props.maxSelectedItems !== undefined && updatedItems.length > props.maxSelectedItems) {
            updatedItems.splice(0, updatedItems.length - props.maxSelectedItems);
        }

        props.onChange(updatedItems);

        if (props.closeOnSelect) {
            closeDropdown();
        }
    }

    function clearValue(): void {
        props.onChange([]);
    }

    function valueContains(item: T): boolean {
        const foundItem = props.value?.find(presentItem => props.valueMapper(presentItem) === props.valueMapper(item));
        return foundItem !== undefined;
    }

    function buildLabel(): string {
        return props.value.map(item => props.labelMapper(item)).join(", ");
    }

    function isSelected(item: T): boolean {
        return valueContains(item);
    }

    return (
        <>
            <TextField disabled={props.disabled}
                variant={props.variant ?? "standard"}
                fullWidth
                label={props.label}
                value={buildLabel()}
                onClick={openDropdown}
                InputProps={{
                    readOnly: true,
                    endAdornment: (
                        <>
                            {
                                props.value.length > 0 && <IconButton onClick={clearValue} disabled={props.disabled}>
                                    <ClearIcon/>
                                </IconButton>
                            }
                            <ArrowDropDownIcon/>
                        </>
                    ),
                }}
                {...props?.inputProps}
            />
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={closeDropdown}
            >
                <Card
                    sx={{
                        minWidth: anchorEl?.offsetWidth,
                    }}
                >
                    <div className="mx-3 mb-3 mt-3">
                        <TextField
                            autoFocus={true}
                            variant="standard"
                            fullWidth
                            label={props.filterLabel ?? strings.filter}
                            value={filter}
                            onChange={handleFilterChanged}
                        />
                    </div>

                    {data.content.map((item) => (
                        <MenuItem
                            key={props.keyMapper(item)}
                            value={props.valueMapper(item)}
                            onClick={() => handleValueSelected(item)}
                            selected={isSelected(item)}
                        >
                            <div
                                className={isSelected(item) ? "me-3" : ""}
                            >
                                {props.itemMapper(item)}
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    width: "100%",
                                }}
                            >
                                {isSelected(item) &&
                                    <CheckCircleIcon
                                        color="primary"
                                        style={{
                                            marginLeft: "auto",
                                        }}
                                    />
                                }
                            </div>
                        </MenuItem>
                    ))}

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 15]}
                        component="div"
                        count={data.totalElements}
                        rowsPerPage={size}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeSize}
                    />
                </Card>
            </Popover>
        </>
    );
}
