import { ActionType } from "./ActionType";
import { RegionDTO } from "./RegionDTO";
import { ServiceDTO } from "./ServiceDTO";

export class SharedActionDTO {
    id:number;
    dateCreated:string;
    dateModified:string;
    actionType:string;
    actionTypeData:ActionType | undefined;
    sourceKey:string;
    region:RegionDTO | undefined;
    service:ServiceDTO;
    constructor(json:SharedActionDTO){
        this.id = json.id;
        this.dateCreated = json.dateCreated;
        this.dateModified = json.dateModified;
        this.actionType = json.actionType;
        this.actionTypeData =  json.actionTypeData ? new ActionType(json.actionTypeData) : undefined;
        this.sourceKey = json.sourceKey;
        this.region =  json.region ? new RegionDTO(json.region) : undefined;
        this.service =  new ServiceDTO(json.service);
    }

}