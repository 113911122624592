export class AccountReportResponseDTO{
    id: number
    email: string
    accountGroupName: string
    regionLabel: string

    constructor(json: any) {
        this.id = json.id;
        this.email = json.email;
        this.accountGroupName = json.accountGroupName;
        this.regionLabel = json.regionLabel;
    }
}