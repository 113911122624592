import {SessionActionCreationDTO} from "./SessionActionCreationDTO";
import {SessionPreferredProxyCreationDTO} from "./SessionPreferredProxyCreationDTO";

export class SessionCreationDTO {
    name?: string;
    accountGroupId?: number | string;
    active: boolean;
    useProxy: boolean;
    actions: SessionActionCreationDTO[];
    preferredProxies?: SessionPreferredProxyCreationDTO[] | undefined;
    behaviorId?: number | string | undefined;
    maxDurationInSeconds: number | undefined;
    loops: number | undefined;
    clientId: number | undefined;
    switchAccountGroupOnFinish: boolean | undefined;
    playlistGenerationGenres? : string;

    constructor(json: SessionCreationDTO) {
        this.name = json.name;
        this.accountGroupId = json.accountGroupId;
        this.active = json.active;
        this.useProxy = json.useProxy;
        this.actions = json.actions;
        this.preferredProxies = json.preferredProxies?.map((item) => new SessionPreferredProxyCreationDTO(item.regionId, item.proxyId));
        this.behaviorId = json.behaviorId;
        this.maxDurationInSeconds = json.maxDurationInSeconds;
        this.loops = json.loops;
        this.clientId = json.clientId;
        this.switchAccountGroupOnFinish = json.switchAccountGroupOnFinish;
        this.playlistGenerationGenres = json.playlistGenerationGenres;
    }
}
