export class MobilePhoneDTO {
    id: number;
    serialNumber: string;
    friendlyName: string;
    shouldReboot: boolean;

    constructor(json: any) {
        this.id = json.id;
        this.serialNumber = json.serialNumber;
        this.friendlyName = json.friendlyName;
        this.shouldReboot = json.shouldReboot;
    }
}
