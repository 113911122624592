import { Grid } from "@mui/material";
import React from "react";

interface Props {
    text: string;
    value: any;
    className?: string;
    children?: React.ReactNode;
    xs?: number;
}

export function DashboardCardComponent(props: Props) {
    return (
        <Grid item xs={props.xs ? props.xs : 6} className={props.className} minHeight={"100px"} sx={{ display: 'flex' }}>
            <div className={"dashboardTotalDataCSS"} style={{ display: 'flex', width: '100%' }}>
                {props.children &&
                    <div style={{ flex: 1 }}>
                        {props.children}
                    </div>
                }
                <div style={{ flex: 1 }}>
                    <div className={"text-center mt-2"}>{props.text}</div>
                    <div className={"text-center"} style={isNaN(props.value) ? { fontSize: "20px", marginTop: "35px"} : { fontSize: "32px" }}>{props.value}</div>
                </div>
            </div>
        </Grid>
    )
}
