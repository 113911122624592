import {PersonDTO} from "./PersonDTO";
import {RoleDTO} from "./RoleDTO";
import {ClientDTO} from "./ClientDTO";

export class UserDTO{
    id : number;
    dateCreated : Date;
    dateModified : Date;
    email : string;
    enabled : boolean;
    person : PersonDTO;
    roles : RoleDTO[];
    client: ClientDTO | undefined;

    constructor(json:any) {
        this.id = json.id;
        this.dateCreated = new Date(json.dateCreated);
        this.dateModified = new Date(json.dateModified);
        this.email = json.email;
        this.enabled = json.enabled;
        this.person = json.person;
        this.roles = json.roles;
        this.client = json.client ? new ClientDTO(json.client) : undefined;
    }
}
