import { SideBarPage } from "../../components/SideBarPage";
import { strings } from "../../localization/Localization";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
    Button,
    CardActions,
    TextField
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ConfirmationModal from "../../components/ConfirmationModal";
import { Routes } from "../../router/Routes";
import { QueueCreationDTO } from "../../models/QueueCreationDTO";
import { CenteredCardLayout } from "../../components/CenteredCardLayout";
import { Controller, useForm } from "react-hook-form";
import { showErrorDialog, showSuccessDialog } from "../../common/Dialogs";
import { ErrorHandler } from "../../utils/ErrorHandler";
import { QueueDTO } from "../../models/QueueDTO";
import { QueueService } from "../../services/QueueService";

export function QueueDetails() {

    const [openModal, setOpenModal] = useState<boolean>(false);

    let { id } = useParams();

    function onSubmit() {
        return isAddMode ? addQueue() : editQueue()
    }

    const {
        handleSubmit,
        setValue,
        control,
        watch,
        getValues,
        formState: { errors }
    } = useForm<QueueCreationDTO>({
        defaultValues: {
            key: "",
        },
        mode: "onChange"
    });
    watch();

    const isAddMode = !id;
    const listLinks = [
        { label: strings.dashboard, currentlyOpened: false, href: Routes.HOME },
        { label: strings.queues, currentlyOpened: false, href: Routes.QUEUES },
        { label: isAddMode ? strings.addQueue : strings.editQueueButton, currentlyOpened: true }
    ];

    const navigate = useNavigate();

    useEffect(() => {
        function getQueueById(id: number) {
            QueueService.getQueueById(id).then((res) => {
                setQueueData(res);
                setValue("key", res.key ?? "");
            })
        }

        async function getData() {
            if (id) {
                await getQueueById(parseInt(id));
            }
        }

        getData().then(_ => {
        });
    }, [id, setValue])

    function addQueue() {
        const queue = new QueueCreationDTO(getValues("key"));

        QueueService.createQueue(queue).then(() => {
            showSuccessDialog(strings.success, strings.queueAddedSuccessfully, strings.ok).then(_ => {
            });

            navigate(Routes.QUEUES);
        }).catch(e => {
            const errorMessage = ErrorHandler.parseErrorMessage(e);
            showErrorDialog(strings.error, errorMessage, strings.ok).then(_ => {
            });
        });
    }

    const [queueData, setQueueData] = useState<QueueDTO | null>(null);

    function editQueue() {
        const queue = new QueueCreationDTO(getValues("key"));

        if (queueData === null) {
            return;
        }

        QueueService.editQueue(queue, queueData?.id).then(() => {
            showSuccessDialog(strings.success, strings.queueUpdatedSuccessfully, strings.ok).then(_ => {
            });

            navigate(Routes.QUEUES);
        }).catch(e => {
            const errorMessage = ErrorHandler.parseErrorMessage(e);
            showErrorDialog(strings.error, errorMessage, strings.ok).then(_ => {
            });
        });
    }

    async function deleteQueue(): Promise<void> {
        setOpenModal(false);

        if (queueData === null) {
            return;
        }

        try {
            await QueueService.deleteQueue(queueData.id);
            showSuccessDialog(strings.success, strings.queueDeletedSuccessfully, strings.ok).then(_ => {
                navigate(Routes.QUEUES);
                window.location.reload();
            });
        } catch (e: any) {
            const errorMessage = ErrorHandler.parseErrorMessage(e);
            showErrorDialog(strings.error, errorMessage, strings.ok).then(_ => { });
        }
    }

    function handleCancel(): void {
        navigate(Routes.QUEUES, {});
    }

    function handleClickOpen(): any {
        setOpenModal(true);
    }

    function handleClose(): any {
        setOpenModal(false);
    }

    return (
        <SideBarPage pageTitle={isAddMode ? strings.addQueue : strings.editQueueButton}
            breadcrumbs={listLinks}
            component={
                <>
                    <CenteredCardLayout>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Controller
                                name="key"
                                rules={{ required: true }}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        InputLabelProps={{
                                            shrink: !!getValues("key"),
                                        }}
                                        {...field}
                                        value={field.value}
                                        id={"key"}
                                        label={strings.labelQueueKey}
                                        className={"w-100"}
                                        style={{ marginBottom: 15 }}
                                        error={errors.key !== undefined}
                                    />
                                )}
                            />
                            <CardActions className="d-flex p-0 mt-4">
                                {isAddMode ? (
                                    <>
                                        <Button
                                            variant="contained"
                                            onClick={handleCancel}
                                            color={"warning"}
                                            className="w-50"
                                        >
                                            {strings.cancel}
                                        </Button>
                                        <Button
                                            variant="contained"
                                            type="submit"
                                            startIcon={<EditIcon />}
                                            color={"primary"}
                                            className="w-50"
                                        >
                                            {strings.saveChanges}
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        <Button
                                            variant="contained"
                                            startIcon={<DeleteIcon />}
                                            onClick={handleClickOpen}
                                            color={"error"}
                                            className="w-50"
                                        >
                                            {strings.deleteQueueButton}
                                        </Button>
                                        <Button
                                            variant="contained"
                                            type="submit"
                                            startIcon={<EditIcon />}
                                            color={"primary"}
                                            className="w-50"
                                        >
                                            {strings.saveChanges}
                                        </Button>
                                    </>
                                )}
                                <ConfirmationModal
                                    open={openModal}
                                    handleClose={handleClose}
                                    handleConfirm={deleteQueue}
                                    title={strings.deleteQueueDialog}
                                    content={strings.deleteQueue}
                                    actionButtonName={strings.deleteQueueButton}
                                />
                            </CardActions>
                        </form>
                    </CenteredCardLayout>
                </>
            } />
    );
}
