export class GenreDTO{
    id: number
    key: string
    name: string
    relatedGenreKeys: string[]

    constructor(json: any) {
        this.id = json.id;
        this.key = json.key;
        this.name = json.name;
        this.relatedGenreKeys = json.relatedGenreKeys;
    }
}