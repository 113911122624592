import {SideBarPage} from "../../components/SideBarPage";
import {strings} from "../../localization/Localization";
import {
    Button,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    SelectChangeEvent,
    TableCell,
    TextField
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {RegionDTO} from "../../models/RegionDTO";
import {RegionService} from "../../services/RegionService";
import React, {useEffect, useState} from "react";
import {ProxyService} from "../../services/ProxyService";
import TableBody from "@mui/material/TableBody";
import {SortingConfiguration} from "../../utils/SortingUtils";
import {BooleanParser} from "../../utils/BooleanParser";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {guardedCallback, guardedObject} from "../../router/guards/GuardedCallback";
import {PrivilegeGuard, PrivilegeGuardMode} from "../../router/guards/PrivilegeGuard";
import {Privileges} from "../../models/nomenclatures/Privileges";
import {Routes} from "../../router/Routes";
import {TableHeader} from "../../components/TableHeader";
import {Clear} from "@mui/icons-material";
import {showErrorDialog, showSuccessDialog} from "../../common/Dialogs";
import {ErrorHandler} from "../../utils/ErrorHandler";
import {ProxyUpdateDTO} from "../../models/ProxyUpdateDTO";
import {Controller, useForm} from "react-hook-form";
import {PaginatedSelect} from "../../components/PaginatedSelect";
import {ProxyGroupReducedDTO} from "../../models/ProxyGroupReducedDTO";
import {ProxyGroupService} from "../../services/ProxyGroupService";

class ProxyMassiveEditForm {
    proxies: ProxyUpdateDTO[];

    constructor(data: ProxyMassiveEditForm) {
        this.proxies = data.proxies;
    }
}

export function ProxiesMassiveEdit() {
    const columns = [
        {label: strings.proxyName, sortBy: "p.name", sortable: false},
        {label: strings.proxyGroup, sortBy: "pg.id", sortable: false},
        {label: strings.region, sortBy: "r.id", sortable: false},
        {label: strings.url, sortBy: "p.url", sortable: false},
        {label: strings.username, sortBy: "p.username", sortable: false},
        {label: strings.password, sortBy: "p.password", sortable: false},
        {label: "", sortBy: "", sortable: false}
    ];
    const listLinks = [
        { label: strings.dashboard, currentlyOpened: false, href:"/"},
        { label: strings.massiveProxyEdit,  currentlyOpened: true },
    ]
    const [searchParams, setSearchParams] = useSearchParams();
    const [regions, setRegions] = useState<RegionDTO[]>([]);
    const name = searchParams.get("name") ?? "";
    const url = searchParams.get("url") ?? "";
    const regionProxy = searchParams.get("regionId") ?? "";
    const id = searchParams.get("id") ?? "";
    const hasAuth = BooleanParser.parseBooleanNullable(searchParams.get("hasAuth"));
    const dateModified = searchParams.get("dateModified") ?? null;
    const sortingConfigurationString = searchParams.get("sort");
    const navigate = useNavigate();
    const region = searchParams.get("regionId") ?? "";
    const [proxyName, setProxyName] = useState<string>('');
    const [isEditMode, setIsEditMode] = useState(false);
    const [selectedProxyGroupInModal, setSelectedProxyGroupInModal] = useState<ProxyGroupReducedDTO | undefined>(undefined);

    function setSearchParam(key: string, value: string | null | undefined) {
        if (value) {
            searchParams.set(key, value);
        } else {
            searchParams.delete(key);
        }

        setSearchParams(searchParams)
    }

    function updateSort(sortingConfiguration: SortingConfiguration): void {
        setSearchParam("sort", sortingConfiguration.toSearchString());
    }

    const handleRegionFilter = (event: SelectChangeEvent) => {
        setSearchParam("regionId", event.target.value);
    }

    const handleProxyFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        setProxyName(event.target.value)
    }

    function handleSelectedProxyGroupFilter(selectedGroup: ProxyGroupReducedDTO | undefined): void {
        setSelectedProxyGroupInModal(selectedGroup);
    }

    useEffect(() => {
        async function getAllRegions(): Promise<void> {
            const regions = await RegionService.getRegions();
            setRegions(regions);
        }
        async function loadData(): Promise<void> {
            await getAllRegions();
        }

        loadData().then((_) => {
        });
    }, []);

    useEffect(() => {
        async function getProxies() {
            const proxies = await ProxyService.getAllProxies(undefined, proxyName, null, region, undefined, null, selectedProxyGroupInModal?.id);
            setValue("proxies", proxies.map((proxy) => proxy.toProxyUpdateDTO()));
        }

        getProxies().then(_ => {});
    }, [url, id, name, region, hasAuth, dateModified, sortingConfigurationString, regionProxy, proxyName, isEditMode, selectedProxyGroupInModal?.id]);

    function getRowLink(entry: ProxyUpdateDTO): string {
        return guardedObject([
            new PrivilegeGuard(
                [Privileges.MANAGE_PROXY, Privileges.READ_REGIONS],
                PrivilegeGuardMode.hasAll
            )
        ], Routes.PROXY_DETAILS.replace(":id", entry.id.toString())) ?? "";
    }

    function navigateProxyRow(id: number): any {
        navigate(Routes.PROXY_DETAILS.replace(":id", id.toString()));
    }

    const {
        setValue,
        control,
        watch,
        getValues,
    } = useForm<ProxyMassiveEditForm>({
        defaultValues: {
            proxies: [],
        },
        mode: "onChange"
    });
    watch();

    const watchFieldArray = watch("proxies");

    function updateMultipleProxies() {
        ProxyService.updateMultipleProxies(getValues("proxies").map(proxy => proxy.withProxyGroupFromObject())).then(() => {
            showSuccessDialog(strings.success, strings.proxyUpdatedSuccessfully, strings.ok).then(_ => {
            });
            setIsEditMode(false)
        }).catch(e => {
            const errorMessage = ErrorHandler.parseErrorMessage(e);
            showErrorDialog(strings.error, errorMessage, strings.ok).then(_ => {
            });
        });
    }

    return (
        <SideBarPage pageTitle={strings.massiveProxyEdit} breadcrumbs={listLinks}
                     component={
            <Grid>
                <TableContainer component={Paper}>
                    <Table sx={{minWidth: 650}} aria-label="custom pagination table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{maxWidth: "20%"}}>
                                    <TextField
                                        name="name"
                                        variant="standard"
                                        label={strings.proxy}
                                        onChange={handleProxyFilter}
                                        fullWidth disabled={isEditMode}
                                    />
                                </TableCell>
                                <TableCell>
                                    <FormControl fullWidth>
                                        <PaginatedSelect<ProxyGroupReducedDTO>
                                            value={selectedProxyGroupInModal}
                                            onChange={handleSelectedProxyGroupFilter}
                                            label={strings.proxyGroup}
                                            valueMapper={(item) => item.id.toString()}
                                            keyMapper={(item) => item.id.toString()}
                                            itemMapper={(item) => <>{item.name}</>}
                                            labelMapper={(item) => item.name}
                                            dataFetcher={(page, size, filter) => {
                                                return ProxyGroupService.getAllProxyGroupsPaged(page, size, filter);
                                            }}
                                            closeOnSelect={true}
                                            disabled={isEditMode}
                                        />
                                    </FormControl>
                                </TableCell>
                                <TableCell style={{width: "12.5%"}}>
                                    <FormControl
                                        style={{width: "125px"}}
                                        variant="standard"
                                    >
                                        <InputLabel>{strings.region}</InputLabel>
                                        <Select disabled={isEditMode}
                                            style={{width: "auto"}}
                                            defaultValue={""}
                                            value={region ? String(region) : ""}
                                            onChange={handleRegionFilter}
                                            label={strings.region}
                                            labelId="regionLabel"
                                            endAdornment={
                                                region && (
                                                    <IconButton
                                                        onClick={() => {
                                                            setSearchParam("regionId", undefined);
                                                        }}
                                                    >
                                                        <Clear/>
                                                    </IconButton>
                                                )
                                            }
                                        >
                                            {regions?.map((region) => (
                                                <MenuItem
                                                    disableRipple
                                                    key={region.id}
                                                    value={region.id}
                                                >
                                                    {region?.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell>
                                    <Grid style={{float: "right", display: "flex", flexDirection: "row"}}>
                                        <Button variant={"contained"} style={{marginRight: "10px"}} onClick={() => setIsEditMode(!isEditMode)}>
                                            {isEditMode ? strings.cancel : strings.enableEdit}
                                        </Button>
                                        <Button disabled={!isEditMode} variant={"contained"} onClick={updateMultipleProxies}>{strings.saveAll}</Button>
                                    </Grid>
                                </TableCell>
                            </TableRow>
                            <TableHeader columns={columns} sortConfigurationString={sortingConfigurationString}
                                         updateSort={updateSort}></TableHeader>
                        </TableHead>
                        <TableBody>
                            {watchFieldArray?.map((item, index) => (

                                <TableRow
                                    component={isEditMode ? 'div' : Link}
                                    to={isEditMode ? undefined : getRowLink(item)}
                                    key={item.id}
                                    hover={true}
                                    className="cursor-pointer"
                                    onClick={isEditMode ? undefined : guardedCallback([
                                        new PrivilegeGuard(
                                            [Privileges.MANAGE_PROXY, Privileges.READ_REGIONS],
                                            PrivilegeGuardMode.hasAll
                                        )
                                    ],() => navigateProxyRow(item.id))}
                                >
                                    <TableCell width={"20%"}>{isEditMode ? (
                                        <Controller
                                            name={`proxies.${index}.name`}
                                            rules={{required: true}}
                                            control={control}
                                            render={({field}) => (
                                                <TextField
                                                    InputLabelProps={{
                                                        shrink: !!getValues(`proxies.${index}.name`),
                                                    }}
                                                    {...field}
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    id={"name"}
                                                    label={strings.labelProxyName}
                                                    className={"w-100"}
                                                />
                                            )}
                                        />
                                    ) : (item.name)}
                                    </TableCell>
                                    <TableCell width={"20%"}>{isEditMode ? (
                                        <Controller
                                            name={`proxies.${index}.proxyGroup`}
                                            rules={{required: true}}
                                            control={control}
                                            render={({field}) => (
                                                <PaginatedSelect<ProxyGroupReducedDTO>
                                                    value={getValues(`proxies.${index}.proxyGroup`)}
                                                    onChange={field.onChange}
                                                    label={strings.proxyGroup}
                                                    valueMapper={(item) => item.id.toString()}
                                                    keyMapper={(item) => item.id.toString()}
                                                    itemMapper={(item) => <>{item.name}</>}
                                                    labelMapper={(item) => item.name}
                                                    dataFetcher={(page, size, filter) => {
                                                        return ProxyGroupService.getAllProxyGroupsPaged(page, size, filter);
                                                    }}
                                                    closeOnSelect={true}
                                                />

                                            )}
                                        />
                                    ) : (item.proxyGroup?.name)}
                                    </TableCell>
                                    <TableCell width={"20%"}>
                                            <FormControl style={{width: "50%"}}>
                                                <InputLabel shrink={true}>
                                                    {strings.region}
                                                </InputLabel>
                                                <Controller
                                                    name={`proxies.${index}.regionId`}
                                                    rules={{
                                                        required: true,
                                                    }}
                                                    control={control}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Select
                                                            value={value} disabled={!isEditMode}
                                                            onChange={onChange}
                                                            input={
                                                                <OutlinedInput
                                                                    label={strings.region}
                                                                />
                                                            }
                                                        >
                                                            {regions.map((region) => (
                                                                <MenuItem
                                                                    value={region.id}
                                                                    key={region.id}
                                                                >
                                                                    {region.label}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    )}
                                                />
                                            </FormControl>
                                    </TableCell>
                                    <TableCell width={"20%"}>
                                        {isEditMode ? (
                                            <Controller
                                                name={`proxies.${index}.url`}
                                                rules={{required: true}}
                                                control={control}
                                                render={({field}) => (
                                                    <TextField
                                                        InputLabelProps={{
                                                            shrink: !!getValues(`proxies.${index}.url`),
                                                        }}
                                                        {...field}
                                                        value={field.value}
                                                        onChange={field.onChange}
                                                        id={"name"}
                                                        label={strings.url}
                                                        className={"w-100"}
                                                    />
                                                )}
                                            />
                                        ) : (item.url)}
                                    </TableCell>
                                    <TableCell width={"20%"}>
                                        {isEditMode ? (
                                            <Controller
                                                name={`proxies.${index}.username`}
                                                rules={{required: true}}
                                                control={control}
                                                render={({field}) => (
                                                    <TextField
                                                        InputLabelProps={{
                                                            shrink: !!getValues(`proxies.${index}.username`),
                                                        }}
                                                        {...field}
                                                        value={field.value}
                                                        onChange={field.onChange}
                                                        id={"name"}
                                                        label={strings.username}
                                                        className={"w-100"}
                                                    />
                                                )}
                                            />
                                        ) : (item.username)}
                                    </TableCell>
                                    <TableCell width={"20%"}>
                                        {isEditMode ? (
                                            <Controller
                                                name={`proxies.${index}.password`}
                                                rules={{required: true}}
                                                control={control}
                                                render={({field}) => (
                                                    <TextField fullWidth
                                                        InputLabelProps={{
                                                            shrink: !!getValues(`proxies.${index}.password`),
                                                        }}
                                                        {...field}
                                                        value={field.value}
                                                        onChange={field.onChange}
                                                        id={"name"}
                                                        label={strings.password}
                                                    />
                                                )}
                                            />
                                        ) : (item.password)}
                                    </TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        } />
    )
}