import {ClientDTO} from "./ClientDTO";

export class SessionReducedDTO {
    id: number;
    service: string;
    enabled:boolean;
    key: string;
    name: string;
    client:ClientDTO|undefined;
    constructor(json: SessionReducedDTO) {
        this.id = json.id;
        this.enabled=json.enabled
        this.service = json.service;
        this.key = json.key;
        this.name = json.name;
        this.client=json.client ? new ClientDTO(json.client) : undefined;
    }
}
