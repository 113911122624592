import { SideBarPage } from "../../components/SideBarPage";
import { strings } from "../../localization/Localization";
import { useParams } from "react-router-dom";
import { Routes } from "../../router/Routes";
import { AddNewTargetPlaylist } from "./AddNewTargetPlaylist";
import { NumberParser } from "../../utils/NumberParser";

export function PlaylistDetails() {
    let { id } = useParams();
    const isAddMode = !id;
    const listLinks = [
        { label: strings.dashboard, currentlyOpened: false, href: Routes.HOME },
        { label: strings.playlistsForGeneration, currentlyOpened: false, href: Routes.PLAYLISTS_FOR_GENERATION },
        { label: isAddMode ? strings.addNewTargetPlaylist : strings.editTargetPlaylist, currentlyOpened: true },
    ]
    return (
        <>
            <SideBarPage pageTitle={isAddMode ? strings.addNewTargetPlaylist : strings.editTargetPlaylist}
                breadcrumbs={listLinks}
                component={
                    <AddNewTargetPlaylist
                        id={NumberParser.parseNumberNullable(id)}
                    />
                } />
        </>

    )
}
