export class ClientCreationDTO {
    name: string | undefined;

    constructor(name: string | undefined) {
        this.name = name;
    }

    static empty(): ClientCreationDTO {
        return new ClientCreationDTO(
            ""
        );
    }
}