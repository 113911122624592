import { Box, Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import { SideBarPage } from "../../components/SideBarPage";
import { strings } from "../../localization/Localization";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Routes } from "../../router/Routes";
import { TableHeader } from "../../components/TableHeader";
import { SortingConfiguration } from "../../utils/SortingUtils";
import { useEffect, useState } from "react";
import { QueueService } from "../../services/QueueService";
import { NumberParser } from "../../utils/NumberParser";
import { QueueDTO } from "../../models/QueueDTO";
import { Privileges } from "../../models/nomenclatures/Privileges";
import { PrivilegeGuard, PrivilegeGuardMode } from "../../router/guards/PrivilegeGuard";
import { guardedObject } from "../../router/guards/GuardedCallback";
import { PaginationPlus } from "../../components/PaginationPlus";
import { defaultRowsPerPageOptions } from "../../common/Constants";
import {DateTimeUtils} from "../../utils/DateTimeUtils";

export function Queues() {
    
    const columns = [
        { label: strings.key, sortBy: "key", sortable: true },
        { label: strings.dateCreated, sortBy: "dateCreated", sortable: true },
        { label: strings.dateModified, sortBy: "dateModified", sortable: true },
    ];

    const listLinks = [
        { label: strings.dashboard, currentlyOpened: false, href: '/' },
        { label: strings.queues, currentlyOpened: true },
    ];

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [queues, setQueues] = useState<QueueDTO[]>([]);
    const [totalElements, setTotalElements] = useState(0);
    const sortingConfigurationString = searchParams.get("sort");
    const key = searchParams.get("key") ?? "";
    const page = NumberParser.parseNumber(searchParams.get("page"), 0);
    const rowsPerPage = NumberParser.parseNumber(searchParams.get("rowsPerPage"), 25);

    function setSearchParam(key: string, value: string | null | undefined) {
        if (value) {
            searchParams.set(key, value);
        } else {
            searchParams.delete(key);
        }

        setSearchParams(searchParams)
    }

    useEffect(() => {
        async function getQueues(page: number): Promise<void> {
            QueueService.getAllQueuesPaged(page, rowsPerPage, key, SortingConfiguration.fromSearchString(sortingConfigurationString)).then((data) => {
                setQueues(data.content);
                setTotalElements(data.totalElements);
            })
        }

        getQueues(page).then(_ => { });
    }, [page, rowsPerPage, key, sortingConfigurationString]);

    function navigateToAddQueues(): any {
        navigate(Routes.QUEUE_NEW);
    }

    function navigateQueueRow(id: number): any {
        navigate(Routes.EDIT_QUEUE.replace(":id", id.toString()));
    }

    function updateSort(sortingConfiguration: SortingConfiguration): void {
        setSearchParam("sort", sortingConfiguration.toSearchString());
    }

    function setPage(page: number) {
        setSearchParam("page", page.toString());
    }

    function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("rowsPerPage", event.target.value);
        setPage(0);
    }

    function getRowLink(entry: QueueDTO): string {
        return guardedObject([
            new PrivilegeGuard(
                [Privileges.MANAGE_QUEUES, Privileges.READ_QUEUES],
                PrivilegeGuardMode.hasAll
            )
        ], Routes.EDIT_QUEUE.replace(":id", entry.id.toString())) ?? "";
    }

    return (
        <SideBarPage
            pageTitle={strings.queues}
            breadcrumbs={listLinks}
            component={
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Box style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem', marginRight: '1rem' }}  >
                            <Button onClick={navigateToAddQueues} aria-label="add" variant={"contained"}>
                                {strings.addQueue}
                            </Button>
                        </Box>
                        <Table sx={{ minWidth: 650 }} aria-label="custom pagination table">
                            <TableHead>

                                <TableHeader columns={columns} sortConfigurationString={sortingConfigurationString}
                                    updateSort={updateSort}></TableHeader>
                            </TableHead>
                            <TableBody>
                                {queues?.map((queue) => (
                                    <TableRow
                                        component={Link}
                                        to={getRowLink(queue) || Routes.EDIT_QUEUE.replace(":id", queue.id.toString())}
                                        key={queue.id}
                                        hover={true}
                                        className="cursor-pointer"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            navigateQueueRow(queue.id);
                                        }}
                                    >
                                        <TableCell width={"20%"}>{queue.key}</TableCell>
                                        <TableCell width={"20%"}>{queue.dateCreated ? DateTimeUtils.formatDateObject(queue.dateCreated) : ""}</TableCell>
                                        <TableCell width={"20%"}>{queue.dateModified ? DateTimeUtils.formatDateObject(queue.dateModified) : ""}</TableCell>
                                    </TableRow>
                                ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Grid sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end', marginTop: "10px" }}>
                        <PaginationPlus totalElements={totalElements} rowsPerPage={rowsPerPage} page={page} onChangePage={(page) => handleChangePage(null, page)} />
                        <TablePagination
                            rowsPerPageOptions={defaultRowsPerPageOptions}
                            component="div"
                            count={totalElements}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Grid>
                </Grid>
            }
        />
    );
}
