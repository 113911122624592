import {MobileProxyStatusDTO} from "../models/MobileProxyStatusDTO";
import {Page} from "../pagination/Page";
import {SortingConfiguration} from "../utils/SortingUtils";
import axiosInstance from "../network/AxiosInstance";
import {Dayjs} from "dayjs";

export class MobileProxiesService {
    static async getAllMobileProxies(page: number, size: number, serialNumber: string, friendlyName: string,
                                     server: string, countryId: number | undefined, proxyUrl: string, rotateUrl: string,
                                     date: string | null | Dayjs, status: string, sortingConfiguration: SortingConfiguration | undefined): Promise<Page<MobileProxyStatusDTO>> {
            const params = new URLSearchParams({
                page: page.toString(),
                size: size.toString()
            });

            if(serialNumber){
                params.append("serialNumber", serialNumber);
            }

            if(friendlyName){
                params.append("friendlyName", friendlyName);
            }

            if(server){
                params.append("server", server)
            }

            if(countryId !== undefined){
                params.append("countryId", countryId.toString())
            }

            if(proxyUrl){
                params.append("proxyUrl", proxyUrl)
            }

            if(rotateUrl){
                params.append("rotateUrl", rotateUrl)
            }

            if(date){
                params.append("date", date.toString())
            }

            if(status){
                params.append("status", status)
            }

            sortingConfiguration?.appendToBackEndSearchParams(params);

            const response = await axiosInstance.get<Page<MobileProxyStatusDTO>>(
                `/rest/mobile-phones/mobile-proxy-status/all-paged`, {params: params}
            )

            const data = response.data;

            return new Page<MobileProxyStatusDTO>(
                data.content.map(mobileProxy => new MobileProxyStatusDTO(mobileProxy)),
                data.totalElements
            )
    }
}