import { strings } from "../../../localization/Localization";
import { SideBarPage } from "../../../components/SideBarPage";
import {
    Button, Grid,
    Input,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow, TextField,
} from "@mui/material";
import { TableHeader } from "../../../components/TableHeader";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { SortingConfiguration } from "../../../utils/SortingUtils";
import { Routes } from "../../../router/Routes";
import { PaginationPlus } from "../../../components/PaginationPlus";
import { defaultRowsPerPageOptions } from "../../../common/Constants";
import { NumberParser } from "../../../utils/NumberParser";
import { guardedCallback, guardedObject } from "../../../router/guards/GuardedCallback";
import { PrivilegeGuard, PrivilegeGuardMode } from "../../../router/guards/PrivilegeGuard";
import { Privileges } from "../../../models/nomenclatures/Privileges";
import {ProxyToProxyDTO} from "../../../models/ProxyToProxyDTO";
import {ProxyToProxyService} from "../../../services/ProxyToProxyService";
import {PaginatedSelect} from "../../../components/PaginatedSelect";
import {CountryDTO} from "../../../models/CountryDTO";
import {CountryService} from "../../../services/CountryService";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import IconButton from "@mui/material/IconButton";
import {Clear} from "@mui/icons-material";
import {Dayjs} from "dayjs";
import {DateTimeUtils} from "../../../utils/DateTimeUtils";
import dayjs from 'dayjs';

export function ProxyToProxy() {

    const listLinks = [
        { label: strings.dashboard, currentlyOpened: false, href: '/' },
        { label: strings.proxyToProxy, currentlyOpened: true },
    ];

    const columns = [
        {label: strings.name, sortBy: "name", sortable: true},
        {label: strings.country, sortBy: "country", sortable: true},
        {label: strings.url, sortBy: "url", sortable: true},
        {label: strings.dateCreated, sortBy: "dateCreated", sortable: true},
        {label: strings.dateModified, sortBy: "dateModified", sortable: true},
        {label: "", sortBy: "", sortable: false},
    ];

    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const [totalElements, setTotalElements] = useState(0);
    const rowsPerPage = NumberParser.parseNumber(searchParams.get("rowsPerPage"), 25);
    const page = NumberParser.parseNumber(searchParams.get("page"), 0);
    const [proxyToProxy, setProxyToProxy] = useState<ProxyToProxyDTO[]>([]);
    const name = searchParams.get("name") ?? "";
    const [country, setCountry] = useState<CountryDTO | undefined>(undefined);
    const url = searchParams.get("url") ?? "";
    const dateCreated = searchParams.get("dateCreated") ?? undefined;
    const dateModified = searchParams.get("dateModified") ?? undefined;

    function setSearchParam(key: string, value: string | null | undefined) {
        if (value) {
            searchParams.set(key, value);
        } else {
            searchParams.delete(key);
        }

        setSearchParams(searchParams)
    }

    const sortingConfigurationString = searchParams.get("sort");

    useEffect(() => {
        async function getProxyToProxy(page: number): Promise<void> {
            ProxyToProxyService.findAllPaged(page, rowsPerPage, name, url, country?.id, dateCreated, dateModified, SortingConfiguration.fromSearchString(sortingConfigurationString)).then((data) => {
                setProxyToProxy(data.content);
                setTotalElements(data.totalElements);
            })
        }

        getProxyToProxy(page).then(_ => { });
    }, [page, rowsPerPage, name, url, country, dateCreated, dateModified, sortingConfigurationString]);

    function updateSort(sortingConfiguration: SortingConfiguration): void {
        setSearchParam("sort", sortingConfiguration.toSearchString());
    }

    function setPage(page: number) {
        setSearchParam("page", page.toString());
    }

    function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
        setPage(newPage);
    }

    function handleNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("name", event.target.value);
        setPage(0);
    }

    function handleCountryChange(event: CountryDTO | undefined) {
        setCountry(event);
        setPage(0);
    }

    function handleUrlChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("url", event.target.value);
        setPage(0);
    }

    function handleChangeDateCreated(date: Dayjs | null) {
        setSearchParam("dateCreated", DateTimeUtils.formatDateDayJs(date));
        setPage(0);
    }

    function handleChangeDateModified(date: Dayjs | null) {
        setSearchParam("dateModified", DateTimeUtils.formatDateDayJs(date));
        setPage(0);
    }

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("rowsPerPage", event.target.value);
        setPage(0);
    }

    function navigateToAddProxyToProxy(): any {
        navigate(Routes.ADD_PROXY_TO_PROXY);
    }

    function navigateProxyToProxyRow(id: number): any {
        navigate(Routes.EDIT_PROXY_TO_PROXY.replace(":id", id.toString()));
    }

    function getRowLink(entry: ProxyToProxyDTO): string {
        return guardedObject([
            new PrivilegeGuard(
                [Privileges.MANAGE_PROXY, Privileges.READ_PROXIES],
                PrivilegeGuardMode.hasAll
            )
        ], Routes.EDIT_PROXY_TO_PROXY.replace(":id", entry.id.toString())) ?? "";
    }
    return(
        <SideBarPage
            pageTitle={strings.proxyToProxy}
            breadcrumbs={listLinks}
            component={
                <Grid>
                    <TableContainer component={Paper}>
                        <Table sx={{minWidth: 650}} aria-label="custom pagination table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{width: "20%"}}>
                                        <Input
                                            name="name"
                                            value={name}
                                            onChange={handleNameChange}
                                            placeholder={strings.nameFilter}
                                            style={{width: "auto"}}
                                        />
                                    </TableCell>
                                    <TableCell style={{paddingBottom: "30px", width: "20%"}}>
                                        <div style={{width: "75%"}}>
                                        <PaginatedSelect<CountryDTO>
                                            value={country}
                                            onChange={handleCountryChange}
                                            label={strings.country}
                                            valueMapper={(item) => item.id.toString()}
                                            keyMapper={(item) => item.id.toString()}
                                            itemMapper={(item) => <>{item.commonName}</>}
                                            labelMapper={(item) => item.commonName}
                                            dataFetcher={(page, size, filter) => {
                                                return CountryService.findAllCountriesPaged(
                                                    page,
                                                    size,
                                                    filter
                                                );
                                            }}
                                        />
                                        </div>
                                    </TableCell>
                                    <TableCell style={{width: "20%"}}>
                                        <Input
                                            name="url"
                                            value={url}
                                            onChange={handleUrlChange}
                                            placeholder={strings.urlFilter}
                                            style={{width: "auto"}}
                                        />
                                    </TableCell>
                                    <TableCell style={{width: "20%"}}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                onChange={handleChangeDateCreated}
                                                value={dateCreated ? dayjs(dateCreated) : null}
                                                label={strings.dateCreated}
                                                inputFormat="DD.MM.YYYY"
                                                renderInput={(params: any) => (
                                                    <TextField
                                                        {...params}
                                                        variant="standard"
                                                        onKeyDown={(e)=>e.preventDefault()}
                                                    />
                                                )}
                                                componentsProps={{
                                                    actionBar: {
                                                        actions: ["today"],
                                                    },
                                                }}
                                                InputProps={{
                                                    endAdornment: dateCreated && (
                                                        <IconButton sx={{height: "10px", width: "10px"}}
                                                                    onClick={() => {
                                                                        setSearchParam("dateCreated", null);
                                                                        setPage(0);
                                                                    }}>
                                                            <Clear/>
                                                        </IconButton>
                                                    ),
                                                }}
                                                InputAdornmentProps={{
                                                    position: "start",
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </TableCell>
                                    <TableCell style={{width: "20%"}}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                onChange={handleChangeDateModified}
                                                value={dateModified ? dayjs(dateModified) : null}
                                                label={strings.dateModified}
                                                inputFormat="DD.MM.YYYY"
                                                renderInput={(params: any) => (
                                                    <TextField
                                                        {...params}
                                                        variant="standard"
                                                        onKeyDown={(e)=>e.preventDefault()}
                                                    />
                                                )}
                                                componentsProps={{
                                                    actionBar: {
                                                        actions: ["today"],
                                                    },
                                                }}
                                                InputProps={{
                                                    endAdornment: dateModified && (
                                                        <IconButton sx={{height: "10px", width: "10px"}}
                                                                    onClick={() => {
                                                                        setSearchParam("dateModified", null);
                                                                        setPage(0);
                                                                    }}>
                                                            <Clear/>
                                                        </IconButton>
                                                    ),
                                                }}
                                                InputAdornmentProps={{
                                                    position: "start",
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </TableCell>
                                    <TableCell>
                                        <Button onClick={navigateToAddProxyToProxy} aria-label="add" variant={"contained"} sx={{float: "right"}}>
                                            {strings.addProxyToProxy}
                                        </Button>
                                    </TableCell>
                                </TableRow>
                                <TableHeader columns={columns} sortConfigurationString={sortingConfigurationString}
                                             updateSort={updateSort}></TableHeader>
                            </TableHead>
                            <TableBody>
                                {proxyToProxy.map((proxyToProxy) => (
                                    <TableRow
                                        component={getRowLink(proxyToProxy) ? Link : TableRow}
                                        to={getRowLink(proxyToProxy)}
                                        key={proxyToProxy.id}
                                        hover={true}
                                        className="cursor-pointer"
                                        onClick={guardedCallback([
                                            new PrivilegeGuard(
                                                [Privileges.MANAGE_PROXY, Privileges.READ_PROXIES],
                                                PrivilegeGuardMode.hasAll
                                            )
                                        ],() => navigateProxyToProxyRow(proxyToProxy.id))}>
                                        <TableCell width={"20%"}>{proxyToProxy.name}</TableCell>
                                        <TableCell width={"20%"}>{proxyToProxy.country?.commonName}</TableCell>
                                        <TableCell width={"20%"}>{proxyToProxy.url}</TableCell>
                                        <TableCell width={"20%"}>{proxyToProxy.dateCreated ? DateTimeUtils.formatDateObject(proxyToProxy.dateCreated) : ""} </TableCell>
                                        <TableCell width={"20%"}>{proxyToProxy.dateModified ? DateTimeUtils.formatDateObject(proxyToProxy.dateModified) : ""}</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Grid sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end', marginTop: "10px" }}>
                        <PaginationPlus totalElements={totalElements} rowsPerPage={rowsPerPage} page={page} onChangePage={(page) => handleChangePage(null, page)} />
                        <TablePagination
                            rowsPerPageOptions={defaultRowsPerPageOptions}
                            component="div"
                            count={totalElements}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Grid>
                </Grid>
            }
        />
    )
}
