export class CookieCollectionReducedDTO {
    id:number;
    dateCreated:Date;
    dateModified:Date;
    name:string;
    fromBot:boolean;

    constructor(json:CookieCollectionReducedDTO){
        this.id = json.id;
        this.dateCreated = new Date(json.dateCreated);
        this.dateModified = new Date(json.dateModified);
        this.name=json.name;
        this.fromBot=json.fromBot;
    }
}