export class ListenedArtistClientInfoDTO {
    serviceName:string;
    serviceId: number;
    totalListeningTimeInSeconds:number;
    totalViews:number | null;
    artist?:string | null;
    constructor(json:ListenedArtistClientInfoDTO){
        this.serviceName = json.serviceName;
        this.serviceId = json.serviceId;
        this.totalListeningTimeInSeconds = json.totalListeningTimeInSeconds;
        this.totalViews = json.totalViews;
        this.artist = json.artist;
    }

    artistEquals(other: ListenedArtistClientInfoDTO): boolean {
        return this.serviceId === other.serviceId && this.artist === other.artist;
    }
}
