import {SideBarPage} from "../../../components/SideBarPage";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";
import {ClientCreationDTO} from "../../../models/ClientCreationDTO";
import {strings} from "../../../localization/Localization";
import {Routes} from "../../../router/Routes";
import {ClientDTO} from "../../../models/ClientDTO";
import {CenteredCardLayout} from "../../../components/CenteredCardLayout";
import {Button, CardActions, FormControl, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ConfirmationModal from "../../../components/ConfirmationModal";
import {ClientService} from "../../../services/ClientService";
import {showErrorDialog, showSuccessDialog} from "../../../common/Dialogs";
import {ErrorHandler} from "../../../utils/ErrorHandler";
import { ServiceDTO } from "../../../models/ServiceDTO";
import { NumberParser } from "../../../utils/NumberParser";
import { ServicesService } from "../../../services/ServicesService";
import { Clear } from "@mui/icons-material";
import { TableHeader } from "../../../components/TableHeader";
import { SortingConfiguration } from "../../../utils/SortingUtils";
import { NullableDatePicker } from "../../../components/NullableDatePicker";
import { DateTimeUtils } from "../../../utils/DateTimeUtils";
import { Dayjs } from "dayjs";
import { ScraperService } from "../../../services/ScraperService";
import { ClientScraperDataDTO } from "../../../models/ClientScraperDataDTO";

export function ClientDetails() {
    const columns = [
        {label:strings.id, sortBy:"id", sortable:true},
        { label: strings.service, sortBy: "service.label", sortable: true },
        { label: strings.referenceKey, sortBy: "referenceKey", sortable: true },
        { label: strings.dateCreated, sortBy: "dateCreated", sortable: true },
    ]
    const [searchParams, setSearchParams] = useSearchParams();
    const [openModal, setOpenModal] = useState<boolean>(false);
    let {id} = useParams();
    const isAddMode = !id;
    function onSubmit() {
        return isAddMode ? addClient() : editClient()
    }
    const [clientData, setClientData] = useState<ClientDTO | null>(null);
    const rowsPerPage = NumberParser.parseNumber(searchParams.get("rowsPerPage"), 5);
    const page = NumberParser.parseNumber(searchParams.get("page"), 0);
    const serviceId = searchParams.get("serviceId");
    const [services, setServices] = useState<ServiceDTO[]>([]);
    const sortingConfigurationString = searchParams.get("sort");
    const [totalElements, setTotalElements] = useState<number>(0);
    const referenceKey = searchParams.get("referenceKey") ?? "";
    const dateCreated = searchParams.get("dateCreated") ?? null;
    const [clientScrapedData, setClientScrapedData] = useState<ClientScraperDataDTO[]>([])
    const clientId  = useParams();
    const listLinks = [
        { label: strings.dashboard, currentlyOpened: false, href:Routes.HOME},
        { label: strings.clients,  currentlyOpened: false, href:Routes.CLIENTS },
        {label: isAddMode ? strings.addClient : strings.editClient, currentlyOpened:true}
    ]

    const {
        handleSubmit,
        setValue,
        control,
        watch,
        getValues,
        formState: {errors}
    } = useForm<ClientCreationDTO>({
        defaultValues: {
            name: "",
        },
        mode: "onChange"
    });
    watch();

    const navigate = useNavigate();

    function handleCancel(): void {
        navigate(Routes.CLIENTS, {});
    }

    function handleClickOpen(): any {
        setOpenModal(true);
    }

    function handleClose(): any {
        setOpenModal(false);
    }
    function setSearchParam(key: string, value: string | null | undefined): void {
        if (value) {
            searchParams.set(key, value);
        } else {
            searchParams.delete(key);
        }

        setSearchParams(searchParams)
    }
    function setPage(page: number) {
        setSearchParam("page", page.toString());
    }
    function updateSort(sortingConfiguration: SortingConfiguration): void {
        setSearchParam("sort", sortingConfiguration.toSearchString());
    }
    function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("rowsPerPage", event.target.value);
        setPage(0);
    }

    function handleChangeServiceId(event: SelectChangeEvent) {
        const serviceIdString = event.target.value;
        setSearchParam("serviceId", serviceIdString.toString())
        setPage(0);
    }
    function handleChangeReferenceKey(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("referenceKey", event.target.value)
        setPage(0);
    }

    function handleChangeDateCreated(date: Dayjs | null) {
        setSearchParam("dateCreated",DateTimeUtils.formatDateDayJs(date));
        setPage(0);
    }
    useEffect(() => {
        async function getAllOfClientPaged(page: number): Promise<void> {
            const response = await ScraperService.getAllOfClientPaged(
              page,
              rowsPerPage,
              clientId.id,
              serviceId,
              referenceKey,
              dateCreated,
              SortingConfiguration.fromSearchString(sortingConfigurationString),
            );
            setTotalElements(response.totalElements);
            setClientScrapedData(response.content);
          }
      
        getAllOfClientPaged(page).then(_ => {});

        function getClientById(id: number) {
            ClientService.getClientById(id).then((res) => {
                setClientData(res);
                setValue("name", res.name ?? "");
            })
        }
        async function getAllServices(): Promise<void> {
            const data = await ServicesService.getAllServices();
            setServices(data);
          }
        async function getData(){
            if (id) {
                await getClientById(parseInt(id));
            }
            getAllServices();
        }

        getData().then(_ => {
        });
    }, [id, setValue, page, rowsPerPage,serviceId, referenceKey, dateCreated, searchParams, sortingConfigurationString])

    function addClient() {
        const client = new ClientCreationDTO(getValues("name"),
        );

        ClientService.createClient(client).then(() => {
            showSuccessDialog(strings.success, strings.clientAddedSuccessfully, strings.ok).then(_ => {
            });

            navigate(Routes.CLIENTS);
        }).catch(e => {
            const errorMessage = ErrorHandler.parseErrorMessage(e);
            showErrorDialog(strings.error, errorMessage, strings.ok).then(_ => {
            });
        });
    }

    function editClient() {
        const client = new ClientCreationDTO(
            getValues("name"),
        );

        if (clientData === null) {
            return;
        }

        ClientService.editClient(client, clientData?.id).then(() => {
            showSuccessDialog(strings.success, strings.clientUpdatedSuccessfully, strings.ok).then(_ => {
            });

            navigate(Routes.CLIENTS);
        }).catch(e => {
            const errorMessage = ErrorHandler.parseErrorMessage(e);
            showErrorDialog(strings.error, errorMessage, strings.ok).then(_ => {
            });
        });
    }

    async function deleteClient(): Promise<void> {
        setOpenModal(false);

        if (clientData === null) {
            return;
        }

        try {
            await ClientService.deleteClient(clientData.id);
            showSuccessDialog(strings.success, strings.clientDeletedSuccessfully, strings.ok).then(_ => {
                navigate(Routes.CLIENTS);
                window.location.reload();
            });
        } catch (e: any) {
            const errorMessage = ErrorHandler.parseErrorMessage(e);
            showErrorDialog(strings.error, errorMessage, strings.ok).then(_ => {});
        }
    }

    return (
        <SideBarPage pageTitle={isAddMode ? strings.addClient : strings.editClient} breadcrumbs={listLinks} component={
            <>
                <CenteredCardLayout minWidth={"60%"} maxWidth={"60%"}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Controller
                            name="name"
                            rules={{required: true}}
                            control={control}
                            render={({field}) => (
                                <TextField
                                    InputLabelProps={{
                                        shrink: !!getValues("name"),
                                    }}
                                    {...field}
                                    value={field.value}
                                    id={"name"}
                                    label={strings.clientName}
                                    className={"w-100"}
                                    style={{marginBottom: 15}}
                                    error={errors.name !== undefined}
                                />
                            )}
                        />
                        <CardActions className="d-flex p-0 mt-4">
                            {isAddMode ? <Button
                                variant="contained"
                                onClick={handleCancel}
                                color={"warning"}
                                className="w-50"
                            >
                                {strings.cancel}
                            </Button> : <Button
                                variant="contained"
                                startIcon={<DeleteIcon/>}
                                onClick={handleClickOpen}
                                color={"error"}
                                className="w-50"
                            >
                                {strings.deleteClient}
                            </Button>}
                            <Button
                                variant="contained"
                                type="submit"
                                startIcon={<EditIcon/>}
                                color={"primary"}
                                className="w-50"
                            >
                                {strings.saveChanges}
                            </Button>
                            <ConfirmationModal
                                open={openModal}
                                handleClose={handleClose}
                                handleConfirm={deleteClient}
                                title={strings.deleteClient}
                                content={strings.deleteClientModal}
                                actionButtonName={strings.deleteClient}
                            />
                        </CardActions>
                    </form>
                </CenteredCardLayout>

                {!isAddMode &&   
                <>
                <h4 className="mt-5">{strings.scraperTargets}</h4>              
                <CenteredCardLayout minWidth={"60%"} maxWidth={"60%"} className="mt-5">
                    <TableContainer>
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                    </TableCell>
                                    <TableCell>
                                        <FormControl fullWidth variant="standard">
                                            <InputLabel>{strings.service}</InputLabel>
                                            <Select
                                                defaultValue={""}
                                                value={services.length > 0 && serviceId ? String(serviceId) : ""}
                                                onChange={handleChangeServiceId}
                                                label={strings.service}
                                                labelId="serviceLabel"
                                                endAdornment={
                                                    serviceId && (
                                                        <IconButton
                                                            onClick={() =>
                                                                setSearchParam("serviceId", null)
                                                            }
                                                        >
                                                            <Clear />
                                                        </IconButton>
                                                    )
                                                }
                                            >
                                                {services?.map((service) => (
                                                    <MenuItem disableRipple key={service.id} value={service.id}>
                                                        {service.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell>
                                    <TextField
                                     variant="standard"
                                     value={referenceKey}
                                     label={strings.referenceKey}
                                     name="key"
                                     fullWidth
                                     onChange={handleChangeReferenceKey}
                                    />
                                    </TableCell>
                                    <TableCell>
                                    <NullableDatePicker label={strings.dateCreated} value={dateCreated} onChange={handleChangeDateCreated} />
                                    </TableCell>
                                </TableRow>
                                <TableHeader columns={columns} sortConfigurationString={sortingConfigurationString} updateSort={updateSort} />
                            </TableHead>
                            <TableBody>
                                  {clientScrapedData.map((client)=>(
                                    <TableRow key={client.id}>
                                        <TableCell>{client.id}</TableCell>
                                        <TableCell>{client.service?.label}</TableCell>
                                        <TableCell>{client.referenceKey}</TableCell>
                                        <TableCell>{DateTimeUtils.formatDate(client.dateCreated)}</TableCell>
                                    </TableRow>

                                  ))}                  
                               
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={totalElements}
                        rowsPerPage={rowsPerPage}
                        page={totalElements <= 0 ? 0 : page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </CenteredCardLayout>
                </>
         }
            </>
        } />
            )
}