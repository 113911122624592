import {PrivilegeGuardData} from "../router/guards/PrivilegeGuard";

export class SideBarItem {
    constructor(
        public name: string,
        public path: string,
        public icon: any,
        public children: SideBarItem[],
        public privilegeGuardData?: PrivilegeGuardData,
    ) {
    }
}
