import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {Button, CardActions, Grid, TextField} from "@mui/material";
import {useEffect, useState} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";
import {strings} from '../../../localization/Localization';
import {CenteredCardLayout} from "../../../components/CenteredCardLayout";
import {SideBarPage} from "../../../components/SideBarPage";
import ConfirmationModal from "../../../components/ConfirmationModal";
import {ErrorHandler} from "../../../utils/ErrorHandler";
import {showErrorDialog, showSuccessDialog} from "../../../common/Dialogs";
import {Routes} from "../../../router/Routes";
import {RecordLabelDTO} from "../../../models/RecordLabelDTO";
import {RecordLabelCreationDTO} from '../../../models/RecordLabelCreationDTO';
import {RecordLabelsService} from "../../../services/RecordLabelsService";

export function RecordLabelsDetails() {
    const [searchParams, setSearchParams] = useSearchParams();

    const [openModal, setOpenModal] = useState<boolean>(false);
    const [recordLabelData, setRecordLabelData] = useState<RecordLabelDTO | null>(null);
    const [recordLabels, setRecordLabels] = useState<RecordLabelDTO[]>([]);
    const key = searchParams.get("key") ?? "";
    const name = searchParams.get("name") ?? "";

    let { id } = useParams();

    function onSubmit() {
        const recordLabel = new RecordLabelCreationDTO(
            getValues("key"),
            getValues("name")
        );

        if (isAddMode) {
            addRecordLabel(recordLabel);
        } else {
            editRecordLabel(recordLabel);
        }
    }

    const {
        handleSubmit,
        setValue,
        control,
        watch,
        getValues,
        formState: { errors }
    } = useForm<RecordLabelCreationDTO>({
        defaultValues: {
            key: "",
            name: ""
        },
        mode: "onChange"
    });
    watch();

    const isAddMode = !id;
    const listLinks = [
        { label: strings.dashboard, currentlyOpened: false, href: Routes.HOME },
        { label: strings.recordLabels, currentlyOpened: false, href: Routes.RECORD_LABEL },
        { label: isAddMode ? strings.addRecordLabel : strings.editRecordLabel, currentlyOpened: true }
    ];

    const navigate = useNavigate();

    useEffect(() => {
        function getRecordLabelById(id: number) {
            RecordLabelsService.getRecordLabel(id).then((res) => {
                setRecordLabelData(res);
                setValue("key", res.key ?? "");
                setValue("name", res.name ?? "");
            })
        }

        async function getData() {
            if (id) {
                await getRecordLabelById(parseInt(id));
            }
        }

        getData().then(_ => {
        });
    }, [id, setValue])

    useEffect(() => {
        async function getRecordLabels(): Promise<void> {
            RecordLabelsService.getAllRecordLabelsPaged(0, 0, key, name, undefined).then((data) => {
                setRecordLabels(data.content);
                // setTotalElements(data.totalElements);
            })
        }

        getRecordLabels().then(_ => { });
    }, [id, setValue]);

    function addRecordLabel(recordLabel: RecordLabelCreationDTO) {
        RecordLabelsService.createRecordLabel(recordLabel).then(() => {
            showSuccessDialog(strings.success, strings.recordLabelAddedSuccessfully, strings.ok).then(_ => {
            });

            navigate(Routes.RECORD_LABEL);
        }).catch(e => {
            const errorMessage = ErrorHandler.parseErrorMessage(e);
            showErrorDialog(strings.error, errorMessage, strings.ok).then(_ => {
            });
        });
    }

    function editRecordLabel(recordLabel: RecordLabelCreationDTO) {
        RecordLabelsService.editRecordLabel(recordLabel, recordLabelData?.id).then(() => {
            showSuccessDialog(strings.success, strings.recordLabelEditedSuccessfully, strings.ok).then(_ => {
            });

            navigate(Routes.RECORD_LABEL);
        }).catch(e => {
            const errorMessage = ErrorHandler.parseErrorMessage(e);
            showErrorDialog(strings.error, errorMessage, strings.ok).then(_ => {
            });
        });
    }

    async function deleteRecordLabel(): Promise<void> {
        setOpenModal(false);

        if (recordLabelData === null) {
            return;
        }

        try {
            await RecordLabelsService.deleteRecordLabel(recordLabelData.id);
            showSuccessDialog(strings.success, strings.recordLabelDeletedSuccessfully, strings.ok).then(_ => {
                navigate(Routes.RECORD_LABEL);
            });
        } catch (e: any) {
            const errorMessage = ErrorHandler.parseErrorMessage(e);
            showErrorDialog(strings.error, errorMessage, strings.ok).then(_ => { });
        }
    }

    function handleCancel(): void {
        navigate(Routes.RECORD_LABEL, {});
    }

    function handleClickOpen(): any {
        setOpenModal(true);
    }

    function handleClose(): any {
        setOpenModal(false);
    }

    return (
        <SideBarPage pageTitle={isAddMode ? strings.addRecordLabel : strings.editRecordLabel}
                     breadcrumbs={listLinks}
                     component={
                         <Grid sx={{display: "flex", flexDirection: "row"}}>
                             <CenteredCardLayout minHeight={"100%"} minWidth={"100%"}>
                                 <form onSubmit={handleSubmit(onSubmit)}>
                                     <Controller
                                         name="key"
                                         rules={{ required: true }}
                                         control={control}
                                         render={({ field }) => (
                                             <TextField
                                                 InputLabelProps={{
                                                     shrink: !!getValues("key"),
                                                 }}
                                                 {...field}
                                                 value={field.value}
                                                 id={"key"}
                                                 label={strings.recordLabelKey}
                                                 className={"w-100"}
                                                 style={{ marginBottom: 15 }}
                                                 error={errors.key !== undefined}
                                             />
                                         )}
                                     />
                                     <Controller
                                         name="name"
                                         rules={{ required: true }}
                                         control={control}
                                         render={({ field }) => (
                                             <TextField
                                                 InputLabelProps={{
                                                     shrink: !!getValues("name"),
                                                 }}
                                                 {...field}
                                                 value={field.value}
                                                 id={"key"}
                                                 label={strings.recordLabelName}
                                                 className={"w-100"}
                                                 style={{ marginBottom: 15 }}
                                                 error={errors.name !== undefined}
                                             />
                                         )}
                                     />
                                     <CardActions className="d-flex p-0 mt-4">
                                         {isAddMode ? (
                                             <>
                                                 <Button
                                                     variant="contained"
                                                     onClick={handleCancel}
                                                     color={"warning"}
                                                     className="w-50"
                                                 >
                                                     {strings.cancel}
                                                 </Button>
                                                 <Button
                                                     variant="contained"
                                                     type="submit"
                                                     startIcon={<EditIcon />}
                                                     color={"primary"}
                                                     className="w-50"
                                                 >
                                                     {strings.saveChanges}
                                                 </Button>
                                             </>
                                         ) : (
                                             <>
                                                 <Button
                                                     variant="contained"
                                                     startIcon={<DeleteIcon />}
                                                     onClick={handleClickOpen}
                                                     color={"error"}
                                                     className="w-50"
                                                 >
                                                     {strings.deleteRecordLabelM}
                                                 </Button>
                                                 <Button
                                                     variant="contained"
                                                     type="submit"
                                                     startIcon={<EditIcon />}
                                                     color={"primary"}
                                                     className="w-50"
                                                 >
                                                     {strings.saveChanges}
                                                 </Button>
                                             </>
                                         )}
                                         <ConfirmationModal
                                             open={openModal}
                                             handleClose={handleClose}
                                             handleConfirm={deleteRecordLabel}
                                             title={strings.deleteRecordLabel}
                                             content={strings.deleteRecordLabelSure}
                                             actionButtonName={strings.deleteRecordLabelM}
                                         />
                                     </CardActions>
                                 </form>
                             </CenteredCardLayout>
                         </Grid>
                     } />
    );
}
