import {Page} from "../pagination/Page";
import axios from "../network/AxiosInstance";
import {AuthService} from "./AuthService";
import {UserDTO} from "../models/UserDTO";
import {UserFilter} from "../models/UserFilter";
import {UserCreationDTO} from "../models/UserCreationDTO";
import {SortingConfiguration} from "../utils/SortingUtils";


export class UserService{
    static async getAllUsersPaged(page: number, size: number, userFilter : UserFilter,sortingConfiguration: SortingConfiguration | null): Promise<Page<UserDTO>> {
        const params = new URLSearchParams({
            page: page.toString(),
            size: size.toString()
        });

        sortingConfiguration?.appendToBackEndSearchParams(params);

        const response = await axios.post<Page<UserDTO>>(
            `${AuthService.PREFIX}/userroles/user/all-paged/`, userFilter,{params:params}
        );

        const data = response.data

        return new Page<UserDTO>(
            data.content.map(user => new UserDTO(user)),
            data.totalElements
        )
    }
    static async createUser(userData:UserCreationDTO, password:string): Promise<void> {
        await axios.post<Page<UserDTO>>(
            `${AuthService.PREFIX}/userroles/user/create`, userData,{ headers:{
                "password" : password
            }}
        );
    }
    static async getById(id : number): Promise<UserDTO> {
        const response = await axios.get<UserDTO>(
            `${AuthService.PREFIX}/userroles/user/get/` + id
        );
        return response.data
    }
    static async updateUser(userData:UserCreationDTO, id:number): Promise<void> {
        await axios.post<Page<UserDTO>>(
            `${AuthService.PREFIX}/userroles/user/update/`+id, userData,{ headers:{}}
        );
    }
    static async updatePassword(password:string, id:number): Promise<void> {
        await axios.put<Page<UserDTO>>(
            `${AuthService.PREFIX}/userroles/user/update-password/`+id, {},{ headers:{
                    "password" : password
                }}
        );
    }
    static async deleteUser(id:number): Promise<void> {
        await axios.delete(
            `${AuthService.PREFIX}/userroles/user/delete/`+id
        );
    }
    static async confirmRegistration (token:string):Promise<any> {
        const response = await axios.post<[]>(
            `${AuthService.PREFIX}/userroles/user/confirm-registration`,null,  {params:{token:token}}
        );
        return response.data
    }

}