import React, { useEffect, useState } from "react"
import {createSearchParams, Link, NavigateFunction, Route, useNavigate, useSearchParams} from "react-router-dom";
import { SideBarPage } from "../../components/SideBarPage";
import { strings } from "../../localization/Localization";
import { ListenedTrackInfoDTO } from "../../models/ListenedTrackInfoDTO";
import { Routes } from "../../router/Routes";
import { ListenedTracksService } from "../../services/ListenedTracksService";
import { NumberParser } from "../../utils/NumberParser";
import { SortingConfiguration } from "../../utils/SortingUtils";
import {Clear} from "@mui/icons-material";
import {
    FormControl, Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
} from "@mui/material";
import {Box} from "@mui/system";
import { ServicesService } from "../../services/ServicesService";
import { ServiceDTO } from "../../models/ServiceDTO";
import { TableHeader } from "../../components/TableHeader";
import { ListenedTrackController } from "../../controllers/ListenedTrackController";
import { DateTimeUtils } from "../../utils/DateTimeUtils";
import {PaginationPlus} from "../../components/PaginationPlus";
export function ListenedTracksPage(){
    const [listenedTracks, setListenedTrack] = useState<ListenedTrackInfoDTO[]>([]);
    const columns = [
        { label: strings.name, sortBy: "name", sortable: true },
        { label: strings.artist, sortBy: "artist", sortable: true },
        { label: strings.service, sortBy: "service.label", sortable: true },
        { label: strings.trackTimeElapsed, sortBy: "timeElapsedInSeconds", sortable: true },
      ]
      const listLinks = [
        { label: strings.dashboard, currentlyOpened: false, href:Routes.HOME},
        { label: strings.listenedTracks,  currentlyOpened: true },
      ]
    const [searchParams, setSearchParams] = useSearchParams();
    const name = searchParams.get("name") ?? "";
    const artist = searchParams.get("artist") ?? "";
    const serviceId = searchParams.get("serviceId") ?? "";
    const timeElapsedInSeconds = searchParams.get("timeElapsedInSeconds") ?? "";
    const sortingConfigurationString = searchParams.get("sort");
    const rowsPerPage = NumberParser.parseNumber(searchParams.get("rowsPerPage"), 25);
    const page = NumberParser.parseNumber(searchParams.get("page"), 0);
    const [totalElements, setTotalElements] = useState(0);
    const [services, setServices] = useState<ServiceDTO[]>([]);
    const navigate = useNavigate();

    function setSearchParam(key: string, value: string | null | undefined) {
      if (value) {
        searchParams.set(key, value);
      } else {
        searchParams.delete(key);
      }
  
      setSearchParams(searchParams)
    }
    function setPage(page: number) {
        setSearchParam("page", page.toString());
      }
    
    function updateSort(sortingConfiguration: SortingConfiguration): void {
        setSearchParam("sort", sortingConfiguration.toSearchString());
      }

    function handleChangePage(
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("rowsPerPage", event.target.value);
        setPage(0);
    }

    function handleChangeName(event: React.ChangeEvent<HTMLInputElement>) {
       setSearchParam("name", event.target.value);
       setPage(0)
    }

    function handleChangeArtist(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("artist", event.target.value);
        setPage(0)
    }

    function handleChangeServiceId(event: SelectChangeEvent) {
        setSearchParam("serviceId", event.target.value);
        setPage(0)
    }
   
useEffect(()=>{
    async function getAllListenedTracksPaged(page: number): Promise<void> {
        const response = await ListenedTracksService.getAllListenedTracksPaged(page,rowsPerPage,name,artist, serviceId, SortingConfiguration.fromSearchString(sortingConfigurationString));
        setTotalElements(response.totalElements);
        setListenedTrack(response.content);
    }

    getAllListenedTracksPaged(page).then((_) => {
    });
},[name,artist,serviceId,timeElapsedInSeconds,page,rowsPerPage,sortingConfigurationString])
useEffect(()=>{
    async function getAllServices(): Promise<void> {
        const services = await ServicesService.getAllServices();
        setServices(services);
    }
    async function loadData(): Promise<void> {
        await getAllServices();
    }

    loadData().then((_) => {
    });
},[])

        let url = Routes.LISTENED_TRACKS_DETAILS;
        const params = {name: name.toString(), artist:artist.toString(), serviceId:serviceId.toString()}
        // return navigate({pathname:url, search:`?${createSearchParams(params)}`});

return (
    <>
        <SideBarPage
            pageTitle={strings.listenedTracks}
            breadcrumbs={listLinks}
            component={
                <Box>
                    <TableContainer component={Paper}>
                        <Table
                            sx={{minWidth: 500}}
                            aria-label="custom pagination table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        {" "}
                                        <TextField
                                            value={name}
                                            name="name"
                                            variant="standard"
                                            label={strings.name}
                                            onChange={handleChangeName}
                                            fullWidth
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            value={artist}
                                            name="artist"
                                            variant="standard"
                                            label={strings.artist}
                                            onChange={handleChangeArtist}
                                            fullWidth
                                        />
                                    </TableCell>
                               
                                    <TableCell>
                                        {" "}
                                        <FormControl fullWidth variant="standard">
                                            <InputLabel>{strings.service}</InputLabel>
                                            <Select
                                                defaultValue={""}
                                                value={
                                                    serviceId ? String(serviceId) : ""
                                                }
                                                onChange={handleChangeServiceId}
                                                label={strings.service}
                                                labelId="serviceLabel"
                                                endAdornment={
                                                    serviceId && (
                                                        <IconButton
                                                            onClick={() => {
                                                                setSearchParam("serviceId", undefined);
                                                                setPage(0);
                                                            }}
                                                        >
                                                            <Clear/>
                                                        </IconButton>
                                                    )
                                                }
                                            >
                                                {services?.map((service) => (
                                                    <MenuItem
                                                        disableRipple
                                                        key={service.id}
                                                        value={service.id}
                                                    >
                                                        {service.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell>
                                       
                                    </TableCell>
                                 
                              
                                </TableRow>
                                <TableHeader columns={columns} sortConfigurationString={sortingConfigurationString} updateSort={updateSort} />
                            </TableHead>
                            <TableBody>
                                {listenedTracks.map((track,index) => (
                                    <TableRow key={index}
                                              component={Link} to={{pathname:Routes.LISTENED_TRACKS_DETAILS, search:`?${createSearchParams({name: track.name.toString(), artist:track.artist.toString(), serviceId:track.service.id.toString()})}`}}
                                               hover={true} className="cursor-pointer">
                                        <TableCell component="th" scope="row">
                                            {track.name}
                                        </TableCell>
                                        <TableCell>{track.artist}</TableCell>
                                        <TableCell>{track.service?.label}</TableCell>
                                        <TableCell>
                                           {DateTimeUtils.convertSectoDay(track.timeElapsedInSeconds)}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Grid sx={{display: 'flex', flexDirection: 'column', alignItems: 'end', marginTop: "10px"}}>
                    <PaginationPlus totalElements={totalElements} rowsPerPage={rowsPerPage} page={page} onChangePage={(page) => handleChangePage(null, page)}/>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={totalElements}
                        rowsPerPage={rowsPerPage}
                        page={totalElements <=0 ? 0 : page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    </Grid>
                </Box>
            }
        />
    </>
);
}

