import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {Button, CardActions, Checkbox, Grid, Table, TableBody, TableHead, TableRow, TextField} from "@mui/material";
import {useEffect, useState} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";
import {GenresService} from "../../../services/GenresService";
import {GenreDTO} from "../../../models/GenreDTO";
import {GenreCreationDTO} from "../../../models/GenreCreationDTO";
import {strings} from '../../../localization/Localization';
import {CenteredCardLayout} from "../../../components/CenteredCardLayout";
import {SideBarPage} from "../../../components/SideBarPage";
import ConfirmationModal from "../../../components/ConfirmationModal";
import {ErrorHandler} from "../../../utils/ErrorHandler";
import {showErrorDialog, showSuccessDialog} from "../../../common/Dialogs";
import {Routes} from "../../../router/Routes";
import TableCell from "@mui/material/TableCell";

export function GenresDetails() {
    const [searchParams, setSearchParams] = useSearchParams();

    const [openModal, setOpenModal] = useState<boolean>(false);
    const [genreData, setGenreData] = useState<GenreDTO | null>(null);
    const [genres, setGenres] = useState<GenreDTO[]>([]);
    const key = searchParams.get("key") ?? "";
    const name = searchParams.get("name") ?? "";

    let { id } = useParams();

    function onSubmit() {
        const genre = new GenreCreationDTO(
            getValues("key"),
            getValues("name"),
            getValues("relatedGenreKeys")
        );

        if (isAddMode) {
            addGenre(genre);
        } else {
            editGenre(genre);
        }
    }

    const {
        handleSubmit,
        setValue,
        control,
        watch,
        getValues,
        formState: { errors }
    } = useForm<GenreCreationDTO>({
        defaultValues: {
            key: "",
            name: "",
            relatedGenreKeys: []
        },
        mode: "onChange"
    });
    watch();

    const isAddMode = !id;
    const listLinks = [
        { label: strings.dashboard, currentlyOpened: false, href: Routes.HOME },
        { label: strings.genres, currentlyOpened: false, href: Routes.GENRES },
        { label: isAddMode ? strings.addGenre : strings.editGenre, currentlyOpened: true }
    ];

    const navigate = useNavigate();

    useEffect(() => {
        function getGenreById(id: number) {
            GenresService.getGenre(id).then((res) => {
                setGenreData(res);
                setValue("key", res.key ?? "");
                setValue("name", res.name ?? "");
                setValue("relatedGenreKeys", res.relatedGenreKeys ?? []);
            })
        }

        async function getData() {
            if (id) {
                await getGenreById(parseInt(id));
            }
        }

        getData().then(_ => {
        });
    }, [id, setValue])

    useEffect(() => {
        async function getGenres(): Promise<void> {
            GenresService.getAllGenresPaged(0, 0, key, name, undefined).then((data) => {
                setGenres(data.content);
                // setTotalElements(data.totalElements);
            })
        }

        getGenres().then(_ => { });
    }, [id, setValue]);

    function addGenre(genre: GenreCreationDTO) {
        GenresService.createGenre(genre).then(() => {
            showSuccessDialog(strings.success, strings.genreAddedSuccessfully, strings.ok).then(_ => {
            });

            navigate(Routes.GENRES);
        }).catch(e => {
            const errorMessage = ErrorHandler.parseErrorMessage(e);
            showErrorDialog(strings.error, errorMessage, strings.ok).then(_ => {
            });
        });
    }

    function editGenre(genre: GenreCreationDTO) {
        GenresService.editGenre(genre, genreData?.id).then(() => {
            showSuccessDialog(strings.success, strings.genreEditSuccessfully, strings.ok).then(_ => {
            });

            navigate(Routes.GENRES);
        }).catch(e => {
            const errorMessage = ErrorHandler.parseErrorMessage(e);
            showErrorDialog(strings.error, errorMessage, strings.ok).then(_ => {
            });
        });
    }

    async function deleteGenre(): Promise<void> {
        setOpenModal(false);

        if (genreData === null) {
            return;
        }

        try {
            await GenresService.deleteGenre(genreData.id);
            showSuccessDialog(strings.success, strings.genreDeletedSuccessfully, strings.ok).then(_ => {
                navigate(Routes.GENRES);
            });
        } catch (e: any) {
            const errorMessage = ErrorHandler.parseErrorMessage(e);
            showErrorDialog(strings.error, errorMessage, strings.ok).then(_ => { });
        }
    }

    function handleCancel(): void {
        navigate(Routes.GENRES, {});
    }

    function handleClickOpen(): any {
        setOpenModal(true);
    }

    function handleClose(): any {
        setOpenModal(false);
    }

    return (
        <SideBarPage pageTitle={isAddMode ? strings.addGenre : strings.editGenre}
                     breadcrumbs={listLinks}
                     component={
                         <Grid sx={{display: "flex", flexDirection: "row"}}>
                             <CenteredCardLayout minHeight={"100%"} minWidth={"100%"}>
                                 <form onSubmit={handleSubmit(onSubmit)}>
                                     <Controller
                                         name="key"
                                         rules={{ required: true }}
                                         control={control}
                                         render={({ field }) => (
                                             <TextField
                                                 InputLabelProps={{
                                                     shrink: !!getValues("key"),
                                                 }}
                                                 {...field}
                                                 value={field.value}
                                                 id={"key"}
                                                 label={strings.genreKey}
                                                 className={"w-100"}
                                                 style={{ marginBottom: 15 }}
                                                 error={errors.key !== undefined}
                                             />
                                         )}
                                     />
                                     <Controller
                                         name="name"
                                         rules={{ required: true }}
                                         control={control}
                                         render={({ field }) => (
                                             <TextField
                                                 InputLabelProps={{
                                                     shrink: !!getValues("name"),
                                                 }}
                                                 {...field}
                                                 value={field.value}
                                                 id={"key"}
                                                 label={strings.genreName}
                                                 className={"w-100"}
                                                 style={{ marginBottom: 15 }}
                                                 error={errors.name !== undefined}
                                             />
                                         )}
                                     />
                                     <CardActions className="d-flex p-0 mt-4">
                                         {isAddMode ? (
                                             <>
                                                 <Button
                                                     variant="contained"
                                                     onClick={handleCancel}
                                                     color={"warning"}
                                                     className="w-50"
                                                 >
                                                     {strings.cancel}
                                                 </Button>
                                                 <Button
                                                     variant="contained"
                                                     type="submit"
                                                     startIcon={<EditIcon />}
                                                     color={"primary"}
                                                     className="w-50"
                                                 >
                                                     {strings.saveChanges}
                                                 </Button>
                                             </>
                                         ) : (
                                             <>
                                                 <Button
                                                     variant="contained"
                                                     startIcon={<DeleteIcon />}
                                                     onClick={handleClickOpen}
                                                     color={"error"}
                                                     className="w-50"
                                                 >
                                                     {strings.deleteGenreM}
                                                 </Button>
                                                 <Button
                                                     variant="contained"
                                                     type="submit"
                                                     startIcon={<EditIcon />}
                                                     color={"primary"}
                                                     className="w-50"
                                                 >
                                                     {strings.saveChanges}
                                                 </Button>
                                             </>
                                         )}
                                         <ConfirmationModal
                                             open={openModal}
                                             handleClose={handleClose}
                                             handleConfirm={deleteGenre}
                                             title={strings.deleteGenre}
                                             content={strings.deleteGenreSure}
                                             actionButtonName={strings.deleteGenreM}
                                         />
                                     </CardActions>
                                 </form>
                             </CenteredCardLayout>
                             <CenteredCardLayout minHeight={"100%"} minWidth={"80%"}>
                                     <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                         <TableHead>
                                             <TableRow>
                                                 <TableCell>{strings.relatedGenres}</TableCell>
                                                 <TableCell></TableCell>
                                             </TableRow>
                                             <TableRow>
                                                 <TableCell>{strings.select}</TableCell>
                                                 <TableCell>{strings.genreName}</TableCell>
                                             </TableRow>
                                         </TableHead>
                                         <TableBody>
                                             {genres.map((genre, index) => (
                                                 <TableRow>
                                                     <TableCell>
                                                         <Controller
                                                             name="relatedGenreKeys"
                                                             rules={{ required: false }}
                                                             control={control}
                                                             render={({ field }) => (
                                                                 <Checkbox
                                                                     key={index}
                                                                     checked={field.value.includes(genre.key)}
                                                                     onChange={(event) => {
                                                                         if (event.target.checked) {
                                                                             field.onChange([...field.value, genre.key]);
                                                                         } else {
                                                                             field.onChange(field.value.filter(id => id !== genre.key));
                                                                         }
                                                                     }}
                                                                 />
                                                             )}
                                                         />
                                                     </TableCell>
                                                     <TableCell>
                                                         {genre.name}
                                                     </TableCell>
                                                 </TableRow>
                                             ))}
                                         </TableBody>
                                     </Table>
                             </CenteredCardLayout>
                         </Grid>
                     } />
    );
}
