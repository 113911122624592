export class AccountGroupScheduleCreationDTO {
    accountGroupId: number
    startTime: string;
    maxAccounts?: number;
    onlyProblematicAccounts: boolean;

    constructor(json: any) {
        this.accountGroupId = json.accountGroupId;
        this.startTime = json.startTime;
        this.maxAccounts = json.maxAccounts;
        this.onlyProblematicAccounts = json.onlyProblematicAccounts;
    }
}
