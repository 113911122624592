import { strings } from "../../../localization/Localization";
import { SideBarPage } from "../../../components/SideBarPage";
import {
    Button, Grid,
    Input,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from "@mui/material";
import { TableHeader } from "../../../components/TableHeader";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { SortingConfiguration } from "../../../utils/SortingUtils";
import { Routes } from "../../../router/Routes";
import { PaginationPlus } from "../../../components/PaginationPlus";
import { defaultRowsPerPageOptions } from "../../../common/Constants";
import { NumberParser } from "../../../utils/NumberParser";
import { guardedCallback, guardedObject } from "../../../router/guards/GuardedCallback";
import { PrivilegeGuard, PrivilegeGuardMode } from "../../../router/guards/PrivilegeGuard";
import { Privileges } from "../../../models/nomenclatures/Privileges";
import {RecordLabelDTO} from "../../../models/RecordLabelDTO";
import {RecordLabelsService} from "../../../services/RecordLabelsService";

export function RecordLabels() {

    const listLinks = [
        { label: strings.dashboard, currentlyOpened: false, href: '/' },
        { label: strings.recordLabels, currentlyOpened: true },
    ];

    const columns = [
        {label: strings.key, sortBy: "key", sortable: true},
        {label: strings.name, sortBy: "name", sortable: true},
        {label: "", sortBy: "", sortable: false},
    ];

    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const [totalElements, setTotalElements] = useState(0);
    const rowsPerPage = NumberParser.parseNumber(searchParams.get("rowsPerPage"), 25);
    const page = NumberParser.parseNumber(searchParams.get("page"), 0);
    const [recordLabels, setRecordLabels] = useState<RecordLabelDTO[]>([]);
    const key = searchParams.get("key") ?? "";
    const name = searchParams.get("name") ?? "";

    function setSearchParam(key: string, value: string | null | undefined) {
        if (value) {
            searchParams.set(key, value);
        } else {
            searchParams.delete(key);
        }

        setSearchParams(searchParams)
    }

    const sortingConfigurationString = searchParams.get("sort");

    useEffect(() => {
        async function getRecordLabels(page: number): Promise<void> {
            RecordLabelsService.getAllRecordLabelsPaged(page, rowsPerPage, key, name, SortingConfiguration.fromSearchString(sortingConfigurationString)).then((data) => {
                setRecordLabels(data.content);
                setTotalElements(data.totalElements);
            })
        }

        getRecordLabels(page).then(_ => { });
    }, [page, rowsPerPage, key, name, sortingConfigurationString]);

    function updateSort(sortingConfiguration: SortingConfiguration): void {
        setSearchParam("sort", sortingConfiguration.toSearchString());
    }

    function setPage(page: number) {
        setSearchParam("page", page.toString());
    }

    function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
        setPage(newPage);
    }

    function handleKeyChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("key", event.target.value);
        setPage(0);
    }

    function handleNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("name", event.target.value);
        setPage(0);
    }

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("rowsPerPage", event.target.value);
        setPage(0);
    }

    function navigateToAddRecordLabels(): any {
        navigate(Routes.ADD_RECORD_LABEL);
    }

    function navigateRecordLabelRow(id: number): any {
        navigate(Routes.EDIT_RECORD_LABEL.replace(":id", id.toString()));
    }

    function getRowLink(entry: RecordLabelDTO): string {
        return guardedObject([
            new PrivilegeGuard(
                [Privileges.MANAGE_RECORD_LABELS, Privileges.READ_RECORD_LABELS],
                PrivilegeGuardMode.hasAll
            )
        ], Routes.EDIT_RECORD_LABEL.replace(":id", entry.id.toString())) ?? "";
    }
    return(
        <SideBarPage
            pageTitle={strings.recordLabels}
            breadcrumbs={listLinks}
            component={
            <Grid>
                <TableContainer component={Paper}>
                    <Table sx={{minWidth: 650}} aria-label="custom pagination table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{width: "45%"}}>
                                    <Input
                                        name="key"
                                        value={key}
                                        onChange={handleKeyChange}
                                        placeholder={strings.keyFilter}
                                        style={{width: "auto"}}
                                    />
                                </TableCell>
                                <TableCell style={{width: "45%"}}>
                                    <Input
                                        name="name"
                                        value={name}
                                        onChange={handleNameChange}
                                        placeholder={strings.nameFilter}
                                        style={{width: "auto"}}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Button onClick={navigateToAddRecordLabels} aria-label="add" variant={"contained"} sx={{float: "right"}}>
                                        {strings.addRecordLabel}
                                    </Button>
                                </TableCell>
                            </TableRow>
                            <TableHeader columns={columns} sortConfigurationString={sortingConfigurationString}
                                         updateSort={updateSort}></TableHeader>
                        </TableHead>
                        <TableBody>
                            {recordLabels.map((recordLabel) => (
                                <TableRow
                                    component={getRowLink(recordLabel) ? Link : TableRow}
                                    to={getRowLink(recordLabel)}
                                    key={recordLabel.id}
                                    hover={true}
                                    className="cursor-pointer"
                                    onClick={guardedCallback([
                                        new PrivilegeGuard(
                                            [Privileges.MANAGE_RECORD_LABELS, Privileges.READ_RECORD_LABELS],
                                            PrivilegeGuardMode.hasAll
                                        )
                                    ],() => navigateRecordLabelRow(recordLabel.id))}>
                                    <TableCell width={"20%"}>{recordLabel.key}</TableCell>
                                    <TableCell width={"20%"}>{recordLabel.name}</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end', marginTop: "10px" }}>
                    <PaginationPlus totalElements={totalElements} rowsPerPage={rowsPerPage} page={page} onChangePage={(page) => handleChangePage(null, page)} />
                    <TablePagination
                        rowsPerPageOptions={defaultRowsPerPageOptions}
                        component="div"
                        count={totalElements}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Grid>
            </Grid>
            }
        />
    )
}
