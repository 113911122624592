import {
    FormControlLabel,
    FormGroup,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField
} from "@mui/material"; // Importing Grid component from Material-UI
import {SideBarPage} from "../../components/SideBarPage";
import {strings} from "../../localization/Localization";
import {Routes} from "../../router/Routes";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {useEffect, useState} from "react";
import {Dayjs} from "dayjs";
import {useSearchParams} from "react-router-dom";
import {NullableCheckbox} from "../../components/NullableCheckbox";
import {BooleanParser} from "../../utils/BooleanParser";
import {ProblematicBotResponseDTO} from "../../models/ProblematicBotResponseDTO";
import {BotsService} from "../../services/BotsService";
import {PaginationPlus} from "../../components/PaginationPlus";
import {defaultRowsPerPageOptions} from "../../common/Constants";
import {DateTimeUtils} from "../../utils/DateTimeUtils";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

export function ProblematicBots() {

    const listLinks = [
        { label: strings.dashboard, currentlyOpened: false, href: Routes.HOME },
        { label: strings.reports, currentlyOpened: true },
    ];

    const [bots, setBots] = useState<ProblematicBotResponseDTO[]>([]);
    const [dateFromValue, setDateFromValue] = useState<Dayjs | null>(null);
    const [page, setPage] = useState<number>(0);
    const [size, setSize] = useState<number>(10);
    const [searchParams, setSearchParams] = useSearchParams();
    const [name, setName] = useState<string>("");
    const isMobile = BooleanParser.parseBooleanNullable(searchParams.get("isMobile"));
    const [totalElements, setTotalElements] = useState<number>(0);


    useEffect(() => {
        const debounce = setTimeout(() => {
            if (dateFromValue !== null && dateFromValue !== undefined) {
                fetchProblematicBots();
            }
        }, 300);
        return () => clearTimeout(debounce);
    }, [dateFromValue, searchParams, page, size, isMobile, name])

    async function fetchProblematicBots() {
        try {
            const { bots: fetchedBots, totalElements } = await BotsService.findAllProblematicBots(
                dateFromValue?.toISOString() ?? '',
                name,
                isMobile,
                page,
                size
            );
            setBots(fetchedBots);
            setTotalElements(totalElements);
        } catch (error) {
        }
    }

    function setSearchParam(key: string, value: string | null | undefined) {
        if (value) {
            searchParams.set(key, value);
        } else {
            searchParams.delete(key);
        }

        setSearchParams(searchParams)
    }

    function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number): void {
        setPage(newPage);
    }
    const handleDateFromChange = (newValue: Dayjs | null) => {
        setDateFromValue(newValue);
        setPage(0);
    };

    function handleChangeName(event: React.ChangeEvent<HTMLInputElement>) {
        setName(event.target.value ?? "");
        setPage(0)
    }

    function setIsMobile(value: boolean | undefined): void {
        setSearchParam("isMobile", value?.toString() ?? "");
        setPage(0);
    }

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setSize(+event.target.value);
        setPage(0);
    }

    return (
        <>
            <SideBarPage
                pageTitle={strings.problematicBots}
                breadcrumbs={listLinks}
                component={
                    <>
                        <TableContainer>
                            <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    onChange={handleDateFromChange}
                                                    value={dateFromValue}
                                                    label={strings.dateFrom}
                                                    inputFormat="DD.MM.YYYY"
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="standard"
                                                            required
                                                        />
                                                    )}
                                                    componentsProps={{
                                                        actionBar: {
                                                            actions: ['today'],
                                                        },
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                name="name"
                                                variant="standard"
                                                label={strings.name}
                                                value={name}
                                                onChange={handleChangeName}
                                                fullWidth
                                            />
                                        </TableCell>
                                        <TableCell>
                                            {" "}
                                            <FormGroup>
                                                <FormControlLabel
                                                    label={strings.isMobile}
                                                    control={
                                                        <NullableCheckbox
                                                            value={isMobile}
                                                            onValueChanged={setIsMobile}
                                                        />
                                                    }
                                                />
                                            </FormGroup>
                                        </TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ width: '20%' }}>{strings.name}</TableCell>
                                        <TableCell sx={{ width: '16%' }}>{strings.lastLog}</TableCell>
                                        <TableCell sx={{ width: '16%' }}>{strings.isMobile}</TableCell>
                                        <TableCell sx={{ width: '16%' }}>{strings.lastActionDate}</TableCell>
                                        <TableCell sx={{ width: '16%' }}>{strings.condition}</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {bots.map((botResponse) => (
                                        <TableRow key={botResponse.botReducedDTO.id}>
                                            <TableCell>{botResponse.botReducedDTO.name}</TableCell>
                                            <TableCell>{botResponse.botReducedDTO.lastLog}</TableCell>
                                            <TableCell>
                                                {botResponse.botReducedDTO.isMobile ? (
                                                    <CheckCircleIcon color="success" sx={{ fontSize: "65px", width: "50px" }} />
                                                ) : (
                                                    <CancelIcon color="error" sx={{ fontSize: "65px", width: "50px" }} />
                                                )}
                                            </TableCell>
                                            <TableCell>{DateTimeUtils.formatDate(botResponse.botReducedDTO.lastActionDate)}</TableCell>
                                            <TableCell>{botResponse.botReducedDTO.status}</TableCell>

                                            <TableCell></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Grid sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end', marginTop: "10px" }}>
                            <PaginationPlus totalElements={totalElements} rowsPerPage={size} page={page} onChangePage={(page) => handleChangePage(null, page)} />
                            <br />
                            <TablePagination
                                rowsPerPageOptions={defaultRowsPerPageOptions}
                                component="div"
                                count={totalElements}
                                rowsPerPage={size}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Grid>
                    </>
                }
            />
        </>
    );
}
