import {ReactElement} from "react";
import {Guard} from "../router/guards/Guard";

export interface GuardedComponentProps {
    component: ReactElement;
    guards: Guard[];
    fallback: ReactElement;
}

export function GuardedComponent(props: GuardedComponentProps) {
    function checkAndBuildFallback(): ReactElement | null {
        for (const guard of props.guards) {
            if (!guard.check()) {
                return props.fallback;
            }
        }

        return null;
    }

    const fallback = checkAndBuildFallback();

    return (
        fallback !== null ? fallback : props.component
    );
}
