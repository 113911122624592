
export class UserFilter {
    email : string | undefined;
    enabled : boolean | undefined;
    firstName : string | undefined;
    lastName : string | undefined;
    phoneNumber : string | undefined;
    role : string | undefined;
    client:string|undefined;
    constructor(email : string | undefined, enabled : boolean | undefined, firstName : string | undefined, lastName : string | undefined, phoneNumber : string | undefined, role : string | undefined, client : string|undefined) {
        this.email = email;
        this.enabled = enabled;
        this.firstName = firstName;
        this.lastName = lastName;
        this.phoneNumber = phoneNumber;
        this.role = role;
        this.client=client;

    }
}
