export class ActionType{
    id:number | string;
    type:string;
    label:string;
    sourceKeyRequired:boolean;
    iterable: boolean;

    constructor(json:{id:number | string, type:string, label:string, sourceKeyRequired:boolean, iterable: boolean}){
        this.id = json.id;
        this.type = json.type;
        this.label = json.label;
        this.sourceKeyRequired = json.sourceKeyRequired;
        this.iterable = json.iterable;
    }

    public get _id() {
        return this.id;
    }
}
