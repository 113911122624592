import {ClientDTO} from "./ClientDTO";

export class UserData {
    email: string | undefined;
    firstName: string | undefined;
    lastName: string | undefined;
    phoneNumber: string | undefined;
    client: ClientDTO | undefined;
    clientId: number | undefined;

    constructor(email: string | undefined, firstName: string | undefined, lastName: string | undefined, phoneNumber: string | undefined, client: ClientDTO | undefined) {
        this.email = email;
        this.firstName = firstName;
        this.lastName = lastName;
        this.phoneNumber = phoneNumber;
        this.client = client ? new ClientDTO(client) : undefined;
        this.clientId = client?.id;
    }

    static fromObject(json: UserData): UserData {
        return new UserData(json.email, json.firstName, json.lastName, json.phoneNumber, json.client ?? undefined);
    }
}