export class ServiceDTO {
    id: number;
    type: string;
    label: string;
    constructor(json: { id: number, type: string, label: string }) {
        this.id = json.id;
        this.type = json.type;
        this.label = json.label;
    }

}