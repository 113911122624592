import { RegionViewsDTO } from "./RegionViewsDTO";

export class ViewCountGeographicReportDTO{
    artistName:string;
    trackName:string;
    countTotal:number;
    regionViewsDTO: RegionViewsDTO[]

    constructor(json:ViewCountGeographicReportDTO){
        this.trackName = json.trackName;
        this.artistName = json.artistName;
        this.countTotal = json.countTotal;
        this.regionViewsDTO = json.regionViewsDTO;
    }

}