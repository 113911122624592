import {strings} from "../localization/Localization";

export class SessionInstanceConditionType{
    id:string;
    label: string;
    key:string;
    constructor(id: string, label:string, key: string){
        this.id=id
        this.label=label
        this.key=key
    }
    static readonly sessionInstanceConditionTypes = [
        new SessionInstanceConditionType("active", strings.conditionActive, "2"),
        new SessionInstanceConditionType("stalled", strings.conditionStalled, "3"),
        new SessionInstanceConditionType("finished", strings.conditionFinished, "4")
    ];
    static readonly activeType = "active";
    static readonly stalledType = "stalled";
    static readonly finishedType = "finished";
}
