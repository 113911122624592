import {AccountGroupDTO} from "./AccountGroupDTO";
import { BehaviorDTO } from "./BehaviorDTO";
import { SessionActionCreationDTO } from "./SessionActionCreationDTO";
import { SessionPreferredProxy } from "./SessionPreferredProxy";
import {RegionDTO} from "./RegionDTO";
import {ClientDTO} from "./ClientDTO";

export class Session {
    id: number;
    enabled:boolean;
    serviceId: number;
    active?: boolean;
    key?: string;
    cookieCollectionId: number;
    name: string;
    service: string;
    accountGroup: AccountGroupDTO;
    useProxy: boolean;
    actions:SessionActionCreationDTO[];
    preferredProxies?:SessionPreferredProxy[] | undefined;
    behavior?: BehaviorDTO | undefined;
    accountGroupRegions?: RegionDTO[] | undefined;
    maxDurationInSeconds: number | undefined;
    dateCreated:string;
    loops: number | undefined;
    client: ClientDTO|undefined;
    switchAccountGroupOnFinish: boolean;
    playlistGenerationGenres: string;

    constructor(json: Session) {
        this.id = json.id;
        this.enabled=json.enabled;
        this.serviceId = json.serviceId;
        this.active = json.active;
        this.key = json.key;
        this.cookieCollectionId = json.cookieCollectionId;
        this.name = json.name;
        this.accountGroup = new AccountGroupDTO(json.accountGroup);
        this.service = json.service;
        this.useProxy = json.useProxy;
        this.actions = json.actions;
        this.preferredProxies = json.preferredProxies?.map((item) => new SessionPreferredProxy(item)) ?? [];
        this.behavior = json.behavior ? new BehaviorDTO(json.behavior) : undefined;
        this.accountGroupRegions = json.accountGroupRegions;
        this.maxDurationInSeconds = json.maxDurationInSeconds;
        this.dateCreated = json.dateCreated;
        this.loops = json.loops;
        this.client = json.client ? new ClientDTO(json.client) : undefined;
        this.switchAccountGroupOnFinish = json.switchAccountGroupOnFinish ?? false;
        this.playlistGenerationGenres = json.playlistGenerationGenres;
    }
}
