import {CountryDTO} from "./CountryDTO";

export class ProxyToProxyDTO {
    id: number;
    dateCreated?: Date;
    dateModified?: Date;
    name?: string;
    url: string;
    country?: CountryDTO;
    provider?: string;
    asn?: number;

    constructor(json: ProxyToProxyDTO) {
        this.id = json.id;
        this.dateCreated = json.dateCreated ? new Date(json.dateCreated) : undefined;
        this.dateModified = json.dateModified ? new Date(json.dateModified) : undefined;
        this.name = json.name;
        this.url = json.url;
        this.country = json.country ? new CountryDTO(json.country) : undefined;
        this.provider = json.provider;
        this.asn = json.asn;
    }
}