import { useEffect, useState } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { SideBarPage } from "../../components/SideBarPage";
import { strings } from "../../localization/Localization";
import { Routes } from "../../router/Routes";
import { ListenedTracksService } from "../../services/ListenedTracksService";
import { NumberParser } from "../../utils/NumberParser";
import { SortingConfiguration } from "../../utils/SortingUtils";
import {Clear} from "@mui/icons-material";
import {
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
} from "@mui/material";
import {Box} from "@mui/system";
import { TableHeader } from "../../components/TableHeader";
import { AccountListeningInfoDTO } from "../../models/AccountListeningInfoDTO";
import { RegionDTO } from "../../models/RegionDTO";
import { RegionService } from "../../services/RegionService";
import { AgeGroupService } from "../../services/AgeGroupService";
import { DateTimeUtils } from "../../utils/DateTimeUtils";
export function ListenedTrackDetails(){
    const [listeningAccounts, setListeningAccounts] = useState<AccountListeningInfoDTO[]>([]);
    const columns = [
        { label: strings.email, sortBy: "a.email", sortable: true },
        { label: strings.region, sortBy:"region.label", sortable: true },
        { label: strings.ageGroup, sortBy: "age_group.label", sortable: true },
        { label: strings.accountGroup, sortBy: "account_group.name", sortable: true },
        { label: strings.timeElapsedInSeconds, sortBy: "timeElapsedInSeconds", sortable: true},
      ]
      const listLinks = [
        { label: strings.dashboard, currentlyOpened: false, href:Routes.HOME},
        { label: strings.listenedTracks,  currentlyOpened: false, href: Routes.LISTENED_TRACKS },
        { label: strings.listenedTracksDetails,  currentlyOpened: true },
      ]
    const [searchParams, setSearchParams] = useSearchParams();
    const accountEmail = searchParams.get("accountEmail") ?? "";
    const accountRegionId = searchParams.get("accountRegionId") ?? "";
    const accountAgeGroupId = searchParams.get("accountAgeGroupId") ?? "";
    const accountGroupName = searchParams.get("accountGroupName") ?? "";
    const timeElapsedInSeconds = searchParams.get("timeElapsedInSeconds") ?? "";
    const name = searchParams.get("name") ?? "";
    const artist = searchParams.get("artist") ?? "";
    const serviceId = searchParams.get("serviceId") ?? "";
    const sortingConfigurationString = searchParams.get("sort");
    const rowsPerPage = NumberParser.parseNumber(searchParams.get("rowsPerPage"), 25);
    const page = NumberParser.parseNumber(searchParams.get("page"), 0);
    const [totalElements, setTotalElements] = useState(0);
    const [regions, setRegions] = useState<RegionDTO[]>([]);
    const [ageGroups, setAgeGroups] = useState<RegionDTO[]>([]);
    const navigate = useNavigate();
    let { id } = useParams();
    function setSearchParam(key: string, value: string | null | undefined) {
      if (value) {
        searchParams.set(key, value);
      } else {
        searchParams.delete(key);
      }
  
      setSearchParams(searchParams)
    }
    function setPage(page: number) {
        setSearchParam("page", page.toString());
      }
    
    function updateSort(sortingConfiguration: SortingConfiguration): void {
        setSearchParam("sort", sortingConfiguration.toSearchString());
      }

    function handleChangePage(
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("rowsPerPage", event.target.value);
        setPage(0);
    }

    function handleChangeAccountEmail(event: React.ChangeEvent<HTMLInputElement>) {
       setSearchParam("accountEmail", event.target.value);
       setPage(0)
    }

    function handleChangeAgeGroupId(event: SelectChangeEvent) {
        setSearchParam("accountAgeGroupId", event.target.value);
        setPage(0)
    }

    function handleChangeRegionId(event: SelectChangeEvent) {
        setSearchParam("accountRegionId", event.target.value);
        setPage(0)
    }
    function handleChangeAccountGroupName(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("accountGroupName", event.target.value);
        setPage(0)
    }
useEffect(()=>{
    async function getListeningAccounts(page: number): Promise<void> {
        const response = await ListenedTracksService.getAccountsOfListenedTracks(page,rowsPerPage,accountEmail,accountRegionId, accountAgeGroupId,name, artist, serviceId, accountGroupName, SortingConfiguration.fromSearchString(sortingConfigurationString));
        setTotalElements(response.totalElements);
        setListeningAccounts(response.content);
    }

    getListeningAccounts(page).then((_) => {
    });
},[name,artist, serviceId, accountEmail,accountRegionId,accountAgeGroupId,accountGroupName,timeElapsedInSeconds,page,rowsPerPage,sortingConfigurationString])
useEffect(() => {

    async function getAllRegions(): Promise<void> {
        const regions = await RegionService.getRegions();
        setRegions(regions);
    }

    async function getAllAgeGroups(): Promise<void> {
        const ageGroups = await AgeGroupService.getAllAgeGroups();
        setAgeGroups(ageGroups);
    }

    async function loadData(): Promise<void> {
        await getAllRegions();
        await getAllAgeGroups();
    }

    loadData().then((_) => {
    });
}, []);
return (
    <>
        <SideBarPage
            pageTitle={strings.listenedTracksDetails}
            breadcrumbs={listLinks}
            component={
                <Box>
                    <TableContainer component={Paper}>
                        <Table
                            sx={{minWidth: 500}}
                            aria-label="custom pagination table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        {" "}
                                        <TextField
                                            value={accountEmail}
                                            name="accountEmail"
                                            variant="standard"
                                            label={strings.email}
                                            onChange={handleChangeAccountEmail}
                                            fullWidth
                                        />
                                    </TableCell>
                                   
                               
                                    <TableCell>
                                        {" "}
                                        <FormControl fullWidth variant="standard">
                                            <InputLabel>{strings.region}</InputLabel>
                                            <Select
                                                defaultValue={""}
                                                value={
                                                    accountRegionId ? String(accountRegionId) : ""
                                                }
                                                onChange={handleChangeRegionId}
                                                label={strings.region}
                                                labelId="serviceLabel"
                                                endAdornment={
                                                    accountRegionId && (
                                                        <IconButton
                                                            onClick={() => {
                                                                setSearchParam("accountRegionId", undefined);
                                                                setPage(0);
                                                            }}
                                                        >
                                                            <Clear/>
                                                        </IconButton>
                                                    )
                                                }
                                            >
                                                {regions?.map((region) => (
                                                    <MenuItem
                                                        disableRipple
                                                        key={region.id}
                                                        value={region.id}
                                                    >
                                                        {region.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell>
                                        {" "}
                                        <FormControl fullWidth variant="standard">
                                            <InputLabel>{strings.ageGroup}</InputLabel>
                                            <Select
                                                defaultValue={""}
                                                value={
                                                    accountAgeGroupId ? String(accountAgeGroupId) : ""
                                                }
                                                onChange={handleChangeAgeGroupId}
                                                label={strings.ageGroup}
                                                labelId="serviceLabel"
                                                endAdornment={
                                                    accountAgeGroupId && (
                                                        <IconButton
                                                            onClick={() => {
                                                                setSearchParam("accountAgeGroupId", undefined);
                                                                setPage(0);
                                                            }}
                                                        >
                                                            <Clear/>
                                                        </IconButton>
                                                    )
                                                }
                                            >
                                                {ageGroups?.map((group) => (
                                                    <MenuItem
                                                        disableRipple
                                                        key={group.id}
                                                        value={group.id}
                                                    >
                                                        {group.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell>
                                        {" "}
                                        <TextField
                                            value={accountGroupName}
                                            name="accountGroupName"
                                            variant="standard"
                                            label={strings.accountGroup}
                                            onChange={handleChangeAccountGroupName}
                                            fullWidth
                                        />
                                    </TableCell>
                                    <TableCell>
                                       
                                    </TableCell>
                              
                                </TableRow>
                                <TableHeader columns={columns} sortConfigurationString={sortingConfigurationString} updateSort={updateSort} />
                            </TableHead>
                            <TableBody>
                                {listeningAccounts.map((item,index) => (
                                    <TableRow key={index} hover={true} >
                                        <TableCell component="th" scope="row">
                                            {item.account.email}
                                        </TableCell>
                                        <TableCell>{item.account.region?.label}</TableCell>
                                        <TableCell>{item.account.ageGroup?.label}</TableCell>
                                        <TableCell>
                                           {item.account.accountGroup?.name}
                                        </TableCell>
                                        <TableCell>
                                        {DateTimeUtils.convertSectoDay(item.timeElapsedInSeconds)}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={totalElements}
                        rowsPerPage={rowsPerPage}
                        page={totalElements <=0 ? 0 : page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
            }
        />
    </>
);
}

    



