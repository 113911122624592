import {
    Box,
    Button,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
} from '@mui/material';
import {strings} from "../../localization/Localization";
import {SideBarPage} from '../../components/SideBarPage';
import {TableHeader} from '../../components/TableHeader';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {Routes} from "../../router/Routes";
import {SortingConfiguration} from '../../utils/SortingUtils';
import React, {useEffect, useState} from 'react';
import {ServicesService} from '../../services/ServicesService';
import {ServiceDTO} from '../../models/ServiceDTO';
import {Clear} from '@mui/icons-material';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DateTimeUtils} from '../../utils/DateTimeUtils';
import {Dayjs} from 'dayjs';
import {TargetPlaylistService} from '../../services/TargetPlaylistService';
import {TargetPlaylistDTO} from '../../models/TargetPlaylistDTO';
import {NumberParser} from '../../utils/NumberParser';
import {PaginationPlus} from '../../components/PaginationPlus';
import {useStateWithDebounce} from "../../utils/debounce/UseStateWithDebounce";
import {TargetPlaylistTypes} from "../../common/TargetPlaylistTypes";

export function Playlists() {

    const [searchParams, setSearchParams] = useSearchParams();
    const sortingConfigurationString = searchParams.get("sort");
    const [service, setService] = useState<string>("");
    const [services, setServices] = useState<ServiceDTO[] | undefined>([]);
    const dateCreated = searchParams.get("dateCreated") ?? null;
    const dateModified = searchParams.get("dateModified") ?? null;
    const navigate = useNavigate();
    const [targetPlaylists, setTargetPlaylists] = useState<TargetPlaylistDTO[]>([]);
    const [totalElements, setTotalElements] = useState<number>(0);
    const page = NumberParser.parseNumber(searchParams.get("page"), 0);
    const rowsPerPage = NumberParser.parseNumber(searchParams.get("rowsPerPage"), 5);
    const type = searchParams.get("type");

    function getParam(key: string) {
        return searchParams.get(key);
    }

    const [name, debouncedName, setName] =
        useStateWithDebounce<string | null>(getParam('name'), (value) =>
            setSearchParam('name', value)
        );

    const [weight, debouncedWeight, setWeight] =
        useStateWithDebounce<number | undefined>(NumberParser.parseNumberNullable(getParam('weight')), (value) =>
            setSearchParam('weight', value?.toString())
        );

    const [key, debouncedKey, setKey] =
        useStateWithDebounce<string | null>(getParam('key'), (value) =>
            setSearchParam('key', value)
        );

    const columns = [
        { label: strings.key, sortBy: "key", sortable: false },
        { label: strings.name, sortBy: "label", sortable: false },
        { label: strings.service, sortBy: "service", sortable: false },
        { label: strings.weight, sortBy: "weight", sortable: false },
        { label: strings.dateCreated, sortBy: "dateCreated", sortable: false },
        { label: strings.dateModified, sortBy: "dateModified", sortable: false },
        { label: strings.type, sortBy: "type", sortable: false },
        { label: '', sortBy: "", sortable: false },
    ];

    const listLinks = [
        { label: strings.dashboard, currentlyOpened: false, href: '/' },
        { label: strings.playlistsForGeneration, currentlyOpened: true },
    ];

    const targetPlaylistTypeLabel = new Map<string, string>([
        [TargetPlaylistTypes.PLAYLIST_GENERATION, strings.playlistGeneration],
        [TargetPlaylistTypes.SEARCH_AND_PLAY, strings.searchAndPlay]
    ]);

    useEffect(() => {
        getAllServices();
        fetchPlaylists();
    }, [page, service, rowsPerPage, dateCreated, dateModified, debouncedKey, debouncedName, debouncedWeight, sortingConfigurationString, type]);

    function getAllServices() {
        ServicesService.getAllServices().then((data) => {
            setServices(data);
        })
    }

    function fetchPlaylists() {
        TargetPlaylistService.getAllTargetPlaylistsPaged(
            page,
            rowsPerPage,
            key || undefined,
            name || undefined,
            service || undefined,
            weight || undefined,
            dateCreated || undefined,
            dateModified || undefined,
            type ?? undefined,
        )
            .then(({ content, totalElements }) => {
                setTargetPlaylists(content);
                setTotalElements(totalElements);
            })
            .catch(error => {
                console.error("Failed to fetch playlists", error);
            });
    }

    function setPage(page: number) {
        setSearchParam("page", page.toString());
    }

    function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("rowsPerPage", event.target.value);
        setPage(0);
    }

    function setValue<T>(setter: React.Dispatch<React.SetStateAction<T>>, value: T): void {
        setter(value);
        setPage(0);
    }

    function setSearchParam(key: string, value: string | null | undefined) {
        if (value !== null && value !== undefined) {
            searchParams.set(key, value);
        } else {
            searchParams.delete(key);
        }

        setSearchParams(searchParams);
    }

    function updateSort(sortingConfiguration: SortingConfiguration): void {
        setSearchParam("sort", sortingConfiguration.toSearchString());
        setPage(0);
    }

    function handleNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        setName(event.target.value);
        setSearchParam("name", event.target.value);
        setPage(0);
    }

    function handleKeyChange(event: React.ChangeEvent<HTMLInputElement>) {
        setKey(event.target.value);
        setSearchParam("key", event.target.value);
        setPage(0);
    }

    function handleWeightChange(event: React.ChangeEvent<HTMLInputElement>) {
        const value = event.target.value ? parseInt(event.target.value) : undefined;
        setWeight(value);
        setSearchParam("weight", value !== undefined ? value.toString() : null);
        setPage(0);
    }

    function handleChangeService(value: string) {
        setValue(setService, value)
        setSearchParam("service", value !== undefined ? value.toString() : null);
        setPage(0);
    }

    function handleChangeDateCreated(date: Dayjs | null) {
        setSearchParam("dateCreated", DateTimeUtils.formatDateDayJs(date));
        setPage(0);
    }

    function handleChangeDateModified(date: Dayjs | null) {
        setSearchParam("dateModified", DateTimeUtils.formatDateDayJs(date));
        setPage(0);
    }

    function handleChaneType(type: string) {
        setSearchParam("type", type);
        setPage(0);
    }

    function navigateAddNewPlaylist(): any {
        navigate(Routes.ADD_NEW_TARGET_PLAYLIST);
    }

    function handleRowClick(id: number) {
        navigate(Routes.PLAYLIST_DETAILS.replace(":id", id.toString()));
    }

    return (
        <SideBarPage
            pageTitle={strings.playlistsForGeneration}
            breadcrumbs={listLinks}
            component={
                <Box>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <TextField
                                            name="key"
                                            variant='standard'
                                            value={key}
                                            onChange={handleKeyChange}
                                            placeholder={strings.keyFilter}
                                            fullWidth
                                            sx={{ paddingTop: '15px' }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            name="name"
                                            variant='standard'
                                            value={name}
                                            onChange={handleNameChange}
                                            placeholder={strings.name}
                                            fullWidth
                                            sx={{ paddingTop: '15px' }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <FormControl fullWidth variant="standard" sx={{ minWidth: '10vh' }}>
                                            <InputLabel>{strings.service}</InputLabel>
                                            <Select
                                                defaultValue={""}
                                                value={service}
                                                onChange={event => handleChangeService(event.target.value)}
                                                label={strings.service}
                                                labelId="serviceLabel"

                                                endAdornment={
                                                    service && (
                                                        <IconButton
                                                            onClick={() => setService("")}
                                                        >
                                                            <Clear />
                                                        </IconButton>
                                                    )
                                                }
                                            >
                                                {services?.map((service) => (
                                                    <MenuItem disableRipple key={service.id} value={service.type}>
                                                        {service.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            type='number'
                                            name="weight"
                                            variant='standard'
                                            value={weight}
                                            onChange={handleWeightChange}
                                            placeholder={strings.weight}
                                            fullWidth
                                            sx={{ paddingTop: '15px' }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                onChange={handleChangeDateCreated}
                                                value={dateCreated}
                                                label={strings.dateCreated}
                                                inputFormat="DD.MM.YYYY"
                                                renderInput={(params: any) => (
                                                    <TextField
                                                        {...params}
                                                        variant="standard"
                                                        onKeyDown={(e) => e.preventDefault()}
                                                    />
                                                )}
                                                componentsProps={{
                                                    actionBar: {
                                                        actions: ["today"],
                                                    },
                                                }}
                                                InputProps={{
                                                    endAdornment: dateCreated && (
                                                        <IconButton
                                                            sx={{ height: "10px", width: "10px" }}
                                                            onClick={() => {
                                                                handleChangeDateCreated(null);
                                                                setPage(0);
                                                            }}
                                                        >
                                                            <Clear />
                                                        </IconButton>
                                                    ),
                                                }}
                                                InputAdornmentProps={{
                                                    position: "start",
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </TableCell>
                                    <TableCell>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                onChange={handleChangeDateModified}
                                                value={dateModified}
                                                label={strings.dateModified}
                                                inputFormat="DD.MM.YYYY"
                                                renderInput={(params: any) => (
                                                    <TextField
                                                        {...params}
                                                        variant="standard"
                                                        onKeyDown={(e) => e.preventDefault()}
                                                    />
                                                )}
                                                componentsProps={{
                                                    actionBar: {
                                                        actions: ["today"],
                                                    },
                                                }}
                                                InputProps={{
                                                    endAdornment: dateModified && (
                                                        <IconButton
                                                            sx={{ height: "10px", width: "10px" }}
                                                            onClick={() => {
                                                                setSearchParam("dateModified", null);
                                                                setPage(0);
                                                            }}
                                                        >
                                                            <Clear />
                                                        </IconButton>
                                                    ),
                                                }}
                                                InputAdornmentProps={{
                                                    position: "start",
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </TableCell>
                                    <TableCell>
                                        <FormControl fullWidth variant="standard" sx={{ minWidth: '10vh' }}>
                                            <InputLabel>{strings.type}</InputLabel>
                                            <Select
                                                value={type}
                                                onChange={(e) => handleChaneType(e.target.value ?? "")}
                                                endAdornment={
                                                    type && (
                                                        <IconButton
                                                            onClick={() => handleChaneType("")}
                                                        >
                                                            <Clear />
                                                        </IconButton>
                                                    )
                                                }
                                            >
                                                <MenuItem key={TargetPlaylistTypes.PLAYLIST_GENERATION} value={TargetPlaylistTypes.PLAYLIST_GENERATION}>{strings.playlistGeneration}</MenuItem>
                                                <MenuItem key={TargetPlaylistTypes.SEARCH_AND_PLAY} value={TargetPlaylistTypes.SEARCH_AND_PLAY}>{strings.searchAndPlay}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell>
                                        <Button onClick={navigateAddNewPlaylist} aria-label="add" variant="contained" className="float-end" sx={{ width: '20vh' }}>
                                            {strings.addNewTargetPlaylist}
                                        </Button>
                                    </TableCell>
                                </TableRow>
                                <TableHeader columns={columns} sortConfigurationString={sortingConfigurationString} updateSort={updateSort} />
                            </TableHead>
                            <TableBody>
                                {targetPlaylists.length > 0 ? targetPlaylists.map((playlist) => (
                                    <TableRow key={playlist.id} onClick={() => handleRowClick(Number(playlist.id))} sx={{ cursor: 'pointer' }}>
                                        <TableCell sx={{ width: '15%' }}>{playlist.key}</TableCell>
                                        <TableCell sx={{ width: '15%' }}>{playlist.name}</TableCell>
                                        <TableCell sx={{ width: '15%' }}>{playlist.service.label}</TableCell>
                                        <TableCell sx={{ width: '15%' }}>{playlist.weight}</TableCell>
                                        <TableCell sx={{ width: '15%' }}>{DateTimeUtils.formatDate(playlist.dateCreated)}</TableCell>
                                        <TableCell sx={{ width: '15%' }}>{DateTimeUtils.formatDate(playlist.dateCreated)}</TableCell>
                                        <TableCell sx={{ width: '10%' }}>{targetPlaylistTypeLabel.get(playlist.type) ?? ""}</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                )) : (
                                    <TableRow>
                                        <TableCell colSpan={columns.length}>{strings.noDataAvailable}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Grid sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end', marginTop: "10px" }}>
                        <PaginationPlus totalElements={totalElements} rowsPerPage={rowsPerPage} page={page} onChangePage={(page) => handleChangePage(null, page)} />
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={totalElements <= 0 ? 0 : totalElements}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Grid>
                </Box>
            }
        />
    );
}
