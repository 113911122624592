export class PaymentAccountCreationDTO {
    accountId: number | undefined;
    paymentAccountTypeId: number | undefined;
    username: string | undefined;
    password: string | undefined;
    phoneNumber: string | undefined;

    constructor(json:any) {
        this.accountId=json.accountId;
        this.paymentAccountTypeId=json.paymentAccountTypeId;
        this.username = json.username;
        this.password = json.password;
        this.phoneNumber =json.phoneNumber;
    }
}