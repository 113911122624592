import {ListenedTrackClientInfoDTO} from "../models/ListenedTrackClientInfoDTO";
import axiosInstance from "../network/AxiosInstance";
import {Page} from "../pagination/Page";
import {TrackNameArtistDTO} from "../models/TrackNameArtistDTO";
import {ViewsCountPerDayDTO} from "../models/ViewsCountPerDayDTO";
import {ListenedArtistClientInfoDTO} from "../models/ListenedArtistClientInfoDTO";
import {ArtistNameDTO} from "../models/ArtistNameDTO";

export class ClientDashboardService{
    static async getListenedTracksForClient(page: number, size: number, id: number | undefined, serviceId: number | undefined, regionIds: string | null, dateFrom: string | null, dateTo: string | null,
                                            trackName: string | null, artistName: string | null) : Promise<Page<ListenedTrackClientInfoDTO>>{

        const params = new URLSearchParams({
            page: page.toString(),
            size: size.toString(),
        });

        if(serviceId) {
            params.append("serviceId", serviceId.toString());
        }
        if(regionIds) {
            params.append("regionIds", regionIds);
        }
        if(dateFrom) {
            params.append("dateFrom", dateFrom);
        }
        if(dateTo) {
            params.append("dateTo", dateTo);
        }
        if(trackName) {
            params.append("trackName", trackName);
        }
        if(artistName){
            params.append("artistName", artistName);
        }

        const response = await axiosInstance.get<Page<ListenedTrackClientInfoDTO>>(
            `/rest/client/client/${id}/listened-tracks`, {params: params}
        )
        const data = response.data;

        return new Page<ListenedTrackClientInfoDTO>(
            data.content.map(ltc => new ListenedTrackClientInfoDTO(ltc)),
            data.totalElements
        )

    }

    static async getListenedArtistClientInfo(page: number, size: number, id: number | undefined, serviceId: number | undefined, regionIds: string | null, dateFrom: string | null, dateTo: string | null, artistName: string | null) : Promise<Page<ListenedArtistClientInfoDTO>>{

        const params = new URLSearchParams({
            page: page.toString(),
            size: size.toString(),
        });

        if(serviceId) {
            params.append("serviceId", serviceId.toString());
        }
        if(regionIds) {
            params.append("regionIds", regionIds);
        }
        if(dateFrom) {
            params.append("dateFrom", dateFrom);
        }
        if(dateTo) {
            params.append("dateTo", dateTo);
        }
        if(artistName){
            params.append("artistName", artistName);
        }

        const response = await axiosInstance.get<Page<ListenedArtistClientInfoDTO>>(
            `/rest/client/client/${id}/listened-artists`, {params: params}
        )
        const data = response.data;

        return new Page<ListenedArtistClientInfoDTO>(
            data.content.map(ltc => new ListenedArtistClientInfoDTO(ltc)),
            data.totalElements
        )

    }
    static async getListenedTracksCountingView(id: number | undefined, serviceId: number | undefined, regionIds: string | null,
                                               dateFrom: string | null, dateTo: string | null, trackNameArtistDTO:TrackNameArtistDTO | {}) : Promise<number>{
        const params = new URLSearchParams({});
        if(serviceId) {
            params.append("serviceId", serviceId.toString());
        }
        if(regionIds) {
            params.append("regionIds", regionIds);
        }
        if(dateFrom) {
            params.append("dateFrom", dateFrom);
        }
        if(dateTo) {
            params.append("dateTo", dateTo);
        }

        const response = await axiosInstance.post<number>(
            `/rest/client/client/${id}/listened-tracks-views-count`, trackNameArtistDTO, {params: params}
        )
        const data = response.data;
        return data;
    }

    static async getListenedArtistsCountingView(id: number | undefined, serviceId: number | undefined, regionIds: string | null,
                                               dateFrom: string | null, dateTo: string | null, artistNameDTO:ArtistNameDTO | {}) : Promise<number>{
        const params = new URLSearchParams({});
        if(serviceId) {
            params.append("serviceId", serviceId.toString());
        }
        if(regionIds) {
            params.append("regionIds", regionIds);
        }
        if(dateFrom) {
            params.append("dateFrom", dateFrom);
        }
        if(dateTo) {
            params.append("dateTo", dateTo);
        }

        const response = await axiosInstance.post<number>(
            `/rest/client/client/${id}/listened-artists-views-count`, artistNameDTO, {params: params}
        )
        const data = response.data;
        return data;
    }

    static async getViewsCountPerDay(id: number | undefined, serviceId: number | undefined, regionIds: string | null, dateFrom: string | null, dateTo: string | null,
                                     trackNameArtistDTO:TrackNameArtistDTO | {}) : Promise<Array<ViewsCountPerDayDTO>>{
        const params = new URLSearchParams({});
        if(serviceId) {
            params.append("serviceId", serviceId.toString());
        }
        if(regionIds) {
            params.append("regionIds", regionIds);
        }
        if(dateFrom) {
            params.append("dateFrom", dateFrom);
        }
        if(dateTo) {
            params.append("dateTo", dateTo);
        }

        const response = await axiosInstance.post<Array<ViewsCountPerDayDTO>>(
            `/rest/client/client/${id}/listened-tracks-views-count-per-day`, trackNameArtistDTO, {params: params}
        )
        const data = response.data;
        return data.map((vcpd) => new ViewsCountPerDayDTO(vcpd));
    }

    static async getArtistsViewsCountPerDay(id: number | undefined, serviceId: number | undefined, regionIds: string | null, dateFrom: string | null, dateTo: string | null,
                                            artistNameDTO:ArtistNameDTO | {}) : Promise<Array<ViewsCountPerDayDTO>>{
        const params = new URLSearchParams({});
        if(serviceId) {
            params.append("serviceId", serviceId.toString());
        }
        if(regionIds) {
            params.append("regionIds", regionIds);
        }
        if(dateFrom) {
            params.append("dateFrom", dateFrom);
        }
        if(dateTo) {
            params.append("dateTo", dateTo);
        }

        const response = await axiosInstance.post<Array<ViewsCountPerDayDTO>>(
            `/rest/client/client/${id}/listened-artists-views-count-per-day`, artistNameDTO, {params: params}
        )
        const data = response.data;
        return data.map((vcpd) => new ViewsCountPerDayDTO(vcpd));
    }

    static async getScrapedViewsCountPerDay(id: number | undefined, serviceId: number | undefined, regionIds: string | null, dateFrom: string | null, dateTo: string | null) : Promise<Array<ViewsCountPerDayDTO>>{
        const params = new URLSearchParams({});
        if(serviceId) {
            params.append("serviceId", serviceId.toString());
        }
        if(regionIds) {
            params.append("regionIds", regionIds);
        }
        if(dateFrom) {
            params.append("dateFrom", dateFrom);
        }
        if(dateTo) {
            params.append("dateTo", dateTo);
        }

        const response = await axiosInstance.get<Array<ViewsCountPerDayDTO>>(
            `/rest/client/client/${id}/scraped-views-count-per-day`, {params: params}
        )
        const data = response.data;
        return data.map((vcpd) => new ViewsCountPerDayDTO(vcpd));
    }

    static async getTotalScrapedViewsCount(id: number | undefined, serviceId: number | undefined, regionIds: string | null,
                                                dateFrom: string | null, dateTo: string | null) : Promise<number>{
        const params = new URLSearchParams({});
        if(serviceId) {
            params.append("serviceId", serviceId.toString());
        }
        if(regionIds) {
            params.append("regionIds", regionIds);
        }
        if(dateFrom) {
            params.append("dateFrom", dateFrom);
        }
        if(dateTo) {
            params.append("dateTo", dateTo);
        }

        const response = await axiosInstance.get<number>(
            `/rest/client/client/${id}/scraped-views-count`, {params: params}
        )
        const data = response.data;
        return data;
    }
}
