import {Guard} from "./Guard";
import React from "react";
import {StorageService} from "../../storage/StorageService";
import {Navigate} from "react-router-dom";
import {Routes} from "../Routes";

export class NonAuthGuard extends Guard {
    constructor() {
        super((
            <Navigate replace={true} to={Routes.HOME}/>
        ));
    }

    check(): boolean {
        return !StorageService.hasAuthToken();
    }
}
