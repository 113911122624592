import axios, {AxiosError} from 'axios';
import {StorageService} from "../storage/StorageService";
import {AppHeaders} from "./AppHeaders";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        'Access-Control-Allow-Origin': '*'
    },
});

axiosInstance.interceptors.request.use(
    config => {
        const token = StorageService.getAuthToken();

        if (token) {
            if (!config.headers.has(AppHeaders.AUTHORIZATION)) {
                config.headers.set(AppHeaders.AUTHORIZATION, `Bearer ${token}`);
            }
        }
        return config;
    },
    error => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
    (config) => config, (error: AxiosError) => {
        if (error.response?.status === 401) {
            StorageService.clearAuthToken();

            window.location.href = "/";
        }

        return Promise.reject(error);
    }
);

export default axiosInstance;
