import {ProxyToProxyDTO} from "./ProxyToProxyDTO";

export class ProxyCreationDTO {
    name: string | undefined;
    url: string | undefined;
    regionId: number | string | undefined;
    username: string | undefined;
    password: string | undefined;
    weight: number | undefined;
    proxyGroupId?: number | string | undefined;
    proxyToProxyId?: number;
    proxyToProxy?: ProxyToProxyDTO;

    constructor(
        name: string | undefined,
        url: string | undefined,
        username: string | undefined,
        password: string | undefined,
        regionId: number | string | undefined,
        weight: number | undefined,
        proxyGroupId: number | string | undefined,
        proxyToProxyId: number | undefined,
        proxyToProxy?: ProxyToProxyDTO,
    ) {
        this.name = name;
        this.url = url;
        this.regionId = regionId ? regionId : undefined;
        this.username = username ? username : undefined;
        this.password = password ? password : undefined;
        this.weight = weight !== undefined ? weight : undefined;
        this.proxyGroupId = proxyGroupId ? proxyGroupId : undefined;
        this.proxyToProxyId = proxyToProxyId;
        this.proxyToProxy = proxyToProxy;
    }

    static empty(): ProxyCreationDTO {
        return new ProxyCreationDTO(
            "",
            "",
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
        );
    }

    withProxyToProxyFromObject(): ProxyCreationDTO {
        return new ProxyCreationDTO(
            this.name,
            this.url,
            this.username,
            this.password,
            this.regionId,
            this.weight,
            this.proxyGroupId,
            this.proxyToProxy?.id,
            this.proxyToProxy,
        )
    }
}
