import {MobilePhoneDTO} from "./MobilePhoneDTO";

export class MobilePhoneStatusDTO {
    mobilePhone: MobilePhoneDTO;
    server: string;
    dateModified: number;
    status: string;

    constructor(json: any) {
        this.mobilePhone = new MobilePhoneDTO(json.mobilePhone);
        this.server = json.server;
        this.dateModified = json.dateModified;
        this.status = json.status;
    }
}