import { Page } from "../pagination/Page";
import { AuthService } from "./AuthService";
import axiosInstance from "../network/AxiosInstance";
import { TargetPlaylistDTO } from "../models/TargetPlaylistDTO";
import { TargetPlaylistResponse } from "../models/TargetPlaylistResponse";
import { TargetPlaylistCreationDTO } from "../models/TargetPlaylistCreationDTO";
import { TargetPlaylistItemCreationDTO } from "../models/TargetPlaylistItemCreationDTO";


export class TargetPlaylistService {
    // static async getAllCollectionsPaged(page: number, size: number, accountId: number | undefined): Promise<Page<CookieCollectionDTO>> {
    //     const response = await axiosInstance.get<Page<CookieCollectionDTO>>(
    //         `${AuthService.PREFIX}/bot-session/cookie-collection/all-paged/${accountId}`, {
    //         params: {
    //             page,
    //             size,
    //         }
    //     },
    //     );
    //     const data = response.data;

    //     return new Page<CookieCollectionDTO>(
    //         data.content.map((cookies) => new CookieCollectionDTO(cookies)),
    //         data.totalElements,
    //     );
    // }

    static async addNewTargetPlaylist(
        targetPlaylist: TargetPlaylistCreationDTO
    ): Promise<TargetPlaylistDTO> {
        const response = await axiosInstance.post<TargetPlaylistDTO>(
            `${AuthService.PREFIX}/playlists/target-playlists/create`,
            targetPlaylist
        );
        return new TargetPlaylistDTO(response.data);

    }

    static async editTargetPlaylist(id: number, targetPlaylist: TargetPlaylistCreationDTO): Promise<TargetPlaylistDTO> {

        const response = await axiosInstance.post<TargetPlaylistDTO>(`${AuthService.PREFIX}/playlists/target-playlists/update/${id}`, targetPlaylist);
        return response.data;

    }

    static async getTargetPlaylistById(id: number): Promise<TargetPlaylistDTO> {

        const response = await axiosInstance.get<TargetPlaylistDTO>(`${AuthService.PREFIX}/playlists/target-playlists/${id}`);
        return response.data;
    };

    static async addNewItem(key: string, item: TargetPlaylistItemCreationDTO): Promise<void> {

        const response = await axiosInstance.post(`${AuthService.PREFIX}/playlists/target-playlists/add-item/${key}`, item, {
            headers: {
                'Content-Type': 'application/json',

            }
        });
        return response.data
    };

    static async getAllTargetPlaylistsPaged(
        page: number,
        size: number,
        key?: string,
        name?: string,
        service?: string,
        weight?: number,
        dateCreated?: string,
        dateModified?: string,
        type?: string,
    ): Promise<Page<TargetPlaylistDTO>> {
        const params: Record<string, any> = {
            page,
            size,
            key,
            name,
            service,
            weight,
            dateCreated,
            dateModified,
            type,
        };

        Object.keys(params).forEach(key => params[key] == null && delete params[key]);

        const response = await axiosInstance.get<TargetPlaylistResponse>(
            `${AuthService.PREFIX}/playlists/target-playlists/all-paged`,
            { params }
        );

        const data = response.data;

        return new Page<TargetPlaylistDTO>(
            data.content.map(item => new TargetPlaylistDTO(item)),
            data.totalElements
        );
    }

    static async importTargetPlaylist(key: string | undefined, file: File) {
        let formData = new FormData();
        formData.append("file", file)

        return await axiosInstance.post(`${AuthService.PREFIX}/playlists/target-playlists/upload-items/${key}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
    }
}
