import {Page} from "../pagination/Page";
import axios from "../network/AxiosInstance";
import {AuthService} from "./AuthService";
import {PaymentAccountDTO} from "../models/PaymentAccountDTO";
import {Dayjs} from "dayjs";
import {PaymentAccountCreationDTO} from "../models/PaymentAccountCreationDTO";
import axiosInstance from "../network/AxiosInstance";


export class PaymentAccountsService {
    static async getAllPaymentAccountsPaged(accountId: number | undefined, paymentAccountTypeId: number | undefined, username: string | undefined, phoneNumber: string | undefined,dateCreated: string | null | Dayjs,dateModified: string | null | Dayjs,page: number, size: number): Promise<Page<PaymentAccountDTO>> {
        const response = await axios.get<Page<PaymentAccountDTO>>(
            `${AuthService.PREFIX}/bot-session/payment-account/all-paged/${accountId}`, {params:{accountId,paymentAccountTypeId, username, phoneNumber, dateCreated,dateModified,page, size}}
        );
        const data = response.data

        return new Page<PaymentAccountDTO>(
            data.content.map(paymentAccount => new PaymentAccountDTO(paymentAccount)),
            data.totalElements,
        )
    }

    static async createPaymentAccount(paymentAccount: PaymentAccountCreationDTO): Promise<PaymentAccountDTO>{
        const response = await axios.post<PaymentAccountDTO>(
            `${AuthService.PREFIX}/bot-session/payment-account/create`, paymentAccount );

        const data = response.data;
        return new PaymentAccountDTO(data);
    }

    static async updatePaymentAccount(paymentAccount:PaymentAccountCreationDTO, id:number): Promise<PaymentAccountDTO> {
        const response = await axios.put<Page<PaymentAccountDTO>>(
            `${AuthService.PREFIX}/bot-session/payment-account/update/${id}`,paymentAccount,{}
        );

        const data = response.data;
        return  new PaymentAccountDTO(data)
    }
    static async getPaymentAccountById(id: number): Promise<PaymentAccountDTO> {
        const response = await axiosInstance.post<PaymentAccountDTO>(`/rest/bot-session/payment-account/get/${id}`,id);
        const data = response.data;

        return new PaymentAccountDTO(data);
    }

}