import { Backdrop, CircularProgress } from '@mui/material';
import React from 'react';
import { useTheme } from '@mui/material/styles';

interface FullPageLoadingCircleProps {
    loading: boolean;
    handleClose?: () => void;
}

export function FullPageLoadingCircle(props: FullPageLoadingCircleProps) {
    const theme = useTheme();

    return props.loading ? (
        <Backdrop
            sx={{
                color: '#fff',
                zIndex: 9999,
            }}
            open={props.loading}
        >
            <div className="flex-column">
                <CircularProgress
                    size={60}
                    style={{ color: theme.palette.primary.main }}
                />
            </div>
        </Backdrop>
    ) : (
        <></>
    );
}
