export class BotPlatformDTO {
    id: number;
    label: string;
    type: string;

    constructor(json: BotPlatformDTO) {
        this.id = json.id;
        this.label = json.label;
        this.type = json.type;
    }
}
