import {SideBarPage} from "../../components/SideBarPage";
import {Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow} from "@mui/material";
import React, {useEffect, useState} from "react";
import {NumberParser} from "../../utils/NumberParser";
import {useParams, useSearchParams} from "react-router-dom";
import {BotsService} from "../../services/BotsService";
import {BotListeningInfoDTO} from "../../models/BotListeningInfoDTO";
import {strings} from "../../localization/Localization";
import {Routes} from "../../router/Routes";
import {DateTimeUtils} from "../../utils/DateTimeUtils";

export function BotDetails() {
    let {id} = useParams();

    const [page, setPage] = useState<number>(0);
    const [totalElements, setTotalElements] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams();
    const [botTracks, setBotTracks] = useState<BotListeningInfoDTO[]>();
    const rowsPerPage = NumberParser.parseNumber(searchParams.get("rowsPerPage"), 5);

    function setSearchParam(key: string, value: string | null | undefined) {
        if (value) {
            searchParams.set(key, value);
        } else {
            searchParams.delete(key);
        }

        setSearchParams(searchParams)
    }

    function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("rowsPerPage", event.target.value);
        setPage(0);
    }

    async function getBotTrackTimeElapsed(id: string | undefined, page: number): Promise<void>{
        await BotsService.getTrackTimeElapsed(id, page, rowsPerPage).then((data) => {
            setBotTracks(data.content);
            setTotalElements(data.totalElements);
        })
    }

    useEffect(() => {
        getBotTrackTimeElapsed(id, page);
    }, [id, page, rowsPerPage])

    return(
        <SideBarPage pageTitle={strings.botDetails}
                     breadcrumbs={[
                         { label: strings.dashboard, currentlyOpened: false, href:Routes.HOME},
                         { label: strings.bots,  currentlyOpened: false, href: Routes.BOTS },
                         { label: strings.botDetails,  currentlyOpened: true, href: Routes.BOTS },
                     ]}
                     component={
            <>
            <TableContainer>
                <Table aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{strings.trackName}</TableCell>
                            <TableCell>{strings.service}</TableCell>
                            <TableCell>{strings.trackTimeElapsed}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            botTracks?.map((botTrack)=>(
                                <TableRow key = {botTrack?.trackName}>
                                    <TableCell>{botTrack?.trackName}</TableCell>
                                    <TableCell>{botTrack?.serviceLabel}</TableCell>
                                    <TableCell>{DateTimeUtils.convertSectoDay(botTrack?.trackTimeElapsed)}</TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
            rowsPerPageOptions={[5,10,25]}
            component="div"
            count={totalElements}
            rowsPerPage={rowsPerPage}
            page={totalElements <= 0 ? 0 : page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
                ".MuiTablePagination-displayedRows": {
                    marginTop: "15px"
                }
            }}
            />
            </>
        }/>
    )
}
