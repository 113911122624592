import {ProxyCreationDTO} from "./ProxyCreationDTO";
import {ProxyGroupReducedDTO} from "./ProxyGroupReducedDTO";

export class ProxyUpdateDTO extends ProxyCreationDTO{
    id: number;
    proxyGroup?: ProxyGroupReducedDTO;

    constructor(json: any) {
        super(json.name, json.url, json.username, json.password, json.regionId, json.weight, json.proxyGroupId, json.proxyToProxyId);
        this.id = json.id;
        this.proxyGroup = json.proxyGroup;
    }

    withProxyGroupFromObject(): ProxyUpdateDTO {
        return new ProxyUpdateDTO({
            id: this.id,
            name: this.name,
            url: this.url,
            username: this.username,
            password: this.password,
            regionId: this.regionId,
            weight: this.weight,
            proxyGroupId: this.proxyGroup?.id,
            proxyToProxyId: this.proxyToProxyId,
        });
    }
}