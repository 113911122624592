import axiosInstance from "../network/AxiosInstance";
import {AuthService} from "./AuthService";
import {PaymentAccountTypeDTO} from "../models/PaymentAccountTypeDTO";

export class PaymentAccountTypeService {
    static async getAllPaymentAccountsType(): Promise<Array<PaymentAccountTypeDTO>> {
        const response = await axiosInstance.get<Array<PaymentAccountTypeDTO>>(`${AuthService.PREFIX}/bot-session/payment-account-type/all`);
        const data =  response.data;
        return data.map(paymentAccountTypeId => new PaymentAccountTypeDTO(paymentAccountTypeId))
    }
}