import React from "react";
import {Pagination} from "@mui/material";

interface Props{
    totalElements: number;
    rowsPerPage: number;
    page: number;
    onChangePage: (page: number) => void;
}

export function PaginationPlus({totalElements, rowsPerPage, page, onChangePage} : Props) {
    return (
        <Pagination  count={Math.ceil(totalElements / rowsPerPage)} showFirstButton showLastButton page={page + 1}
                     onChange={(e, page) => onChangePage(page - 1)}/>
    )
}