import {enStrings} from "./en";
import {ptBRStrings} from "./ptBR";
import {esBRStrings} from "./esBR";

export const languageStrings = {
    en: enStrings,
    pt_BR: ptBRStrings,
    es_BR: esBRStrings
};

