import {FormGroup, TextField} from "@mui/material";
import {CustomSelect} from "./CustomSelect";
import {NullableCheckbox} from "./NullableCheckbox";
import {NullableDatePicker} from "./NullableDatePicker";
import FormControlLabel from "@mui/material/FormControlLabel";
import {EnumType} from "../utils/EnumType";

type Element = {
    id: number | string;
    label: string;
}

type Row = {
    type: number | string;
    label: string;
    handleChange: (event: any) => void;
    value?: any;
}

interface Props {
    type: EnumType;
    label: string;
    handleChange: (event: any) => void;
    value: any;
    handleRemoveFilter?: () => void;
    id: number | string;
    elements?: Element[];
    row: Row;
    search?:boolean
}

export function TableFilter(row: Props) {
    return (
        <div>
            {row.type === EnumType.string &&  row.search && 
                <TextField label={row.label} value={row.value} onChange={row.handleChange} variant="standard"/>
            }
            {row.type === EnumType.boolean && row.search &&
                <FormGroup style={{whiteSpace: "nowrap"}}>
                    <FormControlLabel
                        label={row.label}
                        control={
                            <NullableCheckbox
                                value={row.value}
                                onValueChanged={row.handleChange}
                            />
                        }
                    />
                </FormGroup>
            }
            {row.type === EnumType.dropdown && row.search &&
                <CustomSelect id={row.id} label={row.label} elements={row.elements} handleChange={row.handleChange}
                              handleRemoveFilter={row.handleRemoveFilter ? row.handleRemoveFilter : undefined}
                              value={row.value}/>
            }
            {row.type === EnumType.date && row.search &&
                <NullableDatePicker value={row.value} onChange={row.handleChange} label={row.label}/>
            }
        </div>
    )

}
