import {
    Button,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip
} from "@mui/material";
import React, {useEffect, useState} from "react"
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {CenteredCardLayout} from "../../components/CenteredCardLayout";
import {SideBarPage} from "../../components/SideBarPage";
import {strings} from "../../localization/Localization";
import {SessionInstance} from "../../models/SessionInstance"
import {Routes} from "../../router/Routes";
import {SessionInstanceService} from "../../services/SessionInstanceService";
import {TableHeader} from "../../components/TableHeader";
import {SortingConfiguration} from "../../utils/SortingUtils";
import {NumberParser} from "../../utils/NumberParser";
import {BooleanParser} from "../../utils/BooleanParser";
import {SessionInstanceActionDTO} from "../../models/SessionInstanceActionDTO";
import {ActionTypeService} from "../../services/ActionTypeService";
import {ActionType} from "../../models/ActionType";
import {Clear} from "@mui/icons-material";
import {NullableCheckbox} from "../../components/NullableCheckbox";
import {Chart} from "react-google-charts";
import {ListenedTracks} from "./ListenedTracks";
import BlockIcon from '@mui/icons-material/Block';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorCircleIcon from '@mui/icons-material/Error';
import {CircularProgressbar} from "react-circular-progressbar";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import './SessionInstanceDetails.css'
import {DateTimeUtils} from "../../utils/DateTimeUtils";
import {SessionInstanceConditionType} from "../../models/SessionInstanceConditionType";
import {BotsDTO} from "../../models/BotsDTO";
import {BotController} from "../../controllers/BotController";
import DownloadIcon from '@mui/icons-material/Download';
import {ErrorHandler} from "../../utils/ErrorHandler";
import {showErrorDialog} from "../../common/Dialogs";
import {PaginationPlus} from "../../components/PaginationPlus";
import {BotPlatformIcon} from "../../components/BotPlatformIcon";
import PhonelinkSetupIcon from "@mui/icons-material/PhonelinkSetup";

export function SessionInstanceDetails() {
    const columns = [
        { label: "", sortBy: "", sortable: false },
        {label:strings.order, sortBy:"order", sortable:true},
        { label: strings.dateCreated, sortBy: "dateCreated", sortable: true },
        { label: strings.action, sortBy: "sessionAction.actionType", sortable: true },
        { label: strings.sourceKey, sortBy: "sessionAction.sourceKey", sortable: true },
        { label: strings.durationInSeconds, sortBy: "durationInSeconds", sortable: true },
        { label: strings.timeElapsedInSeconds, sortBy: "timeElapsedInSeconds", sortable: true },
        { label: strings.dateFinished, sortBy: "dateFinished", sortable: true },
        { label: strings.success, sortBy: "success", sortable: false },
        { label: strings.errorMessage, sortBy: "errorMessage", sortable: true },
        { label: strings.numberOfListenedTracks, sortBy: "numberOfListenedTracks", sortable: false },
        { label: strings.screenshots, sortBy: "screenshots", sortable: false },
    ]
    const [sessionInstanceData, setSessionInstanceData] = useState<SessionInstance | null>(null)
    const [sessionInstanceActions, setSessionInstanceActions] = useState<SessionInstanceActionDTO[]>([])
    const [searchParams, setSearchParams] = useSearchParams();
    const rowsPerPage = NumberParser.parseNumber(searchParams.get("rowsPerPage"), 25);
    const page = NumberParser.parseNumber(searchParams.get("page"), 0);
    const actionTypeId = searchParams.get("actionTypeId") ?? "";
    const success = BooleanParser.parseBooleanNullable(searchParams.get("success"))
    const finished = BooleanParser.parseBooleanNullable(searchParams.get("finished"))

    const [totalElements, setTotalElements] = useState(0);
    const [actionTypes, setActionTypes] = useState<ActionType[]>([])
 
    let { id } = useParams();

    const navigate = useNavigate();
    const sortingConfigurationString = searchParams.get("sort");
    const actionsData = [
        ["Total actions", "Actions"],
        ["Successfully finished", sessionInstanceData?.successfullyFinished],
        ["Unsuccessfully finished", sessionInstanceData?.unsuccessfullyFinished],
        ["Unfinished", sessionInstanceData?.unfinished],
    ];
    const actionOptions = {
        title: "Total actions",
        pieSliceText: "label",
        fontSize: 14,
        colors: ['#28a745', '#dc3545', '#ffc107']
    };

    function setSearchParam(key: string, value: string | null | undefined) {
        if (value) {
            searchParams.set(key, value);
        } else {
            searchParams.delete(key);
        }

        setSearchParams(searchParams)
    }
    function setPage(page: number) {
        setSearchParam("page", page.toString());
    }
    function updateSort(sortingConfiguration: SortingConfiguration): void {
        setSearchParam("sort", sortingConfiguration.toSearchString());
    }

    function handleChangePage(
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("rowsPerPage", event.target.value);
        setPage(0);
    }
    function handleChangeActionTypeId(event: SelectChangeEvent) {
        const actionTypeId = event.target.value;
        setSearchParam("actionTypeId", actionTypeId.toString())
        setPage(0);
    }
    
    function navigateToAccountDetails(id: number | undefined) {
        if(id){
        navigate(Routes.ACCOUNT_DETAILS.replace(":id", id.toString()));
        }
    }
    function navigateToProxyDetails(id: number | undefined) {
        if(id){
        navigate(Routes.PROXY_DETAILS.replace(":id", id.toString()));
        }
    }
    function setSuccess(value: boolean | undefined): void {
        setSearchParam("success", value?.toString() ?? "");
        setPage(0);
    }
    function setFinished(value: boolean | undefined): void {
        setSearchParam("finished", value?.toString() ?? "");
        setPage(0);
    }
    useEffect(() => {
        async function getSessionInstanceById(id: number): Promise<void> {
            const sessionInstance = await SessionInstanceService.getSessionInstanceById(id);
            setSessionInstanceData(sessionInstance);
        }
        async function getSessionInstanceActions(page: number): Promise<void> {
            const response = await SessionInstanceService.getSessionInstanceActions(page, rowsPerPage, id, actionTypeId, success, finished, SortingConfiguration.fromSearchString(sortingConfigurationString), );
            setTotalElements(response.totalElements);
            setSessionInstanceActions(response.content);
        }
       
        async function getAllActionTypes(): Promise<void> {
            const actionTypes = await ActionTypeService.getAllActionTypes();
            setActionTypes(actionTypes);
        }
        async function loadData(): Promise<void> {
            await getAllActionTypes();
            if (id) {
                await getSessionInstanceById(parseInt(id));
            }

        }
        loadData().then(_ => { });
        getSessionInstanceActions(page).then((_) => {
        });
      
    }, [page, rowsPerPage, actionTypeId, success, finished, searchParams, sortingConfigurationString,id])
    
    function timeElapsed (){
        let totalTime = 0;
        sessionInstanceData?.actions?.forEach((action)=> totalTime+=action.timeElapsedInSeconds)
        return DateTimeUtils.convertSectoDay(totalTime);
    }

    function returnPercentage(item: SessionInstance) {
        let percentage: number = 0.0;
        if (item.finishedActions < item.totalActions) {
            percentage = parseFloat(((item.finishedActions / item.totalActions) * 100).toFixed(2));
        }
        return percentage;
    }

    function navigateToBotPage(bot: BotsDTO | undefined): void {
        if (bot?.id) {
            BotController.navigateToBotDetailsPage(navigate, bot.id).then(_ => {});
        }
    }
    async function downloadReport(){
        await SessionInstanceService.downloadReport(id?.toString()).catch((error) => {
        const message = ErrorHandler.parseErrorMessage(error);
        showErrorDialog(strings.error, message, strings.ok).then(_ => {});
      });
    }

    async function downloadAllScreenshots() {
        if (sessionInstanceData) {
            await SessionInstanceService.downloadScreenshots(sessionInstanceData.id).catch((error) => {
                const message = ErrorHandler.parseErrorMessage(error);
                showErrorDialog(strings.error, message, strings.ok).then(_ => {});
            });
        }
    }

    function sessionDetails(id: number): any {
        navigate(Routes.SESSION_DETAILS.replace(":id", id.toString()));
    }

    return (
        <>
            <SideBarPage pageTitle={strings.sessionInstanceDetails}
                         breadcrumbs={[
                             { label: strings.dashboard, currentlyOpened: false, href: Routes.HOME},
                             { label: strings.sessionInstances,  currentlyOpened: false, href: Routes.SESSION_INSTANCES },
                             { label: strings.sessionInstanceDetails,  currentlyOpened: true },
                         ]}
                component={
                    <>
                        <CenteredCardLayout minWidth={"80%"} maxWidth={"80%"}>
                            <h5 className="p-1">{strings.session}</h5>
                            <div className="d-flex justify-content-between">
                                <div>
                                    { (sessionInstanceData?.session?.enabled) ?
                                    <div className="p-1 mb-3 navigateLink" style={{cursor:"pointer"}} onClick={()=> {
                                        if (sessionInstanceData?.session) {
                                            sessionDetails(sessionInstanceData.session.id);
                                        }
                                    }
                                    }>
                                        <Grid container>
                                            <div className="p-1">{sessionInstanceData?.session?.name}</div>
                                            <div className="p-1">{sessionInstanceData?.session?.key}</div>
                                        </Grid>
                                    </div>
                                    : <Grid container className="mb-3">
                                            <div className="p-1">{sessionInstanceData?.session?.name}</div>
                                            <div className="p-1">{sessionInstanceData?.session?.key}</div>
                                        </Grid> }
                            <h5 className="p-1">{strings.account}</h5>
                            <div className="p-1 mb-3 navigateLink" style={{cursor:"pointer"}} onClick={()=>navigateToAccountDetails(sessionInstanceData?.account?.id)}>{sessionInstanceData?.account?.email}</div>
                                    {sessionInstanceData?.usedProxy?.id === sessionInstanceData?.proxy?.id ? (
                                    <>
                                        <h5 className="p-1">{strings.proxy}</h5>
                                        <div className="p-1 mb-3 navigateLink" style={{cursor: "pointer"}} onClick={() => navigateToProxyDetails(sessionInstanceData?.usedProxy?.id)}>
                                            {sessionInstanceData?.usedProxy?.name ? sessionInstanceData.usedProxy.name : strings.noProxy}
                                        </div>
                                    </>
                                    ) : (
                                    <>
                                        <h5 className="p-1">{strings.initialProxy}</h5>
                                        <div className="p-1 mb-3 navigateLink" style={{cursor: "pointer"}} onClick={() => navigateToProxyDetails(sessionInstanceData?.proxy?.id)}>
                                            {sessionInstanceData?.proxy?.name ? sessionInstanceData.proxy.name : strings.noProxy}
                                        </div>
                                        <h5 className="p-1">{strings.usedProxy}</h5>
                                        <div className="p-1 mb-3 navigateLink" style={{cursor: "pointer"}} onClick={() => navigateToProxyDetails(sessionInstanceData?.usedProxy?.id)}>
                                            {sessionInstanceData?.usedProxy?.name ? sessionInstanceData.usedProxy.name : strings.noProxy}
                                        </div>
                                    </>
                                    )}
                                    <h5 className="p-1">{strings.botPlatform}</h5>
                                    <div className="p-1 mb-3" >{sessionInstanceData?.botPlatform?.label ? sessionInstanceData.botPlatform.label : ""}
                                        <BotPlatformIcon botPlatform={sessionInstanceData?.botPlatform}/>
                                    </div>
                            <h5 className="p-1">{strings.bot}</h5>
                            <div className={"mb-3"}>
                                {sessionInstanceData?.bot ?
                                    <Grid container>
                                        <div className="p-1 navigateLink cursor-pointer"
                                             onClick={() => navigateToBotPage(sessionInstanceData.bot)}>{sessionInstanceData?.bot?.name}</div>
                                        <div
                                            className="p-1">{sessionInstanceData?.bot?.status === strings.statusDied ? <> {sessionInstanceData?.bot?.status}
                                            <BlockIcon color={'error'}/> </> : null}</div>
                                        <div
                                            className="p-1">{sessionInstanceData?.bot?.status === strings.statusIdle ? <> {sessionInstanceData?.bot?.status}
                                            <AccessAlarmIcon color={'warning'}/> </> : null}</div>
                                        <div
                                            className="p-1">{sessionInstanceData?.bot?.status === strings.statusActive ? <> {sessionInstanceData?.bot?.status}<LiveTvIcon
                                            color={"success"}/> </> : null}</div>
                                    </Grid>
                                    :
                                    <div className="p-1">{strings.noBot}</div>
                                }
                            </div>
                                    {sessionInstanceData?.mobilePhone &&
                                        <div className={"mb-3"}>
                                            <h5>{strings.mobilePhone} <PhonelinkSetupIcon /></h5>
                                            <div>
                                                {
                                                    sessionInstanceData.mobilePhone.friendlyName && <span>{strings.friendlyName + ": " + sessionInstanceData.mobilePhone.friendlyName}
                                                        <br/></span>
                                                }
                                                {strings.serialNumber + ": " + sessionInstanceData.mobilePhone.serialNumber}
                                            </div>
                                        </div>
                                    }
                                    <div className={"mb-3"}>
                                        <h5>{strings.initialized}</h5>
                                        <Tooltip title={sessionInstanceData?.initialized ? strings.initialized : sessionInstanceData?.initialized === false ? strings.notInitialized : strings.null}>
                                            {sessionInstanceData?.initialized ?
                                                <CheckCircleIcon
                                                color="success"
                                                sx={{fontSize: "65px", width: "65px"}}
                                            />  : sessionInstanceData?.initialized === false ? <ErrorCircleIcon
                                                color="error"
                                                sx={{fontSize: "65px", width: "65px"}}
                                            /> : <WarningAmberIcon
                                                color="warning"
                                                sx={{fontSize: "65px", width: "65px"}}
                                            /> }
                                        </Tooltip>
                                    </div>
                            <Grid>
                                <h5 className="p-1">{strings.totalBotUpTime}</h5>
                                <div className="p-1">{sessionInstanceData?.bot?.executionTotalUpTime !== undefined?
                                DateTimeUtils.convertSectoDay(sessionInstanceData?.bot?.executionTotalUpTime): 0
                                }</div>
                            </Grid>
                            <Grid>
                                <h5 className="p-1">{strings.status}</h5>
                                <div className="p-1">{ sessionInstanceData?.sessionInstanceConditionType === SessionInstanceConditionType.finishedType && sessionInstanceData?.successfullyExecuted ? (
                    <div>
                        <Tooltip title={strings.conditionFinishedSuccessfully}>
                            <CheckCircleIcon
                                color="success"
                                sx={{fontSize:"40px", width: "65px"}}
                            />
                        </Tooltip>
                    </div>
                ) : sessionInstanceData?.sessionInstanceConditionType === SessionInstanceConditionType.finishedType && !sessionInstanceData?.successfullyExecuted ? (
                    <div>
                        <Tooltip title={strings.conditionFinishedUnsuccessfully}>
                            <ErrorCircleIcon
                                color="error"
                                sx={{fontSize:"40px", width: "65px"}}
                            />
                        </Tooltip>
                    </div>
                ) : sessionInstanceData?.sessionInstanceConditionType === SessionInstanceConditionType.stalledType ? (
                    <div>
                        <Tooltip title={strings.conditionStalled}>
                            <WarningAmberIcon
                                color="warning"
                                sx={{fontSize:"40px", width: "65px"}}
                            />
                        </Tooltip>
                    </div>
                         ) : sessionInstanceData?.sessionInstanceConditionType === SessionInstanceConditionType.activeType ? (
                    <Tooltip title={strings.conditionActive}>
                        <div style={{maxWidth: "65px"}}>
                            <CircularProgressbar
                                value={returnPercentage(sessionInstanceData)}
                                text={`${returnPercentage(sessionInstanceData)}%`}
                                strokeWidth={7}
                                        />
                                        </div>
                                    </Tooltip>
                                 ) : (<div></div>)
                                    }
                                </div>
                                </Grid>
                            <Grid>
                                <h5 className="p-1"> {strings.totalUpTimeOfExecution} </h5>
                                <div className="p-1">{timeElapsed()}</div>
                            </Grid>
                            <Grid>
                                <h5 className="p-1"> {strings.profile} </h5>
                                <div className="p-1">{sessionInstanceData?.profile !== null? sessionInstanceData?.profile :
                                <div className={"alert alert-warning"}>
                                <div>
                                    {strings.warningMessageProfile}
                                </div>
                                
                                <div>
                                    {strings.firstWarningProfile}
                                </div>

                                <div>
                                    {strings.secondWaringProfile}
                                </div>
                                </div>
                                }</div>
                            </Grid>
                            </div>
                            <div className="d-flex" style={{marginRight:"5%"}}>
                            <div style={{marginRight:"5%"}}> 
                                <h5 className="mb-2">{strings.reports}</h5>
                                <Button className="btn btn-sm mb-2" onClick={downloadReport} variant="contained" style={{whiteSpace:"nowrap"}}><DownloadIcon/> {" "} {strings.downloadReport}</Button>

                                <h5 className="mb-2">{strings.screenshots}</h5>
                                <Button className="btn btn-sm mb-2" onClick={downloadAllScreenshots} variant="contained" style={{whiteSpace:"nowrap"}}><DownloadIcon/> {" "} {strings.downloadAllScreenshots}</Button>
                            </div>
                            <div className="ml-5"  style={{borderStyle: "solid", borderWidth: 2, borderRadius: 5, borderColor: "#e9ecef", maxHeight:"270px"}}>
                            <Chart
                                chartType="PieChart"
                                data={actionsData}
                                options={actionOptions}
                                width={"100%"}
                                height={"230px"}

                            />
                           </div>
                           </div>
                          </div>
                        </CenteredCardLayout>
                        <br />
                        <CenteredCardLayout minWidth={"80%"} maxWidth={"80%"}>
                            <TableContainer>
                                <Table aria-label="collapsible table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                            </TableCell>
                                            <TableCell>
                                            </TableCell>
                                            <TableCell>
                                            </TableCell>
                                            <TableCell>
                                                <FormControl fullWidth variant="standard">
                                                    <InputLabel>{strings.action}</InputLabel>
                                                    <Select
                                                        defaultValue={""}
                                                        value={actionTypes.length > 0 && actionTypeId ? String(actionTypeId) : ""}
                                                        onChange={handleChangeActionTypeId}
                                                        label={strings.action}
                                                        endAdornment={
                                                            actionTypeId && (
                                                                <IconButton
                                                                    onClick={() =>
                                                                        setSearchParam("actionTypeId", null)
                                                                    }
                                                                >
                                                                    <Clear />
                                                                </IconButton>
                                                            )
                                                        }
                                                    >
                                                        {actionTypes?.map((action) => (
                                                            <MenuItem disableRipple key={action.id} value={action.id}>
                                                                {action.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell>
                                            {" "}
                                                <FormGroup>
                                                    <FormControlLabel
                                                        label={strings.finished}
                                                        control={
                                                            <NullableCheckbox
                                                                value={finished}
                                                                onValueChanged={setFinished}
                                                            />
                                                        }
                                                    />
                                                </FormGroup>
                                            </TableCell>
                                            <TableCell>
                                            {" "}
                                                <FormGroup>
                                                    <FormControlLabel
                                                        label={strings.success}
                                                        control={
                                                            <NullableCheckbox
                                                                value={success}
                                                                onValueChanged={setSuccess}
                                                            />
                                                        }
                                                    />
                                                </FormGroup>
                                                
                                            </TableCell>
                                            <TableCell/>
                                            <TableCell/>
                                            <TableCell/>
                                        </TableRow>
                                        <TableHeader columns={columns} sortConfigurationString={sortingConfigurationString} updateSort={updateSort} />
                                    </TableHead>
                                    <TableBody>
                                        {sessionInstanceData && sessionInstanceActions.map((row: any) => (
                                            <ListenedTracks key={row.id} row={row} sessionInstance={sessionInstanceData}/>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Grid sx={{display: 'flex', flexDirection: 'column', alignItems: 'end', marginTop: "10px"}}>
                                <PaginationPlus totalElements={totalElements} rowsPerPage={rowsPerPage} page={page} onChangePage={(page) => handleChangePage(null, page)}/>
                            <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={totalElements}
                            rowsPerPage={rowsPerPage}
                            page={totalElements <= 0 ? 0 : page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                            </Grid>
                        </CenteredCardLayout>
                    </>
                }
            />
        </>
    )
}
