import axios from '../network/AxiosInstance';
import {Buffer} from "buffer";
import {AxiosResponse} from "axios";

export class AuthService {
    static readonly PREFIX = "/rest";

    static async login(username: string, password: string): Promise<string> {
        const auth = new Buffer(`${username}:${password}`).toString('base64');

        const response = await axios.post<string>(`${AuthService.PREFIX}/login`, null, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + auth,
            }
        });

        return response.data;
    }

    static async resetPassword(email: string) : Promise<AxiosResponse<string>> {
        return await axios.post<string>(`/rest/userroles/user/reset-password`, {},  {
            params: {email},
        });
    }

    static async confirmResetPassword(token: string | null, password: string) : Promise<void>{
        await axios.post<string>(`/rest/userroles/user/confirm-password-reset?token=${token}`, {}, {
            headers: {password}
        });
    }
}
