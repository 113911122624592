import axios from "../network/AxiosInstance";
import { AccountGroupDTO } from "../models/AccountGroupDTO";
import { AuthService } from "./AuthService";
import {Page} from "../pagination/Page";
import { RegionDTO } from "../models/RegionDTO";
import { AccountGroupCreationDTO } from "../models/AccountGroupCreationDTO";
import { AccountGroupScheduleDTO } from "../models/AccountGroupScheduleDTO";
import { AccountGroupScheduleCreationDTO } from "../bot-session/dto/AccountGroupScheduleCreationDTO";
import {ScheduledInterval} from "../models/ScheduledInterval";
export class AccountGroupService {
  static async getAllAccountGroups(): Promise<Array<AccountGroupDTO>> {
    const response = await axios.get<Array<AccountGroupDTO>>(
      `${AuthService.PREFIX}/bot-session/account-group/all`
    );
    const data = response.data;
    return data.map((accountGroup) => new AccountGroupDTO(accountGroup));
  }

  static async getAccountGroupById(id: number): Promise<AccountGroupDTO> {
    const response = await axios.get<AccountGroupDTO>(`${AuthService.PREFIX}/bot-session/account-group/get/${id}`);
    const data = response.data;

    return new AccountGroupDTO(data);
  }

  static async getScheduledIntervals(timeZone : string): Promise<Array<ScheduledInterval>> {
    const response = await axios.get<Array<ScheduledInterval>>(`${AuthService.PREFIX}/bot-session/account-group-schedule/scheduled-intervals`, {params:{timeZone}});
    const data = response.data;

    return data.map(scheduledInterval => new ScheduledInterval(scheduledInterval));
  }

    static async getScheduleByAccountGroupId(page: number, size: number, id: number): Promise<Page<AccountGroupScheduleDTO>> {
    const response = await axios.get<Page<AccountGroupScheduleDTO>>(`${AuthService.PREFIX}/bot-session/account-group/${id}/schedules`,
    {params:{page,size}}
    );
    const data = response.data

          return new Page<AccountGroupScheduleDTO>(
              data.content.map(item => new AccountGroupScheduleDTO(item)),
              data.totalElements,
          )
  }

  static async getAllAccountGroupsPaged(page: number, size: number, name?: string | undefined, serviceId? : number| string | undefined,botPlatformId?: string | undefined): Promise<Page<AccountGroupDTO>> {
    const response = await axios.get<Page<AccountGroupDTO>>(
        `${AuthService.PREFIX}/bot-session/account-group/all-paged`,
        {params: {page, size, name, serviceId,botPlatformId}},
    );
    const data = response.data;

    return new Page<AccountGroupDTO>(
        data.content.map(accountGroup => new AccountGroupDTO(accountGroup)),
        data.totalElements,
    );
  }

  static async getAllAppearingRegionsInAccountGroup(id:number | undefined): Promise<Array<RegionDTO>> {
    const response = await axios.get<Array<RegionDTO>>(
      `${AuthService.PREFIX}/bot-session/account-group/${id}/appearing-regions`
    );
    const data = response.data;
    return data.map((region) => new RegionDTO(region));
  }

  static async createAccountGroup(
    accountGroupCreationDTO: AccountGroupCreationDTO
  ): Promise<AccountGroupDTO> {
    const response = await axios.post<AccountGroupDTO>(
      `${AuthService.PREFIX}/bot-session/account-group/create`,
      accountGroupCreationDTO
    );
    const data = response.data;
    return new AccountGroupDTO(data);
  }

  static async editAccount( id: number, accountGroupCreationDTO: AccountGroupCreationDTO): Promise<AccountGroupDTO> {
    const response = await axios.post<AccountGroupDTO>(
        `${AuthService.PREFIX}/bot-session/account-group/update/${id}`, accountGroupCreationDTO);

    const data = response.data;
    return new AccountGroupDTO(data);
}

static async createAccountGroupScheduleTime( accountGroupScheduleCreationDTO:AccountGroupScheduleCreationDTO): Promise<AccountGroupScheduleCreationDTO> {
  const response = await axios.post<AccountGroupScheduleCreationDTO>(
      `${AuthService.PREFIX}/bot-session/account-group-schedule/create/`, accountGroupScheduleCreationDTO);

  const data = response.data;
  return new AccountGroupScheduleCreationDTO(data);
}

static async editAccountGroupScheduleTime( id:number, accountGroupScheduleCreationDTO:AccountGroupScheduleCreationDTO): Promise<AccountGroupScheduleCreationDTO> {
  const response = await axios.put<AccountGroupScheduleCreationDTO>(
      `${AuthService.PREFIX}/bot-session/account-group-schedule/update/${id}`, accountGroupScheduleCreationDTO);

  const data = response.data;
  return new AccountGroupScheduleCreationDTO(data);
}

static async deleteAccountGroupScheduleTime(id: number): Promise<void> {
  await axios.delete<AccountGroupScheduleCreationDTO>(`${AuthService.PREFIX}/bot-session/account-group-schedule/delete/${id}`, {params: {id}});
}

static async deleteAccountGroup(id: number): Promise<void> {
    await axios.delete<AccountGroupDTO>(`/rest/bot-session/account-group/delete/${id}`,{});
}

}