import {
    TableContainer,
    TablePagination,
    TableCell,
    TableHead,
    TableRow,
    TableBody,
    Fab,
    Grid,
    Table, Box
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {DashboardBots} from "./DashboardBots";
import {useSearchParams} from "react-router-dom";
import {NumberParser} from "../../utils/NumberParser";
import {BotsService} from "../../services/BotsService";
import {ListenedTrackDTO} from "../../models/ListenedTrackDTO";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {LightTooltip} from "../../components/LightTooltip";
import "../dashboard/TableCSS.css";

type OnSentIdCleared = {
    (): void
};

interface DashboardBotPreviewProps {
    sentId?: number | undefined;
    onSentIdCleared: OnSentIdCleared;
}

export function DashboardBotPreview(props: DashboardBotPreviewProps) {
    const [lastListenedTracks, setLastListenedTracks] = useState<ListenedTrackDTO[]>();
    const [page, setPage] = useState<number>(0);
    const [totalElements, setTotalElements] = useState(0);
    const [searchParams] = useSearchParams();
    const rowsPerPage = NumberParser.parseNumber(searchParams.get("rowsPerPage"), 4);

    function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
        setPage(newPage);
    }

    async function getLastListenedTracks(id: number, page: number): Promise<void>{
        await BotsService.getLastTracks(id, page, rowsPerPage).then((data) => {
            setLastListenedTracks(data.content);
            setTotalElements(data.totalElements);
            setPage(page);
        })
    }

    useEffect(() => {
        if(props.sentId !== undefined){
            getLastListenedTracks(props.sentId, page);
        }
    }, [props.sentId, page, rowsPerPage])


    return(
        props.sentId !== undefined ?
            <Box className={"tableCSS"}>
                <div className={"mt-3"}>
                <Grid>
                    <Fab onClick={props.onSentIdCleared} color="primary" className={"m-3 float-start"} size={"small"}> <KeyboardBackspaceIcon/> </Fab>
                </Grid>
            <TableContainer>
                <Table aria-label="custom pagination table">
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell width={"21%"}>Name</TableCell>
                        <TableCell width={"15%"}>Artist</TableCell>
                        <TableCell width={"15%"}>URL</TableCell>
                        <TableCell>Service</TableCell>
                        <TableCell>S-I Actions</TableCell>
                        <TableCell>Start Date</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        lastListenedTracks?.map((listenedTrack) => (
                            <TableRow key={listenedTrack?.id}>
                                <TableCell>{listenedTrack?.id}</TableCell>
                                <LightTooltip title={listenedTrack?.name}><TableCell className="truncate-table-cell">{listenedTrack?.name}</TableCell></LightTooltip>
                                <TableCell>{listenedTrack?.artist}</TableCell>
                                <LightTooltip title={listenedTrack?.url}><TableCell className="truncate-table-cell">{listenedTrack?.url}</TableCell></LightTooltip>
                                <TableCell>{listenedTrack.serviceName}</TableCell>
                                <TableCell>{listenedTrack?.sessionInstanceAction.sessionAction.actionType}</TableCell>
                                <LightTooltip title={listenedTrack?.startDate}><TableCell className="truncate-table-cell">{listenedTrack?.startDate}</TableCell></LightTooltip>
                            </TableRow>
                        ))
                    }
                </TableBody>
                </Table>
            </TableContainer>
                </div>
            <TablePagination
                rowsPerPageOptions={[]}
                component="div"
                count={totalElements}
                rowsPerPage={4}
                page={page}
                onPageChange={handleChangePage}
            />
        </Box> : <DashboardBots />


)
}