import { AuthService } from "./AuthService";
import axiosInstance from "../network/AxiosInstance";
import { Dayjs } from "dayjs";
import { SessionInstanceDailyReportDTO } from "../models/SessionInstanceDailyReportDTO";
import { AccountReportResponseDTO } from "../models/AccountReportResponseDTO";
import { ListenedTracksPerDayPerGroupDTO } from "../models/ListenedTracksPerDayPerGroupDTO";
import { ListenedTracksPerArtistDateResponse } from "../models/ListenedTracksPerArtistDateResponse";
import { ListenedTracksPerHourResponse } from "../models/ListenedTracksPerHourResponse";


export class SessionInstanceReportService {

    static async totalListenedTracksPerDay(date: string | null | Dayjs, accountGroupIds: number[]) {
        const params = new URLSearchParams();

        if (accountGroupIds) {
            params.append("accountGroupIds", accountGroupIds.toString());
        }

        if (date) {
            params.append("date", date.toString());
        }

        return await axiosInstance.get(`${AuthService.PREFIX}/bot-session/session-instance-report/total-listened-tracks-per-day`, {
            params: params,
        });
    }

    static async getTotalExecutedSessionInstances(date: string | null, accountGroupIds: number[], regionId: number | undefined): Promise<SessionInstanceDailyReportDTO> {
        let params = new URLSearchParams();
        if (date) {
            params.append("date", date)
        }
        if (accountGroupIds) {
            params.append("accountGroupIds", accountGroupIds.toString())
        }
        if (regionId) {
            params.append("regionId", regionId.toString())
        }

        const response = await axiosInstance.get<SessionInstanceDailyReportDTO>(
            `/rest/bot-session/session-instance-report/total-executed-session-instances`, { params: params }
        );
        const data = response.data;
        return new SessionInstanceDailyReportDTO(data)
    }

    static async getAccountsWithNoListenedTracks(date: string | null, accountGroupIds: number[], regionId: number | undefined): Promise<Array<AccountReportResponseDTO>> {
        let params = new URLSearchParams();
        if (date) {
            params.append("date", date)
        }
        if (accountGroupIds) {
            params.append("accountGroupIds", accountGroupIds.toString())
        }
        if (regionId) {
            params.append("regionId", regionId.toString())
        }

        const response = await axiosInstance.get<Array<AccountReportResponseDTO>>(
            `/rest/bot-session/session-instance-report/accounts-no-listened-tracks`, { params: params }
        );
        const data = response.data;
        return data.map((noListenedTracks) => new AccountReportResponseDTO(noListenedTracks));
    }

    static async getAccountsWithAtLeastOneListenedTrack(date: string | null, accountGroupIds: number[], regionId: number | undefined): Promise<Array<AccountReportResponseDTO>> {
        let params = new URLSearchParams();
        if (date) {
            params.append("date", date)
        }
        if (accountGroupIds) {
            params.append("accountGroupIds", accountGroupIds.toString())
        }
        if (regionId) {
            params.append("regionId", regionId.toString())
        }

        const response = await axiosInstance.get<Array<AccountReportResponseDTO>>(
            `/rest/bot-session/session-instance-report/accounts-at-least-one-listened-track`, { params: params }
        );
        const data = response.data;
        return data.map((noListenedTracks) => new AccountReportResponseDTO(noListenedTracks));
    }

    static async getAccountsWithLastSuccessfulSession(date: string | null, accountGroupIds: number[], regionId: number | undefined): Promise<Array<AccountReportResponseDTO>> {
        let params = new URLSearchParams();
        if (date) {
            params.append("date", date)
        }
        if (accountGroupIds) {
            params.append("accountGroupIds", accountGroupIds.toString())
        }
        if (regionId) {
            params.append("regionId", regionId.toString())
        }

        const response = await axiosInstance.get<Array<AccountReportResponseDTO>>(
            `/rest/bot-session/session-instance-report/accounts-last-successful-session`, { params: params }
        );
        const data = response.data;
        return data.map((noListenedTracks) => new AccountReportResponseDTO(noListenedTracks));
    }

    static async getAccountsThatHaveSwitchedAccountGroup(date: string | null, accountGroupIds: number[], regionId: number | undefined): Promise<Array<AccountReportResponseDTO>> {
        let params = new URLSearchParams();
        if (date) {
            params.append("date", date)
        }
        if (accountGroupIds) {
            params.append("accountGroupIds", accountGroupIds.toString())
        }
        if (regionId) {
            params.append("regionId", regionId.toString())
        }

        const response = await axiosInstance.get<Array<AccountReportResponseDTO>>(
            `/rest/bot-session/session-instance-report/accounts-switched-account-group`, { params: params }
        );
        const data = response.data;
        return data.map((noListenedTracks) => new AccountReportResponseDTO(noListenedTracks));
    }

    static async getTotalListenedTracksPerAccountGroups(date: string | null, accountGroupIds: number[]): Promise<Array<ListenedTracksPerDayPerGroupDTO>> {
        let params = new URLSearchParams();
        if (date) {
            params.append("date", date)
        }
        if (accountGroupIds) {
            params.append("accountGroupIds", accountGroupIds.toString())
        }
        const response = await axiosInstance.get<Array<ListenedTracksPerDayPerGroupDTO>>(
            `/rest/bot-session/session-instance-report/total-listened-tracks-per-day-group`, { params: params }
        );
        const data = response.data;
        return data.map((listenedTracks) => new ListenedTracksPerDayPerGroupDTO(listenedTracks));
    }

    static async listenedTracksPerArtist(date: string, serviceId: number): Promise<ListenedTracksPerArtistDateResponse> {
        const params = new URLSearchParams();
        params.append("date", date);
        params.append("serviceId", serviceId.toString());

        const response = await axiosInstance.get<ListenedTracksPerArtistDateResponse>(
            `/rest/bot-session/session-instance-report/total-listened-tracks-per-artist-date`,
            { params }
        );
        return response.data;

    }

    static async totalListenedTracksByHour(date: string, serviceId: number): Promise<Array<ListenedTracksPerHourResponse>> {
        const params = new URLSearchParams();
        params.append("date", date);
        params.append("serviceId", serviceId.toString());

        const response = await axiosInstance.get<Array<ListenedTracksPerHourResponse>>(
            `/rest/bot-session/session-instance-report/total-listened-tracks-by-hour`,
            { params }
        );

        const data = response.data;
        return data.map((listenedTracks) => new ListenedTracksPerHourResponse(listenedTracks));

    }
}
