import {
    Breadcrumbs,
    Button,
    CardActions, Checkbox,
    FormControl, FormControlLabel, Grid,
    InputAdornment, InputLabel,
    Link,
    MenuItem, OutlinedInput,
    Select,
    TextField, Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import { SideBarPage } from "../../../components/SideBarPage";
import {strings} from "../../../localization/Localization";
import {RoleService} from "../../../services/RoleService";
import {RoleDTO} from "../../../models/RoleDTO";
import {UserCreationDTO} from "../../../models/UserCreationDTO";
import {UserData} from "../../../models/UserData";
import {UserService} from "../../../services/UserService";
import {Routes} from "../../../router/Routes";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import { useForm, Controller } from 'react-hook-form';
import {InterComponentExchangeConstants} from "../../../models/InterComponentExchangeConstants";
import {DateTimeUtils} from "../../../utils/DateTimeUtils";
import {UserCreationWrapper} from "../../../models/UserCreationWrapper";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from "@mui/icons-material/Edit"
import {CenteredCardLayout} from "../../../components/CenteredCardLayout";
import ConfirmationModal from "../../../components/ConfirmationModal";
import {ErrorHandler} from "../../../utils/ErrorHandler";
import {PasswordUpdateDTO} from "../../../models/PasswordUpdateDTO";
import {useNavigate} from "react-router-dom";
import {showErrorDialog, showSuccessDialog} from "../../../common/Dialogs";
import {PaginatedSelect} from "../../../components/PaginatedSelect";
import {ClientDTO} from "../../../models/ClientDTO";
import {ClientService} from "../../../services/ClientService";

export function AddEditUser(props:any){
    const listLinks = [
        { label: strings.dashboard, currentlyOpened: false, href:Routes.HOME},
        { label: strings.users,  currentlyOpened: false, href:Routes.USERS},
        { label: props.info.interComponentExchange.exchangeType === new InterComponentExchangeConstants().ADD_COMPONENT ? strings.addUser : strings.editUser,  currentlyOpened: true},
    ]
    const [roles, setRoles] = useState<RoleDTO[]>([])
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const [showPasswordNew, setShowPasswordNew] = useState<boolean>(false)
    const [showPasswordRetype, setShowPasswordRetype] = useState<boolean>(false)
    const [dateCreated, setDateCreated] = useState<Date | undefined>(undefined)
    const [dateModified, setDateModified] = useState<Date | undefined>(undefined)
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [title, setTitle] = useState<string | undefined>(undefined)
    const {
        handleSubmit,
        setValue,
        control,
        watch,
        getValues,
        formState: { errors }
    } = useForm<UserCreationWrapper>({
        defaultValues: {
            userCreationDTO: new UserCreationDTO({
                userData: new UserData("", "", "", "",undefined),
                roleIds:[],
                enabled:false
            }),
            password: ""

        },
        mode: "onChange",
    });

    const {
        handleSubmit:handleSubmit1,
        setValue: setValue1,
        control:control1,
        watch:watch1,
        getValues:getValues1,
        reset: reset,
        formState: { errors:errors1 }
    } = useForm<PasswordUpdateDTO>({
        defaultValues: {
            passwordNew: "",
            passwordRetype: ""
        },
        mode: "onChange",
    });
    watch();
    watch1();

    function handleCancel() {
        setOpenModal(false);
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    };
    const handleClickShowPasswordNew = () => {
        setShowPasswordNew(!showPasswordNew)
    };
    const handleClickShowPasswordRetype = () => {
        setShowPasswordRetype(!showPasswordRetype)
    };

    useEffect(() => {
        if(props.info.interComponentExchange.exchangeType === new InterComponentExchangeConstants().EDIT_COMPONENT){
            UserService.getById(props.info.interComponentExchange.id).then((res) => {
                setDateCreated(new Date(res.dateCreated))
                setDateModified(new Date(res.dateModified))
                setValue("userCreationDTO.userData.email", res.email)
                setValue("userCreationDTO.userData.firstName", res.person?.firstName)
                setValue("userCreationDTO.userData.lastName", res.person?.lastName)
                setValue("userCreationDTO.userData.phoneNumber", res.person?.phoneNumber)
                setValue("userCreationDTO.userData.client", res.client);
                setValue("userCreationDTO.roleIds", res.roles.map(r=>r.id))
                setValue("userCreationDTO.enabled", res.enabled)
            })
            setTitle(strings.editUser)
        }else if(props.info.interComponentExchange.exchangeType === new InterComponentExchangeConstants().ADD_COMPONENT){
            setTitle(strings.addUser)
        }

        async function getAllRoles(): Promise<void> {
            const response = await RoleService.getAllRoles();
            setRoles(response);
        }

        getAllRoles().then(_ => {});
    }, [props.info.interComponentExchange.exchangeType, props.info.interComponentExchange.id, setValue]);

    const handleClickOpen =()=> {
        setOpenModal(true);
    }
    function createUser(): void {
        const data = new UserCreationDTO(getValues("userCreationDTO"));
        if (props.info.interComponentExchange.exchangeType === new InterComponentExchangeConstants().EDIT_COMPONENT) {
            UserService.updateUser(data, props.info.interComponentExchange.id).then(() => {
                showSuccessDialog(strings.success, strings.userUpdatedSuccessfully, strings.ok).then(_ => { window.location.reload();});
            }).catch(error => {
                const message = ErrorHandler.parseErrorMessage(error);
                showErrorDialog(strings.error, message, strings.ok).then(_ => {});
            });
        } else {
            UserService.createUser(data, getValues("password")).then(() => {
                showSuccessDialog(strings.success, strings.successfullyAddedNewUser, strings.ok).then(_ => {});
                navigate(Routes.USERS);
            }).catch(error => {
                const message = ErrorHandler.parseErrorMessage(error);
                showErrorDialog(strings.error, message, strings.ok).then(_ => {});
            });
        }
    }
    const updatePassword =() => {
        if(getValues1("passwordNew") === getValues1("passwordRetype")){
            UserService.updatePassword(getValues1("passwordNew"), props.info.interComponentExchange.id).then(() => {
                showSuccessDialog(strings.success, strings.passwordUpdatedSuccessfully, strings.ok).then(_ => {});
                reset();
            }).catch(error => {
                const message = ErrorHandler.parseErrorMessage(error);
                showErrorDialog(strings.error, message, strings.ok).then(_ => {});
            });
        }
    }
    const deleteUser = async () => {
        try {
            setOpenModal(false);

            await UserService.deleteUser(props.info.interComponentExchange.id);
            showSuccessDialog(strings.success, strings.successfullyDeletedUser, strings.ok).then(_ => {
                navigate(Routes.USERS);
                window.location.reload();
            });
        } catch (e: any) {
            const errorMessage = ErrorHandler.parseErrorMessage(e);
            showErrorDialog(strings.error, errorMessage, strings.ok).then(_ => {});
        }
    }

    return (
        <SideBarPage pageTitle={title!}
        breadcrumbs={listLinks}
                     component={
                         <CenteredCardLayout minWidth= {props.info.interComponentExchange.exchangeType === new InterComponentExchangeConstants().EDIT_COMPONENT  ? "70%" : "40%"}>
                             <Grid
                                 container
                                 rowSpacing={1}
                                 columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                             >
                                 <Grid item xs={12} sm={12} md={props.info.interComponentExchange.exchangeType === new InterComponentExchangeConstants().EDIT_COMPONENT ? 6 : 12}>
                                 {((props.info.interComponentExchange.exchangeType === new InterComponentExchangeConstants().EDIT_COMPONENT)
                                     || (props.info.interComponentExchange.exchangeType === new InterComponentExchangeConstants().ADD_COMPONENT))
                                 &&
                                 <form onSubmit={handleSubmit(createUser)}>
                                     <Controller
                                         name="userCreationDTO.userData.email"
                                         rules={{
                                             required: true,
                                             pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                         }}
                                         control={control}
                                         render={({field}) => (
                                             <TextField
                                                 InputLabelProps={{shrink: getValues("userCreationDTO.userData.email") !== undefined}}
                                                 {...field}
                                                 fullWidth
                                                 id={strings.email}
                                                 label={strings.email}
                                                 className="mb-3"
                                                 error={errors.userCreationDTO?.userData?.email !== undefined}
                                             />)}
                                     />
                                     <Controller
                                         name="userCreationDTO.userData.firstName"
                                         rules={{
                                             required: true,
                                         }}
                                         control={control}
                                         render={({field}) => (
                                             <TextField
                                                 InputLabelProps={{shrink: getValues("userCreationDTO.userData.firstName") !== undefined}}
                                                 {...field}
                                                 fullWidth
                                                 id={strings.firstName}
                                                 label={strings.firstName}
                                                 className="mb-3"
                                                 error={errors.userCreationDTO?.userData?.firstName !== undefined}
                                             />)}
                                     />
                                     <Controller
                                         name="userCreationDTO.userData.lastName"
                                         rules={{
                                             required: true,
                                         }}
                                         control={control}
                                         render={({field}) => (
                                             <TextField
                                                 InputLabelProps={{shrink: getValues("userCreationDTO.userData.lastName") !== undefined}}
                                                 {...field}
                                                 fullWidth
                                                 id={strings.lastName}
                                                 label={strings.lastName}
                                                 className="mb-3"
                                                 error={errors.userCreationDTO?.userData?.lastName !== undefined}
                                             />)}
                                     />
                                     <Controller
                                         name="userCreationDTO.userData.phoneNumber"
                                         rules={{
                                             required: true,
                                         }}
                                         control={control}
                                         render={({field}) => (
                                             <TextField
                                                 InputLabelProps={{shrink: getValues("userCreationDTO.userData.phoneNumber") !== undefined}}
                                                 {...field}
                                                 fullWidth
                                                 id={strings.phoneNumber}
                                                 label={strings.phoneNumber}
                                                 className="mb-3"
                                                 error={errors.userCreationDTO?.userData?.phoneNumber !== undefined}
                                             />)}
                                     />
                                     {props.info.interComponentExchange.exchangeType === new InterComponentExchangeConstants().ADD_COMPONENT &&
                                         <FormControl variant="outlined" fullWidth className="mb-3">
                                             <InputLabel
                                                 htmlFor="outlined-adornment-password">Password</InputLabel>
                                             <Controller
                                                 name="password"
                                                 rules={{
                                                     required: true,
                                                 }}
                                                 control={control}
                                                 render={({field}) => (
                                                     <OutlinedInput
                                                         {...field}
                                                         fullWidth
                                                         id="outlined-adornment-password"
                                                         type={showPassword ? 'text' : 'password'}
                                                         error={errors.password !== undefined}
                                                         endAdornment={
                                                             <InputAdornment position="end">
                                                                 <IconButton
                                                                     aria-label="toggle password visibility"
                                                                     onClick={handleClickShowPassword}
                                                                     edge="end"
                                                                 >
                                                                     {showPassword ? <VisibilityOff/> :
                                                                         <Visibility/>}
                                                                 </IconButton>
                                                             </InputAdornment>
                                                         }
                                                         label="Password"
                                                     />)}
                                             />
                                         </FormControl>
                                     }
                                     <FormControl fullWidth className="mb-3">
                                         <InputLabel id="demo-simple-select-label">Select role</InputLabel>
                                         <Controller
                                             name="userCreationDTO.roleIds"
                                             rules={{
                                                 required: true,
                                             }}
                                             control={control}
                                             render={({field: {onChange, value}}) => (

                                                 <Select
                                                     onChange={onChange}
                                                     multiple
                                                     labelId="demo-simple-select-label"
                                                     id="demo-simple-select"
                                                     value={value}
                                                     label="Select role"
                                                     error={errors.userCreationDTO?.roleIds !== undefined}
                                                 >
                                                     {roles.map((role) => (
                                                         <MenuItem
                                                             key={role.role}
                                                             value={role.id}
                                                         >
                                                             {role.label}
                                                         </MenuItem>
                                                     ))}
                                                 </Select>
                                             )}
                                         />
                                     </FormControl>
                                     <FormControl
                                         fullWidth
                                         sx={{ textAlign: "left" }}
                                         error={errors.userCreationDTO?.userData?.client !== undefined}
                                     >
                                         <Controller
                                             name="userCreationDTO.userData.client"
                                             rules={{
                                                 required: false,
                                             }}
                                             control={control}
                                             render={({ field: { onChange, value } }) => (
                                                 <PaginatedSelect<ClientDTO>
                                                     value={value ?? undefined}
                                                     onChange={onChange}
                                                     label={strings.client}
                                                     valueMapper={(item) => item.id.toString()}
                                                     keyMapper={(item) => item.id.toString()}
                                                     itemMapper={(item) => <>{item.name}</>}
                                                     labelMapper={(item) => item?.name ?? ""}
                                                     dataFetcher={(page, size, filter) => {
                                                         return ClientService.getAllClientsPaged(
                                                             page,
                                                             size,
                                                             filter,
                                                             null,
                                                             null,
                                                             undefined

                                                         );
                                                     }}
                                                     inputProps={{
                                                         variant: undefined,
                                                         error:errors.userCreationDTO?.userData?.client !== undefined,
                                                         helperText:
                                                             errors.userCreationDTO?.userData?.client !== undefined
                                                                 ? errors.userCreationDTO?.userData?.client.message
                                                                 : "",
                                                     }}
                                                 />
                                             )}
                                         />
                                     </FormControl>



                                     {props.info.interComponentExchange.exchangeType === new InterComponentExchangeConstants().EDIT_COMPONENT &&
                                         <>
                                             <FormControlLabel
                                                 control={
                                                     <Controller
                                                         name="userCreationDTO.enabled"
                                                         control={control}
                                                         render={({field: props}) => (
                                                             <Checkbox
                                                                 {...props}
                                                                 checked={props.value}
                                                             />)}
                                                     />
                                                 }
                                                 label={strings.enabled}
                                             />
                                             {dateCreated &&
                                                 <>
                                                     <Typography>Date
                                                         created: {DateTimeUtils.formatDateObject(dateCreated!)}</Typography>
                                                     <Typography>Date
                                                         modified: {DateTimeUtils.formatDateObject(dateModified!)} </Typography>
                                                 </>
                                             }
                                         </>
                                     }
                                     <CardActions className="p-0 mt-4 d-flex justify-content-end">
                                         {props.info.interComponentExchange.exchangeType === new InterComponentExchangeConstants().ADD_COMPONENT ? (
                                             <>
                                                 <Button type="submit" variant={"contained"}
                                                         className="w-50"><AddIcon/> {strings.addUser}</Button>
                                             </>
                                         ) : props.info.interComponentExchange.exchangeType === new InterComponentExchangeConstants().EDIT_COMPONENT ? (
                                             <>
                                                 <Button onClick={handleClickOpen}
                                                         variant={"contained"}
                                                         color={"error"}
                                                         className="w-50"><DeleteIcon/>{strings.delete}</Button>
                                                 <Button type="submit" variant={"contained"}
                                                         className="w-50"><EditIcon/>{strings.save}</Button>
                                             </>
                                         ) : (<div></div>)
                                         }
                                         <ConfirmationModal
                                             open={openModal}
                                             handleClose={handleCancel}
                                             handleConfirm={deleteUser}
                                             title={strings.deleteUser}
                                             content={strings.deleteUserDialog}
                                             actionButtonName={strings.deleteUserButton}
                                         />
                                     </CardActions>
                                 </form>
                             }
                                 </Grid>
                                 {props.info.interComponentExchange.exchangeType === new InterComponentExchangeConstants().EDIT_COMPONENT ? (
                                     <Grid item xs={12} sm={4} md={6}>
                                         <form onSubmit={handleSubmit1(updatePassword)}>
                                             <FormControl variant="outlined" fullWidth className="mb-3">
                                                 <InputLabel
                                                     htmlFor="outlined-adornment-password">{strings.passwordNew}</InputLabel>
                                                 <Controller
                                                     name="passwordNew"
                                                     rules={{
                                                         required: true,
                                                     }}
                                                     control={control1}
                                                     render={({field}) => (
                                                         <OutlinedInput
                                                             {...field}
                                                             fullWidth
                                                             id="outlined-adornment-password"
                                                             type={showPasswordNew ? 'text' : 'password'}
                                                             error={errors1.passwordNew !== undefined}
                                                             endAdornment={
                                                                 <InputAdornment position="end">
                                                                     <IconButton
                                                                         aria-label="toggle password visibility"
                                                                         onClick={handleClickShowPasswordNew}
                                                                         edge="end"
                                                                     >
                                                                         {showPasswordNew ? <VisibilityOff/> :
                                                                             <Visibility/>}
                                                                     </IconButton>
                                                                 </InputAdornment>
                                                             }
                                                             label={strings.passwordNew}
                                                         />)}
                                                 />
                                             </FormControl>
                                             <FormControl variant="outlined" fullWidth className="mb-3">
                                                 <InputLabel
                                                     htmlFor="outlined-adornment-password">{strings.passwordRetype}</InputLabel>
                                                 <Controller
                                                     name="passwordRetype"
                                                     rules={{
                                                         required: true,
                                                         validate : (value) => value === watch1('passwordNew') || "Passwords do not match!"
                                                     }}
                                                     control={control1}
                                                     render={({field}) => (
                                                         <OutlinedInput
                                                             {...field}
                                                             fullWidth
                                                             id="outlined-adornment-password"
                                                             type={showPasswordRetype ? 'text' : 'password'}
                                                             error={errors1.passwordRetype !== undefined}
                                                             endAdornment={
                                                                 <InputAdornment position="end">
                                                                     <IconButton
                                                                         aria-label="toggle password visibility"
                                                                         onClick={handleClickShowPasswordRetype}
                                                                         edge="end"
                                                                     >
                                                                         {showPasswordRetype ? <VisibilityOff/> :
                                                                             <Visibility/>}
                                                                     </IconButton>
                                                                 </InputAdornment>
                                                             }
                                                             label={strings.passwordRetype}
                                                         />)}
                                                 />
                                                 {errors1.passwordRetype && <p className="text-danger">{errors1.passwordRetype.message}</p>}
                                             </FormControl>
                                             <Button type="submit" variant={"contained"}
                                                     className="w-50 float-end"><EditIcon/>{strings.save}</Button>
                                         </form>
                                     </Grid>
                                 ) : (<></>)}
                                 </Grid>
                         </CenteredCardLayout>
                     }/>
    )


}
