import { TargetPlaylistItemCreationDTO } from "../models/TargetPlaylistItemCreationDTO";
import { TargetPlaylistItemDTO } from "../models/TargetPlaylistItemDTO";
import axiosInstance from "../network/AxiosInstance";
import { AuthService } from "./AuthService";

export class TargetPlaylistItemService {

    static async getTargetPlaylistItemById(id: number): Promise<TargetPlaylistItemDTO> {

        const response = await axiosInstance.get<TargetPlaylistItemDTO>(`${AuthService.PREFIX}/playlists/target-playlist-item/get/${id}`);
        return response.data;
    };

    static async editTargetPlaylistItem(id: number, data: TargetPlaylistItemCreationDTO) {
        const response = await axiosInstance.post(`${AuthService.PREFIX}/playlists/target-playlist-item/update/${id}`, data);
        return response.data;
    };

    static async deleteTargetPlaylistItem(id: number): Promise<void> {
        await axiosInstance.delete(`${AuthService.PREFIX}/playlists/target-playlist-item/delete/${id}`)
    }
}