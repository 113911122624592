import { strings } from "../../localization/Localization";
import DeleteIcon from '@mui/icons-material/Delete';
import {
    Button,
    CardActions,
    FormControl,
    Grid,
    Input,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    TextField
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { AccountService } from "../../services/AccountService";
import { AccountDTO } from "../../models/AccountDTO";
import { Routes } from "../../router/Routes";
import { useNavigateWithParams } from "../../router/NavigateWithParams";
import AccountModal from "./AccountModal";
import { RegionService } from "../../services/RegionService";
import { AccountCreationDTO } from "../../models/AccountCreationDTO";
import { RegionDTO } from "../../models/RegionDTO";
import { AccountGroupDTO } from "../../models/AccountGroupDTO";
import { ServiceDTO } from "../../models/ServiceDTO";
import { AgeGroupDTO } from "../../models/AgeGroupDTO";
import { AccountGroupService } from "../../services/AccountGroupService";
import { AgeGroupService } from "../../services/AgeGroupService";
import { ServicesService } from "../../services/ServicesService";
import { Controller, useForm } from "react-hook-form";
import { Clear, Edit } from "@mui/icons-material";
import { AccountFilter } from "../../models/filters/AccountFilter";
import { PaginatedSelect } from "../../components/PaginatedSelect";
import { CenteredCardLayout } from "../../components/CenteredCardLayout";
import { CookieCollectionDTO } from "../../models/CookieCollectionDTO";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import { CookieService } from "../../services/CookieService";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ErrorHandler } from "../../utils/ErrorHandler";
import { showErrorDialog, showSuccessDialog } from "../../common/Dialogs";
import IconButton from "@mui/material/IconButton";
import { PaymentAccountDTO } from "../../models/PaymentAccountDTO";
import { PaymentAccountsService } from "../../services/PaymentAccountsService";
import { Dayjs } from "dayjs";
import { DateTimeUtils } from "../../utils/DateTimeUtils";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AddIcon from '@mui/icons-material/Add';
import { PaymentAccountTypeService } from "../../services/PaymentAccountTypeService";
import { PaymentAccountTypeDTO } from "../../models/PaymentAccountTypeDTO";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import { ProxyDTO } from "../../models/ProxyDTO";
import { ProxyService } from "../../services/ProxyService";
import { VisibilityEye } from "../../components/VisibilityEye";
import { PaginationPlus } from "../../components/PaginationPlus";
import { FingerprintService } from "../../services/FingerprintService";
import { FingerprintDTO } from "../../models/FingerprintDTO";


class AccountFormData {
    email: string | undefined;
    parentAccount: AccountDTO | undefined;
    accountGroup: AccountGroupDTO | undefined;
    ageGroupId: string | undefined;
    regionId: string | undefined;
    serviceId: string | undefined;
    cookieCollection: CookieCollectionDTO | undefined;
    paymentAccounts: PaymentAccountDTO | undefined;
    proxy: ProxyDTO | undefined;
    loginUsername?: string;
    loginPassword?: string;
    recoveryEmail?: string;
    fingerprint?: FingerprintDTO
    activationDate?: Date;

    constructor(json: any) {
        this.email = json.email;
        this.regionId = json.regionId;
        this.serviceId = json.serviceId;
        this.ageGroupId = json.ageGroupId;
        this.parentAccount = json.parentAccount;
        this.accountGroup = json.accountGroup;
        this.cookieCollection = json.cookieCollection;
        this.paymentAccounts = json.paymentAccounts;
        this.proxy = json.proxy;
        this.loginUsername = json.loginUsername;
        this.loginPassword = json.loginPassword;
        this.recoveryEmail = json.recoveryEmail;
        this.fingerprint = json.fingerprint;
        this.activationDate = json.activationDate;
    }

    toAccountCreationDTO(): AccountCreationDTO {
        return new AccountCreationDTO({
            email: this.email,
            regionId: this.regionId,
            serviceId: this.serviceId,
            ageGroupId: this.ageGroupId,
            parentAccountId: this.parentAccount?.id,
            accountGroupId: this.accountGroup?.id,
            cookieCollection: this.cookieCollection,
            paymentAccounts: this.paymentAccounts,
            proxyId: this.proxy?.id,
            loginUsername: this.loginUsername ? this.loginUsername : undefined,
            loginPassword: this.loginPassword ? this.loginPassword : undefined,
            recoveryEmail: this.recoveryEmail ? this.recoveryEmail : undefined,
            fingerprint: this.fingerprint ? this.fingerprint : undefined,
            activationDate: this.activationDate ? this.activationDate : undefined
        })
    }
}

interface AccountDetailsComponentProps {
    id?: number;
}

export function AccountDetailsComponent(props: AccountDetailsComponentProps) {
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [regions, setRegions] = useState<RegionDTO[] | undefined>([]);
    const [service, setService] = useState<ServiceDTO[] | undefined>([]);
    const [ageGroup, setAgeGroup] = useState<AgeGroupDTO[] | undefined>([]);
    const [cookieCollection, setCookieCollection] = useState<CookieCollectionDTO[] | undefined>([]);
    const [fingerprints, setFingerprints] = useState<FingerprintDTO[] | undefined>([]);
    const [page, setPage] = useState<number>(0);
    const [totalElements, setTotalElements] = useState(0);
    const [fingerprintPage, setFingerprintPage] = useState<number>(0);
    const [totalFingerprintElements, setTotalFingerprintElements] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [rowsPerPageFingerprints, setRowsPerPageFingerprints] = useState(5);
    const [paymentAccountsPage, setPaymentAccountsPage] = useState<number>(0);
    const [totalPaymentAccounts, setTotalPaymentAccounts] = useState(0);
    const [paymentAccountsPerPage, setPaymentAccountsPerPage] = useState(5);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFingerprint, setSelectedFingerprint] = useState(null);
    const [paymentAccountType, setPaymentAccountType] = useState<PaymentAccountTypeDTO[] | undefined>([]);
    const [paymentAccountTypeId, setPaymentAccountTypeId] = useState<number | undefined>(undefined);
    const [dateCreated, setDateCreated] = useState<string | null | Dayjs>(null);
    const [dateModified, setDateModified] = useState<string | null | Dayjs>(null);
    const [paymentAccounts, setPaymentAccounts] = useState<PaymentAccountDTO[]>([]);
    const [username, setUsername] = useState<string | undefined>("");
    const [phoneNumber, setPhoneNumber] = useState<string | undefined>("");

    const id = props.id;
    const isAddMode = !id;

    const navigate = useNavigateWithParams();

    const {
        handleSubmit,
        setValue,
        control,
        watch,
        getValues,
        formState: { errors }
    } = useForm<AccountFormData>({
        defaultValues: {
            email: "",
            parentAccount: undefined,
            accountGroup: undefined,
            ageGroupId: "",
            regionId: "",
            serviceId: "",
            cookieCollection: undefined,
            proxy: undefined,
            loginUsername: "",
            loginPassword: "",
            recoveryEmail: "",
            activationDate: undefined
        },
        mode: "onChange"
    });
    watch();

    function onSubmit() {
        return isAddMode ? addAccount() : editAccount()
    }

    function buildAccount(): AccountCreationDTO {
        return new AccountFormData(getValues()).toAccountCreationDTO();
    }

    function addAccount() {
        let region = regions?.find((el => el.id.toString() === getValues("regionId")?.toString()))
        if (getValues("proxy") && region?.id !== getValues("proxy")?.region?.id) {
            showErrorDialog(strings.error, strings.regionEqualAsProxyRegion, strings.ok).then(_ => { });
        }
        else {
            AccountService.createAccount(buildAccount()).then((newAccount) => {
                showSuccessDialog(strings.success, strings.AccountAddedSuccessfully, strings.ok).then(_ => {
                    navigate(Routes.ACCOUNT_DETAILS.replace(":id", newAccount?.id!.toString()))
                });
            }).catch((error) => {
                const message = ErrorHandler.parseErrorMessage(error);
                showErrorDialog(strings.error, message, strings.ok).then(_ => { });
            });

        }
    }

    function editAccount() {
        if (accountData === null) {
            return;
        }
        let region = regions?.find((el => el.id.toString() === getValues("regionId")?.toString()))
        if (getValues("proxy") && region?.id !== getValues("proxy")?.region?.id) {
            showErrorDialog(strings.error, strings.regionEqualAsProxyRegion, strings.ok).then(_ => { });
        }
        else {
            AccountService.editAccount(buildAccount(), accountData?.id).then(() => {
                showSuccessDialog(strings.success, strings.accountUpdatedSuccessfully, strings.ok).then(_ => {
                    window.location.reload();
                });
            }).catch(e => {
                const errorMessage = ErrorHandler.parseErrorMessage(e);
                showErrorDialog(strings.error, errorMessage, strings.ok).then(_ => {
                });
            });
        }
    }

    async function deleteAccount(): Promise<void> {
        setOpenModal(false);

        if (accountData === null) {
            return;
        }
        try {
            await AccountService.deleteAccount(accountData.id);
            showSuccessDialog(strings.success, strings.accountDeletedSuccessfully, strings.ok).then(_ => {
                navigate(Routes.ACCOUNTS);
                window.location.reload();
            });
        } catch (e: any) {
            const errorMessage = ErrorHandler.parseErrorMessage(e);
            showErrorDialog(strings.error, errorMessage, strings.ok).then(_ => { });
        }
    }

    const [accountData, setAccountData] = useState<AccountDTO | null>(null);
    async function getAllRegions() {
        const regions = await RegionService.getRegions();
        setRegions(regions);
    }

    async function getAllServices() {
        const services = await ServicesService.getAllServices();
        setService(services);
    }

    async function getAllAgeGroups() {
        const ageGroups = await AgeGroupService.getAllAgeGroups();
        setAgeGroup(ageGroups);
    }

    function handleChangePage(
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) {
        setPage(newPage);
    }

    function handleChangePageFingerprints(
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) {
        setFingerprintPage(newPage);
    }

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    function handleChangeRowsPerPageFingerprints(event: React.ChangeEvent<HTMLInputElement>) {
        setRowsPerPageFingerprints(parseInt(event.target.value, 10));
        setFingerprintPage(0);
    }
    function handleChangePages(
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPages: number
    ) {
        setPaymentAccountsPage(newPages);
    }

    function handleChangeRowsPerPages(event: React.ChangeEvent<HTMLInputElement>) {
        setPaymentAccountsPerPage(parseInt(event.target.value, 10));
        setPaymentAccountsPage(0);
    }

    async function uploadFile() {
        try {
            if (accountData) {
                await CookieService.importCookieCollections(accountData.id, selectedFile);
                showSuccessDialog(strings.success, strings.cookieCollectionUploadedSuccessfully, strings.ok).then(_ => {
                    window.location.reload();
                });
            }
        } catch (e) {
            const message = ErrorHandler.parseErrorMessage(e);
            showErrorDialog(strings.error, message, strings.ok).then(_ => { });
        }
    }

    async function uploadFingerprint() {
        try {
            if (accountData) {
                await FingerprintService.importFingerprint(accountData.id, selectedFingerprint);
                showSuccessDialog(strings.success, strings.uploadSuccessFingerprint, strings.ok).then(_ => {
                    window.location.reload();
                });
            }
        } catch (e) {
            const message = ErrorHandler.parseErrorMessage(e);
            showErrorDialog(strings.error, message, strings.ok).then(_ => { });
        }
    }

    function downloadObjectAsJson(exportObj: any, exportName: string) {
        let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj));
        let downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute("href", dataStr);
        downloadAnchorNode.setAttribute("download", exportName + ".json");
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    }

    async function exportCookieCollections(): Promise<void> {
        const cookieId = getValues("cookieCollection.id");
        if (cookieId) {
            let cookieCollectionDTO = await CookieService.exportCookieCollection(+cookieId);
            downloadObjectAsJson(cookieCollectionDTO, cookieCollectionDTO.name);
        }
    }

    async function exportFingerprint(): Promise<void> {
        const fingerprintId = getValues("fingerprint.id");
        if (fingerprintId) {
            let fingerprintDTO = await FingerprintService.exportFingerprint(+fingerprintId);
            downloadObjectAsJson(fingerprintDTO, getValues("fingerprint.name"));
        }
    }

    const handleFileSelect = (event: any) => {
        setSelectedFile(event.target.files[0])
    }

    const handleFingerprintSelect = (event: any) => {
        setSelectedFingerprint(event.target.files[0])
    }

    async function getAllPaymentAccounts(paymentAccountsPage: number) {
        await PaymentAccountsService.getAllPaymentAccountsPaged(id ? +id : undefined, paymentAccountTypeId, username, phoneNumber, dateCreated, dateModified, paymentAccountsPage, paymentAccountsPerPage).then((data) => {
            setPaymentAccounts(data.content);
            setTotalPaymentAccounts(data.totalElements);
            setPaymentAccountsPage(paymentAccountsPage);
        })
    }

    useEffect(() => {
        async function getAccountById(id: number): Promise<void> {
            const account = await AccountService.getAccountById(id);
            setValue("email", account.email);
            setValue("accountGroup", account.accountGroup);
            setValue("serviceId", account.service?.id.toString());
            setValue("regionId", account.region?.id.toString());
            setValue("ageGroupId", account.ageGroup?.id.toString());
            setValue("parentAccount", account.parentAccount);
            setValue("proxy", account.proxy);
            setValue("loginUsername", account.loginUsername);
            setValue("loginPassword", account.loginPassword);
            setValue("recoveryEmail", account.recoveryEmail);
            setValue("activationDate", account.activationDate);
            setAccountData(account);
        }
        async function getAllCollections(page: number) {
            await CookieService.getAllCollectionsPaged(page, rowsPerPage, id ? +id : undefined).then((data) => {
                setCookieCollection(data.content);
                setTotalElements(data.totalElements);
                setPage(page);
            })
        }

        async function getAllFingerprints(page: number) {
            await FingerprintService.getAllFingerprintsPage(page, rowsPerPage, id ? +id : undefined).then((data) => {
                setFingerprints(data.content);
                setTotalFingerprintElements(data.totalElements);
                setFingerprintPage(page);
            })
        }

        async function fetchData(): Promise<void> {
            await getAllRegions();
            await getAllServices();
            await getAllAgeGroups();
            await getAllPaymentAccountsType();

            if (id) {
                await getAccountById(id);
                await getAllPaymentAccounts(page);

                if (!isAddMode) {
                    await getAllCollections(page);
                    await getAllFingerprints(fingerprintPage)
                }
            }

        }

        fetchData().then(_ => {
        });
    }, [id, setValue, page, fingerprintPage, rowsPerPage, paymentAccountsPage, paymentAccountsPerPage, isAddMode])

    function handleClickOpen(): any {
        setOpenModal(true);
    }

    function handleClose(): any {
        setOpenModal(false);
    }

    function getSelectedServiceId() {
        const serviceIdString = getValues("serviceId");

        if (serviceIdString) {
            return +serviceIdString;
        }
        return null;
    }
    function handleChangeDateCreated(date: Dayjs | null) {
        setDateCreated(DateTimeUtils.formatDateDayJs(date));
        setPaymentAccountsPage(0);
    }
    function handleChangeDateModified(date: Dayjs | null) {
        setDateModified(DateTimeUtils.formatDateDayJs(date));
        setPaymentAccountsPage(0);
    }
    function handleChangeUsername(event: React.ChangeEvent<HTMLInputElement>) {
        setUsername(event.target.value !== "" ? event.target.value : undefined);
        setPaymentAccountsPage(0);
    }
    function handleChangePhoneNumber(event: React.ChangeEvent<HTMLInputElement>) {
        setPhoneNumber(event.target.value !== "" ? event.target.value : undefined);
        setPaymentAccountsPage(0);
    }
    function handleChangePaymentAccountType(event: SelectChangeEvent) {
        const paymentAccountType = event.target.value;
        setPaymentAccountTypeId(paymentAccountType ? +paymentAccountType : undefined);
        setPage(0);
    }

    useEffect(() => {
        if (id) {
            getAllPaymentAccounts(0).then(_ => { });
        }

    }, [paymentAccountTypeId, username, phoneNumber, dateCreated, dateModified, page, rowsPerPage, id])

    async function getAllPaymentAccountsType() {
        const paymentAccountType = await PaymentAccountTypeService.getAllPaymentAccountsType();
        setPaymentAccountType(paymentAccountType);
    }
    function navigateAccountRow(id: number): any {
        navigate(Routes.PAYMENT_ACCOUNT_DETAILS.replace(":id", id.toString()).replace(":accountId", accountData!.id.toString()));
    }
    function navigateAccount(): any {
        navigate(Routes.PAYMENT_ACCOUNT_NEW.replace(":accountId", accountData!.id.toString()));
    }
    function checkValueOfRegion() {
        if (!isAddMode && getValues(`regionId`)) {
            return true;
        } else if (!isAddMode && !getValues('regionId')) {
            return false;
        } else if (isAddMode && getValues('regionId')) {
            return true;
        } else if (isAddMode && !getValues('regionId')) {
            return false;
        }
    }

    return (
        <>
            <CenteredCardLayout minWidth={!isAddMode ? "70%" : "40%"}>
                {((isAddMode) || (!isAddMode && getValues("serviceId"))) &&
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {(!isAddMode) && (accountData!.actionNeeded) ?
                            <Grid direction={"column"} container justifyContent={"center"} alignItems={"center"} className={"mt-2 mb-5"}>
                                <div className={"alert alert-warning"}>
                                    {strings.actionNeeded}{strings.pleaseCheck}<br></br>
                                    <ul>
                                        <li>{strings.accountIsBanned}</li>
                                        <li>{strings.accountHasValidCookies}</li>
                                    </ul>
                                </div>

                            </Grid> : null}
                        <Grid
                            container
                            rowSpacing={1}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        >
                            <Grid item xs={12} sm={4} md={!isAddMode ? 6 : 12}>
                                <Controller
                                    name="email"
                                    rules={{
                                        required: true,
                                        pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                    }}
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            InputLabelProps={{ shrink: getValues("email") !== undefined }}
                                            {...field}
                                            fullWidth
                                            id={strings.email}
                                            label={strings.email}
                                            className="mb-3"
                                            error={errors.email !== undefined}
                                        />)}
                                />
                                <FormControl
                                    fullWidth
                                    className="mb-3"
                                    sx={{ textAlign: "left" }}
                                    error={errors.serviceId !== undefined}
                                >
                                    <InputLabel
                                        shrink={!!getValues("serviceId")}
                                    >
                                        {strings.service}
                                    </InputLabel>
                                    <Controller
                                        name="serviceId"
                                        rules={{
                                            required: true,
                                        }}
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <Select
                                                value={value}
                                                onChange={onChange}
                                                input={
                                                    <OutlinedInput
                                                        label={strings.service}
                                                        notched={
                                                            !!(!isAddMode || (isAddMode && getValues("serviceId")))
                                                        }
                                                    />
                                                }
                                            >
                                                {service!.map((ser) => (
                                                    <MenuItem key={ser.id} value={ser.id}>
                                                        {ser.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                    />
                                </FormControl>
                                <FormControl
                                    fullWidth
                                    className="mb-3"
                                    sx={{ textAlign: "left" }}
                                    error={errors.accountGroup !== undefined}
                                >
                                    <Controller
                                        name="accountGroup"
                                        rules={{
                                            required: false,
                                        }}
                                        control={control}
                                        render={({ field: { onChange } }) => (
                                            <PaginatedSelect<AccountGroupDTO>
                                                value={getValues("accountGroup")}
                                                onChange={onChange}
                                                label={strings.accountGroup}
                                                valueMapper={(item) => item.id.toString()}
                                                keyMapper={(item) => item.id.toString()}
                                                itemMapper={(item) => <>{item.name}</>}
                                                labelMapper={(item) => item.name}
                                                dataFetcher={(page, size, filter) => {
                                                    return AccountGroupService.getAllAccountGroupsPaged(page, size, filter, getValues("serviceId"));
                                                }}
                                                inputProps={{
                                                    variant: undefined,
                                                    error: errors.accountGroup !== undefined,
                                                    helperText: errors.accountGroup !== undefined ? errors.accountGroup.message : "",
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                                <FormControl
                                    fullWidth
                                    className="mb-3"
                                    sx={{ textAlign: "left" }}
                                >
                                    <InputLabel
                                        shrink={
                                            getValues("regionId")
                                                ? true
                                                : false
                                        }
                                    >
                                        {strings.region}
                                    </InputLabel>
                                    <Controller
                                        name="regionId"
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <Select
                                                value={value}
                                                onChange={onChange}
                                                input={
                                                    <OutlinedInput
                                                        label={strings.region}
                                                        notched={
                                                            checkValueOfRegion()
                                                        }
                                                    />
                                                }
                                                endAdornment={
                                                    getValues("regionId") && (
                                                        <IconButton
                                                            onClick={() =>
                                                                setValue("regionId", "")
                                                            }
                                                        >
                                                            <Clear />
                                                        </IconButton>
                                                    )
                                                }
                                            >
                                                {regions!.map((reg) => (
                                                    <MenuItem key={reg.id} value={reg.id}>
                                                        {reg.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                    />
                                </FormControl>
                                <FormControl
                                    fullWidth
                                    sx={{ textAlign: "left" }}
                                    className="mb-3"
                                >
                                    <InputLabel
                                        shrink={!!getValues("ageGroupId")}
                                    >
                                        {strings.ageGroup}
                                    </InputLabel>
                                    <Controller
                                        name="ageGroupId"
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <Select
                                                value={value}
                                                onChange={onChange}
                                                input={
                                                    <OutlinedInput
                                                        label={strings.ageGroup}
                                                        notched={
                                                            getValues("ageGroupId") ? true : false
                                                        }
                                                    />
                                                }
                                                endAdornment={
                                                    getValues("ageGroupId") && (
                                                        <IconButton
                                                            onClick={() =>
                                                                setValue("ageGroupId", "")
                                                            }
                                                        >
                                                            <Clear />
                                                        </IconButton>
                                                    )
                                                }
                                            >
                                                {ageGroup!.map((agc) => (
                                                    <MenuItem key={agc.id} value={agc.id}>
                                                        {agc.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                    />
                                </FormControl>
                                <FormControl
                                    fullWidth
                                    sx={{ textAlign: "left" }}
                                    error={errors.parentAccount !== undefined}
                                >
                                    <Controller
                                        name="parentAccount"
                                        rules={{
                                            required: false,
                                        }}
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <PaginatedSelect<AccountDTO>
                                                value={value}
                                                onChange={onChange}
                                                label={strings.parentAccount}
                                                valueMapper={(item) => item.id.toString()}
                                                keyMapper={(item) => item.id.toString()}
                                                itemMapper={(item) => <>{item.email}</>}
                                                labelMapper={(item) => item.email}
                                                dataFetcher={(page, size, accountFilter) => {
                                                    return AccountService.getAllAccountsPaged(page, size, new AccountFilter(accountFilter, getSelectedServiceId()), null);
                                                }}
                                                inputProps={{
                                                    variant: undefined,
                                                    error: errors.parentAccount !== undefined,
                                                    helperText: errors.parentAccount !== undefined ? errors.parentAccount.message : "",
                                                }}
                                            />

                                        )}
                                    />
                                </FormControl>

                                <FormControl
                                    className="mt-3"
                                    fullWidth
                                    sx={{ textAlign: "left" }}
                                    error={errors.proxy !== undefined}
                                >
                                    <Controller
                                        name="proxy"
                                        rules={{
                                            required: false,
                                        }}
                                        control={control}
                                        render={({ field }) => (
                                            <PaginatedSelect<ProxyDTO>
                                                value={getValues("proxy")}
                                                onChange={field.onChange}
                                                label={strings.proxy}
                                                valueMapper={(item) => item.id.toString()}
                                                keyMapper={(item) => item.id.toString()}
                                                itemMapper={(item) => <>{item.name}</>}
                                                labelMapper={(item) => item.name}
                                                dataFetcher={(page, size, filter) => {
                                                    return ProxyService.getAllProxiesPaged2(
                                                        page,
                                                        size,
                                                        filter,
                                                        null,
                                                        getValues("regionId")
                                                    );
                                                }}
                                                inputProps={{
                                                    variant: undefined,
                                                    error: errors.proxy !== undefined,
                                                    helperText:
                                                        errors.proxy !== undefined
                                                            ? errors.proxy.message
                                                            : "",
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                                <Controller
                                    name="loginUsername"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            className="mt-3"
                                            InputLabelProps={{ shrink: getValues("loginUsername") !== undefined }}
                                            {...field}
                                            value={field.value ?? ""}
                                            fullWidth
                                            id={"loginUsername"}
                                            label={strings.loginUsername}
                                            error={errors.loginUsername !== undefined}
                                        />)}
                                />
                                <Controller
                                    name="loginPassword"
                                    control={control}
                                    render={({ field }) => (
                                        <VisibilityEye
                                            getValue={getValues("loginPassword") !== undefined}
                                            value={getValues("loginPassword") ?? ""}
                                            className={"w-100 mt-3"}
                                            required={false}
                                            onChange={field.onChange}
                                            label={strings.loginPassword}
                                        />
                                    )}
                                />
                                <Controller
                                    name="recoveryEmail"
                                    control={control}
                                    rules={{
                                        pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            InputLabelProps={{ shrink: getValues("recoveryEmail") !== undefined }}
                                            {...field}
                                            value={field.value ?? ""}
                                            fullWidth
                                            id={"recoveryEmail"}
                                            label={strings.recoveryEmail}
                                            error={errors.recoveryEmail !== undefined}
                                        />)}
                                />
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Controller
                                        name="activationDate"
                                        control={control}
                                        render={({ field }) => (
                                            <DatePicker
                                                className="mt-3"
                                                label={strings.activationDate}
                                                value={field.value || null}
                                                inputFormat="DD.MM.YYYY"
                                                onChange={(date) => field.onChange(date)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        error={errors.activationDate !== undefined}
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Grid>
                                {(!isAddMode && getValues("serviceId")) &&
                                    <Grid item xs={12} sm={4} md={6}>
                                        <Box>
                                            <input type="file" onChange={handleFileSelect} className="mb-3" />
                                            <Button variant="contained" startIcon={<FileUploadIcon />}
                                                    disabled={!selectedFile} onClick={uploadFile}>{strings.upload}</Button>
                                            <TableContainer>
                                                <Table sx={{ minWidth: 500 }}
                                                       aria-label="custom pagination table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>{strings.cookieCollection}</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {cookieCollection?.map((cookie) => (
                                                            <TableRow key={cookie.id}>
                                                                <TableCell scope="row">
                                                                    {cookie.name}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <TablePagination
                                                rowsPerPageOptions={[5, 10, 25]}
                                                component="div"
                                                count={totalElements}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                            />
                                            <FormControl
                                                fullWidth
                                                sx={{ textAlign: "left" }}
                                                error={errors.cookieCollection !== undefined}
                                            >
                                                <Controller
                                                    name="cookieCollection"
                                                    rules={{
                                                        required: false,
                                                    }}
                                                    control={control}
                                                    render={({ field: { onChange, value } }) => (
                                                        <PaginatedSelect<CookieCollectionDTO>
                                                            value={value}
                                                            onChange={onChange}
                                                            label={strings.cookieCollection}
                                                            valueMapper={(item) => item.id.toString()}
                                                            keyMapper={(item) => item.id.toString()}
                                                            itemMapper={(item) => <>{item.name}</>}
                                                            labelMapper={(item) => item.name}
                                                            dataFetcher={(page, size) => {
                                                                return CookieService.getAllCollectionsPaged(page, size, id ? +id : undefined);
                                                            }}
                                                            inputProps={{
                                                                variant: undefined,
                                                                error: errors.cookieCollection !== undefined,
                                                                helperText: errors.cookieCollection !== undefined ? errors.cookieCollection.message : "",
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                            <Button variant="contained" startIcon={<FileDownloadIcon />}
                                                    onClick={exportCookieCollections}
                                                    className="mb-3 mt-3">{strings.exportCookieCollectionButton}</Button>
                                        </Box>
                                        <hr/>
                                        <Box>
                                            <input type="file" onChange={handleFingerprintSelect} className="mb-3" />
                                            <Button variant="contained" startIcon={<FileUploadIcon />}
                                                    disabled={!selectedFingerprint} onClick={uploadFingerprint}>{strings.upload}</Button>
                                            <TableContainer>
                                                <Table sx={{ minWidth: 500 }}
                                                       aria-label="custom pagination table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>{strings.fingerprints}</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {fingerprints?.map((fingerprint) => (
                                                            <TableRow key={fingerprint.id}>
                                                                <TableCell scope="row">
                                                                    {fingerprint.name}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <TablePagination
                                                rowsPerPageOptions={[5, 10, 25]}
                                                component="div"
                                                count={totalFingerprintElements}
                                                rowsPerPage={rowsPerPageFingerprints}
                                                page={fingerprintPage}
                                                onPageChange={handleChangePageFingerprints}
                                                onRowsPerPageChange={handleChangeRowsPerPageFingerprints}
                                            />
                                            <FormControl
                                                fullWidth
                                                sx={{ textAlign: "left" }}
                                                error={errors.cookieCollection !== undefined}
                                            >
                                                <Controller
                                                    name="fingerprint"
                                                    rules={{
                                                        required: false,
                                                    }}
                                                    control={control}
                                                    render={({ field: { onChange, value } }) => (
                                                        <PaginatedSelect<FingerprintDTO>
                                                            value={value}
                                                            onChange={onChange}
                                                            label={strings.fingerprintCollection}
                                                            valueMapper={(item) => item.id.toString()}
                                                            keyMapper={(item) => item.id.toString()}
                                                            itemMapper={(item) => <>{item.name}</>}
                                                            labelMapper={(item) => item.name}
                                                            dataFetcher={(page, size) => {
                                                                return FingerprintService.getAllFingerprintsPage(page, size, id ? +id : undefined);
                                                            }}
                                                            inputProps={{
                                                                variant: undefined,
                                                                error: errors.fingerprint !== undefined,
                                                                helperText: errors.fingerprint !== undefined ? errors.fingerprint.message : "",
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                            <Button variant="contained" startIcon={<FileDownloadIcon />}
                                                    onClick={exportFingerprint}
                                                    className="mb-3 mt-3">{strings.exportFingerprints}</Button>
                                        </Box>
                                    </Grid>
                                }
                        </Grid>

                        <CardActions className="d-flex p-0 mt-4">
                            {!isAddMode && <Button variant="contained" startIcon={<DeleteIcon />}
                                onClick={handleClickOpen} color={"error"} fullWidth>
                                {strings.deleteAccountButton}
                            </Button>
                            }
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                startIcon={!isAddMode && <Edit />}>
                                {isAddMode ? strings.addAccount : strings.saveChanges}
                            </Button>

                            <AccountModal open={openModal} handleClose={handleClose}
                                handleDelete={deleteAccount} />
                        </CardActions></form>
                }
            </CenteredCardLayout>
            {!isAddMode &&
                <CenteredCardLayout className="mt-3" minWidth={!isAddMode ? "70%" : "40%"}>
                    <div className="d-flex justify-content-md-center align-items-center mb-3"><b>{strings.paymentAccounts}</b></div>
                    <Table sx={{ minWidth: 500 }}
                        aria-label="custom pagination table">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <FormControl fullWidth variant="standard">
                                        <InputLabel id="demo-simple-select-label">{strings.paymentAccountType}</InputLabel>
                                        <Select
                                            defaultValue={""}
                                            value={
                                                paymentAccountTypeId ? String(paymentAccountTypeId) : ""
                                            }
                                            onChange={handleChangePaymentAccountType}
                                            label={strings.paymentAccountType}
                                            labelId="paymentAccountType"
                                            endAdornment={
                                                paymentAccountTypeId && (
                                                    <IconButton
                                                        onClick={() => setPaymentAccountTypeId(undefined)}
                                                    >
                                                        <Clear></Clear>
                                                    </IconButton>
                                                )
                                            }
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {paymentAccountType!.map((pa) => (
                                                <MenuItem key={pa.id} value={pa.id}>
                                                    {pa.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </TableCell>
                                <TableCell>Username
                                    <Input name="Username" onChange={handleChangeUsername} />
                                </TableCell>
                                <TableCell>
                                    Password
                                </TableCell>
                                <TableCell>Phone number
                                    <Input name="phone number" onChange={handleChangePhoneNumber} />
                                </TableCell>
                                <TableCell>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            onChange={handleChangeDateCreated}
                                            value={dateCreated}
                                            label={strings.dateCreated}
                                            inputFormat="DD.MM.YYYY"
                                            renderInput={(params: any) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                />
                                            )}
                                            componentsProps={{
                                                actionBar: {
                                                    actions: ["today"],
                                                },
                                            }}
                                            InputProps={{
                                                endAdornment: dateCreated && (
                                                    <IconButton sx={{ height: "10px", width: "10px" }}
                                                        onClick={() => setDateCreated(null)}>
                                                        <Clear />
                                                    </IconButton>
                                                ),
                                            }}
                                            InputAdornmentProps={{
                                                position: "start",
                                            }}
                                        />
                                    </LocalizationProvider>
                                </TableCell>
                                <TableCell>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            onChange={handleChangeDateModified}
                                            value={dateModified}
                                            label={strings.dateModified}
                                            inputFormat="DD.MM.YYYY"
                                            renderInput={(params: any) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                />
                                            )}
                                            componentsProps={{
                                                actionBar: {
                                                    actions: ["today"],
                                                },
                                            }}
                                            InputProps={{
                                                endAdornment: dateModified && (
                                                    <IconButton sx={{ height: "10px", width: "10px" }}
                                                        onClick={() => setDateModified(null)}>
                                                        <Clear />
                                                    </IconButton>
                                                ),
                                            }}
                                            InputAdornmentProps={{
                                                position: "start",
                                            }}
                                        />
                                    </LocalizationProvider>
                                </TableCell>
                                <TableCell>
                                    <Button onClick={navigateAccount} aria-label="add" variant={"contained"} className={"float-end"} startIcon={<AddIcon />}>
                                        Add
                                    </Button>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ width: '17%' }}>{strings.paymentAccountType}</TableCell>
                                <TableCell sx={{ width: '17%' }}>{strings.username}</TableCell>
                                <TableCell sx={{ width: '17%' }}>{strings.password}</TableCell>
                                <TableCell sx={{ width: '17%' }}>{strings.phoneNumber}</TableCell>
                                <TableCell sx={{ width: '17%' }}>{strings.dateCreated}</TableCell>
                                <TableCell sx={{ width: '17%' }}>{strings.dateModified}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paymentAccounts.map((e: any) => (
                                <TableRow key={e.id} onClick={() => navigateAccountRow(e.id)} hover={true}>
                                    <TableCell>
                                        {e.paymentAccountType !== null ? e.paymentAccountType?.label : " "}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {e.username}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {e.password}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {e.phoneNumber}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {DateTimeUtils.formatDate(e.dateCreated)}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {DateTimeUtils.formatDate(e.dateModified)}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Grid sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end', marginTop: "10px" }}>
                        <PaginationPlus totalElements={totalElements} rowsPerPage={rowsPerPage} page={page} onChangePage={(page) => handleChangePage(null, page)} />
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            count={totalPaymentAccounts}
                            component="div"
                            rowsPerPage={paymentAccountsPerPage}
                            page={paymentAccountsPage}
                            onPageChange={handleChangePages}
                            onRowsPerPageChange={handleChangeRowsPerPages}
                        />
                    </Grid>
                </CenteredCardLayout>
            }
        </>
    )
}
