import {Page} from "../pagination/Page";
import {ClientDTO} from "../models/ClientDTO";
import {Dayjs} from "dayjs";
import {SortingConfiguration} from "../utils/SortingUtils";
import axiosInstance from "../network/AxiosInstance";
import {ClientCreationDTO} from "../models/ClientCreationDTO";
import { ListenedTrackClientInfoDTO } from "../models/ListenedTrackClientInfoDTO";
import { ViewCountGeographicReportDTO } from "../models/ViewCountGeographicReportDTO";
import { TimeElapsedGeographicReportDTO } from "../models/TimeElapsedGeographicReportDTO";
export class ClientService {

    static async getAllClientsPaged(page: number, size: number, name: string | null|undefined, dateCreated: string | null,
                                    dateModified: string | null | Dayjs, sortingConfiguration: SortingConfiguration | undefined): Promise<Page<ClientDTO>> {
        const params = new URLSearchParams({
            page: page.toString(),
            size: size.toString(),
        });

        if (name) {
            params.append("name", name);
        }

        if(dateCreated) {
            params.append("dateCreated", dateCreated.toString());
        }

        if(dateModified) {
            params.append("dateModified", dateModified.toString());
        }

        sortingConfiguration?.appendToBackEndSearchParams(params);

        const response = await axiosInstance.get<Page<ClientDTO>>(
            `/rest/client/client/all-paged`, {params: params},
        );

        const data = response.data;

        return new Page<ClientDTO>(
            data.content.map(client => new ClientDTO(client)),
            data.totalElements,
        )
    }

    static async createClient(client: ClientCreationDTO) : Promise<ClientDTO> {
        const response = await axiosInstance.post<ClientDTO>(
            `/rest/client/client/create`, client
        );

        const data = response.data;
        return new ClientDTO(data);
    }

    static async editClient(client: ClientCreationDTO, id: number | undefined): Promise<ClientDTO>{
        const response = await axiosInstance.put<ClientDTO>(
            `/rest/client/client/update/${id}`, client,
        );

        const data = response.data;
        return new ClientDTO(data);
    }

    static async deleteClient(id: number): Promise<void> {
        await axiosInstance.delete<ClientDTO>(`/rest/client/client/delete/${id}`, {});
    }

    static async getClientById(id: number): Promise<ClientDTO> {
        const response = await axiosInstance.get<ClientDTO>(`/rest/client/client/${id}`,);
        const data = response.data;

        return new ClientDTO(data);
    }

    static async getGeographicReportForClient(id:number | string | undefined):Promise<void> {
        const response = await axiosInstance.get(`/rest/client/client/${id}/geographic-report`,{
            responseType:"blob"
        });
        var blob = response.data;
        let fileName = response.headers["content-disposition"]?.split("filename=")[1].split(".")[0].replace(/"/g,'');
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = fileName? fileName : ""
        alink.click();
      }

      static async getStatisticReportForClient(id:number | string | undefined):Promise<void> {
        const response = await axiosInstance.get(`/rest/client/client/${id}/statistics-report`,{
            responseType:"blob"
        });
        var blob = response.data;
        let fileName = response.headers["content-disposition"]?.split("filename=")[1].split(".")[0].replace(/"/g,'');
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = fileName? fileName : ""
        alink.click();
      }


    static async getListenedTracksClientPaged(page:number, size:number,id:number | string | undefined, serviceId:number | string | undefined, trackName: string | undefined, sortingConfiguration: SortingConfiguration | undefined):Promise<Page<ListenedTrackClientInfoDTO>> {
        const params = new URLSearchParams({
            page: page.toString(),
            size: size.toString(),
        });
        if(serviceId) {
            params.append("serviceId", serviceId.toString());
        }
        if(trackName){
                params.append("trackName", trackName);
        }
        sortingConfiguration?.appendToBackEndSearchParams(params);
        const response  = await axiosInstance.get<Page<ListenedTrackClientInfoDTO>>(`/rest/client/client/${id}/listened-tracks-statistics`, {params: params})
        const data = response.data;

        return new Page<ListenedTrackClientInfoDTO>(
            data.content.map(track => new ListenedTrackClientInfoDTO(track)),
            data.totalElements
        )
    }

    static async getGeographicReportListenedTrackViewCount(page:number, size:number,id:number | string | undefined, serviceId:number | string | undefined, trackName: string | undefined, artistName: string | undefined, sortingConfiguration: SortingConfiguration | undefined):Promise<Page<ViewCountGeographicReportDTO>> {
        const params = new URLSearchParams({
            page: page.toString(),
            size: size.toString(),
        });
        if(serviceId) {
            params.append("serviceId", serviceId.toString());
        }
        if(trackName){
                params.append("trackName", trackName);
        }
        if(artistName){
            params.append("artistName", artistName);
    }
        sortingConfiguration?.appendToBackEndSearchParams(params);
        const response  = await axiosInstance.get<Page<ViewCountGeographicReportDTO>>(`/rest/client/client/${id}/geographic-report-listened-track-views-count`, {params: params})
        const data = response.data;

        return new Page<ViewCountGeographicReportDTO>(
            data.content.map(track => new ViewCountGeographicReportDTO(track)),
            data.totalElements
        )
    }

    static async getGeographicReportListendedTrack(id:number | string | undefined):Promise<void> {
        const response = await axiosInstance.get(`/rest/client/client/${id}/geographic-report-listened-track`,{
            responseType:"blob"
        });
        var blob = response.data;
        let fileName = response.headers["content-disposition"]?.split("filename=")[1].split(".")[0].replace(/"/g,'');
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = fileName? fileName : ""
        alink.click();
      }

    static async getGeographicReportListenedTrackTimeElapsed(page:number, size:number,id:number | string | undefined, serviceId:number | string | undefined, trackName: string | undefined, artistName: string | undefined, sortingConfiguration: SortingConfiguration | undefined):Promise<Page<TimeElapsedGeographicReportDTO>> {
        const params = new URLSearchParams({
            page: page.toString(),
            size: size.toString(),
        });
        if(serviceId) {
            params.append("serviceId", serviceId.toString());
        }
        if(trackName){
                params.append("trackName", trackName);
        }
        if(artistName){
            params.append("artistName", artistName);
    }
        sortingConfiguration?.appendToBackEndSearchParams(params);
        const response  = await axiosInstance.get<Page<TimeElapsedGeographicReportDTO>>(`/rest/client/client/${id}/geographic-report-listened-track-time-elapsed`, {params: params})
        const data = response.data;

        return new Page<TimeElapsedGeographicReportDTO>(
            data.content.map(track => new TimeElapsedGeographicReportDTO(track)),
            data.totalElements
        )
    }
}
