import { Box, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { GuardedComponent } from "../../components/GuardedComponent";
import { SideBarPage } from "../../components/SideBarPage";
import { strings } from "../../localization/Localization";
import { Privileges } from "../../models/nomenclatures/Privileges";
import { PrivilegeGuard, PrivilegeGuardMode } from "../../router/guards/PrivilegeGuard";
import { GeographicViewCount } from "../listenedTrackStatistics/GeographicViewCount";
import { GeographicTimeElapsed } from "../listenedTrackStatistics/GeographicTimeElapsed";
import { ListenedTrackStatistics } from "../listenedTrackStatistics/ListenedTrackStatistics";
import {ComparingViews} from "./ComparingViews";

export function ClientDashboard() {
    const [value, setValue] = useState('one');
    const [activeTab, setActiveTab] = useState(1)
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const navigate = useNavigate();

    return (
        <SideBarPage pageTitle={strings.clientDashboard} component={
            <>
                <Box sx={{ width: '100%' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        textColor="primary"
                        indicatorColor="primary"
                        aria-label="secondary tabs example"
                    >
                        <Tab value="one" label={strings.listenedTrackStatistics} onClick={() => setActiveTab(1)} />

                        <Tab value="two" label={strings.geoViewsCountReport} onClick={() => setActiveTab(2)} />

                        <Tab value="three" label= {strings.geoTimeElapsedReport} onClick={() => setActiveTab(3)} />

                        <Tab value="four" label={strings.comparingViews} onClick={() => setActiveTab(4)} />


                    </Tabs>
                </Box>
                {
                    activeTab === 1 &&
                    <div>
                        <GuardedComponent
                            component={
                                <ListenedTrackStatistics />
                            }
                            guards={
                                [
                                    new PrivilegeGuard(
                                        [Privileges.READ_CLIENT_REPORT],
                                        PrivilegeGuardMode.hasAny,
                                    )
                                ]
                            }
                            fallback={
                                <Box />
                            }
                        />
                    </div>
                   
                }
                {
                activeTab === 2 &&
                <div>
                <GuardedComponent
                    component={
                        <GeographicViewCount />
                    }
                    guards={
                        [
                            new PrivilegeGuard(
                                [Privileges.ADMINISTER_USER],
                                PrivilegeGuardMode.hasAny,
                            )
                        ]
                    }
                    fallback={
                        <Box />
                    }
                />
            </div>
                }
                {
                activeTab === 3 &&
                    <div>
                <GuardedComponent
                    component={
                        <GeographicTimeElapsed />
                    }
                    guards={
                        [
                            new PrivilegeGuard(
                                [Privileges.READ_CLIENT_REPORT],
                                PrivilegeGuardMode.hasAny,
                            )
                        ]
                    }
                    fallback={
                        <Box />
                    }
                />
                    </div>
                }
                {
                    activeTab === 4 &&
                  <>
                    <GuardedComponent component={<ComparingViews />} guards={
                        [
                            new PrivilegeGuard(
                                [Privileges.READ_CLIENT_REPORT],
                                PrivilegeGuardMode.hasAny,
                            )
                        ]
                    } fallback={
                        <Box />
                    } />
                  </>
                }
            </>
        } />
    )
}
